.content {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--color-info-4);
  border: 1px solid var(--color-primary-2);

  .questionImagesThumb {
    margin-top: 10px;

    ul {
      display: grid;
      justify-content: center;
      gap: 20px;
      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .fullImageIcon {
      border-radius: 7px;
      padding: 7px;
      background: var(--color-primary-2);
    }

    li {
      align-self: baseline;
      display: grid;
      text-align: right;

      .description {
        color: var(--color-black-80);
        word-break: break-word;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
}

