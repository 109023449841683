.emptyChapterCardBlock {
  font-family: "Manrope", sans-serif;
  display: block;
  text-align: center;
  margin: 20px 0;

  .emptyChapterCardImg {
    width: 100%;
    max-width: 400px;
  }

  .text {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: var(--color-black-100);
  }

}