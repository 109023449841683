.text {
  color: var(--color-text);
  font-size: 16px;
  line-height: 2.5;

  input {
    border: 1px solid var(--color-outline);
    border-radius: 3px;
    font-family: inherit;
    height: 35px;
    padding: 0 10px;
    text-align: center;
    width: 130px;
  }
}
