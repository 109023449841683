.modal-selectors {
  width: 100%;
  select {
    background: white url('../../img/select-down.svg') center right 10px no-repeat;
    background-size: 8px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    margin-bottom: 0;
    margin-top: 10px; // не везде есть отступ сверху, позиционирование должно задаваться во внешнем компоненте
    height: 45px;
    padding-bottom: 3px;
    padding-left: 19px;
    padding-right: 30px;
    border-radius: 5px;
    font-size: 14px;
    -webkit-appearance: none;
    width: 230px; // не везде ширина фиксированная, в большинстве случаев она плавающая

    &:nth-child(even) {
      float: right;
    }
  }

  &--active{
    transition: .2s;
  }

  &--disabled {
    background-color: #e2e2e2 !important;
    color:  #000;
    opacity: 1; // Webkit добавляет select:disabled {opacity: 0.7}
    transition: .2s;
  }

  &--error select {
    border: 1px solid #d80027 !important;
  }
}

.optionBox{
  height: 50px;
}
