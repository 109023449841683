.selectWords {
  color: var(--color-black-100);
  font-size: 18px;
  line-height: 40px;

  .container {
    padding: 0 5px;
    display: inline-block;
    vertical-align: baseline;
    line-height: 23px;
  }
}
