.client_title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text);
}

.label {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text);

  span {
    color: var(--color-error);
  }
}

.custom_margin + div {
  margin-top: 7px;
}

.inputs_wrapper {
  margin-top: 20px;
}

.phone {
  order: 2;
  input {
    border-radius: 0 5px 5px 0;
  }
}

.birthday {
  order: 6;
  &.guardian {
    order: 2;
  }
  select {
    text-align: left;
  }
}

.firstname {
  order: 1;
}
.level {
  order: 3;
}
.lastname {
  order: 4;
}
.email {
  order: 5;
}
.fathername {
  order: 7;
}
.inn {
  order: 8;
}
.sex {
  order: 10;
  &.guardian {
    order: 6;
  }
}
.add1 {
  order: 9;
}
.add2 {
  order: 11;
}
.add3 {
  order: 12;
}

.firstname, .lastname, .fathername, .phone, .email, .inn, .add1, .add2 {
  flex-basis: 100%;
  @media (min-width: 1024px) {
    flex-basis: calc((100% - 310px) / 2 - 20px);
  }
}

.level, .birthday, .sex, .add3, .inn {
  flex-basis: 100%;
  @media (min-width: 1024px) {
    flex-basis: 310px;
  }
}

.error_list {
  margin-top: 20px;
  padding: 20px 10px;
  border-radius: 5px;
  background-color: var(--color-error-light);
}

.error_item {
  color: var(--color-error);
  &:not(:last-of-type) {
    margin-bottom: 7px;
  }
}

.error_name {
  font-weight: 500;
}
