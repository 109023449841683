.main-table {


  &__header {
    display: flex;
    flex-direction: row;
    color: #3f4d63;
    border-bottom: 2px solid #80868b;
    font-weight: 600;
    font-size: 15px;
    padding: 17px 10px;
  }

  &__column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    cursor: pointer;
  }

  &__column:hover {
    opacity: .8;
  }

  &__column:nth-child(even) {
    background-color: #f6f6f7;
  }

  &__body th {
    color: #3f4d63;
    border-bottom: 2px solid #80868b;
    font-weight: 600;
    font-size: 15px;
    padding: 17px 10px;
  }

  &__body td {
    padding: 15px 10px;
    color: #5f6368;
    font-size: 13px;
    vertical-align: middle;
    min-width: 150px;
  }

  &__body td:last-child {
    min-width: 100px;
    /*max-width: 150px;*/
    text-align: center;
  }

  &__svg {
    transition: all .2s ease;
    cursor: pointer;
  }

  &__spoiler {
    height: 100px;
    transition: all .2s ease;
    max-width: 500px;
    opacity: 1;
  }

  &__count {
    height: 45px;
    background-color: #3474ff;
    color: #fff;
    font-weight: 600;
    display: flex;
    padding-left: 20px;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin: 20px 10px;

    span {
      cursor: pointer;
    }

  }

  // Нет записей
  &__empty-result {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-style: italic;
    height: 100px;
    justify-content: center;
    text-align: center;
  }
}

.rotate {
  transition: all .2s ease;
  transform: rotate(-90deg);
}