.page {
    background-color: white;
    background-image: url("../../Auth/bg.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    padding: 40px 15px;
    text-align: center;
    @media (max-width: 767px) {
        padding: 20px 15px;
    }
    img {
        width: 135px;
        height: 180px;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            width: 100px;
            height: 130px;
            margin-bottom: 30px;
        }
    }
    & .content {
        h1 {
            color: #d9dbe0;
            font-size: 270px;
            font-weight: 600;
            line-height: 0.7;
            margin-bottom: 30px;
            @media (max-width: 767px) {
                font-size: 150px;
            }
        }
        & a {
            font-size: 20px;
            font-weight: 500;
            color: #254b77;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        div {
            max-width: 450px;
            width: 100%;
            margin: 0 auto;
            background: #d9dbe0;
            height: 1px;
            margin-bottom: 15px;
        }
        .subtitle {
            font-size: 40px;
            font-weight: 700;
            color: #3f4d63;
            margin-bottom: 15px;
            @media (max-width: 767px) {
                font-size: 25px;
            }
        }
        .text {
            font-size: 20px;
            color: #7b7b7b;
            margin-bottom: 25px;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
}
