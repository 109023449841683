.quantityVisitsDetails {
    width: 150px;
    text-align: center;
    font-size: 14px;
    position: absolute;
    background-color: var(--color-black-0);
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 3;

    &:before {
        background-color: var(--color-black-0);
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        content: "";
        display: block;
        height: 10px;
        left: calc(50% - 5px);
        position: absolute;
        top: -6px;
        transform: rotate(45deg);
        width: 10px;
    }
}

.pointer {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}