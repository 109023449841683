.duration{
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    overflow: hidden;
    height: 45px;
    display: flex;
    flex-direction: row;
    input{
        border: none !important;
        width: calc(100%/4) -50px !important;
        border-radius: 0px;
        cursor: pointer;
    }
    &--br{
        border-right: 1px solid #bdbdbd;
    }
    &--bl{
        border-left: 1px solid #bdbdbd;
    }
    &__delimiter{
        width: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #bdbdbd;
        color: #fff;
    }
}