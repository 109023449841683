.wrapper {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #bdbdbd;
    border-radius: 5px;
    overflow: hidden;
    @media (max-width: 767px) {
        flex-direction: column;
        height: 80px;
    }
    p {
        background: #ededed;
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: solid 1px #bdbdbd;
        color: #5f6368;
        width: 40%;
        @media (max-width: 767px) {
            width: 100%;
            border-bottom: solid 1px #bdbdbd;
            border-right: none;
        }
    }
    input {
        width: 60%;
        height: 100%;
        border: none;
        padding: 0 10px;
        @media (max-width: 767px) {
            width: 100%;
            text-align: center;
        }
    }
    &.hover {
        border: 1px solid #0062ba;
        box-shadow: 0 0 0 3px #b8d4ff;
    }
    &.error {
        border: solid 1px #db3031;
        input {
            &::placeholder {
                color: #db3031;
            }
        }
        &.hover {
            box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.3);
        }
    }
}
