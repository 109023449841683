.box {
    height: 64px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #2d8cff;
    color: rgb(255, 255, 255);
    padding: 10px;
    cursor: pointer;
    gap: 5px;
    img {
        min-width: 40px;
        height: 40px;
    }
    svg {
        min-width: 15px;
        height: 16px;
    }
}
.client {
    justify-content: center;
    padding: 0;
    img {
        margin-right: 10px;
    }
}
.content {
    text-align: center;
    p {
        &:nth-child(1) {
            font-size: 16px;
            font-weight: 500;
        }
        &:nth-child(2) {
            font-size: 12px;
        }
    }
}
