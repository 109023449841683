.confirmBox {
    background: white;
    border: 1px solid gray;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    &__img {
        margin-bottom: 25px;
        width: 93px;
    }

    &__title {
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 15px;
        color: #3f4d63;

        &_success {
            color: #00a42a;
            font-weight: bold;
            font-size: 30px;
            margin-bottom: 15px;
        }
    }

  &__text {
    color: #4c4c4c;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
  }

  &__number {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #4c4c4c;
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

    &__change {
        margin-bottom: 20px;

        a {
            color: #4c4c4c;
            font-weight: 600;
        }

        border-bottom: 2px solid #4c4c4c;
        ;
    }

    &__btn {
        width: 100%;
        max-width: 450px;
        font-weight: 500;
        padding: 16px;
    }

    &__btn:disabled,
    &__btn[disabled] {
        width: 100%;
        max-width: 450px;
        font-weight: 500;
        padding: 16px;
        // background: #888888;
        background-color: transparent !important;
        border: 2px solid #959595 !important;
        color: #959595 !important;
        cursor: default;

        &:hover {
            color: black;
        }
    }
}

.input__box {
    display: flex;
    margin-bottom: 30px;
    gap: 20px;

}

.display_none {
    display: none;
}

.error {
    color: #db3031;
    margin-bottom: 20px;
    font-size: 13px
}

.alert {
    color: black;
    margin-bottom: 20px;
    font-size: 13px
}

.input {
  padding: 10px 0;
  font-size: 14px;
  width: 22px;
  border: 2px solid #888888;
  border-radius: 10px;
  color: #495057;
  text-align: center;
  @media (min-width: 768px) {
    padding: 20px 15px;
    font-size: 30px;
    width: 55px;
  }
}