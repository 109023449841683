.asideModal {
  background: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(2px);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 61px;
  z-index: 999;

  &__center {
    align-items: center;
    display: flex;
    justify-content: center;

    .modalHeader {
      border-radius: 7px 7px 0 0;
    }

    .modalWindow {
      border-radius: 7px;
      height: auto;
      max-height: 80vh;
      opacity: 1;
      transform: translateY(0);

      &Hide {
        opacity: 0;
        transform: translateY(-100%);
        transition: .3s;
      }
    }

    .modalFooter {
      border-radius: 0 0 7px 7px;
    }
  }
}
.modalWindow {
  background-color: var(--color-black-0);
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  transform: translateX(0);
  transition: transform .3s;
  width: 320px;
  &Responsive {
    @media (min-width: 768px) {
      width: 620px;

      .modalFooter {
        display: flex;
        justify-content: space-between;

        button {
          width: 120px;
        }
      }
    }
  }
}
.modalWindowHide {
  transform: translateX(-100%);
  transition: transform .3s;
}
.modalHeader {
  background-color: var(--color-primary-1);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}
.modalTitle {
  color: var(--color-black-0);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.32px;
}
.modalBody {
  overflow-y: auto;
  //max-height: calc(100vh - 200px);
  padding: 20px 15px 3px;

  &::-webkit-scrollbar {
    display: none;
  }
}
.modalFooter {
  display: grid;
  gap: 44px;
  grid-template-columns: 1fr 1fr;
  padding: 20px 15px;

  button {
    user-select: none;
    width: 100%;
  }
}
