.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 50px);
}
.rules {
  background-color: #fff;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 478px;
  width: 100%;
  position: relative;
  padding: 10px;
  @media (min-width: 768px){
    height: 700px;
  }
  @media (min-width: 1024px){
    padding: 50px;
  }
  h1 {
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  img {
    position: absolute;
    right: 20px;
    top: 80px;
    width: 113px;
    @media (max-width: 767px) {
      display: none;
    }
    @media (min-width: 768px){
      right: 0;
      top: 108px;
      width: 292px;
    }
    @media (min-width: 1024px){
      top: 0;
      width: 426px;
    }
  }
}

.title {
  color: #445084;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  text-transform: uppercase;
  @media (min-width: 768px){
    font-size: 45px;
  }
  b {
    display: block;
    font-size: 28px;
    font-weight: 700;
    @media (min-width: 768px){
      font-size: 60px;
    }
  }
}

.rule {
  color: #445084;
  font-size: 14px;
  line-height: 1.55;
  font-weight: 500;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  @media (min-width: 768px){
    font-size: 20px;
  }

  li {
    align-items: center;
    display: flex;

    &::before {
      background-color: #445084;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      margin: 0 5px;
      width: 10px;
    }
  }

}

.denied {
  color: #ff0000;
  line-height: 1.55;
  margin-top: 50px;
}

.deniedTitle {
  font-size: 16px;
  line-height: 1.55;
  font-weight: 700;
  text-transform: uppercase;
  @media (min-width: 768px){
    font-size: 38px;
  }
}

.deniedList {
  li {
    display: flex;

    &::before {
      background-color: #ff0000;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      margin: 5px 5px 0;
      width: 10px;
      @media (min-width: 768px){
        margin-top: 10px;
      }
    }
  }
  div {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.55;
    font-weight: 500;
    @media (min-width: 768px){
      font-size: 20px;
    }

    small {
      color: #445084;
      font-size: 12px;
    }
  }
}

.buttonBox {
  align-items: center;
  bottom: 20px;
  display: flex;
  //width: calc(100% - 100px);
  //justify-content: center;
  flex-direction: column;
  left: 0;
  padding: 0 20px;
  position: absolute;
  right: 0;
}

.count {
  padding-top: 10px;
  color: #445084;
  font-size: 12px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 500;
}

.button {
  border: 2px solid #445084;
  border-radius: 5px;
  cursor: pointer;
  color: #445084;
  font-size: 16px;
  font-family: inherit;
  font-weight: 500;
  line-height: 50px;
  max-width: 382px;
  text-align: center;
  transition: all .2s ease-in-out;
  width: 100%;
  @media (min-width: 768px){
    font-size: 20px;
  }

  &:hover {
    background-color: #445084;
    color: #fff;
  }
}
