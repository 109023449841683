.super-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1009;
  width: 100vw;
  height: 100vh;
  background-color: rgba(50, 50, 50, 0.2);
  transition: 0.3s;
  &--wait {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    padding-top: 20px;
  }
  // Кнопки
  &__buttons {
    align-items: center;
    border-top: 1px solid #e2e2e2;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    padding: 20px;
  }

  &__add-button, &__cancel-button {
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding: 10px 18px;
    transition: .2s;
  }

  // Добавление
  &__add-button {
    border-color: #3f4d63;
    color: #3f4d63;
  }

  // `Отмена`
  &__cancel-button {
    border-color: transparent;
    color: #3f4d63;
    &:hover {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
      color: #5f6368;
    }
  }

  input {
    width: 100%;
    height: 45px;
    color: #5f6368;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    //margin-bottom: 15px; ядреная бомба, взрыватель оставить
    padding-left: 20px;
    &:hover {
      border: 1px solid #bdbdbd;
      box-shadow: 0 0 0 3px #b8d4ff;
      transition: .3s;
    }
    &:focus {
      border: 1px solid #0062ba;
      box-shadow: 0 0 0 3px #b8d4ff;
    }
  }
  .InputFlagPhone__wrapper {
    height: 45px;
  }
  
  &__input {
    &--error {
      border-color: #db3031;
      &:hover, &:focus {
        border-color: #db3031;
        box-shadow: 0 0 0 3px rgba(255, 0, 0, .3);
      }
      &::placeholder {
        color: #db3031;
      }
    }
    &--center {
      text-align: center;
      padding-left: 0;
    }
  }

  input[type=number]{
    padding-left: 0;
    text-align: center;
  }

  &__form-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__form-row2 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  &__form-field {
    display: flex;
    flex-direction: column;
    gap: .5em;

    input {
      margin: 0;
    }
  }

  &__form-label {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;

    &--required {
      &::before {
        color: var(--color-danger-1);
        content: '*';
        margin-right: 5px;
      }
    }
  }

  &__date-picker {
    .react-datepicker-wrapper {
      width: 100%;

      input {
        padding: 0;
        text-align: center;
      }
    }
  }

  .selectCustom {
    input {
      height: auto;
      margin: 0;
      &:hover, &:focus {
        box-shadow: none;
      }
    }
  }
}

.super-modal-inside {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  position: relative;
  flex-direction: column;
  opacity: 1;
  text-align: left;
  transform: scale(1);
  transition: .2s ease-out;
  width: 520px;
  max-height: 90vh;
 
  &--slide-down {
    opacity: 0;
    transform: scale(0);
  }
  @media (max-width: 768px){
    width: 90% !important;
    max-height: 90%;
  }
}
.labelNote{
  display: flex;
  font-size: 12px;
  margin: 15px 5px !important;
  color: #5f6368;
  font-weight: 600;
}

.super-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 20px;
}

.super-modal-title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #5f6368;
  @media (min-width: 768px){
    font-size: 18px;
  }
}

.super-modal-close {
  display: block;
  position: absolute;
  content: '&#215;';
  right: 20px;
  font-size: 40px;
  color: #3f4d63;
  top: 10px;
  font-weight: 600;
  cursor: pointer;
}

.super-modal-content {
  padding: 20px 20px;
  max-height: 80vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  //overflow: scroll;
  //max-height: 500px;
}
.super-modal-content::-webkit-scrollbar { width: 0; }
.super-modal-content-one {
  padding: 20px 20px 0 20px;
}

.contentZoom{
  margin-bottom: 5px;
}

.super-modal-date {
  margin-top: 10px;
  @media (max-width: 768px) {
   display: flex;
  }
}

.super-modal-date span {
  margin-left: 20px;
  @media (max-width: 768px) {
    padding-top: 8px;
  }
}

.super-modal_bold{
  font-size: 12px;
  font-weight: 400;
}

.super-modal-media div {
  //height: 100px;
  //width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //margin-bottom: 20px;
}

.super-modal-media textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 0;
  color: #5f6368;
  font-size: 14px;
  border-color: #bdbdbd;
  box-sizing: border-box;
  resize: none;
  border-radius: 5px;
  padding-left: 20px;
  padding-top: 18px;
}

.super-modal-main-select{
  min-height: 90px;
  display: flex;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  &__icon {
    background-color: #ff9e00;
    border-radius: 5px 0 0 5px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: center;
    align-items: flex-start;
    width: 50px;
    padding-top: 20px;

    img{
      width: 30px;
    }
    svg {
      fill: white;
      width: 30px;
    }
  }
  &__container {
    flex-grow: 1;
    padding: 20px;
  }
}

.super-modal-twin-selectors {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-top: 10px;
  @media (max-width: 768px){
    flex-direction: column;
  }

  .modal-selectors{
    @media (max-width: 768px){
      margin-bottom: 10px;
      select{
        width: 100% !important;
      }
    }
  }
}

.super-modal-errors {
  padding: 20px 20px;
  background-color: #ffdae7;
  color: #9b0b31;
}

.super-modal-errors span:first-child {
  font-weight: 600;
  margin-right: 10px;
}

.super-modal-media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #bdbdbd;
  border-radius: 5px;
  padding: 20px;
  /*input[type="file"] {
    border: none !important;
  }*/
  &--error {
    border-color: #db3031;
  }
}

.super-modal-twin-selectors input {
  width: 230px !important;
  @media (max-width: 768px){
      width: 100% !important;
  }
}

.super-modal-twin-selectors input[type="number"] {
  height: 44px;
  -moz-appearance: textfield;
  margin-bottom: 20px;
  padding-left: 20px;

}

.super-modal-twin-selectors-number {
  text-align: left !important;
}

.reactCropImage {
  border-radius: 10px;
  max-width: 500px;
  max-height: 500px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.finishImg {
  display: flex;
  justify-content: center;
  padding-top: 15px
}

.finishImg img {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  border-radius: 10px
}

input[type="number"] {
  height: 44px;
  -moz-appearance: textfield;

}

.super-modal-informer-text {
  line-height: 30px;
}

.addMediaTextarea{
  width: 100%;
  margin-top: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  resize: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  &.super-modal__textarea--error {
    border-color: #db3031;
    &:hover, &:focus {
      border-color: #db3031;
      box-shadow: 0 0 0 3px rgba(255, 0, 0, .3);
    }
    &::placeholder {
      color: #db3031;
    }
  }
}

textarea::placeholder {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
.modal-line{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  label{
    position: absolute;
    top: -10px;
  }
  &_note{
    width: 50%;
    text-align: right;
    padding-bottom: 10px;
    font-weight: 600;
  }
  padding: 10px 0;
}
.super-modal-many-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.super-modal-many-buttons-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 20px;
}

.super-modal-many-buttons-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.super-modal-many-buttons button {
  margin-left: 20px;
  margin-bottom: 30px;
}

.super-modal-group-form-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.super-modal-group-form-inputs select {
  margin-top: 20px !important;
}

.super-modal-group-form-inputs input[type="text"] {
  width: 240px !important;
  margin-bottom: -10px;
}

.super-modal-group-form-inputs textarea {
  margin-top: 20px;
  height: 150px;
}

.super-modal-line {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.super-modal-simple-media{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #bdbdbd;
  border-radius: 5px;
  &--error {
    border: 2px dashed #db3031;
  }
  &--hide{
    height: 0 !important;
    overflow: hidden;
    transition: .2s ease;
  }
  &__cover{
        background-color:rgba(0, 0, 0, 0.6);
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        height: 100%;
        transition: all .2s ease-in-out;
        width: 100%;
        img{
          cursor: pointer;
          width: 40px !important;
          height: 40px !important;
          transition: .3s;
        }
        img:hover{
          height: 55px;
          width: 55px;
          transition: .3s;
      }
  }
  &__img-box{
    display: flex;
    position: relative;
    width: 100%;
    max-height: 250px;
    overflow: hidden;
    border-radius: 5px;
    &--delete{
            display: flex;
            align-items: center;
            justify-content: center;
            content: '&#215;';
            font-size: 40px;
            height: 50px;
            color: #3f4d63;
            top: 20px;
            font-weight: 600;
            cursor: pointer;
    }
  }
  img{
    height: 100%;
    width: 100%;
  }

}

.w220 input {
  max-width: 240px;
}

.super-modal select {
  margin-top: 0 !important;
  &:hover {
    border: 1px solid #bdbdbd;
    box-shadow: 0 0 0 3px #b8d4ff;
    transition: .3s;
    cursor: pointer;
  }
  &:focus {
    border: 1px solid #0062ba;
    box-shadow: 0 0 0 3px #b8d4ff;
  }
}

.super-modal-probe{
  //margin-top: 10px;
  //margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
  border: 1px solid #bdbdbd;
  background-color: #e0e7ef;
}
.super-modal-notice{
  color: #5f6368;
  font-size: 12px;
  //margin-top: 10px;
  p {line-height: 15px;
    padding-bottom: 5px;
    font-weight: 500;
  }
}
.many_uploads{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  textarea{
    max-height: 100px !important;
    min-height: 100px !important;
    resize: none;
  }
  img {
    object-fit:cover;
    width: 120px;
    height: 100px;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 20px;
  }
}

.cogo_toast_error {
  padding: 0;
}
.cogo_toast_message {
  padding-left: 40px;

  position: relative;
  display: flex;
  flex-direction: column;

  svg {
    position: absolute;
    left: 0;
    top: 0;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.32px;
  }
  p {
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.28px;
    margin-bottom: 24px;
  }
}
.ct-toast {
  max-width: 416px;
  width: calc(100vw - 20px);
  padding: 32px 32px 24px 32px!important;
  border-left: none !important; 
  border-radius: 7px !important;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08) !important;
    & > svg {
      display: none;
    }  
}
.cogo_toast_button {
  margin-left: auto;
}
.ct-text-group {
  margin-left: 0;
  width: 100vw;
}
.ct-group {
  margin-top: auto;
  margin-bottom: auto;
}
.ct-group > *:not(:first-child) {
  display: none;
}
.cogo_toast_overlay {
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.2);
}

