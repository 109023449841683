.clientProfile {
  display: grid;
  font-family: 'Manrope', sans-serif;
  gap: 20px;
  padding: 20px 10px;
  @media (min-width: 768px) {
    padding: 20px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 256px 1fr;
  }
}
.clientProfileMain {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.menu {
  @media (min-width: 1024px) {
    display: none;
  }
}