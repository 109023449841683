$days:  (6, var(--color-black-20), var(--color-black-40) ),
        (2, #FFF9E9, #FFD252),
        (holiday, #057fed, #0062ba),
        (4, #FDEFFC,#E26BF5),
        (3, var(--color-success-4), var(--color-success-2)),
        (5, var(--color-success-4), var(--color-success-2)),
        (11, var(--color-black-40), var(--color-black-20)),
        (9, var(--color-black-20), var(--color-black-40)),
        (8, var(--color-black-20), var(--color-black-40)),
        (otpuskbs, var(--color-success-4), var(--color-success-2));
