.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text);
}

.button {
  position: relative;
  width: 50px;
  height: 30px;
  padding: 5px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0;
  border: none;
  background-color: #eeeeee;
  transition: background-color 0.3s;

  &:hover {
    background-color: #dedede;
  }

  &::before {
    content: '';
    position: absolute;
    top: 12px;
    right: 5px;
    border: 4px solid transparent;
    border-top: 8px solid black;
    transition: transform 0.3s;
  }

  &__open::before {
    transform: translateY(-4px) scale(1, -1);
  }
}

.active_color {
  width: 25px;
  height: 20px;
  border: 1px solid black;
}


.colors_list {
  position: absolute;
  width: 115px;
  top: 10px;
  right: 0;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
  list-style: none;
  background-color: #eeeeee;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, top 0.3s, visibility 0.3s;

  &__open {
    top: 35px;
    visibility: visible;
    opacity: 1;
  }
}

.input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.color {
  display: inline-block;
  width: 25px;
  height: 20px;
  border: 1px solid black;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
}
