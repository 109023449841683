.courseLessonCard {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  background-color: #fff;

  &__header {
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    user-select: none;
  }

  &__headerSection {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: 500;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    //cursor: pointer;

    a {
      color: #222222;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__arrows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40px;
    width: 20px;
    margin-right: 10px;

    &--hide {
      cursor: default;
      opacity: 0;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &:first-child {
        transform: rotate(180deg);
      }
    }
  }

  &__pic {
    background-color: #dddddd;
    border-radius: 5px;
    height: 50px;
    width: 50px;
    margin-right: 20px;
    overflow: hidden;

    img {
      height: 50px;
      width: 50px;
    }

    div {
      align-items: center;
      color: white;
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;

      svg {
        fill: currentColor;
        height: 70%;
        width: 70%;
      }
    }
  }

  &__body {
    border-top: 1px solid #bdbdbd;
    color: #5f6368;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 1em;
    line-height: 1.5;
    padding: 1em 20px;
  }

  &__unit {
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    span:first-child {
      min-width: 200px;
      font-weight: 500;
    }
  }
}