.wrapper {

}

.tabs {
  margin: 0 0 10px;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  list-style: none;

  @media (max-width: 639px) {
    flex-wrap: wrap;
  }
}
