.searchList {
    background: var(--color-black-0);
    border: solid 1px var(--color-primary-2);
    border-radius: 5px;
    border-top: solid 1px var(--color-black-60);

    &__btn {
        width: 100%;
        border: none;
        border-top: solid 1px var(--color-black-60);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-black-80);
        font-size: 12px;
        font-weight: 700;
        gap: 5px;

        svg {
            width: 10px;
            height: 10px;
        }
    }
    &__item {
        padding: 10px;
        position: relative;
        border-bottom: solid 1px var(--color-black-60);
        display: flex;
        flex-direction: column;
        gap: 5px;
        z-index: 900;

        &:nth-last-child(1) {
            border-bottom: none;
        }
        &:hover {
            background-color: var(--color-primary-4);
        }
    }
    &__title {
        display: flex;
        gap: 13px;
        p {
            &:nth-child(1) {
                color: #111314;
                font-weight: 600;
                font-size: 14px;
                word-break: break-word;
            }
            &:nth-child(2) {
                font-size: 12px;
                color: var(--color-black-80);
            }
        }
    }
    &__description {
        font-size: 12px;
        color: var(--color-black-80);
        display: flex;
        gap: 10px;
    }
    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 13px;
        color: var(--color-black-80);
        cursor: pointer;
    }
    &__warning {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-black-80);
        font-weight: 600;
    }
}
