.footer {
  align-items: center;
  background-color: var(--color-primary-4);
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media (min-width: 1024px) {
    justify-content: space-between;
    order: -1;
  }
}
.logoLanguageRow {
  @media (min-width: 1024px) {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
  }
}
.footerLogo {
  width: 34px;
  img {
    width: 100%;
  }
}
.language {
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
}
.promo {
  margin-top: 38px;
  width: 175px;
  @media (min-width: 1024px) {
    margin-top: 0;
    width: 350px;
  }
  @media (min-width: 1440px) {
    width: 500px;
  }
  @media (min-width: 1920px) {
    width: 650px;
  }

  img {
    width: 100%;
  }
}
.copyrightDomain {
  align-items: center;
  color: var(--color-black-80);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  gap: 12px;
  margin-top: 27px;
  @media (min-width: 1024px) {
    margin-top: 0;
  }
}
.domain {
  color: var(--color-primary-2);
}
