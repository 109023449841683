.card {
  border-radius: 5px;
  background-color: inherit;
}

.header {
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  user-select: none;

  &:hover {
    background-color: var(--color-primary-4);
  }
}

.section {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  width: 100%;
  text-align: left;

  .chapterName {
    width: inherit;
    color: var(--color-black-100);
    font-size: 16px;
    font-weight: 500;
    padding-right: 8px;
  }
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  width: 20px;
  margin-right: 10px;
}

.arrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  * {
    fill: var(--color-black-60);
  }
}

.arrowRotate {
  transform: rotate(180deg);
}

.arrowHide {
  cursor: default;
  opacity: 0;
}

.pic {
  background-color: var(--color-primary-4);
  border-radius: 5px;
  margin-right: 20px;

  .noPhoto {
    height: 48px;
    width: 48px;
  }

  img {
    object-fit: cover;
    border-radius: 5px;
    height: 48px;
    width: 48px;
  }

  div {
    align-items: center;
    color: white;
    display: flex;
    justify-content: center;

    svg {
      width: 21px;
    }
  }
}

.exercisesCountBadge {
  min-width: 120px;
  border: 1px solid var(--color-success-1);
  background: inherit;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.42px;
  display: none;
  @media (min-width: 768px) {
    display: inherit;
    padding: 5px;
  }
  @media (min-width: 1024px) {
    padding: 8px 16px;
  }

  &:hover {
    border: 1px solid var(--color-success-1);
    background: inherit;
  }
}

.buttonsBlock {
  width: max-content;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  gap: 10px;
  @media (min-width: 768px) {
    width: 100%;
    gap: 20px;
  }

  .linkBtn {
    height: 30px;
    width: 30px;
    padding: 0;
    background: var(--color-black-0);
    @media (min-width: 768px) {
      width: max-content;
      padding: 7px 20px;
    }
  }

  .linkText {
    display: none;
    @media (min-width: 768px) {
      display: inherit;
    }
  }
}