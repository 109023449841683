.rows {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1em 20px;
}
.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  span {
    color: var(--color-text);
  }
}
.button {
  background-color: #007be8;
  border: 2px solid #0069d2;
  border-radius: 5px;
  color: white;
  line-height: 26px;
  padding: 0 15px;
  &:hover {
    background-color: #fff;
    color: #007be8;
  }
}
