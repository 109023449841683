.questions, .question, .answers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.question {
  border-radius: 6px;
  border: 1px dashed var(--color-black-60);
  background: var(--color-black-10);
  padding: 10px;
}

.questionText {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.28px;
}

.answer {
  align-items: center;
  display: flex;
  gap: 10px;

  svg {
    width: 16px;
  }
}

.test {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.questionHeader {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  word-break: break-word;
}

.scoreBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 6px;
  border: 1px dashed var(--color-warning-1);
  background: var(--color-warning-4);
  color: var(--color-warning-1);
  font-size: 18px;
  font-weight: 800;
  line-height: 17px;
  letter-spacing: 0.36px;
  height: fit-content;
  word-break: initial;

  svg {
    width: 17px;
    height: 17px;
  }

  path {
    fill: var(--color-warning-1);
  }
}

.paramsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px dashed var(--color-warning-1);
  background: var(--color-warning-4);
  color: var(--color-black-80);
  font-size: 16px;

  &.paramsBox:has(.time){
    justify-content: flex-start;
  }
  &.paramsBox:has(.points){
    justify-content: flex-end;
  }
  .boxInfo {
    font-weight: 700;
  }
  .values {
    display: flex;
    align-items: center;
    gap: 15px;
  }

}
.paramsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px dashed var(--color-warning-1);
  background: var(--color-warning-4);
  color: var(--color-black-80);
  font-size: 16px;

  &.paramsBox:has(.time){
    justify-content: flex-start;
  }
  &.paramsBox:has(.points){
    justify-content: flex-end;
  }
  .boxInfo {
    white-space: nowrap;
    font-weight: 700;
  }
  .paramsValues {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  path {
    fill: var(--color-black-80);
  }

}

.results {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
  border: 1px dashed var(--color-success-1);
  background: var(--color-success-4);
  color: var(--color-black-80);

 &.mistake {
   border: 1px dashed var(--color-danger-1);
   background: var(--color-danger-4);
 }
  .resultsTitle {

    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .resultValues {
   display: flex;
   justify-content: space-between;
    .valueTitle {
      line-height: normal;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.24px;
    }
    .resultValues {
      line-height: normal;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.36px;
    }
  }
}

.timerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 25px 0;
  border-radius: 6px;
  line-height: 28px;
  text-align: center;
  border: 1px dashed var(--color-primary-2);
  background: var(--color-success-4);
  color: var(--color-black-80);

  h3 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.44px;
    color: var(--color-primary-1);;
  }

  .showModalBtn {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.42px;
    width: 160px;
    margin-top: 10px;
    padding: 10px 15px;
    border-radius: 7px;
    border: 0;
    background: var(--color-primary-3);
    color: var(--color-black-0);

    &:hover {
      background: var(--color-primary-2);;
    }
  }

  .timerBoxCols {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    max-width: 350px;
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 20px;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.24px;
      color: var(--color-primary-1);
    }

    .value {
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 0.32px;
      color: var(--color-primary-2);
    }

    .verticalLine {
      width: 60px;
      height: 1px;
      background: var(--color-info-3);
      @media (min-width: 768px) {
        height: 30px;
        width: 1px;
      }
    }
  }
}

.testModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 20px 10px;
  max-width: 300px;
  width: calc(100% - 10px);
  background-color: var(--color-black-0);
  border-radius: 7px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  @media (min-width: 768px) {
    padding: 20px;
    width: 100%;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.4px;
    padding: 16px 0 8px 0;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.42px;
    color: var(--color-black-80);
  }

  .btns {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    @media (min-width: 768px) {
      gap: 0px;
    }

    .btn {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.42px;
      padding: 10px 5px;
      border: none;
      border-radius: 7px;
      @media (min-width: 768px) {
        padding: 10px 15px;
      }
    }

    .cancel {
      color: var(--color-black-60);
      border: 1px solid var(--color-black-60);

      &:hover {
        color: var(--color-black-80);
        border: 1px solid var(--color-black-80);
        box-shadow: inset 0 0 0 1px var(--color-black-80);
        background: var(--color-black-0);
      }
    }

    .start {
      color: var(--color-black-0);;
      background-color: var(--color-primary-3);
      max-width: 120px;
      width: 100%;
      @media (min-width: 768px) {
        min-width: 160px;
      }

      &:hover {
        background-color: var(--color-primary-2);
      }
    }

  }
}