.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 26px;
  font-weight: 700;
  gap: 20px;
  justify-content: space-between;
  padding-bottom: 30px;
  position: relative;
  @media (min-width: 768px) {
    flex-direction: row;
    padding-bottom: 0;
  }
}

.headerNotPaid {
  color: #cc0000;
}

.headerPaid {
  color: #3c763d;
}

.updateStatus{
  color: #959595;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
}

.checkStatusProcess{
  color: #959595;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  -webkit-animation: blink 1.4s linear infinite;
  animation: blink 1.4s linear infinite;
}

.headerCancelled {
  color: #999999;
}

.statusDate {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 2em;
}

.cancelBtn {
  bottom: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  right: 0;

  &:hover {
    text-decoration: underline;
  }
}

.body {
  background-color: #f2f4f6;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 2.5em;
  padding: 30px 20px;

  h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 1em 0 1.5em;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  li {
    align-items: center;
    display: flex;
    gap: 20px;
    &:last-child {
      font-weight: 700;
    }

    span {
      &:first-child {
        order: 1;
      }
      &:last-child {
        order: 3;
      }
    }

    &::after {
      background-color: #e2e2e2;
      content: '';
      display: block;
      flex-grow: 1;
      height: 1px;
      order: 2;
    }
  }
}