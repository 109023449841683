.days {
  display: grid;
  flex-wrap: wrap;
  gap: 2px;
  grid-template-columns: repeat(8, 1fr);
}
.day {
  align-items: center;
  border: 1px solid #919396;
  color: #5f6368;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 28px;
  justify-content: center;
  user-select: none;
}
.transferDate {
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  display: flex;
  height: 45px;
  overflow: hidden;

  &Text {
    align-items: center;
    background-color: var(--color-outline);
    color: #fff;
    display: flex;
    flex-shrink: 0;
    font-size: 12px;
    padding: 0 10px;
    text-align: center;
    width: 100px;
  }

  & :global(.dataPickerSplit) {
    border: none;
  }

  :global(.react-datepicker__input-container) {
    height: 100%;
    input {
      border: none;
      border-radius: 0;
      height: 100%;
      margin-bottom: 0;
      padding: 0;
      text-align: center;
      &:hover, &:focus {
        box-shadow: none;
        border: none;
      }
    }
  }
}
.flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.teachersClassrooms {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .sumContainer {
    display: flex;
    gap: 10px;

    .sumDymmyCounter {
      max-width: 155px;
    }

    .sumMoneyBox {
      max-width: 135px;
    }
  }

}