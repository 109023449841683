.wrapper {
  border-radius: 5px;
  background-color: white;
}

.with_icon {
  display: flex;
  border: 1px solid var(--color-outline);

  .color_list {
    padding-right: 20px;
  }
}

.info_icon {
  width: 75px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff9e00;
  border-radius: 5px 0 0 5px;

  svg {
    fill: white;
    display: block;
    height: 40px;
    width: 40px;
  }
}

.color_list {
  padding: 20px 0;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 170px));
  justify-content: space-between;
  gap: 20px;
}

.color_item {
  min-width: 110px;
  display: flex;
  align-items: center;
}

.color {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border: 1px solid var(--color-text);
  border-radius: 5px;
}

.color_label {
  font-weight: 500;
}
