.grid {
  width: 100%;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  list-style: none;

  @media (max-width: 767px) {
    &:last-of-type {
      margin-bottom: 10px;
    }
  }
}

.grid__employees {
  flex-grow: 1;

  @media (max-width: 767px) {
    flex-basis: 100%;
  }
}

.grid__price {
  flex-basis: 20%;

  @media (max-width: 767px) {
    flex-basis: 100%;
  }
}

.grid__type {
  flex-basis: 30%;

  @media (max-width: 767px) {
    flex-basis: 100%;
  }
}

.grid__buttons {
  width: 50px;
  justify-self: end;
  align-self: center;
}

.default_wrapper {
  display: flex;
  flex-direction: column;
}

.default_label {
  margin: 0 0 7px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text);
}

.default_employee {
  height: 45px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid var(--color-outline);
}

.grid__buttons_mandatory {
  padding-top: 15px;
  justify-self: start;

  @media (max-width: 767px) {
    padding-top: 0;
  }
}

.button {
  position: relative;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  transition: box-shadow 0.3s, opacity 0.3s;
  cursor: pointer;

  &:hover {
    background-color: white;
  }

  &:focus-visible {
    box-shadow: 0 1px 0 3px white, 0 1px 1px 5px #b8d4ff;
  }

  &:active {
    opacity: 0.7;
  }

  &:first-of-type {
    margin-right: 10px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    left: 0;
    border-radius: 2px;
    background-color: var(--color-accent-light);
  }

  &::after {
    top: 50%;
    transform: rotate(90deg);
  }
}

.button__remove {

  &::before,
  &::after {
    height: 2px;
    background-color: black;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
