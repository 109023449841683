.modalCompany {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    &__field {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        &:nth-child(1) {
            width: 64%;
            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
        &:nth-child(2) {
            width: 31.5%;
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        input {
            margin: 0;
        }
    }
    &__box {
        height: 45px;
        display: flex;
        width: fit-content;
        align-items: center;

    }
}

.line {
    width: 100%;
    height: 1px;
    background-color: var(--color-black-40);;
}