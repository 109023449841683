
.box {
  position: relative;
}

.preview {
  align-self: stretch;
  border-radius: 5px;
  position: relative;
  div {
    img {
      border-radius: 6px;
    }
  }

  &:hover {
    .iconBox {
      opacity: 1;
      display: none;
      @media (min-width: 320px) {
        display: flex;
      }
    }
  }
}

.iconBox {
  align-items: center;
  bottom: 0;
  content: "";
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
  gap: 20px;
  background: rgba(235, 250, 250, 0.50);
  backdrop-filter: blur(2px);
  border-radius: 5px;

  svg {
    cursor: pointer;
    fill: white;
    height: 26px;
    transition: 0.3s;
    width: 26px;
  }
}

.fullImageIcon {
  border-radius: 7px;
  padding: 7px;
  background: var(--color-primary-2);
}

.viewIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    fill: var(--color-primary-2);
    width: 40px;
    height: 40px;
  }
}
