.day-info {
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  color: #5f6368;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  opacity: 0;
  padding: 1em 20px;
  position: absolute;
  transition: opacity .3s;
  z-index: 1;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }

  &::before {
    content: '';
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    right: 0;
    top: -10px;
  }

  b {
    color: #3f4d63;
    font-weight: 500;
  }

  &__lesson-duration {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  &__lessons {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: .5em;
    //line-height: 2;
  }

  &__duration {
    font-style: italic;
    margin-left: 20px;
    //line-height: 14px;
    //position: absolute;
    //right: 20px;
    //top: 20px;
  }

  &__teacher {
    font-style: italic;
    margin-top: .5em;
    p{
      line-height: 18px;
    }
  }

  &__text {
    font-style: italic;
    line-height: 1.5;
    margin-top: 1em;
    max-width: 320px;
  }

  &__params {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 20px;
    max-width: 600px;
  }

  &__param {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    line-height: 1.5;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    &:first-child {
      margin-top: 0;
    }

    b {
      min-width: 160px;
    }
  }

  &__arrow {
    background-color: #fff;
    border-left: 1px solid #bdbdbd;
    border-top: 1px solid #bdbdbd;
    display: block;
    height: 12px;
    left: 0;
    position: absolute;
    top: -7px;
    transform: rotate(45deg);
    width: 12px;
  }

  &__button {
    display: block;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    //max-width: 320px;
    padding: 0 20px;
    width: 100%;
  }
}
