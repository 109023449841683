.page {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 15px;
}
.questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--color-black-0);
  padding: 20px;
  border-radius: 7px;
  border: 1px solid var(--color-black-40);
}
.btnContainer {

  .btn {
    width: 100%;
    border: 1px solid var(--color-black-60);
    color: var(--color-black-60);
    height: 36px;
    padding: 0;
    background: var(--color-black-0);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.42px;
  }

  :hover {
    color: var(--color-primary-2);
    background: var(--color-black-0);
    border: 1px solid var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
  }
}