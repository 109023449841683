.container {
  align-self: stretch;
  border-radius: 5px;
  position: relative;

  div {
    img {
      border-radius: 6px;
    }
  }

  &:hover {
    .iconBox {
      opacity: 1;
      display: none;
      @media (min-width: 320px) {
        display: flex;
      }
    }
  }
}

.box {
  position: relative;
}

.preview {
  align-self: stretch;
  border-radius: 5px;
  position: relative;

  div {
    img {
      border-radius: 6px;
    }
  }

  &:hover {
    .iconBox {
      opacity: 1;
      display: none;
      @media (min-width: 320px) {
        display: flex;
      }
    }
  }
}

.iconBox {
  align-items: center;
  bottom: 0;
  content: "";
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
  gap: 20px;
  border-radius: 5px;

  svg {
    cursor: pointer;
    transition: 0.3s;
  }
}

.fullImageIcon {
  border-radius: 7px;
  padding: 7px;
}

.viewIcon {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;
  bottom: 10px;
  color: var(--color-black-60);
  padding: 5px;
}

.listSearchPlus {
  height: 30px;

  svg {
    padding: 3px;
    width: 30px;
    height: 30px;
  }
}
