.box {
    border-radius: 7px;
    padding: 17px 21px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;

    &__top {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;

        @media (min-width: 768px) {
            display: flex;
            flex-direction: row;
            gap: 0;
        }
    }
    &__title {
        display: flex;
        gap: 15px;
        align-items: flex-start;

        a {
            color: var(--color-primary-1);
            font-weight: 600;
            align-self: flex-start;

            &:hover {
                text-decoration: underline;
                text-decoration-color: var(--color-primary-1);
            }
        }

        span {
            border-radius: 5px;
            padding: 3px 5px;
            font-size: 8px;
            position: relative;
            top: -5px;
            white-space: nowrap;
        }
    }
    &__company {
        border-radius: 5px;
        border: solid 1px var(--color-black-60);
        background: var(--color-black-0);
        padding: 3px 10px;
        font-size: 10px;
        color: var(--color-black-100);
        align-self: flex-start;

        @media (min-width: 768px) {
            position: absolute;
            top: 11px;
            right: 11px;
        }
    }
    &__middle,
    &__bottom {
        gap: 5px;
        flex-direction: column;

        @media (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
        }

        span {
            margin: 0 5px;
            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }
        p {
            font-size: 12px;
            color: var(--color-black-100);
            margin-bottom: 5px;
            margin-left: 10px;

            @media (min-width: 768px) {
                margin-bottom: 0;
                margin-left: 0;
            }
        }
        .teacher {
            color: var(--color-primary-1);
            font-weight: 500;
        }
        a {
            font-size: 12px;
            color: var(--color-primary-1);


            &:hover {
                text-decoration: underline;
                text-decoration-color: var(--color-primary-1);
            }
        }
    }

    &.in_progress {
        border: 1px dashed #1abc97;
        background: rgba(26, 188, 151, 0.1);
    }
    &.scheduled {
        border: 1px dashed var(--color-primary-1);
        background: rgba(37, 75, 119, 0.1);
    }
    &.formation {
        border: 1px dashed var(--color-warning-1);
        background: rgba(255, 199, 0, 0.1);
    }
    &.finished {
        border: 1px dashed var(--color-danger-1);
        background: rgba(255, 0, 34, 0.1);
    }
    .status {
        color: var(--color-primary-1);
        border: solid 1px var(--color-primary-1);
    }
}
