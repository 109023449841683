.row {
  align-items: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  &:hover {
    background-color: var(--color-primary-4);
  }

  @media (min-width: 768px) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .paramNameLevel {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      color: var(--color-primary-1);
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: .32px;
      text-decoration-line: underline;
      &:hover {
        text-decoration: none;
      }
    }

    span {
      color: var(--color-black-80);
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: .24px;
    }
  }
}
.params {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: auto 1fr auto;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.param {
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    padding: 0 20px;
  }

  &Name {
    color: var(--color-black-100);
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: .24px;
    @media (min-width: 1024px) {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: .28px;
    }
  }
  &Value {
    color: var(--color-success-1);
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: .32px;
  }
}
.paramTime {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    color: var(--color-black-100);
    font-size: 16px;
    line-height: normal;
    letter-spacing: .32px;
  }

  time {
    color: var(--color-black-80);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: .24px;
  }

  b {
    color: var(--color-success-1);
    font-weight: 700;

    &.Danger {
      color: var(--color-danger-1);
    }
  }
}
.separator {
  background-color: var(--color-black-40);
  display: inline-block;
  height: 10px;
  margin: 0 10px;
  width: 1px;
}
.buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.button {
  align-items: center;
  background: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  color: var(--color-black-60);
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
  &:hover {
    border-color: 1px solid var(--color-primary-3);
    color: var(--color-primary-2);
  }
}
