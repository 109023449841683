.list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
  font-size: 12px;

  dt {
    flex-basis: 100%;
    font-weight: 500;

    @media (min-width: 1024px) {
      flex-basis: calc(50% - 20px);
      text-align: left;
    }
  }

  dd {
    padding-bottom: 10px;
    flex-basis: 100%;
    border-bottom: 1px solid var(--color-outline);

    @media (min-width: 1024px) {
      flex-basis: 50%;
      text-align: right;
      border: none;
    }
  }

  .full_width {
    flex-basis: 100%;
  }

  .slots_list {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.slot {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid var(--color-outline);
  text-align: center;
  width: 100px;
}
