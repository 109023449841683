.selectCustom {
  border-radius: 5px;

  &--error & {
    &__control {
      &:hover {
        box-shadow: 0 0 0 3px rgba(255, 0, 0, .3);
      }
    }
  }

  &__control {
    &:hover {
      box-shadow: 0 0 0 3px #b8d4ff;
    }
  }

  &__single-value {
    line-height: 1.5;
  }

  &__option {
    line-height: 1.5;
  }

  &__menu-portal#{&}__menu-portal {
    z-index: 2000;
  }

  & &__control {
    border-radius: 5px;
    cursor: pointer;
    min-height: 48px;
    transition: .3s;
  }

  & &__value-container {
    padding: 0 18px;
  }

  &__menu#{&}__menu {
    border: 1px solid #bdbdbd;
    border-radius: 5px;
  }

  & &__option {
    transition: .2s;
  }

  .loader-main img {
    width: 20px;
  }
}