.content {
  h3 {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.5;
    letter-spacing: .4px;
    margin-bottom: 10px;
  }

  ul, ol {
    margin: 10px 0;
    padding-left: 20px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }
}