.placementTestBundle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #bdbdbd;
  padding-bottom: 13px;
  padding-top: 13px;
  a {
    font-size: 12px;
    font-weight: 400;
    color: black;
    display: flex;
    align-items: center;
    &:hover {
      color: #3f4d63;
      text-decoration: underline;
    }
    svg {
      margin-left: 10px;
    }
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__plus {
    min-width: 20px;
    height: 20px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    &::after, &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      background: #5a8dee;
      transform: translate(-50%, -50%);
    }
    &::after {
      width: 100%;
      height: 3px;
    }
    &::before {
      width: 3px;
      height: 100%;
    }
  }
  &__options {
    margin-left: 15px;
    p {
      color: #5f6365;
      font-size: 14px;
      &:nth-child(1) {
        font-weight: 600;
        margin-bottom: 10px;
      }
      &:nth-child(2) {
        font-weight: 400;
        margin-right: 17px;
        @media screen and (max-width: 500px) {
        font-size: 12px;
        line-height: 1.5;
        }
      }
    }
  }
}
