.groupCard {
  background-color: var(--color-black-0);
  transition: .3s;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  &:nth-child(even):not(&__debtors) {
    background-color: var(--color-black-10);

    &:hover {
      background-color: var(--color-primary-4);
    }
  }

  &__debtors {
    background-color: var(--color-danger-4);
  }

  &__head {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 0;
    
    @media (min-width: 1440px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
    @media (min-width: 1024px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 90px;
    }
  }

  &__info {
    padding: 10px;
  }

  & &__groupName {
    color: var(--color-primary-1);
    font-size: 16px;
    font-weight: 600;
    width: 100%;

    &--debt {
      color: var(--color-danger-1);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__infoBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1em;

    div:last-child {

      font-size: 12px;
    }
  }

  & &__infoBlock {
    color: var(--color-black-100);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    min-width: 50%;


    a {
      color: var(--color-primary-1);
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }

  }

  &__note {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    min-height: 69px;
    align-items: center;
    padding-bottom: 10px;

    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 69px;
      padding: 0;
      width: fit-content;
    }
  }

  &__studentsStats {
    display: flex;
    gap: 10px;
  }

  &__delimiter {
    width: 1px;
    background-color: var(--color-black-60);
  }

  &__stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h4 {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      color: var(--color-black-80);
    }

    span {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__sticker {
    background-color: var(--color-success-4);
    border: 1px dashed var(--color-success-1);
    border-radius: 5px;
    gap: 5px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-black-100);
    line-height: 18px;
    font-size: 12px;
    margin-right: 10px;
    margin-top: 10px;


    @media (min-width: 768px) {
      margin-top: 0;
    }


    h3 {
      font-size: 12px;
      text-transform: uppercase;
    }

    
    &--debt {
      background-color: var(--color-danger-4);
      border: 1px dashed var(--color-danger-1);
    }

    span {
      white-space: nowrap;
    }
  }

  &__teachers {
    display: flex;
    flex-direction: column;
    gap: .5em;
    @media (min-width: 768px) {
      gap: 1em;
    }

    & span a {
      text-decoration: none;
    }

    &>li {
      display: flex;
      flex-direction: column;
      gap: .5em;
      @media (min-width: 768px) {
        flex-direction: row;
      }

      &>span {
        @media (min-width: 768px) {
          width: 200px;
        }
      }
    }
  }

  &__teacherParams {
    display: flex;
    font-size: 12px;
    @media (min-width: 768px) {
      flex-grow: 1;
    }

    &>li {
      width: 25%;
    }
  }

  &__propbka {
    margin-top: 1.5em;
    font-style: italic;
  }

  &:hover {
    background-color: var(--color-primary-4);
  }
}