.blueLine {
  background-color: #3474ff;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  line-height: 1.2;
  padding: 1em 20px;
  @media (min-width: 768px) {
    font-size: 14px;
  }

  b {
    font-weight: 700;
  }
}