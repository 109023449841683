.invoicePage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  &__createdAt {
    color: #6b6b6b;
    font-size: 16px;
  }

  &__invoiceInfo {
    padding: 30px;
  }
  &__cols{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    &:last-child{
      margin-bottom: 0px;
    }
  }
  &__field{
    display: flex;
    flex-direction: column;
    width: 310px;
    p{
      width: 100%;
      display: flex;
    }
  }

  &__field2col{
    display: flex;
    flex-direction: column;
    min-width: calc(100% - ((100% /3))  - 10px);
  }

  &__go {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;

    p {
      text-align: center;
    }

    button {
      font-family: inherit;
      font-weight: 500;
      white-space: nowrap;
      @media (min-width: 768px) {
        width: 400px;
      }
    }
  }

  .checkPayService {
    margin-top: 0;
  }
}