.matchWords {
  position: relative;
  &__words {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    @media (min-width: 768px) {
      gap: 40px;
    }

    @media (min-width: 1024px) {
      gap: 57px;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: stretch;
  }

  &__line {
    svg {
      left: 0;
      height: 100%;
      position: absolute;
      stroke-width: 1px;
      stroke: var(--color-text-accent);
      top: 0;
      width: 100%;
    }
  }

  &__word {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-black-60);
    word-break: break-word;
    padding: 3px 5px;
    line-height: 28px;
    text-align: center;
    border-radius: 6px;
    font-size: 18px;
    color: var(--color-black-100);
    cursor: pointer;
    z-index: 1;

    &_clicked {
      border: solid 1px var(--color-text-accent);
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-black-100);
    line-height: 28px;
  }

  .right {
    border: 2px solid var(--color-success-1);
    background: var(--color-success-4);
  }
  .mistake {
    border: 2px solid var(--color-danger-1);
    background: #ffe7ea;
  }
  .right_line {
    stroke: var(--color-success-1);
  }
  .mistake_line {
    stroke: var(--color-danger-1);
  }
}