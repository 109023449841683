.teacherCard {

  .main-table-card-box__body {
    padding: 0;
  }

  &__summary {
    border-bottom: 1px solid #bdbdbd;
    //border-top: 1px solid #bdbdbd;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 24px;
    padding: 10px;
    @media (min-width: 768px){
      flex-direction: row;
      font-size: 14px;
      padding: 20px;
    }
  }

  &__summaryCol {
    @media (min-width: 768px){
      width: 50%;
    }

    div {
      display: flex;
      white-space: nowrap;
    }

    b {
      font-weight: 500;
      width: 35%;
    }

    span {
      text-align: right;
      width: 15%;
      &:first-of-type {
        width: 5%;
      }
      &:last-of-type {
        width: 10%;
      }
    }

    i {
      margin: 0 1em;
    }
  }

  &__days {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 10px;
    @media (min-width: 768px){
      padding: 20px;
    }

    time {
      font-size: 12px;
      font-weight: 500;
      @media (min-width: 768px){
        font-size: 14px;
      }
    }
  }

  &__slots {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    margin-top: .5em;
    @media (min-width: 768px) {
      gap: 15px;
    }
  }

  &__slot {
    align-items: center;
    border: 1px solid #3f4d63;
    border-radius: 5px;
    color: #3f4d63;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    height: 40px;
    width: calc((100% - 20px) / 2);
    @media (min-width: 768px) {
      width: calc((100% - 60px) / 5);
    }
    @media (min-width: 1024px) {
      width: calc((100% - 105px) / 8);
    }

    &--booked {
      background-color: #b1f5cf;
      border-color: #32b76c;
      color: #4d5055;
    }

    &--deleted {
      background-color: #ffdae7;
      border-color: #d80027;
      color: #000;
    }

    &--hc {
      background-color: #fed76e;
      border-color: #ebc05f;
    }

    &--deleted#{&}--hc {
      background: linear-gradient(to bottom right, #fed76e, #fed76e 49%, #ffdae7 51%, #ffdae7);
      border-color: #d80027;
    }

    sup {
      font-size: 8px;
      margin-bottom: 8px;
    }
  }
}
