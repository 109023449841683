.wrapper {
  position: relative;
  width: 20px;
  height: 20px;

  &:hover,
  &__active {
    .modal_wrapper {
      display: block;
    }
  }
}

.modal_wrapper {
  display: none;
  position: absolute;
  top: 15px;
  right: -50px;
  width: 240px;
  padding: 20px;
}

.modal {
  position: relative;
  padding: 10px;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  content: "";
  background-color: white;
  z-index: 3;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
  }

  &::before {
    top: -12px;
    left: 148px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 12px solid #c5c5c5;
  }

  &::after {
    top: -10px;
    left: 149px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
}

.title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-accent);
}

.text {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  color: var(--color-text);
}
