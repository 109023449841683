.alert {
	display: flex;
	padding: 12px;
	gap: 12px;
	width: 300px;
	background: var(--color-danger-4);
	border: 1px solid var(--color-danger-1);
	box-shadow: 0px 2px 4px rgba(25, 33, 61, 0.08);
	border-radius: 16px;
}

.textContainer {
	display: flex;
	flex-direction: column;
	gap: 2px;

	.title {
		font-weight: 800;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.02em;
		color: var(--color-primary-1);
	}

	.text {
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.02em;
		color: var(--color-black-100);
		overflow: visible;
	}
}
