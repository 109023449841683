.answers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px;
}
.answer {
  display: grid;
  gap: 20px;
  grid-template-columns: 25px 1fr;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
}
.pos {
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 26px;
  }
}
