.calendar {
  width: 100%;
}

.wrapper {
  margin-bottom: 10px;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  background-color: white;
}

.header {
  padding: 20px 10px 20px 20px;
  & h2 {
    width: 60%;
  }
}

.dayslist_wrapper {
  position: relative;
}

.dayslist {
  position: relative;
  min-height: 216px;
  margin: 0 -1px -1px -1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
  border: none;
  border-left: 1px solid var(--color-outline);
  border-top: 1px solid var(--color-outline);
  border-radius: 0 0 5px 5px;

  @media (min-width: 768px) {
    border-top: none;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
