.sentencesBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 10px 10px 10px;

  .sentence {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--color-black-60);
    background: var(--color-black-0);
    color: var(--color-black-100);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.32px;
    word-break: break-word;
  }

  .right {
    border-radius: 6px;
    background: var(--color-success-4);
  }

  .wrong {
    border-radius: 6px;
    background: var(--color-danger-4);
  }
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
}

.arrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  * {
    fill: var(--color-black-60);
  }
}

.arrowRotate {
  transform: rotate(180deg);
}

.arrowHide {
  cursor: default;
  opacity: 0;
}

.right {
  border-radius: 6px;
  background: var(--color-success-4);
}

.wrong {
  border-radius: 6px;
  background: var(--color-danger-4);
}