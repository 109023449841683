.row {
  border-bottom: 1px solid var(--color-black-60);
  color: #5f6368;
  display: grid;
  font-size: 12px;
  cursor: pointer;
  grid-template-columns: 1.5fr repeat(5, 50px);
  min-height: 44px;

  div {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 8px;
    &:first-child {
      font-weight: 500;
      justify-content: flex-start;
      padding-left: 0;
    }
    // &:last-child {
    //   justify-content: flex-end;
    //   padding-right: 0;
    //   text-align: right;
    // }
  }

  svg {
    height: 20px;
    width: 20px;
  }
 
  &Error {
    border-color: var(--color-danger-1);
  }
}
.rowRating {
  text-align: center;
}

.customTextarea {
  &>div {
    margin-top: 0;
  }
}

