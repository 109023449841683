.answerFiles {
  align-items: center;
  border-top: 1px solid var(--color-outline);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.noLine {
 display: none;
 }
.chooseFile {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 1em;
  text-align: center;

  button {
    max-width: 250px;
    width: 100%;
  }

  span {
    color: var(--color-text);
    font-size: 12px;
  }
}
.files {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.file {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: .5em;
}
.fileIcon {
  position: relative;
  span {
    bottom: 6px;
    color: white;
    font-size: 8px;
    left: 12px;
    position: absolute;
    text-transform: uppercase;
  }
}
.fileName {
  color: var(--color-text);
  font-size: 12px;
  font-weight: 500;
}
