.timetables {
  border-bottom: 1px solid var(--color-outline);
  display: flex;
  flex-direction: column;
  gap: .5em;
  padding: 0 20px;
}

.addBtn {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 36px;
  height: 36px;
  justify-content: center;
  padding: 0;
  color: var(--color-primary-3);
  border: 1px solid var(--color-primary-3);
  border-radius: 7px
;
  svg {
    width: 22px;
    height: 22px;
  }
  &:hover {
    color: var(--color-primary-2);
    background: var(--color-black-0);
    border: 1px solid var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
  }

  @media (min-width: 768px) {
    padding: 7px 10px;
    width: auto;
  }
}

.headerBtnName {
  display: none;
  @media (min-width: 768px) {
    display: block;
    font-weight: 700;
  }
}