.questions-box {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__content {
    padding: 0 20px;
    .react-player {
      width: 100% !important;
      max-width: 400px !important;
    }
  }

  &__text {
    color: #3c3c3c;
    font-size: 24px;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  &__image {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;

    img {
      border-radius: 5px;
      display: block;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  &__variants {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .video-player-box-content, .player-box-content {
    margin-bottom: 20px;
  }
}
