.studentRow {
  align-items: center;
  border-top: 1px solid var(--color-black-60);
  display: grid;
  gap: 10px;
  padding: 10px;
  @media (min-width: 768px) {
    grid-template-columns: 200px 1fr auto;
  }

  .studentName {
    grid-column: 1/3;
    @media (min-width: 768px) {
      grid-column: unset;
    }

    a {
      color: var(--color-primary-1);
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: .42px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.result {
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
}
.markLabel {
  border-radius: 6px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: .42px;
  padding: 5px 10px;
  &Confirm {
    background-color: var(--color-success-4);
    border-color: var(--color-success-1);
    color: var(--color-success-1);
  }
  &Fail {
    background-color: var(--color-danger-4);
    border-color: var(--color-danger-1);
    color: var(--color-danger-1);
  }
  &Revision {
    background-color: var(--color-warning-4);
    border-color: var(--color-warning-1);
    color: var(--color-warning-1);
  }
}
.numberMark {
  border-radius: 6px;
  color: var(--color-black-0);
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .54px;
  padding: 5px 10px;
  &Min {
    background-color: var(--color-danger-1);
  }
  &Mid {
    background-color: var(--color-warning-1);
  }
  &Max {
    background-color: var(--color-success-1);
  }
}
.cellButton {
  place-self: flex-end;
}
