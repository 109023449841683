.box {
    display: flex;
    width: 100%;
    height: 45px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    background: #fff;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #bdbdbd;
    color: #5f6368;
    font-weight: 500;
}

.item:last-child {
    border-right: none;

}

.item.select {
    background-color: #3474ff;
    color: #fff;
}

.item:hover {
    background-color: #3474ff;
    color: #fff;
    cursor: pointer;
}