.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85px;
    height: 24px;
    background: var(--color-black-20);
    padding: 5px 6px;
    border-radius: 5px;

    p {
        color: var(--color-black-100);
        font-weight: 600;
        font-size: 12px;
    }
}