.multiCheckbox {
  .line {
    align-items: center;
    background-color: var(--color-black-0);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 30px;
    transition: all .2s;
    line-height: 16px;
    letter-spacing: 0.42px;
    padding: 0 7px;
    &Selected, &:hover {
      background-color: var(--color-primary-4);
    }
    &Selected span {
      font-weight: 700;
    }
  }

  .checkbox {
    gap: 15px;
  }
}