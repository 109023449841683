.checkboxToggle {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  font-weight: 500;

  &--inline {
    justify-content: flex-start;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;
  }

  &--unselected {
    color: #bdbdbd;
  }

  &__toggle {
    background-color: rgba(189, 189, 189, .3);
    border: 1px solid #bdbdbd;
    border-radius: 9px;
    height: 18px;
    transition: .2s;
    width: 26px;

    &::before {
      background-color: white;
      //border: 1px solid #bdbdbd;
      border-radius: 50%;
      box-shadow: 1px 0 1px 0.5px rgba(0, 0, 0, .12), 2px 4px 6px rgba(0, 0, 0, .2);
      content: '';
      display: block;
      height: 14px;
      margin-top: 1px;
      margin-left: 1px;
      transition: .2s;
      width: 14px;
    }

    &--checked {
      background-color: #007be8;

      &::before {
        margin-left: 9px;
      }
    }
  }

  &__label {
    color: var(--color-text);
    user-select: none;
  }
}