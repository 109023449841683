.employeeContract {
  background-color: var(--color-success-4);
  border-bottom: 1px solid var(--color-black-40);
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 1em 20px;

  h4 {
    color: var(--color-black-100);
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.28px;
    @media (min-width: 768px) {
      font-size: 18px;
      letter-spacing: 0.36px;
    }
  }
}
.body {
  padding: 1em 20px;
}
.bodyMain {
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 250px;
  }
}
.params {
  display: grid;
  gap: 10px;
  @media (min-width: 1440px) {
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr;
  }
}
.param {
  display: flex;
  flex-direction: column;

  span {
    &:first-child {
      color: var(--color-black-80);
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.2px;
      @media (min-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.24px;
      }
    }
    &:last-child {
      color: var(--color-black-100);
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.28px;
    }
  }

  time {
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.28px;
  }
}
.comment {
  padding: 1em 0;
}
