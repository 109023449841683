.counter {
  background-color: var(--color-black-10);
  border-radius: 3px;
  color: var(--color-black-100);
  text-align: center;
  font-size: 12px;
  letter-spacing: .24px;
  line-height: 1;
  padding: 2px 4px;
  position: relative;
  top: 23px
}
