.del {
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  cursor: pointer;
  background: var(--color-black-0);
  transition: .3s;
  path {
    fill: var(--color-black-60);
  }

  &:hover {
    border: 1px solid var(--color-primary-3);
    path {
      fill: var(--color-primary-3);
    }
  }
}
