.page {
  display: grid;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 254px 1fr;
  }
}

.header {
  padding: 10px 10px;
  border-bottom: 1px solid var(--color-black-40);
  font-size: 16px;
  font-weight: 500;
}

.addChapterButton {
  width: 100%;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid var(--color-black-60);
  color: var(--color-black-60);

  &:hover {
    color: var(--color-primary-2);
    background: var(--color-black-0);
    border: 1px solid var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
  }
}

.noRegister {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  svg {
    max-width: 100%;
  }
}

.addChapterButtonNoChapters {
  display: block;
  margin: 0 auto;
  min-width: 100%;
  background: var(--color-primary-1);
  border-radius: 7px;
  color: var(--color-black-0);
  border: none;
  @media (min-width: 768px) {
    min-width: 270px;
  }

  &:hover {
    background: var(--color-primary-2);
  }
}

.btn {
  padding: 20px 10px;
}

.lessons {
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  background-color: var(--color-black-0);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-height: 556px;
}
