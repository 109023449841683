.content {
  padding: 0 15px;
}

.selectors_wrapper {
  margin-bottom: 20px;
  display: grid;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 33% 1fr;
  }
}

.selectors {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button_wrapper {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  order: 3;

  @media (min-width: 768px) {
    order: initial;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  button {
    width: 100%;
  }
}

.teachers_filter {
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    grid-row: 1 / 3;
  }
}

.teachers_filter > div {
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  & > div:first-child,
  & > div:last-child {
    width: 100%;
  }

  & > div:nth-child(2) {
    width: 100%;
    flex-direction: row;
    justify-content: center;

    @media (min-width: 768px) {
      width: 20px;
      flex-direction: column;
      gap: 20px;
    }

    img {
      margin: 0;
    }
  }
}

.teachers_count {
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: white;
  font-weight: 600;
  background-color: var(--color-accent-600);
}

.teachers_list {
  margin-bottom: 20px;
}

.empty {
  padding: 20px 0;
  font-size: 20px;
  font-style: italic;
  text-align: center;
}
