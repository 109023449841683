.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 15px;
}
.total {
  background-color: #fff;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  gap: 1px;
  line-height: 3;
  margin: 0 -15px;
}
.totalRow {
  background-color: var(--color-accent);
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}
