.slider {
  overflow: hidden;
  position: relative;

  ul {
    display: flex;
    position: relative;
    transition: .3s;

    .fullImageIcon {
      border-radius: 7px;
      padding: 7px;
      background: var(--color-primary-2);
    }
  }

  .slideCount {
    text-align: center;
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 5px;
  }

  li {
    color: var(--color-black-80);
    flex: 0 0 100%;
    text-align: right;

    div {
      &:first-child {
        width: 100%;
      }
    }

    .description {
      word-break: break-word;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
    }

    img {
      display: block;
      height: 100%;
      max-inline-size: fit-content;
      width: 100%;
      margin: 0 auto;
    }
  }
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  margin-bottom: 10px;

  .sliderInfo {
    display: flex;
    flex-direction: column;
  }

  .imageLineBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    gap: 5px;

    .imageLine {
      width: 15px;
      height: 3px;
      background: var(--color-black-40);
    }

    .active {
      background: var(--color-primary-2);
    }
  }

  div {
    cursor: pointer;
    transition: .3s;

    &:last-child {
      svg {
        transform: rotate(180deg);
      }
    }

    &[data-disabled="true"]{
      cursor: default;
      opacity: 0.5;
    }
  }
}