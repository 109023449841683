.questionBlock {
    background-color: #f6f6f6;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: relative;
}
.questionDelete{
    position: absolute;
    right: 20px;
    font-weight: 600;
    font-size: 20px;    
    cursor: pointer;
    top: 10px;
}
.pointsRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.pointsRow span {
    color: #5f6368;
    font-weight: 500;
}
.pointsField {
    width: 86px;
}
.answers {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.answer {
    align-items: center;
    display: flex;
    gap: 15px;
    position: relative;
}
.variant {
    flex-grow: 1;
}
.deleteButton {
    cursor: pointer;
    display: block;
    height: 20px;
    position: relative;
    width: 20px;
}
.deleteButton::before, .deleteButton::after {
    background-color: black;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 9px;
}
.deleteButton::before {
    transform: rotate(-45deg);
}
.deleteButton::after {
    transform: rotate(45deg);
}
.deleteButtonHide {
    display: block;
    height: 20px;
    width: 20px;
}
.addButton {
    margin-bottom: 0;
}

.questionBlock.questionBlock input {
    margin-bottom: 0;
}
.questionBlock .modal-question-add-button-add {
    margin-bottom: 0;
}