.questionTest {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__questionText {
    font-size: 18px;
    line-height: 1.2;
  }

  &__answers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 1em;
  }
  &_emptyBox{
    content: "";
    height: 20px !important;
    width: 20px !important;
    margin-right: 0;
    float: left;
    border: 2px solid #3f4d63;
    transition: all .15s ease-out;
    border-radius: 5px;
    background-color: transparent;
  }
  &_variant{
    display: flex;
    align-items: center;
    svg{
      width: 20px;
    }
    div:last-child{
      margin-left: 10px;
    }
  }
  &__timerBox {
    align-items: center;
    background: radial-gradient(circle at top, #ffdb39, #ff5868);
    border-radius: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 50px 0 38px;
    text-align: center;

    h3 {
      font-size: 30px;
      font-weight: 700;
      margin-top: 1em;
    }

    button {
      border-color: white;
      color: #3f4d63;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 700;
      margin-top: 38px;
      &:hover {
        color: white;
      }
    }
  }

  &__timerBoxCols {
    display: flex;
    margin-top: 3em;

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 0 80px;
      &:first-child {
        border-right: 1px solid white;
      }
    }

    b {
      font-size: 40px;
      font-weight: 500;
      margin-top: 18px;
    }
  }
}