.box {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  color: #3f4d63;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 2.5em;
  justify-content: space-between;
  user-select: none;

  div {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: 0 20px;
  }
}
