.container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: var(--color-warning-4);
  border: 1px dashed var(--color-warning-1);
  border-radius: 6px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }

  color: var(--color-black-100);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.54px;

  .date {
    font-weight: 700;
  }

  span {
    font-weight: 500;
  }
}

.main {
  display: flex;
  gap: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 0 10px;
}

