.modal-date-range{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    &__unit{
        &--titles{
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 10px;
            color: #5f6368;
            span:last-child{
                font-weight: 400 !important;
                font-size: 12px;
                cursor: pointer;
                text-decoration: underline;
            }
            span:last-child:hover{
                text-decoration: none;
            }
        }
    }
}