.container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  background: var(--color-black-10);

  @media (min-width: 768px) {
    align-items: center;
  }


  .lessonInfo {
    display: flex;
    flex-direction: column;
    padding: 13px;
    border: 2px solid var(--color-secondary-1);
    border-radius: 6px;
    background: var(--color-secondary-4);
    width: 100%;
    gap: 10px;
    justify-content: space-between;
    
    @media (min-width: 768px) {
      flex-direction: row;
    }


    .item {
      display: flex;
      align-items: center;
      gap: 20px;

      h3 {
        color: var(--color-primary-1);
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.32px;
        text-decoration-line: underline;
      }
    }
  }

  .lessonStatus {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--color-danger-1);
  }
}