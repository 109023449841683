.answer {
  border: 1px solid var(--color-outline);
  border-radius: 5px;
}
.answerFail {
  composes: answer;
  background-color: var(--color-error-light);
  border-color: var(--color-error);
}

.comment {
  border-top: 1px solid var(--color-outline);
  padding: 20px;
}

.comment {
  display: flex;
  gap: 1em;
  flex-direction: column;

  div {
    font-size: 12px;
  }
}
