.container {
  background-color: var(--color-black-10);
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
.text {
  word-break: break-word;
  width: 100%;
  padding: 20px 10px 10px 10px;
  border: 1px solid var(--color-black-60);
  border-radius: 6px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.36px;
}
.example {
  transform: translateX(-50%);
  left: 50%;
  top: -18px;
  height: 36px;
  background-color: var(--color-black-10);
  position: absolute;
  z-index: 2;
  font-weight: 700;
  line-height: 20px;
  border: 1px solid var(--color-black-60);
  border-radius: 6px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}