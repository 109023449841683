.text {
  margin: 0 0 7px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-text);
}

.margin {
  margin-bottom: 15px;
}

.margin_top {
  margin-top: 10px;
}

.warning {
  color: var(--color-error);
}
