.datetime-box{
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    overflow: hidden;
}
.datetime-box input{
    width: 100%;
    height: 46px;
    margin-bottom: 0 !important;
    border: none !important;
    padding-left: 0 !important;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--color-primary-2);
}

.datetime-box:hover {
    box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60);
}

.datetime-box:focus {
    border-color: var(--color-primary-2);
    box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60), inset 0 0 0 1px var(--color-primary-2);
}

.datetime-box-middle{
    min-width: 20px;
    background-color: var(--color-black-40);
    color: var(--color-black-80);
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
       display: none;
       -webkit-appearance: none;
}
