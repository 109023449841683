.button {
  align-items: center;
  border: 2px solid #3f4d63;
  border-radius: 5px;
  color: #3f4d63;
  cursor: pointer;
  display: flex;
  font-size: 2em;
  font-weight: bold;
  height: 45px;
  justify-content: center;
}
