.container {
  overflow: visible;

  ul {
    list-style: disc;
  }

  ol {
    list-style-type: none; 
    counter-reset: my-counter; 
 
    
      > li::before {
      content: counter(my-counter) ".";
      counter-increment: my-counter;
      margin-right: 0.5em;
    }

}

  &:hover {
    border-radius: 7px;
    box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60);
  }

  &:focus-within {
    border-radius: 7px;
    box-shadow: 0 0 0 1px var(--color-primary-2), /* Рамка при фокусе */
    0 0 0 3px rgba(85, 177, 243, 0.60), /* Тень при фокусе */
  }

  &.error {
    border-radius: 7px;
    box-shadow: 0 0 0 1px var(--color-danger-1), /* Рамка при фокусе */
    0 0 0 3px rgba(255, 0, 34, 0.60), /* Тень при фокусе */
  }
  .customTextarea {
    display: flex;
    flex-direction: column;
    width: 100%;

    .bar {
      border: 1px solid var(--color-black-40);
      border-radius: 7px 7px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 10px;

      &.error {

      }
      @media (min-width: 768px) {
        gap: 15px;
        justify-content: flex-start;
      }
    }

    .btn {
      display: flex;
      background-color: var(--color-black-0);
      cursor: pointer;
      transition: .2s ease;
      position: relative;

      &:hover .toolTip:not(.palette) {
        visibility: visible;
      }

      svg {
        user-select: none;
        width: 18px;
        height: 18px;
        color: var(--color-black-80);
        align-self: center;

        &:hover:not(.paletteIcon) {
          color: var(--color-primary-3);
        }
        &.showPalette {
          color: var(--color-primary-3);
        }
      }

      &.btnActive {
        svg {
          color: var(--color-primary-3);
        }
      }

      .toolTip {
        user-select: none;
        visibility: hidden;
        transition: visibility 0.1s ease-in-out;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        width: 129px;
        height: 30px;
        position: absolute;
        top: -35px;
        right: -6px;
        color: var(--color-black-0);
        &.blue {
          background-color: var(--color-primary-3);
        }
        &.palette {
          background-color: var(--color-black-0);
          border: 1px solid var(--color-black-40);
          width: 150px;
          height: 30px;
        }
        &.showPalette {
          visibility: visible;
        }

        border-radius: 7px;
        @media (min-width: 768px) {
          left: -6px;
        }

        &::before {
          content: "";
          position: absolute;
          bottom: -5px;
          right: 10px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid var(--color-primary-3);
          @media (min-width: 768px) {
            left: 10px;
          }
        }
        &.firstFourToolTip {
          left: -6px;
        }
        &.firstFourToolTip::before {
          left: 10px;
        }
        &.palette::before {
          border-top: 5px solid var(--color-black-40);
        }
      }
    }

    .textarea {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      min-height: 100px;
      max-height: 1000px;
      width: 100%;
      border: 1px solid var(--color-black-40);
      border-top: none;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 18px;
      overflow-y: hidden;
      background-color: var(--color-black-0);
      border-radius: 0 0 7px 7px;

      &:empty:not(:focus)::before {
        content: attr(data-text);
        color: #959595;
      }
    }
  }
}

