.box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  color: var(--color-black-100);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: .5em;

  div:first-child {
    font-size: 14px;
    font-weight: 600;
  }
}
.variants {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.variant {
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 20px;
}

.inputWord {
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  height: 48px;
  width: 100%;
  padding-left: 8px;
}