.modalAddPayment {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__wrapper_checkbox {
    display: flex;
    gap: 5px;

  }

  &__checkbox_input {
    border: none;
  }

  &__row {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
  }
  
  .react-datepicker-wrapper {
    width: 100%;
    input {
      text-align: center;
      padding: 0;
    }
  }
  .checkboxToggle {
    justify-content: flex-start;
  }
  &__field {
    display: flex;
    flex-direction: column;
    gap: .5em;
  }

  &__total {
    align-items: center;
    background-color: #b1f5cf;
    border: 1px solid #32b76c;
    color: #5f6368;
    min-height: 45px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 0 20px;
    margin-top: 10px;
    @media screen and (max-width:  767px) {
      display: flex;
      flex-direction: column;
      padding: 5px;
      font-size: 12px;
    }
  }
  input {
    margin-bottom: 0;
    &:disabled {
      background: #ebebeb;
    }
  }
}