.teachers_item {
  position: relative;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--color-outline);

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.card_open {
  .card_header {
    border-bottom-color: var(--color-outline);
  }
}

.card_header {
  min-height: 46px;
  padding: 10px 50px 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid transparent;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  & > p {
    flex: 1 1 100%;
  }
}

.card_header_name {
  font-weight: 600;
}

.card_menu {
  position: absolute;
  right: 20px;
}

.card_body {
  transition: height 0.4s ease-in-out;
}

.data_list {
  min-height: 120px;
  padding: 15px;
  display: grid;
  row-gap: 10px;
  column-gap: 40px;
  grid-template-columns: 1fr;
  border-bottom: 1px solid var(--color-outline);

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    justify-content: space-between;
  }

  li {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;

    @media (min-width: 1024px) {
      gap: 10px;
    }

    &  > span {
      font-weight: 500;
    }
  }
}

.data_time {
  margin-bottom: 5px;
  flex-grow: 1;
  display: flex;
  gap: 14px;
  text-align: right;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    justify-content: flex-end;
  }

  span:nth-child(2) {
    width: 80px;
  }
  span:last-child {
    width: 65px;
  }

  span:not(:last-child) {
    position: relative;

    &::after {
      content: '/';
      position: absolute;
      right: -13px;
      top: 0;
    }
  }
}

.slots_daylist {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.slot_date {
  margin-bottom: 10px;
  font-weight: 500;
}

.slots_list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.slot {
  position: relative;
  width: 100px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  cursor: pointer;
}

.swal_content {
    line-height: 1.5em;
    max-height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    margin-top: 22px;
    &::-webkit-scrollbar { 
        width: 0px;  
        background: transparent; 
    } 
    ul {
        margin-left: 0;
        margin-top: 10px;
        padding-left: 0;
    }
    div {
        padding: 10px;
    }
    span{
        display: block;
        font-size: 14px;
    }
    li {
        font-size: 14px;
        line-height: 1em;
        list-style-type: none;
    }
    a {
        display: block;
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
