.button {
  width: 100%;
  padding: 0;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s;
  background-color: transparent;
  border: none;
  color: var(--color-text);

  &:hover {
    color: var(--color-text);
    opacity: 0.7;
    background-color: transparent;
  }

  &:focus-visible .text{
    box-shadow: 0 0 0 3px #e0e7ef, 0 0 1px 5px var(--color-accent);
  }

  &__up {
    .arrow::before {
      transform: rotate(-45deg);
    }
    .arrow::after {
      transform: rotate(45deg);
    }
  }
}

.text {
  display: inline-block;
  padding: 0 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  border-radius: 5px;
}

.arrow {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 7px;

  &:first-of-type {
    margin-right: -20px;
  }

  &:last-of-type {
    margin-left: -20px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 1px;
    width: 8px;
    height: 2px;
    background-color: var(--color-text-accent);
    border-radius: 2px;
    transition: transform 0.3s;
  }

  &::before {
    left: 0;
    transform: rotate(45deg);
  }
  &::after {
    left: 4px;
    transform: rotate(-45deg);
  }
}
