.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.column {
  flex-direction: column;
}

.gap {
  column-gap: 20px;
  row-gap: 20px;
}

.center {
  justify-content: center;
}
.start {
  justify-content: flex-start;
}
.end {
  justify-content: flex-end;
}

.align_center {
  align-items: center;
}
.align_start {
  align-items: flex-start;
}
.align_end {
  align-items: flex-end;
}
.align_stretch {
  align-items: stretch;
}

@media (max-width: 767px) {
  .md_center {
    justify-content: center;
  }
}
