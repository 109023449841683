.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.container {
  border: 1px solid var(--color-primary-3);
  background: var(--color-primary-4);
  padding: 10px;
  align-items: center;
  border-radius: 8px;

  .audio {
    width: 100%;
  }
  .script {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    font-size: 14px;
    line-height: 1.5;
  }
}

.desc {
  word-break: break-all;
}

.showScriptBtn {
  position: relative;
  cursor: pointer;
  border: 1px solid var(--color-primary-1);
  background-color: var(--color-black-0);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  fill: var(--color-primary-1);
  margin-right: 10px;

  &:hover {
    * {
      fill: #FFFFFF;
    }

    background-color: var(--color-primary-1);
  }
}

.scriptBody {
  border: 1px solid var(--color-black-0);
  position: absolute;
  z-index: 500;
  width: 300px;
  word-break: break-word;
  background-color: var(--color-black-0);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px var(--color-black-60);
  line-height: 1.25;
  top: 40px;
  right: -12px;
  &::after {
    content: '';
    position: absolute;
    right: 17px;
    top: -14px;
    border: 8px solid transparent;
    border-bottom: 8px solid var(--color-black-0);
  }

  p:first-child {
    padding-bottom: 10px;
  }

  transition: opacity .2s ease-in-out;
}

.audioContainer {
  display: flex;
  align-items: center;
}

:root {
  --plyr-audio-controls-background: var(--color-primary-4)
}