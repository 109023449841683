.modalTimeTableDay {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & &__transferDate {
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    height: 45px;
    overflow: hidden;

    & .dataPickerSplit {
      border: none;
    }

    &Text {
      align-items: center;
      background-color: #bdbdbd;
      color: #fff;
      display: flex;
      flex-shrink: 0;
      font-size: 12px;
      padding: 0 10px;
      text-align: center;
      width: 100px;
    }

    .react-datepicker__input-container {
      height: 100%;
      input {
        border: none;
        border-radius: 0;
        height: 100%;
        margin-bottom: 0;
        padding: 0;
        text-align: center;
        &:hover, &:focus {
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  &__weekdays {
    background: #eeeeee;
  }

  &__timeCheckRow {
    align-items: center;
    display: flex;
    font-size: 14px;
    gap: 20px;
  }

  /*&__lineRow {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }*/

  &__gridRow {
    display: grid;
    gap: 20px;

    &--shareSum {
      align-items: center;
      grid-template-columns: 1fr 145px 130px;
    }

    &--col3 {
      grid-template-columns: repeat(3, 1fr);

      .hour-counter {
        width: auto;
      }
    }

    &--teachVsClass {
      grid-template-columns: 2fr 1fr;
    }
  }

  .datetime-box {
    .datetime-box-middle {
      flex-shrink: 0;
      width: 43px;
    }

    input {
      border: none;
      border-radius: 0;
      padding: 0;
      text-align: center;
      width: 100%;
      &:hover, &:focus {
        box-shadow: none;
        border: none;
      }
    }
  }

  .block_Textarea {
    margin-top: 0;
  }

  .selectCustom {
    input {
      height: auto;
      margin: 0;
      &:hover, &:focus {
        box-shadow: none;
      }
    }
  }
}
