.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
  row-gap: 7px;

  &__column {
    flex-direction: column;
    align-items: flex-start;
  }

  &__center {
    justify-content: center;
  }
  &__end {
    justify-content: flex-end;
  }
  &__stretch {
    justify-content: stretch;
  }
}

.label {
  font-weight: 500;
  color: var(--color-text);
}

.inner {
  height: 45px;
  display: flex;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
}

.control {
  width: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: white;
  border: none;
  background-color: var(--color-accent-light);
  transition: opacity 0.3s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      opacity: 0.5;
      background-color: var(--color-accent-light);
    }
  }

  &:hover {
    background-color: var(--color-accent-light);
    opacity: 0.7;
  }

  &__left {
    border-radius: 5px 0 0 5px;
  }

  &__right {
    border-radius: 0 5px 5px 0;
  }
}

.value {
  min-width: 72px;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-right: 1px solid var(--color-outline);
}

.type {
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 400;
  background-color: #d4d4d4;
}

