.box {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 250px;
    min-height: 200px;
    border-radius: 6px;
    border: 1px solid var(--color-black-60, #B2BEC3);
    background: var(--color-black-0, #FFF);
    z-index: 600;
    right: 60px;
    padding: 11px;
    top: -200px;

}

.name {
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: 0.42px;
    margin-bottom: 5px;
    cursor: pointer;
}

.rateList {
    display: flex;
    gap: 4px;
}

.userItem {
    padding: 8px;
}

.redIcon {
    svg {
      
    }
}

.greenIcon {
    svg {
   
    }
}