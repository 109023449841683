.groupSimpleMainBox {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;


    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr ;
    }
    
    @media (min-width: 1440px) {
      grid-template-columns: repeat(5,1fr);
    }
  }

  &__cols {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1440px) {
      grid-template-columns: 1fr 2fr;
    }


  }

  &__col {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;

    &__card {
      border: 1px solid var(--color-black-40);
      border-radius: 7px;
      background: var(--color-black-0);
    }
  }

  &__card {
    background-color: white;
    border: 1px dashed var(--color-primary-2);
    background: var(--color-primary-4);
    border-radius: 7px;
    padding: 5px 20px;
    width: 100%;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__cardHeader {
    align-items: center;
    border-bottom: 1px solid var(--color-black-40);
    color: var(--color-black-100);
    display: flex;
    font-size: 20px;
    font-weight: 500;
    justify-content: space-between;
    padding: 10px 20px;

  }

  // Часы
  &__hours {
    display: flex;

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      text-align: center;
      @media (min-width: 768px) {
        width: 50%;
      }

      &:nth-child(2) {
        border-top: 1px solid #bdbdbd;
        @media (min-width: 768px) {
          border-top: none;
          border-left: 1px solid #bdbdbd;
        }
      }
    }

    span:nth-child(odd) {
      color: #3f4d63;
      font-size: 20px;
      font-weight: 700;
    }

    span:nth-child(even) {
      color: #5f6368;
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .notFoundTeachers {
    color: var(--color-black-100);
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.28px;
  }

  // Параметры
  &__config {
    font-size: 14px;
    padding: 10px 20px;

    &__container {
      display: grid;
      gap: 15px;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }

      @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 294px;

        .itemName {
          color: var(--color-black-80);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.24px;
        }

        .itemValue {
          color: var(--color-black-100);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.28px;
        }
      }
    }

    span {

      a {
        color: var(--color-black-100);
        font-weight: 400;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

    b {
      font-weight: 700;
    }
  }
}

// Money
.groupSimpleBox {
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  border: 1px dashed var(--color-primary-2);


  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 100%;

    h3 {
      color: var(--color-black-80);
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }

    &__valueName {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--color-black-80);


      &__optionValue {
        color: var(--color-black-60);
        font-weight: 400;
      }

      .delimiter {
        width: 1px;
        height: 5px;
        background: var(--color-black-60);
      }

      div {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }

    &__value {
      display: flex;
      gap: 10px;
      font-size: 18px;
      align-items: center;
      font-weight: 800;

      .delimiter {
        width: 2px;
        height: 10px;
        background: var(--color-black-60);
      }
    }
  }

}

.cardHidden {
  display: none;
  
  @media (min-width: 1440px) {
    display: block;
  }
}


// Статистика
.groupSimpleMainBoxStats {
  &__block {
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    flex-direction: column;
    gap: .5em;
    padding: 20px 10px;
    @media (min-width: 768px){
      padding: 20px;
    }

    &:last-child{
      border-bottom: none;
    }

    div {
      display: flex;
      justify-content: space-between;

      &:nth-child(n+2) span:first-child {
        margin-left: 1em;
      }
    }

    span {
      line-height: 20px;
      color: #5f6368;
    }

    span:last-child, b {
      color: #000000;
    }

    b {
      font-weight: bold;
    }
  }
}

// Комментарий к группе
.groupSimpleMainBoxNote {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
}


.progress {
  display: flex;
  width: 100%;
  height: 7px;
  border-radius: 7px;
  background: var(--color-black-60);

  &__completed {
    background: var(--color-success-1);
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  &__remaind {
    background: var(--color-danger-1);
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}

.debtors {
  color: var(--color-danger-1);
}

.debtorsContainer {

}

.studentMoney {
  
  background: var(--color-success-4);
  border: 1px dashed var(--color-success-1);
  &Debtor {
    background: var(--color-danger-4);
    border: 1px dashed var(--color-danger-1);
  }
  
  @media (min-width: 768px) {
    grid-column: 1/3;
  }

  @media (min-width: 1024px) {
    grid-column: unset;
  }
}

.hours {
  background: var(--color-secondary-4);
  border: 1px dashed var(--color-secondary-1);
}

.editBlock {
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  padding: 5px;

  * {
    fill: var(--color-black-80);
  }

  &:hover {
    * {
      fill: var(--color-primary-3);
    }

    border: 1px solid var(--color-primary-3);
    cursor: pointer;
  }
}

.noAddBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-weight: 700;
}

.addButton {
  border: 1px solid var(--color-primary-2);
  border-radius: 7px;
  padding: 10px 15px;
  background: var(--color-black-0);
  color: var(--color-primary-2);
  font-size: 14px;
  font-weight: 700;

  &:hover {
    background: none;
    color: var(--color-primary-2);
    border: 1px solid var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
  }
}

.slider {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px dashed var(--color-primary-2);
  background: var(--color-primary-4);
  border-radius: 7px;
  padding: 5px 20px;

  @media (min-width: 1440px) {
    display: none;
  }
}

.slide {
  display: none;
}

.slide.active {
  display: block;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-black-60);
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  width: 8px;
  height: 8px;
  background-color: var(--color-primary-3);
}