.answers {
    padding: 33px 27px 0;
    width: 100%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &_click {
        cursor: pointer;
    }
    li {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        p {
            margin-right: 10px;
            width: 21px;
            text-align: center;
            color: rgb(0, 0, 0);
            font-size: 20px;
            font-weight: 500;
        }
        svg {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}
