.display_none {
    display: none;
}

.container {
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.content {

    display: flex;

    flex-direction: column;

    &__wrapper {
        border: 1px solid gray;
        border-radius: 6px;
        background: #edebea;
        padding: 30px;
    }
}

.list {
    background: white;
    padding: 15px 80px;
    min-height: 100vh;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

}

.header {
    padding: 15px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;

}

.wrapper_btn {
    display: flex;
    gap: 15px;
    height: 50px;
    padding-top: 0;
}

.print {
    display: none;
}

@media print {
    .notPrint {
        display: none;
    }

    .footer {
        display: none !important;
    }

    .menu-top-box {
        display: none;
    }

    .print {
        display: block;
    }

    .container {
        height: max-content;
    }

    .list {
        box-shadow: none;
        padding: 0px;
    }

}

@media (max-width: 767px) {
    .container {
        grid-template-columns: 1fr;
        gap: 20px;
        height: calc(120vh - 100px);
    }

    .content {
        overflow-x: scroll;
        min-height: 400px;

    }

    .contant__wrapper {
        min-width: 600px;
    }

    .button {
        min-width: 120px;
        text-align: center;
        font-size: 12px;
        padding: 6px 12px;
        flex-grow: 1;
    }
}