.list {
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  column-gap: 20px;
  row-gap: 20px;
}

.margin {
  margin-bottom: 20px;
}
