.homeTasks {

  .list {
    padding: 5px 0;
    @media (min-width: 768px) {
      padding: 10px 10px;
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}

