.showInitTest {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  &_tries {
    width: 120px;
    border-right: 1px solid #bdbddb;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 1px 10px 15px;
    cursor: pointer;
    line-height: 2;
    overflow: auto;
    @media (max-width: 767px) {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #bdbddb;
      border-right: none;
      line-height: 1;
      padding: 10px;
      div {
        margin-right: 22px;
        white-space: nowrap;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;
    //scrollbar-width: none;
    &--selected {
      color: var(--color-accent-600);
      border-right: 3px solid var(--color-accent-600);
      @media (max-width: 767px) {
        border-right: none;
        border-bottom: 3px solid var(--color-accent-600);
      }
    }

    &--unit {
      color: #333333;

      &:hover {
        border-right: 3px solid #bdbddb;
        opacity: 0.9;
      }
    }

    &--disabled {
      color: #bfbfbf;
      border-right: none !important;
      cursor: default !important;
    }
  }

  &_showTries {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  & &__info {
    align-items: center;
    border-bottom: 1px solid #bdbddb;
    display: flex;
    justify-content: space-between;
    line-height: 1.55;
    padding: 1em 20px;
    @media (max-width: 767px) {
     flex-direction: column-reverse;
    }
    &Line {
      display: grid;
      grid-template-columns: 162px 1fr;
      @media (max-width: 767px) {
        margin-bottom: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }

    &Fail {
      display: flex;
      flex-direction: column;
    }

    a {
      color: black;
      text-decoration: underline;
      @media (max-width: 767px) {
        margin-bottom: 13px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__questions {
    border-bottom: 1px solid #bdbddb;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 20px;
    @media (max-width: 767px) {
      padding-left: 25px;
    }
  }

  &__questBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-black-60);
    border-radius: 6px;
    color: var(--color-black-80);
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    line-height: 21px;
    text-align: center;
    user-select: none;
    width: 34px;
    height: 34px;

    &--many {
      color: var(--color-black-80);
      border: 2px solid var(--color-black-60);

      &--selected {
        background-color: var(--color-black-80);
        color: var(--color-black-0);
      }
    }

    &--wrong {
      color: var(--color-danger-1);
      border: 1px solid var(--color-danger-1);
      background-color: var(--color-danger-4);

      &--selected {
        background-color: var(--color-danger-1);
        color: var(--color-danger-4);
      }
    }

    &--correct {
      color: #4caf50;
      border: 1px solid #4caf50;
      background: var(--color-success-4);

      &--selected {
        background-color: var(--color-success-1);
        color: var(--color-black-0);
      }
    }
    &--yellow {
      color: var(--color-warning-1);
      border: 1px solid var(--color-warning-1);
      background: var(--color-warning-4);

      &--selected {
        background-color: var(--color-warning-1);
        color: var(--color-black-0);
      }
    }
  }

  &_questionBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    //height: 400px;
    //overflow: auto;
    &::-webkit-scrollbar {
      width: 6px !important;
      &-thumb {
        background: var(--color-accent-600) !important;
      }
    }
  }
}   

// #3474ff