.placementTestLogoBox {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 310px;
    button {
      font-size: 14px;
      color: #3f4d63;
      font-weight: 500;
      &:hover {
        color: white;
      }
      &:disabled {
        background: #3f4d63;
        color: white;
      }
    }
    input {
      display: none;
    }
  }
  &__logo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 135px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid #bdbdbd;
    text-align: center;
    line-height: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    padding: 5px;
    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
