.container {
	display: flex;
	align-items: flex-end;
	gap: 10px;
	overflow: hidden;
}

.messageContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 12px 18px 6px;
	gap: 6px;
	background: var(--color-black-10);
	max-width: 256px;
	overflow: hidden;
	word-wrap: break-word;
}

.message {
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.02em;
	color: var(--color-black-100);
	max-width: 220px;
}

.userMessage {
	border-radius: 0px 16px 16px 16px;
}

.alienMessage {
	border-radius: 16px 16px 0px 16px;
}

.errorMessage {
	background-color: var(--color-danger-4);
}

.warningMessage {
	background-color: var(--color-warning-4);
}

.date {
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	color: var(--color-black-60);
}

.userAvatar {
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.isRead {
	display: flex;
	gap: 8px;
	align-items: center;

	.readAt {
		font-size: 12px;
		line-height: 16px;
		color: rgba(0, 0, 0, 0.45);
	}
}
