.placementTestInputType {
  width: 100%;
  height: 45px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &--focus {
    border: 1px solid #0062ba;
    box-shadow: 0 0 0 3px #b8d4ff;
  }
  &--error {
    border-color: #db3031;
    &.placementTestInputType--focus {
      border-color: #db3031;
      box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.3);
    }
  }
  &__current {
    align-items: center;
    border-right: 1px solid #bdbdbd;
    cursor: pointer;
    background: #ededed;
    min-width: 76px;
    padding: 0 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &--disabled {
      background-color: #e2e2e2;
    }
    p {
      font-size: 14px;
      color: #5f6368;
      font-weight: 400;
    }
    &::after {
      content: "";
      border: 4px solid transparent;
      border-top-color: #5f6368;
      height: 8px;
      margin-left: 5px;
      margin-top: 5px;
      width: 8px;
    }
  }
  &__dropdown {
    background-color: white;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    left: 0;
    padding: 5px 0;
    position: absolute;
    top: 50px;
    width: 76px;
    z-index: 1;
    li {
      cursor: pointer;
      line-height: 30px;
      padding-left: 10px;
      &:hover {
        background-color: #ededed;
      }
    }
  }
  input {
    height: 100%;
    width: 100%;
    border: none;
    font-size: 14px;
    color: #5f6368;
    font-weight: 400;
    padding: 0 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
