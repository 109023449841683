.choiceLocation {

  &__selectors {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (min-width: 768px){
      flex-direction: row;
    }
  }

  /*&__select {
    //background: white url('../../../img/br_down.png') center right 10px no-repeat;
    background: white url('../../../img/select-down.svg') center right 10px no-repeat;
    background-size: 8px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    height: 40px;
    padding: 0 20px;
    -webkit-appearance: none;
    @media (min-width: 768px) {
      width: calc((100% - 20px) / 3);
    }
  }*/

  &__selectWrapper {
    width: 100%;
    @media (min-width: 768px) {
      width: calc((100% - 20px) / 3);
    }
  }

  &__notice {
    background-color: #3474ff;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    line-height: normal;
    margin-top: 10px;
    padding: .75em 20px;
    text-align: center;
    width: 100%;
    @media (min-width: 768px) {
      font-size: 14px;
      text-align: left;
    }

    b {
      font-weight: 700;
    }
  }

}
