.visualSheduleTooltip {
    position: absolute;
    top: -240px;
    left : 300px;
    z-index: 20;
    background-color: white;
    border: 1px solid var(--color-outline);
    border-radius: 5px;
    width: 170px;
    min-height: 60px;
    padding: 10px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__text {
        font-style: italic;
        font-size: 12px;
        text-align: center;
    }
    &::before {
        content: '';
        position: absolute;
        display: var(--arrowDisplay);
        top: -6px;
        left: var(--leftBefore);
        width: 10px;
        height: 10px;
        border: 1px solid var(--color-outline);
        background-color: white;
        transform: rotate(45deg);
    }

    &::after {
        content: '';
        position: absolute;
        display: var(--arrowDisplay);
        top: -1px;
        left: var(--leftAfter);
        width: 12px;
        height: 10px;
        background-color: white;
    }
}