$border: 1px solid var(--color-black-60);
@mixin btn {
  align-items: center;
  color: var(--color-black-60);
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: .3s;
  &:hover {
    background-color: var(--color-primary-4);
    border-color: var(--color-primary-3);
    color: var(--color-primary-3);
  }
}

.exerciseEditPanel {
  border-bottom: $border;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr auto;
  padding: 10px 0;

  .exerciseLeftBtn {
    display: flex;
    gap: 10px;
  }
}
.btn {
  @include btn;
  border-radius: 7px;
  border: $border;
  background: var(--color-black-0);
  height: 40px;
  width: 40px;
}
.groupButtons {
  border-radius: 7px;
  border: $border;
  background: var(--color-black-0);
  display: flex;
  height: 40px;
  justify-self: flex-end;
  overflow: hidden;
  &:hover {
    border-color: var(--color-primary-3);
    .groupButton:not(:first-child) {
      border-color: var(--color-primary-3);
    }
  }

  .groupButton:not(:first-child) {
    border-left: $border;
  }
}
.groupButton {
  @include btn;
  width: 39px;
}

.checkboxButton {
  align-items: center;
  color: var(--color-black-0);
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: .3s;
  border-radius: 7px;
  border: 1px solid  var(--color-primary-3);
  background:  var(--color-primary-3);
  height: 40px;
  width: 40px;

  :hover {
    color: var(--color-black-0);
  }
}