.formSignUp {
  :global(.selectCustom__control){
    min-height: 48px;
  }
  :global(.input-inn){
    min-height: 48px;
  }
  :global(.input-innNew){
    min-height: 48px;
  }
}
.inputPassword {
  position: relative;
  input::-ms-reveal {
    display: none;
  }
  svg {
    cursor: pointer;
    height: 16px;
    position: absolute;
    width: 16px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}
.checkPassword {
  &Item {
    color: var(--color-warning-1);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 1.4;
    &Correct {
      color: var(--color-success-1);
    }
  }
}
