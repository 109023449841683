.wordForm {
  &_card {
    background-color: #f6f6f6;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-bottom: 10px;
  }

  &.error {
    box-shadow: 0px 0px 0px 2px rgba(173, 0, 0, 0.25);
    border: 1px solid var(--color-danger-1);
  }
}