.container {
  height: 88px;
  padding: 1em 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: stretch;
  background-color: #eff4f8;
  border-top: 3px solid #ededed;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.back_link {
  color: #2451b2;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  svg {
    margin: 0 10px;
    width: 20px;
    transform: rotate(180deg);
  }
}

.title {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-text-accent);

  @media (min-width: 768px) {
    font-size: 26px;
  }
}
