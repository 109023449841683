.timetable-calendar {
  $block: &;

  padding-bottom: 20px;
  position: relative;

  &__slider {
    overflow: hidden;
    transition: .3s;
  }

  &__days {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    transition: 1s;
    @media (min-width: 768px){
      padding: 20px;
    }
  }

  &__day-box{
    //animation: timetable-day-box-hide .3s forwards reverse;
    position: relative;
    width: calc((100% - 10px) / 6);
    @media (min-width: 768px){
      width: calc((100% - 16px) / 9);
    }
    @media (min-width: 1024px){
      width: calc((100% - 34px) / 18);
    }

    &--hide {
      display: none;
    }
  }

  &__day{
    align-items: center;
    border: 1px solid #919396;
    color: #5f6368;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 28px;
    justify-content: center;

    &::after {
      background-color: rgba(0,0,0,.1);
      bottom: 50%;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      right: 50%;
      top: 50%;
      transition: .2s;
    }

    &:hover {
      &::after {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
    }

    &--past{
      background-color: #aaaaaa;
      color: #fff;
      border: 1px solid #5f6368;
    }

    &--transfer{
      pointer-events: none;
      position: absolute;
      top: 2px;
      right: 2px;
      border-top: 10px solid red;
      border-left: 10px solid transparent;
    }
    &--rep{
      pointer-events: none;
      position: absolute;
      top: 2px;
      right: 2px;
      border-top: 10px solid #626262;
      border-left: 10px solid transparent;
    }

    // Выходной
    &--holiday{
      //background-color: #057fed;
      background-color: #626262;
      color: #fff;
    }

    &--debet-full{
      background-color: #45b167 !important;
      color: #fff;
    }

    &--debet-half{
      background: linear-gradient(to right, #45b167 50%, transparent 50%) !important;
      color: #5f6368;
      span:first-child{
        color: #fff;
      }
    }

    &--creditor-full{
      background-color: #cc0000;
      color: #fff;
    }

    &--creditor-half{
      background: linear-gradient(to right, #aaaaaa 50%, #cc0000 50%);
      color: #5f6368;
      span:first-child{
        color: #fff !important;
      }
      span:last-child{
        color: #fff !important;
      }
    }

    // Перенос
    &--transfer-bg {
      background-color: #626262;
      color: white;
    }

    &--1{
      background-color: #eac459;
      color: #000;
    }
    &--2{
      background-color: #fff14f;
      color: #000;
    }
    &--3{
      background-color: #4aa9e9;
      color: #fff;
    }
    &--4{
      background-color: #d780ab;
      color: #fff;
    }
    &--5{
      background-color: #626262;
      color: #fff;
    }
    &--6{
      background-color: #925774;
      color: #fff;
    }
    &--7{
      background-color: #aaaaaa;
      color: #fff;
    }
    &--8{
      background-color: #ff9e00;
      color: #fff;
    }
    &--9{
      background-color: #626262;
      color: #fff;
    }

    // Если есть комментарий
    &--comment {
      color: black;
      fill: currentColor;
      height: 10px;
      left: 2px;
      position: absolute;
      top: 2px;
      width: 10px;
    }

    // Замена учителя
    &--replace {
      align-items: center;
      display: flex;
      color: black;
      fill: currentColor;
      height: 10px;
      left: 50%;
      margin-left: -10px;
      pointer-events: none;
      position: absolute;
      top: 2px;
      width: 20px;
    }
  }

  &__more{
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    justify-content: center;
    width: 100%;

    img {
      margin: 0 10px;
      width: 10px;
    }

    &--less img{
      transform: rotate(180deg);
    }
  }
}
