.tooltip {
  position: absolute;
  top: 42px;
  left: -30px;
  width: 170px;
  min-height: 60px;
  padding: 10px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  z-index: 1;
  opacity: 0;

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border: 1px solid var(--color-outline);
    background-color: white;
    transform: rotate(45deg);
  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: calc(50% - 6px);
    width: 12px;
    height: 10px;
    background-color: white;
  }
}

.wrapper {
  position: fixed;
}

.tooltip_name {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.tooltip_text {
  font-style: italic;
  font-size: 12px;
  text-align: center;
  & span {
    font-weight: 500;
  }
}

.show {
  &:local {
    animation: show-animation 0.3s ease forwards;
  }
}

@keyframes show-animation {
  0% {
    opacity: 0;
    top: 20px;
  }

  100% {
    opacity: 1;
    top: 42px;
  }
}
