.result {
  background-color: #fff;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 240px 1fr;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
.numbers {
  border-right: 1px solid var(--color-outline);
  display: flex;
  flex-direction: column;

  header,
  footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    padding: 1em;
  }

  footer {
    border-top: 1px solid var(--color-outline);
    @media (max-width: 767px) {
      border-bottom: 1px solid var(--color-outline);
    }
  }

  h4 {
    color: #2c3e50;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    align-items: center;
    display: flex;
  }

  span {
    &:nth-child(1) {
      color: #0099de;
      font-size: 65px;
      font-weight: 700;
    }
    &:nth-child(2) {
      color: #000;
      font-size: 65px;
      font-weight: 300;
    }
    &:nth-child(3) {
      color: #2c3e50;
      font-size: 35px;
      font-weight: 700;
    }
  }
}
.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 2em;
}
.subtitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 17px;
}
.description {
  font-weight: 600;
  font-size: 14px;
}
.top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 27px;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
   
  }
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .fullname {
    @media (max-width: 1023px) {
      margin-bottom: 17px;
    }
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 7px;
    }
    p {
      font-size: 12px;
    }
  }
  .datePassage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1023px) {
      align-items: center;
    }
    p {
      &:nth-child(1) {
        margin-bottom: 7px;
        font-size: 12px;
      }
    }
  }
}
.bottom {
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
}
.info {
  max-width: 300px;
  width: 100%;
  margin-right: 37px;
  @media (max-width: 1023px) {
    margin-right: 0;
    margin-bottom: 37px;
  }
  @media (max-width: 767px) {
    margin: 0 auto;
    text-align: center;
  }
  .block {
    &:nth-child(2) {
      margin-top: 27px;
    }
    li {
      margin-bottom: 12px;
      display: flex;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      span {
        &:nth-child(1) {
          width: 170px;
          display: block;
          margin-right: 7px;
          @media (max-width: 767px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 7px;
          }
        }
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}
.sections {
  max-width: 275px;
  width: 100%;
  @media (max-width: 767px) {
    margin: 0 auto;
    margin-top: 27px;
    text-align: center;
  }
  ul {
    li {
      margin-bottom: 17px;
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        justify-content: center;
      }
      span {
        &:nth-child(1) {
          display: block;
          width: 140px;
          @media (max-width: 767px) {
            width: 120px;
          }
        }
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}
