.box {
    position: absolute;
    top: 0px;
    right: 10px;
    gap: 10px;
    background: white;
    padding: 3px;
    z-index: 10;
}

.icon {
    height: 15px;
    opacity: .5;
    cursor: pointer;
}