.button {
  padding: 11px 20px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text-accent);
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  transition: color 0.4s, background-color 0.4s, border-color 0.4s;

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    color: white;
    background-color: var(--color-text-accent);

    &:disabled {
      color: var(--color-text-accent);
      background-color: white;
      cursor: not-allowed;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px white, 0 0 1px 5px var(--color-accent);
  }
}

.outlined {
  background-color: white;
  border-color: var(--color-text-accent);
}

.secondary {
  color: var(--color-text-accent);
  background-color: #f2f2f2;
  border-color: #f2f2f2;

  &:hover {
    border-color: var(--color-text-accent);

    &:disabled {
      color: var(--color-text-accent);
    }
  }
}

.white {
  background-color: white;
  border-color: white;

  &:hover {
    color: var(--color-text-accent);
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }
}

.accent {
  color: white;
  border-color: var(--color-accent);
  background-color: var(--color-accent);

  &:hover {
    color: var(--color-accent);
    border-color: var(--color-accent);
    background-color: white;
    &:disabled {
      color: white;
      border-color: var(--color-accent);
      background-color: var(--color-accent);
    }
  }
}

.wide {
  padding-left: 65px;
  padding-right: 65px;
}
