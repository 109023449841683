.mainInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mainInfoSkills {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
}
.skillRow {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  padding: 18px 20px;
  @media (min-width: 768px) {
    grid-template-columns: 170px 1fr auto;
    grid-template-rows: unset;
  }
  &:not(:first-of-type){
    border-top: 1px solid var(--color-black-40);
  }

  .skillContent {
    a {
      color: var(--color-primary-1);
      font-weight: 600;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.skillName {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.28px;
}
.skillContent {
  grid-column: 1/3;
  grid-row: 2/3;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.28px;
  @media (min-width: 768px) {
    grid-column: unset;
    grid-row: unset;
  }
}
.skillEmpty {
  color: var(--color-black-80);
  font-size: 12px;
  line-height: 2;
  letter-spacing: .24px;
  @media (min-width: 768px) {
    font-size: 14px;
    letter-spacing: 0.28px;
  }
}
.skillPhones {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    &:not(:first-child)::before {
      @media (min-width: 768px) {
        color: var(--color-black-80);
        content: '|';
        margin: 0 5px;
      }
    }
  }
}
.editButton {
  align-items: center;
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  color: var(--color-black-60);
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  transition: .3s;
  width: 30px;
  &:hover {
    border-color: var(--color-primary-3);
    color: var(--color-primary-2);
  }

  svg {
    height: 18px;
    width: 18px;
  }
}
