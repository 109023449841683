$screen-desktop-min: 1024px;

.timeSlot {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  color: #5f6368;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 10px;
  font-weight: 500;
  height: 74px;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all .3s ease;
  width: 119/244*100%;
  @media (min-width: $screen-desktop-min) {
    font-size: 14px;
    height: 50px;
    margin-right: 20px;
    width: calc(100% / 8);

    &:nth-child(7n){
      margin-right: 0;
    }
  }

  &:hover {
    border-color: #3f4d63;
  }

  &--active {
    background-color: #3f4d63;
    border-color: #3f4d63;
    color: white;
  }

  &__time {
    font-size: 16px !important;
    font-weight: 600;
    @media (min-width: $screen-desktop-min) {
      font-size: 16px !important;
    }

    sup {
      display: block;
      font-size: 10px;
      @media (min-width: $screen-desktop-min) {
        display: inline;
        font-size: (7em/12);
        vertical-align: super;
      }
    }
  }
  &__desc{
    font-size: 9px;
    font-weight: 500;
  }
}
