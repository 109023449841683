.mainSelectorContent {
  //width: 100%;
}

.row {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr auto;

  input {
    margin-bottom: 0;
  }
}

.btn {
  align-items: center;
  background-color: #5a8dee;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  height: 45px;
  padding: 0 20px;
  user-select: none;

  svg {
    height: 22px;
  }
}

.notice {
  font-size: 12px;
}