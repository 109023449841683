.wrapper {
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  background-color: var(--color-black-0);
  min-width: 254px;
  .row {
    color: var(--color-black-100);
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.32px;
    padding: 10px;
    border-bottom: 1px solid var(--color-black-40);
  }

  .btn {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }

  .empty {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.28px;
    text-align: center;
    padding: 10px;
  }

  .section {
    cursor: pointer;
    user-select: none;
    display: flex;
    gap: 38px;
    justify-content: space-between;
    align-items: center;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.42px;
    padding: 16px 10px;
    position: relative;

    &:hover {
      background-color: var(--color-primary-4);
      text-decoration: underline var(--color-primary-1);

      .name {
        color: var(--color-primary-1);
      }

      .control {
        visibility: unset;
      }
    }

    .name {
      color: var(--color-black-60);
    }

    .activeName {
      color: var(--color-primary-1);
      font-weight: 700;
    }
  }
}
.control {
  align-items: stretch;
  visibility: hidden;
  height: 26px;
  border: 1px solid var(--color-black-60);
  border-radius: 7px;
  display: flex;
  background-color: var(--color-black-0);
  position: absolute;
  right: 10px;
}
.icon_container {
  cursor: pointer;
  display: flex;
  width: 24px;

  justify-content: center;
  align-items: center;
  color: var(--color-black-60);
  border-right: 1px solid var(--color-black-60);
  &:first-child {
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
  }
  &:last-child {
    border-right: none;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
  }

  &:hover {
    color: var(--color-primary-2);
    background-color: var(--color-primary-4);
  }

  svg {
    height: 16px;
    width: 16px;
  }
}
