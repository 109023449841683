.makeWord {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 19.12px;
    color: var(--color-black-100);
    margin-bottom: 10px;

    @media (min-width: 768px) {
      line-height: 28px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 20px;
    }
  }

  // Подсказка
  &__clue {
    align-items: center;
    background-color: var(--color-info-4);
    border: 1px solid var(--color-primary-3);
    border-radius: 6px;
    display: flex;
    padding: 15px 12px;
    gap: 12px;

    p {
      font-size: 18px;
      font-weight: 600;
      color: var(--color-primary-1);
      word-break: break-all;
    }

    svg {
      color: var(--color-primary-3);
      min-width: 20px;
      height: 20px;
    }
  }

  &__bottom, &__top {
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    user-select: none;

    span {
      border: 1px solid var(--color-black-60);
      border-radius: 7px;
      background: var(--color-black-0);
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 400;
      color: var(--color-black-100);

      &.onDragOver {
        box-shadow: 0 0 0 2px #55b1f399;
        border: 1px solid var(--color-primary-2);
        background: var(--color-black-0);
      }
      &.right {
        box-shadow: 0 0 0 2px #1abc9799;
        border: 1px solid var(--color-success-1);
        background: var(--color-black-0);
      }
      &.mistake {
        box-shadow: 0 0 0 2px #ff002299;
        border: 1px solid var(--color-danger-1);
        background: var(--color-black-0);
      }
    }
  }

  &__top {
    border: 1px dashed var(--color-warning-1);
    background: var(--color-warning-4);

    span {
      cursor: grab;

      &.selected {
        border: 1px solid var(--color-black-20);
        background:var(--color-black-10);
        color: var(--color-black-40);
        pointer-events: none;
      }
    }
  }

  &__bottom {
    border: dashed 1px var(--color-black-60);
    background: var(--color-black-10);

    span {
      &:empty {
        background: var(--color-black-0);
        border: 1px solid var(--color-black-40);
      }
    }
  }

  & &__word {
    box-shadow: 0px 0px 0px 2px #55b1f399;
    border: 1px solid var(--color-primary-2);
    background: var(--color-black-0);
    cursor: pointer;
    font-weight: 700;
  }
}
