.inputContainer {
  position: relative;

  .calendarIcon {
    color: var(--color-black-80);
    position: absolute;
    width: 16px;
    height: 16px;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .input {
    padding: 16px 12px;
  }
}

.action {
  float: right;
  color: var(--color-primary-2);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.28px;
  text-decoration-line: underline;
  cursor: pointer;
}