.colours {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  .container {
    padding: 3px;

    .boxBorderCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
      border-radius: 50%;

      .circle {
        height: 10px;
        width: 10px;
        border-radius: 50%;
      }
    }
  }
}