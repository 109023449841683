.bookDocs {
    &__list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
    }
    &__item {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 17px;
        padding: 10px;

        @media (min-width: 768px) {
            grid-template-columns: auto 1fr 160px;
            grid-template-rows: auto auto auto;
            padding: 10px 20px 10px;
        }
    }
    &__file {
        position: relative;
        display: flex;
        height: 36px;
        cursor: pointer;
        width: 28px;
        &Wrapper {
            grid-row: 1 / 4;
        }
        svg {
            width: 100%;
            height: 100%;
        }
        span {
            color: black;
            font-size: 10px;
            font-weight: 700;
            position: absolute;
            top: 18px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__description {
        font-size: 12px;
        color: var(--color-black-80);
        padding: 10px 0;

        @media (min-width: 768px) {
            padding: 5px 0 10px 0;
            grid-row: 2/4;
        }
    }
    &__name {
        margin-bottom: 5px;
        @media (min-width: 768px) {
            margin-bottom: 0;
        }
        a {
            color: var(--color-black-100);
            font-weight: 700;
        }
    }
    &__dateName {
        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
            align-items: flex-end;
            grid-row: 1/3;
            grid-column: 3/4;
        }
    }
    &__user {
        color: var(--color-black-60);
        font-size: 12px;
        line-height: 18px
    }
    &__date {
        color: var(--color-black-100);
        line-height: 18px;
        font-size: 12px;
        white-space: nowrap;
        strong {
            font-weight: 700;
        }
    }
    &__btns {
        display: flex;
        justify-content: center;
        grid-column: 1 / 3;
        padding-top: 0;
        gap: 25px;

        @media (min-width: 768px) {
            padding-top: 5px;
            grid-column: 3 / 4;
            align-items: flex-start;
            justify-content: flex-end;
        }

        button {
            color: var(--color-primary-1);
            font-size: 12px;
            font-weight: 700;
            border: none;
            background: none;
            padding: 0;
            cursor: pointer;

            &:hover {
                background: none;
                color: var(--color-primary-1);
            }
        }
    }
    &__empty {
        @media (min-width: 768px) {
            margin-top: 10px;
        }
    }
    &__add {
        color: var(--color-black-60);
        background: var(--color-black-0);
        border: 1px solid var(--color-black-60);
        width: 100%;
        border-radius: 7px;
        padding: 10px 0;
        font-weight: 700;
        &:hover {
            border: 1px solid var(--color-primary-2);
            color: var(--color-primary-2);
            background: var(--color-black-0);
            box-shadow: inset 0 0 0 1px var(--color-primary-2);
        }
    }
}

.bodyNotFound {
    display: block;
    text-align: center;
    margin: 20px 0;

    .imgNotFound {
        width: 100%;
        max-width: 400px;
    }

    .text {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: var(--color-black-100);
    }
}