.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.attention {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--color-danger-1);
  background: var(--color-danger-4);
  color: var(--color-black-80);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
}
