.ebookHomework {
  border: 1px solid transparent;
  background-color: var(--color-black-0);
  border-radius: 6px;
  transition: .3s;
  &:hover {
    background-color: var(--color-primary-4);
  }
  &Opened {
    background-color: var(--color-black-10);
    border-color: var(--color-black-40);
  }

  .teacherDate {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 20px;
    place-self: flex-start;

    a {
      color: var(--color-primary-1);
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: .42px;
      text-decoration-line: underline;
      &:hover {
        text-decoration: none;
      }
    }

    time {
      color: var(--color-black-100);
      font-size: 12px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: .24px;
    }
  }
}
.homeworkHeader {
  cursor: pointer;
  display: grid;
  gap: 10px;
  padding: 10px;
  @media (min-width: 768px) {
    align-items: center;
    grid-template-columns: auto 1fr auto;
  }
  @media (min-width: 1024px) {
    grid-template-columns: auto 1fr auto auto;
  }
}
.slider {
  overflow: hidden;
  transition: height .3s ease-in;
}
.counts {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  span {
    color: var(--color-black-100);
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: .24px;
    text-transform: uppercase;
  }

  b {
    color: var(--color-success-1);
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: .32px;
  }
}
.comment {
  color: var(--color-black-80);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  @media (min-width: 768px) {
    grid-column: 1/4;
  }
  @media (min-width: 1024px) {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
.buttons {
  justify-content: center;
  display: flex;
  gap: 10px;
}
.students {
  padding: 0 10px;
}
