$border: 1px solid var(--color-black-40);

.personalInfo {
  display: flex;
  flex-direction: column;
  background-color: var(--color-black-0);
  border: $border;
  border-radius: 7px;
  position: relative;
}
.button {
  align-items: center;
  border: $border;
  border-radius: 5px;
  color: var(--color-black-60);
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  transition: all .3s;
  width: 30px;
  &:hover {
    border-color: var(--color-primary-3);
    color: var(--color-primary-2);
  }
}
.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1em 15px;

  ul {
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
    @media (min-width: 1440px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    li {
      display: flex;
      flex-direction: column;
    }

    a {
      color: var(--color-black-100);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.paramName {
  color: var(--color-black-80);
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 16px;
  @media (min-width: 768px) {
    font-size: 12px;
    letter-spacing: 0.24px;
  }
}
.paramValue {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.28px;
}
