.section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.42px;
  padding: 10px 20px;
  position: relative;

  &:hover {
    background-color: var(--color-primary-4);

    .active {
      visibility: unset;
    }
  }

  .name {
    align-self: center;
    justify-self: start;
    color: var(--color-primary-1);
    font-weight: 500;
  }

  .questionsBlock {
    align-self: center;
    justify-self: center;
    font-weight: 500;
    color: var(--color-primary-1);

    .questionsInfo {
      display: flex;
      gap: 10px;

      .questionsCount {
        font-weight: 800;
      }

      .show {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.36px;

        .showText {
          text-decoration: underline;
        }
      }
    }
  }

  .percent {
    align-self: center;
    justify-self: end;
    font-weight: 800;
  }
}
