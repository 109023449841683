.smart-filter {
  width: 100%;
  position: relative;

  & &__field {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 48px;
    padding: 10px 14px;
    border-radius: 7px;
    border: 1px solid var(--color-black-40);

    input {
      width: 100%;
      margin-bottom: 0;
      border: none;
      &::placeholder {
        color: var(--color-black-60);
      }
    }
  }
  
  &__icon {
    width: 20px;
    height: 20px;
    color: var(--color-black-60);
  }

  &__list {
    position: absolute;
    z-index: 1099;
    border-radius: 5px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    border: 1px solid #bdbdbd;
    margin-top: 10px;
    height: auto;
    max-height: 150px;
    overflow-y: auto;
  }

  &__line {
    padding: 10px 15px;
    cursor: pointer;
    transition: .1s ease-in;
    &:hover {
      background-color: #3474ff;
      color: #fff;
    }
  }

  &__empty {
    padding: 10px 15px;
  }
}