.kyndelik {
  display: grid;
  gap: 20px;
}
.kyndelikButton {
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--color-black-60);
  background: var(--color-black-0);
  justify-content: center;
  display: flex;
  padding: 5px 0;
}
.kyndelikSeparator {
  align-items: center;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr auto 1fr;
  color: var(--color-black-80);
  font-size: 12px;
  line-height: 1;
  &::before, &::after {
    background-color: var(--color-black-60);
    height: 1px;
    content: '';
    display: block;
  }
}
.enterCorrectData {
  color: var(--color-danger-1);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.rememberMe {
  align-items: center;
  display: flex;
  justify-content: space-between;

  a {
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}
.bottom {
  margin-top: 17px;
  div {
    text-align: left;
  }
}
.inputPassword::-ms-reveal {
  display: none;
}
