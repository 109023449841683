.searchField {
    background: var(--color-black-0);
    height: 50px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding: 0 14px;
    gap: 14px;
    border: solid 1px var(--color-primary-2);
    position: relative;
    border-radius: 5px;
    z-index: 999;

    &__icon {
        color: var(--color-primary-2);
        width: 24px;
        height: 24px;
    }
    &__input {
        height: 100%;
        border: none;
        border-radius: 5px;

        &, &::placeholder {
            color: var(--color-black-60);
            font-weight: 500;
            font-size: 12px;
        }

        & {
            color: var(--color-primary-2);
        }
    }
    &__dropdown {
        position: relative;
        cursor: pointer;
    }
}