.wrap {
  background-color: #fff;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto 1fr;
}
.tries {
  border-right: 1px solid var(--color-outline);
  padding: 1em 0 1em 20px;
}

.try {
  border-right: 2px solid transparent;
  color: #000;
  cursor: pointer;
  font-weight: 600;
  line-height: 2;
  padding-right: 2em;
}
.trySelected {
  composes: try;
  border-color: #3474ff;
  color: #3474ff;
}
.tryDisabled {
  composes: try;
  color: #bfbfbf;
}

.info {
  border-bottom: 1px solid var(--color-outline);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 0;
}
.infoCell {
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 20px;
  justify-content: center;

  img {
    width: 25px;
  }
}
.buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}