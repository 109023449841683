.makeWord {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.letters {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
.letter {
  align-items: center;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  height: 45px;
  text-transform: uppercase;
  user-select: none;
  min-width: 45px;
  @media (max-width: 767px) {
    min-width: 30px;
    height: 30px;
    font-size: 12px;
  }
}
.line {
  border: 1px solid var(--color-outline);
  width: 100%;
  height: 1px;
}
.empty {
  composes: letter;
  background-color: #ebebeb;
  cursor: default;
}
