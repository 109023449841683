.booked-online-schedule-page {
  padding: 20px 15px;

  &__clients {
    margin-top: 20px;
  }

  .main-table-card-box {
    margin: 10px 0 0;
  }
}