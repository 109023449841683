.controlAttemps {
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-black-60);
  border-radius: 6px;
  display: inline-flex;
  gap: 10px;
  padding: 20px 10px;
  position: relative;
  cursor: pointer;
  width: 44px;
  height: 64px;
  &:hover {
    border-color: var(--color-primary-3);
    background: var(--color-primary-4);
    path {
      fill: var(--color-primary-2);
    }
  }
  .rotate {
    transform: rotate(180deg);
  }
}
