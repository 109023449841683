.color-selector-box{
    height: 45px;
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.color-selector-box-selector{
    height: 30px;
    width: 55px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px 10px 5px;
    cursor: pointer;
}
.color-selector-box-item{
    width: 25px;
    height: 20px;
    border: 1px solid #000000;
    transition: all .1s ease-in;

}
.color-selector-box-drop{
    position: absolute;
    top: 40px;
    background-color: #fff;
    border: 1px solid #eeeeee;
    width: 240px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 5px;
    justify-content: flex-start;
    padding: 5px;
    z-index: 100;
}
.color-selector-box-drop div{
    cursor: pointer;
}

.color-selector-box-drop div:hover{
    border: 1px solid #eeeeee;
    transition: all .1s ease-in;
}