.certificates {
  &_line {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    &_title {
      display: flex;
      flex-direction: column;
      width: 35%;

      span {
        color: #959595;
        font-size: 12px;

        &:last-child {
          font-size: 20px;
          color: #222;
        }
      }
    }

    &_companies {
      width: 15%;
      display: flex;
      flex-direction: column;
      color: #959595;
      line-height: 20px;
    }

    &_sum {
      width: 30%;
      display: flex;
      flex-direction: row;
      color: #222;
      font-size: 20px;
    }

    &_until {
      width: 20%;
      display: flex;
      flex-direction: column;
      color: #959595;
      line-height: 20px;
      right: 0;
      padding-right: 15px;
    }
  }
}
