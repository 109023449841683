.text {
  width: 100%;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  background-color: var(--color-accent-600);
}

.offset {
  margin-top: -10px;
}
