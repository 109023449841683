.header {
  align-items: center;
  border-bottom: 1px solid var(--color-black-40);
  display: flex;
  height: 50px;
  padding: 0 20px;

  .employeeName {
    color: #222;
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .actionMenu {
    margin-left: auto;
  }
}
.top {
  border-bottom: 1px solid var(--color-black-40);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 10%;
  }
}
.daysInfo {
  display: grid;
  width: 100%;
  gap: 10px;
  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  div {
    color: var(--color-black-100);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: .28px;
  }

  b {
    color: var(--color-black-80);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: .24px;
  }
}
.monthNav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 768px) {
    grid-template-columns: auto 1fr auto;
  }

  svg {
    border-radius: 5px;
    border: 1px solid var(--color-black-40);
    color: currentColor;
    width: 24px;
    height: 24px;
  }
}
.currentMonth {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  gap: 20px;
  grid-column: 1/3;
  order: -1;
  place-self: center;
  color: var(--color-black-100);

  @media (min-width: 768px) {
    grid-column: unset;
    order: unset;
  }

  svg {
    border-radius: 5px;
    border: 1px solid var(--color-black-40);
    cursor: pointer;
  }
}
.prevMonth, .nextMonth {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  color: var(--color-black-60);

  span {
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  &:hover {
    span {
      color: var(--color-primary-2);
    }

    svg {
      border: 1px solid var(--color-primary-2);

      * {
        fill: var(--color-primary-2);
      }
    }
  }
}
.nextMonth {
  place-self: flex-end;
}
.prevMonth {
  svg {
    transform: rotate(180deg);
  }
}
.bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.days {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  @media (min-width: 1024px) {
    gap: 5px;
  }
}
