.dragWords {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.wordsBox {
  border: 1px dashed var(--color-warning-1);
  border-radius: 6px;
  background: #fff9e9;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  span {
    border: 1px solid var(--color-black-60);
    border-radius: 6px;
    background: var(--color-black-0);
    display: flex;
    min-height: 30px;
    padding: 6px 10px 6px 6px;
    align-items: center;
    justify-content: center;
    cursor: grab;
    font-size: 18px;
    font-weight: 400;
    word-break: break-all;
    color: var(--color-black-100);
    & .dote {
      height: 100%;
      width: 3px;
      margin-right: 7px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;

      div, &::after, &::before {
        min-width: 3.5px;
        background: var(--color-black-60);
        border-radius: 50%;
        height: 3.5px;
        content: '';
        display: block;
      }
    }
    &.onDrag {
      border: 1px solid var(--color-black-40);
      background: var(--color-black-10);
      color: var(--color-black-40);

      & .dote {
        div, &::after, &::before {
          background: var(--color-black-20);
        }
      }
    }
    &.selected {
      border: 1px solid var(--color-black-40);
      background:var(--color-black-10);
      color: var(--color-black-40);
      pointer-events: none;
      & .dote {
        div, &::after, &::before {
          background:var(--color-black-40);
        }
      }
    }
  }

}
.text {
  color: var(--color-black-100);
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.28px;
}
.dropZone {
  align-items: center;
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  box-shadow: 0 0 0 #3f4d63;
  cursor: default;
  display: inline-flex;
  font-weight: 400;
  min-height: 30px;
  justify-content: center;
  min-width: 80px;
  padding: 0 8px;
  transition: .3s;
  vertical-align: middle;
  line-height: 16px;
  font-size: 18px;
  word-break: break-all;
  &.onDragOver {
    background-color: #c9dcff;
  }

  &:not(:empty){
    margin: 0 3px;
    background-color: white;
    cursor: pointer;
  }

  &_right {
    border: 1px solid var(--color-success-1);
    box-shadow: 0 0 0 2px rgba(26, 188, 151, 0.60);
  }

  &_wrong {
    border: 1px solid var(--color-danger-1);
    box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.60);
  }
}
