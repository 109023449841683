.header {
  align-items: center;
  border-bottom: 1px solid var(--color-black-40);
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  height: 52px;
  padding: 0 10px;
  user-select: none;
  @media (min-width: 768px) {
    padding: 0 20px;
  }

  h3 {
    color: var(--color-black-100);
    font-size: 20px;
    font-weight: 500;
    line-height: 2.5;
    letter-spacing: 0.4px;
  }

  .buttons {
    display: flex;
    gap: 10px;
  }

  .addButton {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-black-40);
    border-radius: 5px;
    transition: all 0.3s ease;

    svg {
      transition: all 0.3s ease;
      color: var(--color-black-60);
    }

    &:hover {
      cursor: pointer;
      border: 1px solid var(--color-primary-3);
      svg {
        color: var(--color-primary-2);
      }

    }
  }
}
