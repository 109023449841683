.timeTableSlot {
    background-color: transparent;
    border-radius: 5px;
    box-sizing: border-box;
    width: 5px;
    position: absolute;
    top: 1px;
    height: 38px;
    cursor: pointer;
    &__body {
        position: relative;
    }
}