.counterBox {
  max-width: 118px;
  max-height: 48px;
  border-radius: 7px;
  border: 1px solid var(--color-black-40);
  display: flex;
  justify-content: space-between;

  &__button {
    align-items: center;
    background-color: var(--color-primary-3);
    color: var(--color-black-0);
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 30px;
    font-size: 20px;
    cursor: pointer;
    user-select: none;

    &__plus {
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
    }

    &__minus {
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
    }

    &__disabled{
      background-color: var(--color-black-20);
      color: var(--color-black-40);
    }
  }



  input {
    max-width: 58px;
    border: none;
    flex-grow: 1;
    text-align: center;
  }
}