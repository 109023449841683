.aiButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border: none;
	border-radius: 30px;
	background: var(--color-primary-5);
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06), 0px 2px 32px rgba(0, 0, 0, 0.16);
	cursor: pointer;
	transition: background 0.2s linear;

	&:hover {
		background: var(--color-primary-2);
	}

	.aiIconLg {
		display: block;
	}

	.aiIconSm {
		display: none;
	}

	@media (max-width: 1440px) {
		width: 32px;
		height: 32px;

		.aiIconLg {
			display: none;
		}

		.aiIconSm {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 1px;
		}
	}
}
