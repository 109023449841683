.smartFilter {
  width: 100%;
  position: relative;
}

.inputContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  input {
    padding-right: 30px;
    padding-left: 40px;
    width: 100%;
  }
}

.iconSearch {
  width: 20px;
  height: 20px;
  color: var(--color-black-60);
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.filterLine {
  padding: 10px 15px;
  cursor: pointer;
  transition: .1s ease-in;
  &:hover {
    background-color: var(--color-accent-600);
    color: var(--color-black-0);
  }
}

.filterEmpty {
  padding: 10px 15px;
}

.filterList {
  position: absolute;
  z-index: 1099;
  border-radius: 5px;
  background-color: var(--color-black-0);
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border: 1px solid var(--color-outline);
  margin-top: 10px;
  height: auto;
  max-height: 150px;
  overflow-y: auto;
}