.variants {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 767px)  {
    align-items: center;
  }
}
.variant {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 20px;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    border: dashed 2px rgb(194, 197, 202);
    padding: 10px;
    border-radius: 5px;
  }
}
.img {
  max-width: 150px;
  img {
    width: 100%;
  }
}
.checkbox {
  pointer-events: none;
}
