.placementTestAddBundle {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modalAddPayment__row {
    @media screen and (max-width: 360px) {
      grid-row: initial;
    }
  }
  &__testTime {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
    @media screen and (max-width: 360px) {
      flex-direction: column;
    }
    .counterBox {
      width: 180px;
      &__button {
        width: 30px;
      }
    }
  }
}
