.watch {
  padding: 7px;
  display: flex;
  gap: 20px;
}

.runTime {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.formTime {
  font-size: 14px;
  margin-left: 10px;
}

.finishTime {
  font-size: 16px;
  margin-left: 10px;
}

.timeLeft {
  color: var(--color-black-100);
  display: flex;
  font-size: 14px;
  justify-content: center;
  text-align: center;

  span {
    margin-left: 5px;
  }
}
.colorDate {
  color: var(--color-black-80);
}

.clrLine {
  color: var(--color-black-40);
}

.time {
  font-weight: 400;
  font-size: 14px;
}

.colorTime {
  color: var(--color-black-100);
}

.stylesTime {
  display: flex;
  align-items: baseline;
}

.clrTime {
  color: var(--color-black-100);
}


