@mixin basicButton {
  border: 1px solid var(--color-black-80);
  background: var(--color-black-20);
  color: var(--color-black-80);
}

@mixin coloredButton {
  border: 1px solid var(--color-success-1);
  background: var(--color-success-4);
  color: var(--color-success-1);
}

.button, .badge, .link {
  font-family: "Manrope", sans-serif;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  border-radius: 7px;
}

.link .arrow {
  font-family: "Manrope", sans-serif;
  position: relative;
  bottom: 1px;
}

.filter {
  @include basicButton;
  padding: 6px 5px 6px 10px;

  .iconImage {
    margin: 0 4px 0 14px;
    fill: var(--color-success-1);
  }

  &:hover {
    @include basicButton;
  }
}

.filterColored {
  @include coloredButton;

  .iconImage * {
    fill: var(--color-success-1);
  }

  &:hover {
    @include coloredButton;
  }
}

.filterWithoutIcon {
  padding: 6px 16px;
}

.badge {
  white-space: nowrap;
  @include basicButton;
  padding: 6px 16px;

  &:hover {
    @include basicButton;
  }
}

.badgeColored {
  @include coloredButton;

  &:hover {
    @include coloredButton;
  }
}

.link {
  padding: 7px 20px;
  gap: 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;
  border: 1px solid var(--color-black-80);
  color: var(--color-black-80);

  .iconImage {
    max-width: 13px;
    width: 100%;
  }

  &:hover {
    color: var(--color-black-100);
    background: #fff;
  }
}

.linkColored {
  border: 1px solid var(--color-success-1);
  color: var(--color-black-100);

  &:hover {
    outline: 1px solid var(--color-success-1);
    color: var(--color-success-1);
    background: var(--color-black-0)
  }
}

.goToLink {
  align-items: center;
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  color: var(--color-black-60);
  display: flex;
  height: 30px;
  justify-content: center;
  transition: .3s;
  width: 30px;
  &:hover {
    border-color: var(--color-primary-3);
    color: var(--color-primary-2);
    cursor: pointer;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

// RESET BTN
.reset {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  width: 100%;

  border-radius: 7px;
  border: 1px solid var(--color-black-60);
  background: var(--color-black-0);
  color: var(--color-black-60);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;
  &:hover {
    border-color: var(--color-black-80);
    box-shadow: inset 0 0 0 1px var(--color-black-80);
    background: var(--color-black-0);
    color: var(--color-black-80);
  }
}

// confirm BTN
.confirm {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  border: none;
  border-radius: 7px;
  background: var(--color-primary-3);
  color: var(--color-black-0);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;
  &:hover {
    background: var(--color-primary-2);
    color: var(--color-black-0);
  }
}

.closeButton {
  align-items: center;
  background: var(--color-primary-3);
  border-radius: 5px;
  color: var(--color-black-0);
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: .2s;
  width: 20px;
  height: 20px;
  svg {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: var(--color-primary-2);
  }
}

// Header add button
.headerAddButton {
  align-items: center;
  border: 1px solid var(--color-primary-3);
  color: var(--color-primary-3);
  display: flex;
  gap: 20px;
  height: 36px;
  justify-content: center;
  padding: 0;
  width: 36px;
  &:hover {
    color: var(--color-primary-2);
    background: var(--color-black-0);
    border: 1px solid var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
  }
  @media (min-width: 768px) {
    padding: 0 15px;
    width: auto;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  span {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
}
