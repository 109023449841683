.progressBar {
  width: 138px;
  height: 138px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media (min-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
  @media (min-width: 1440px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
}

.timerBlock {
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  //margin-bottom: 10px;
  font-size: 10px;

  @media (min-width: 767px) {
    font-size: 12px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
}

.timerHeader, .timerDescription {
  color: black;
  font-weight: bold;
}

.timerDescription {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--color-black-80);
  line-height: 16px;
}

.timerValue {
  color: var(--color-black-100);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 600;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
    font-weight: 700;
  }
}

.timerLabel {
  color: var(--color-black-80);
  font-weight: 400;
  font-size: 10px;
}