.filterTextValues {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;

  button {
    color: var(--color-black-100);

    &:hover {
      color: var(--color-black-100);
    }

    svg {
      path {
        fill: var(--color-black-100) !important;
      }
    }
  }
}
