.makeSentences {
  display: grid;
  gap: 20px;
}
.oneSentence {
  background-color: var(--color-black-10);
  border-radius: 6px;
  padding: 10px;
}
.counter {
  background-color: var(--color-black-10);
  border-radius: 3px;
  color: var(--color-black-100);
  text-align: center;
  font-size: 12px;
  left: -10px;
  letter-spacing: .24px;
  line-height: 1;
  padding: 2px 4px;
  position: relative;
  top: -16px;
}
.clue {
  align-items: center;
  background-color: var(--color-info-4);
  border: 1px solid var(--color-primary-3);
  border-radius: 6px;
  display: flex;
  padding: 15px 12px;
  gap: 12px;
  margin-bottom: 20px;

  p {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-primary-1);
    word-break: break-all;
  }

  svg {
    color: var(--color-primary-3);
    min-width: 20px;
    height: 20px;
  }
}
