.text {
  color: var(--color-text);
  font-size: 16px;
  line-height: 2.5;

  select {
    border-radius: 3px;
    height: 35px;
  }
}
