.del {
  align-items: center;
  color: #3f4d63;
  cursor: pointer;
  display: flex;
  justify-content: center;
  content: '&#215;';
  font-size: 40px;
  font-weight: 600;
  &[disabled] {
    pointer-events: none;
    visibility: hidden;
  }
}
