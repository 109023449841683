.groupTabs {
  border-bottom: 1px solid var(--color-black-40);
  display: grid;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  user-select: none;
}

.tabs {
  display: flex;
  margin-bottom: -1px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0;
    display: none;
  }
}

.tab {
  color: var(--color-black-80);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  white-space: nowrap;

  sup {
    font-size: 10px;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: .3px;
    vertical-align: super;
  }
}

.active {
  border-bottom: 2px solid var(--color-primary-2);
  color: var(--color-primary-2);
  background: var(--color-black-0);
  font-weight: 700;
}
