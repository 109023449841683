.groupForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.fieldSet {
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.formField {
  position: relative;
}

.toggleCheckboxWrapper {
  display: inline-block;
  position: relative;
}

.toggleCheckbox input {
  position: relative;
  z-index: 1;
}

.toggleCheckbox label {
  cursor: pointer;
  user-select: none;
}

.placeholder {
  font-family: 'Manrope', sans-serif;
}
