.input-inn {
  align-items: stretch;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  color: #5f6368;
  display: flex;
  font-size: 14px;
  height: 45px;
  &:hover {
    border: 1px solid var(--color-outline);
    box-shadow: var(--focus-box-shadow);
    transition: .3s;
  }

  &__select {
    border: none;
    border-radius: 5px 0 0 5px;
    text-align: center;
    width: 105px;
    //text-align-last: center;
    border-right: 1px solid #bdbdbd;
    background: #ebebeb url('../../img/select-down.svg') center right 10px no-repeat;
    background-size: 8px;
    -webkit-appearance: none;

    &--empty {
      color: #959595;
    }
    option{
      background-color: #ffffff;
    }
  }

  option:not(:disabled){
    color: #000;
  }

  input {
    border: none !important;
    height: 100% !important;
    padding-left: 20px;
    width: 100%;
    box-shadow: 0 0 0 0 !important;

    &:disabled {
      border-radius: 0;
    }
  }
}
.input-innNew {
  align-items: stretch;
  border-radius: 5px;
  color: #5f6368;
  display: flex;
  font-size: 14px;
  height: 45px;
  border: 1px solid var(--color-primary-1);
  box-shadow: var(--focus-box-shadow);

  &__select {
    border: none;
    padding-left: 15px;
    border-radius: 5px 0 0 5px;
    text-align: center;
    width: 105px;
    //text-align-last: center;
    border-right: 1px solid #bdbdbd;
    background: #ebebeb url('../../img/select-down.svg') center right 10px no-repeat;
    background-size: 8px;
    -webkit-appearance: none;

    &--empty {
      color: #959595;
    }
    option{
      background-color: #ffffff;
    }
  }

  option:not(:disabled){
    color: #000;
  }

  input {
    border: none !important;
    height: 100% !important;
    padding-left: 20px;
    width: 100%;
    box-shadow: 0 0 0 0 !important;

  }
}
.input-inn select:focus {
  border: none !important;
  border-right: 1px solid #bdbdbd;
  box-shadow: none !important;
}
