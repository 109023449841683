.addEbook {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.info {
  align-items: center;
  display: grid;
  grid-template-columns: 200px 1fr;
  height: 25px;

  span {
    font-weight: 600;

    &:first-child {
      font-weight: 400;
    }
  }
}
