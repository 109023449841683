.units {
  display: grid;
  grid-template-columns: 149px 149px;
  gap: 20px;
  align-items: start;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.unit {
  align-self: baseline;
  display: grid;
  gap: 10px;
  width: 149px;

  textarea {
    display: flex;
    align-items: center;
  }

  img {
    border-radius: 6px;
    display: block;
  }

  .customInput {
    color: var(--color-black-100);
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    border-radius: 7px;
    border: 1px solid var(--color-black-40);
    background: var(--color-black-0);
    height: 36px;
    overflow: hidden;
    resize: none;
    padding: 7px 12px;
    line-height: normal;

    &:hover {
      box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60);
    }

    &:focus {
      border-color: var(--color-primary-2);
      box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60), inset 0 0 0 1px var(--color-primary-2);
    }

    &::placeholder {
      color: var(--color-black-60);
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 16px;
      position: relative;
      top: 2px;
    }
  }

  .correct {
    border: 1px solid var(--color-success-1);
    box-shadow: 0 0 0 2px rgba(26, 188, 151, 0.60);

    &:hover {
      border: 1px solid var(--color-success-1);
      box-shadow: 0 0 0 2px rgba(26, 188, 151, 0.60);
    }
  }

  .mistake {
    border: 1px solid var(--color-danger-1);
    box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.60);

    &:hover {
      border: 1px solid var(--color-danger-1);
      box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.60);
    }
  }
}