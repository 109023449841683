.clientCard {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 10px;

  &__header {
    padding: 10px;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @media (min-width: 768px) {
      align-items: center;
      flex-direction: row;
    }
  }

  &__client {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    line-height: 1;

    span {
      &:first-child{
        font-weight: 500;
      }
      &:last-child {
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }

  &__phone {
    margin-top: 1em;
    min-width: 20%;
    text-align: left;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    a {
      color: #000;
      text-decoration: underline;
    }
  }

  &__email {
    align-items: center;
    display: flex;
    line-height: 2;
    min-width: 20%;
    max-width: 300px;
    overflow: hidden;
    text-align: left;

    a {
      color: #4d5055;
      text-decoration: underline;
    }
  }

  &__actions {
    position: absolute;
    right: 10px;
    top: 10px;

    @media (min-width: 768px) {
      position: unset;
    }
  }

  &__body{
    padding: 10px;
  }
}
