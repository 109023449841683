.form_fields__item {
  width: 360px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 639px) {
    width: 100%;
  }
}
