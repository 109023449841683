.inner {
    flex-basis: 100%;
    color: var(--color-text);
    @media (min-width: 768px) {
        flex-basis: calc(50% - 20px);
    }
}

.info_wrapper {
    font-size: 26px;
    text-align: center;
}

.order_title {
    margin-bottom: 10px;
    font-weight: 400;
}

.order_number {
    margin-bottom: 10px;
    font-weight: 700;
}

.order_status {
    font-size: 14px;
    text-align: center;

    &__wait {
        color: #d9b717;
    }
    &__error {
        color: var(--color-error);
    }
    &__success {
        color: #00a42a;
    }
}

.time_end {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-error);
    text-align: center;
}

.list_title {
    margin-bottom: 10px;
    font-weight: 600;
}

.list {
    margin-left: 25px;

    li:not(:last-of-type) {
        margin-bottom: 10px;
    }
}

.timer {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;

    &__end {
        color: var(--color-error);
    }
}

.timer_text {
    text-align: center;
}

.check_button {
    width: 100%;
}

.actions {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    button {
        padding: 0;
        border: none;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-decoration: underline;
        background-color: white;

        &:hover {
            background-color: white;
            text-decoration: none;
        }
    }
}

.actions_cancel {
    margin-right: 15px;
    position: relative;
    color: var(--color-error);

    &:hover {
        color: var(--color-error);
    }

    &::after {
        content: "|";
        position: absolute;
        right: -10px;
        top: 0;
        color: var(--color-text);
    }
}

.actions_change {
    color: var(--color-text-accent);

    &:hover {
        color: var(--color-text-accent);
    }
}

.success_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.success_title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    color: #00a42a;
}

.success_text {
    max-width: 830px;
    margin-bottom: 20px;
    font-size: 12px;
    text-align: center;

    &__no_margin {
        margin-bottom: 5px;
    }
}
