.timer {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;

  & &__minute {
    align-items: center;
    display: grid;
    gap: 8px;
    grid-template-columns: 70px auto;
  }
}
.text {
  font-size: 12px;
  color: var(--color-text);
  margin-top: 1em;
}
