.container {
  border-radius: 6px;
  border: 1px solid var(--color-black-40);
  background: var(--color-black-0);
  padding: 20px;
}

.studentCardBox {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;

  .birthdayIcon {
    width: 25px;
  }

  .header {
    align-items: center;
    border-bottom: 1px solid var(--color-black-40);
    display: flex;
    gap: 0;
    justify-content: space-between;
    padding: 10px 20px;

    @media (min-width: 1440px) {
      gap: 105px;
    }

    &Comment {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      div {
        &:nth-child(1) {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }

      svg {
        cursor: pointer;
        fill: currentColor;
        height: 18px;
        width: 18px;
      }
    }
  }

  .units {
    color: var(--color-black-80);
    display: grid;
    grid-template-columns: 1fr;
    flex-grow: 1;
    font-size: 12px;
    gap: .5em;
    padding-right: 10px;

    // временно, пока нет responsive
    @media (min-width: 768px){
      font-size: 14px;
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1024px){
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .unitName {
    display: flex;
    flex-direction: column;

    .studentName {
      color: var(--color-black-100);
      font-size: 18px;
      font-weight: 700;
      outline: none;
      position: relative;
      line-height: 27px;
      white-space: nowrap;
      @media (min-width: 768px){
        font-size: 16px;
      }
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        .studentTooltip {
          opacity: 1;
          transform: scale(1);
        }
      }

      .studentTooltip {
        background: white;
        border: 1px solid #bdbdbd;
        border-radius: 3px;
        color: #4d5055;
        font-size: 12px;
        opacity: 0;
        padding: 1em 10px;
        position: absolute;
        top: -150px;
        transform: scale(0);
        transform-origin: center bottom;
        transition: .3s;
        white-space: nowrap;
        z-index: 1000;
        @media (min-width: 768px){
          display: block;
        }
        &::before {
          background-color: white;
          border-top: 1px solid #bdbdbd;
          border-left: 1px solid #bdbdbd;
          bottom: -6px;
          content: "";
          display: block;
          height: 10px;
          left: calc(50% - 7px);
          position: absolute;
          transform: rotate(-135deg);
          width: 10px;
        }
      }

      a {
        color: var(--color-black-100);
        font-size: 18px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.36px
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      gap: 50px;
    }
  }

  .phone {
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
    text-align: start;
    
    @media (min-width: 768px) {
      text-align: center;
    }

    a {
      color: var(--color-black-80);
    }
  }

  .dateOfBirth {
    cursor: help;
    font-size: 14px;
    flex-direction: column;
    position: relative;
    color: var(--color-black-100);
    font-weight: 400;
    letter-spacing: 0.28px;
    @media (min-width: 768px){
      align-items: center;
      display: flex;
    }
    &:hover {
      div {
        opacity: 1;
        transform: scale(1);
      }
    }

    div {
      background: white;
      border: 1px solid #bdbdbd;
      border-radius: 3px;
      color: #4d5055;
      font-size: 12px;
      opacity: 0;
      padding: 1em 10px;
      position: absolute;
      top: -44px;
      transform: scale(0);
      transform-origin: center bottom;
      transition: .3s;
      white-space: nowrap;
      z-index: 1000;
      @media (min-width: 768px){
        display: block;
      }
      &::before {
        background-color: white;
        border-top: 1px solid #bdbdbd;
        border-left: 1px solid #bdbdbd;
        bottom: -6px;
        content: "";
        display: block;
        height: 10px;
        left: calc(50% - 7px);
        position: absolute;
        transform: rotate(-135deg);
        width: 10px;
      }
    }

    .birthday {
      font-weight: 600;
      div {
        color: red;
      }
    }
  }


  .unitAmount {
    display: flex;
    gap: 20px;
    align-items: center;
    color: var(--color-success-1);
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.48px;
    line-height: 40px;

    @media (min-width: 1024px) {
      margin-left: auto;
    }


  }
}

.headerActions {
  align-items: center;
  display: flex;
  gap: 10px;
}

.openInfo {
  background: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  color: var(--color-black-60);
  cursor: pointer;
  display: flex;
  transition: all .3s ease;
  &:hover {
    border: 1px solid var(--color-primary-3);
    color: var(--color-primary-1);
  }
}

.addBalanceBtn {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 30px;
  height: 30px;
  justify-content: center;
  padding: 0;
  color: var(--color-success-1);
  border: 1px solid var(--color-success-1);
  background: var(--color-black-0);

  &:hover {
    background: var(--color-success-4);
    color: var(--color-success-1);
  }
}

.debt {
  border: 1px solid var(--color-danger-1);
  color: var(--color-danger-1);

  &:hover {
    background: var(--color-danger-4);
    color: var(--color-danger-1);
  }
}
