.timeTable {
    background: white;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    margin-top: 20px;

    &__header {
        padding: 30px 20px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__wrapper_btn {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__loader {
        font-size: 12px;
        font-weight: 600;
        padding-left: 10px;
        flex-grow: 1;
    }

    &__control {
        display: flex;
        border: 1px solid #bdbdbd;
        border-radius: 5px;
        padding: 10px;
    }
    &__date {
        font-weight: 600;
        font-size: 18px;
    }
    &__table {
        overflow-x: scroll;
        overflow-y: visible;
        -ms-overflow-style: none ;
        scrollbar-width: none ;
        position: relative;

    }

    &__table::-webkit-scrollbar {
        width: 0px ;
        background: transparent ;
        width: 0;
        height: 8px;
        border-radius: 8px;
    }

    &__row {
        display: grid;
        width: 1128px;
        grid-template-columns: 200px 1fr;
        height: 40px;
        overflow-y: auto;
        overflow-y: visible;
        border-top: 1px solid #bdbdbd;
    }
    &__row_empty {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #bdbdbd;
    }

    &__wrapper_slot {
        display: flex;

    }

    &__col {
        display: flex;
        width: 60px;
        box-sizing: border-box;
        border-right: 1px solid #bdbdbd;
        
    }

    &__timeLine {
        padding: 5px 0;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #bdbdbd;
    }

    &__col_hour {
        width: 60px;
        text-align: center;
    }

    &__col_hour:last-child {
        width: 30px;
        text-align: right;
    }

    &__col_teacher {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        width: 200px;
        padding: 0 10px;
        border-right: 1px solid #bdbdbd;
        font-weight: 500;

        span {
            font-size: 13px;
        }
    }

    &__timeSlot {
        background-color: transparent;
        border-radius: 5px;
        box-sizing: border-box;
        width: 5px;
        position: absolute;
        top: 1px;
        height: 38px;

    }

    &__timeStap_standart {
        border-radius: 5px;
        width: 5px;
        background-color: yellowgreen;
        border: 1px solid #bdbdbd;
    }

    &__timeStap_helpClub {
        border-radius: 5px;
        width: 5px;
        background-color: yellow;
    }

    &__wrapper_tooltip {
        position: relative;
        height: 0;
    }
    &__text_info {
        font-size: 18px;
        color: #bdbdbd;
        text-align: center;
    }
}

.calendar {
    position: relative;
    height: 30px;
    width: 30px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
        opacity: 1;
        width: 15px;
        height: 15px;
        cursor: pointer;

    }
  
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        height: 30px;
        width: 30px;
        z-index: 0;
        cursor: pointer;
    }
}