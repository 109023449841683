.placementTestCreate__result {
  .placementTestBox {
    &__subtitle,
    &__text {
      margin-bottom: 8px;
    }
    &__sample {
        padding: 20px;
      p {
        margin-bottom: 10px;
        font-size: 14px;
        color: #5f6368;
        font-weight: 400;
      }
    }
  }
}
