.mainTableRowScore {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.bigNumbers {
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}

.largeNumber {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  color: var(--color-primary-1);

}

.secondaryNumber {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-primary-2);
}

.scoreInfo {
  display: flex;
  flex-direction: column;
}

.verticalLine {
  color: var(--color-black-40);
  padding: 0 5px;
  width: 12px;
}

.fontWeightNumbers {
  font-weight: 700;
  font-size: 16px;
  padding-left: 10px;
}

.numbers {
  font-weight: 400;
  font-size: 16px;
}

.percentNumbers {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-primary-2);
}

.questionInfo, .scoreNumbers {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  font-weight: 400;
  font-size: 14px;
  margin-top: 6px;
}

