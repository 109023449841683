.emailReader {

}

.content {
  background-color: transparent;
  margin: auto;
  max-width: 800px;
  position: relative;
}

.notFound {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  position: relative;

  span {
    margin-top: 20px;
    text-align: center;
  }
}

.strikethrough {
  position: absolute;
  transform: rotate(-45deg);
  top: 35px;
  width: 80px;
  font-size: 40px;
  border-bottom: 3px solid red;
}
