.box {
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.list {
    padding: 0 5px;
}

.item {
    font-weight: 600;
    margin-bottom: 15px;
}