.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sentence, .words {
  .word {
    border: 1px solid var(--color-outline);
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.5;
    padding: 0 1em;
    &:hover {
      background-color: var(--color-accent-600);
      border-color: var(--color-accent-600);
      color: white;
    }
  }
}
.sentence {
  align-items: center;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 75px;
  padding: 10px;
}
.words {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
.empty {
  color: #c2c5ca;
}
