.placementTestInfo {
  position: relative;
  top: 1px;
  &:hover {
    .placementTestInfo__modal {
      display: block;
    }
  }
  &__modal {
    width: 240px;
    min-height: 80px;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    content: "";
    top: 155%;
    right: -126%;
    background: white;
    z-index: 3;
    display: none;
    &--active {
      display: block;
    }
    &::before {
      content: "";
      position: absolute;
      top: -12px;
      left: 148px;
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 12px solid #c5c5c5;
    }
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      left: 149px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }
  }
  &__title {
    font-size: 14px;
    color: #3f4d63;
    font-weight: 500;
    margin-bottom: 10px;
  }
  &__text {
    font-size: 12px;
    color: #5f6368;
    font-weight: 300;
    margin-bottom: 5px;
    font-style: italic;
  }
}
