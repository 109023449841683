.chatContainer {
	width: 320px;
	max-height: calc(100vh - 60px);
	background-color: var(--color-black-0);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.chatHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px;
	background: var(--color-primary-1);
}

.title {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: var(--color-black-0);
}

.closeBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	width: 20px;
	height: 20px;
	border: none;
	background: var(--color-primary-3);
}

.chatMessages {
	flex-grow: 1;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
}

.alert {
	margin-bottom: 20px;
}

.chatFooter {
	display: flex;
	justify-content: space-between;
	padding: 6px 10px;
	border-top: 1px solid var(--color-black-40);

	.inputMessage {
		width: 158px;
		height: 36px;
		background-color: var(--color-black-10);
		border: none;
		border-radius: 7px;

		&::placeholder {
			font-weight: 300;
			color: var(--color-black-100);
		}
	}

	.sendBtn {
		width: 130px;
	}
}

.loader {
	margin: 0 auto;
	width: 50px;
	height: 50px;
}
