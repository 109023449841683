.groupForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.fieldSet {
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.optionsStatus {
  align-items: center;
  color: var(--color-success-1);
  display: flex;
  gap: 8px;
  &--2, &--8 {
    color: var(--color-secondary-1);
  }
  &--4, &--5 {
    color: var(--color-warning-1);
  }
  &--3, &--6 {
    color: var(--color-danger-1);
  }
  &--7 {
    color: var(--color-black-80);
  }

  svg {
    height: 16px;
    width: 16px;
  }
}
.fieldTypeCapacity {
  display: grid;
  gap: 10px;
  grid-template-columns: 3fr 2fr;
}
.fieldFormat {
  grid-column: 1/3;
}
