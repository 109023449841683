.placementTestCreate {
  button {
    font-family: "Montserrat";
  }
  .placementTestBox {
    background: white;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    width: calc(100% - 30px);
    margin: 20px auto 0;
    flex-direction: column;
    &__mainBoxLine {
      margin-bottom: 16px;
    }
    &__safeUser {
      width: 100%;
      input {
        max-width: 580px;
      }
    }
    &__block {
      width: 20px;
      height: 20px;
      background: white;
    }
    &__row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &.space-between {
        justify-content: space-between;
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__title {
      color: #5f6368;
      font-size: 20px;
      font-weight: 600;
      @media screen and (max-width: 500px) {
        text-align: center;
      }
    }
    &__subtitle {
      font-size: 14px;
      color: #5f6368;
      font-weight: 600;
    }
    &__text {
      font-size: 12px;
      color: #5f6368;
      font-weight: 400;
    }
    &__field {
      width: 334px;
      margin-bottom: 18px;
      @media screen and (max-width: 450px) {
        width: 240px;
      }
      .selectCustom .selectCustom__control {
        width: 100%;
      }
    }
    &__plus {
      font-size: 40px;
      font-weight: 500;
      color: #5a8dee;
      cursor: pointer;
    }
    &__input {
      width: 100%;
      height: 45px;
      color: #5f6368;
      border: 1px solid #bdbdbd;
      border-radius: 5px;
      padding-left: 20px;
      &:hover {
        border: 1px solid #bdbdbd;
        box-shadow: 0 0 0 3px #b8d4ff;
        transition: 0.3s;
      }
      &:focus {
        border: 1px solid #0062ba;
        box-shadow: 0 0 0 3px #b8d4ff;
      }
      &--error {
        border-color: #db3031;
        &:hover,
        &:focus {
          border-color: #db3031;
          box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.3);
        }
      }
    }
    .super-modal__form-label {
      margin-bottom: 8px;
    }
    &__editBtn {
      width: 320px;
      padding: 10px 0;
      border: solid 2px #3f4d63;
      border-radius: 3px;
      color: #3f4d63;
      font-size: 14px;
      font-weight: 500;
      margin: 0 auto;
      @media screen and (max-width: 400px) {
        width: 240px;
      }
      &:hover {
        background: #3f4d63;
        color: white;
      }
      &:disabled {
        color: white;
        background: #3f4d63;
      }
    }
  }
}
.placementTestBox__textarea {
  border-radius: 5px;
  border: solid 1px #bdbdbd;
  resize: none;
  padding: 15px 20px;
  font-size: 14px;
  color: #5f6368;
  font-weight: 400;
  font-family: "Montserrat";
  &:hover {
    border: 1px solid #bdbdbd;
    box-shadow: 0 0 0 3px #b8d4ff;
    transition: 0.3s;
  }
  &:focus {
    border: 1px solid #0062ba;
    box-shadow: 0 0 0 3px #b8d4ff;
  }
  &--error {
    border-color: #db3031;
    &:hover,
    &:focus {
      border-color: #db3031;
      box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.3);
    }
  }
}
