.title {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: gray;

}


.box {
    padding-top: 20px;
    margin-top: 20px;
    background: white;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.noData {
    text-align: center;
}