.display_none {
    display: none;
}

.title {
    &__error {
        color: #db3031;
        padding-top: 10px;
        font-size: 13px;
    }

    &__box {
        padding: 0 15px;
        margin-bottom: 20px;
    }

    &__input {
        padding-left: 10px;
        padding-right: 10px;
        min-width: 280px;
    }
    &__input_error {
        border-color: #db3031;
        padding-left: 10px;
        padding-right: 10px;
        min-width: 280px;
        color: #db3031;
    }
}



.container {
    padding: 0 10px;
    display: grid;
    grid-template-columns: minmax(500px, 800px) minmax(100px, 180px);
    gap: 20px;
    height: calc(100vh - 300px);
    min-height: 500px;
}

.content {

    border: 1px solid gray;
    border-radius: 6px;
    min-height: 60vh;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;

    &__wrapper {
        overflow-y: scroll;
        height: 100%;
        background: #edebea;
        padding: 30px;
    }
}

.list {
    background: white;
    padding: 15px 80px;
    min-height: 100vh;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

}

.sideBar {

    background: white;
    border: 1px solid gray;
    border-radius: 6px;
    max-height: 100%;
    overflow: hidden;


    &__title {
        padding: 10px;
        font-size: 18px;
        border-bottom: 1px solid gray;
    }

    &__box {
        padding: 10px;
        display: flex;
        flex-direction: column;

        gap: 10px;
        height: calc(100% - 50px);
        overflow-y: scroll;
    }
}

.footer {
    padding: 15px;
    display: flex;
    gap: 15px;
}

.button {
    width: 100%;
    border: 1px solid #b8d4ff;
    background: #f4f8ff;
    text-align: left;
    color: #5f6368;
}

.button_link {
    border: 1px solid #b8d4ff;
    background: #f4f8ff;
    text-align: center;
    color: #5f6368;
    margin: 0 auto;
}



@media (max-width: 767px) {
    .container {
        grid-template-columns: 1fr;
        gap: 20px;
        height: calc(100vh - 100px);
        min-height: 500px;
    }

    .content {
        overflow-x: scroll;
    }

    .contant__wrapper {
        min-width: 600px;
    }

    .sideBar {
        grid-row-start: 1;
        grid-row-end: 2;
        height: 140px;
        
        &__box {
            flex-direction: row;
            overflow-y: hidden;
            overflow-x: scroll;
        }
    }

    .button {
        min-width: 120px;
        text-align: center;
        font-size: 12px;
        padding: 6px 12px;
        flex-grow: 1;
    }
}