.questionImagesCarousel {

  &__slider {
    //height: 420px;
    overflow: hidden;
    position: relative;
    white-space: pre;
  }

  ul {
    display: flex;
    gap: 20px;
    left: 0;
    position: relative;
    transition: .3s;
  }

  li {
    color: #5f6368;
    flex: 0 0 100%;
    font-style: italic;
    line-height: 2;
    text-align: right;
    @media (min-width: 768px){
      flex-basis: auto;
    }

    div {
      &:first-child {
        width: 100%;
        @media (min-width: 768px){
          height: 400px;
          width: auto;
        }
      }
      &:last-child {
        padding-right: 5px;
      }
    }

    img {
      display: block;
      height: 100%;
      object-fit: contain;
      width: 100%;
      @media (min-width: 768px){
        width: auto;
      }
    }
  }

  &__control {

    bottom: 20px;
    display: flex;
    gap: 10px;
    position: absolute;
    user-select: none;
    @media (min-width: 768px){
      bottom: 48px;
      gap: 20px;
      left: 20px;
    }

    div, span {
      align-items: center;
      background-color: white;
      border-bottom: 2px solid #5a8dee;
      display: flex;
      height: 30px;
      justify-content: center;
      width: 80px;
    }

    div {
      color: #5a8dee;
      cursor: pointer;
      transition: .3s;

      svg {
        fill: currentColor;
        height: 50%;
      }

      &:last-child {
        svg {
          transform: rotate(180deg);
        }
      }

      &[data-disabled="true"]{
        border-color: #bdbdbd;
        color: #bdbdbd;
        cursor: default;
      }
    }

    span {
      color: #000;
    }
  }
}