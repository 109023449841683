@import "../MainStyles";

.answerBody {
  @include answerBodyMain;
  padding: 20px 10px 10px 10px;

  .span {
    align-self: flex-start;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.36px;
  }
}

.spnAnswer{
  font-weight: 700;
}

.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.correctColor {
  @include correctColorMain;
}

.rightAnswer {
  @include rightAnswerMain;
  font-weight: 700;
}

.wrongAnswer {
  @include wrongAnswerMain;
  font-weight: 700;
}

.usualText {
color: var(--color-primary-1);
}