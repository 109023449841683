.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 20px;
    justify-content: space-between;
}

.item {
    position: relative;
    padding: 20px 20px 25px;
    width: 200px;
    border: 1px solid var(--color-outline);
    border-radius: 5px;
    transition: background-color 0.3s, border-bottom-color 0.3s;
    cursor: pointer;

    &:hover {
        background-color: #f1f8ff;
    }

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 5px;
        bottom: 0;
        left: 0;
        border-radius: 0 0 5px 5px;
        transition: background-color 0.3s;
    }
}

.item_selected {
    background-color: #f1f8ff;
    border-bottom-color: var(--color-text-accent);

    &::after {
        background-color: var(--color-text-accent);
    }

    .custom_radio {
        border-color: var(--color-text-accent);
        background-color: var(--color-text-accent);
    }
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image_wrapper {
    height: 70px;

    img {
        width: 150px;
        object-fit: contain;
    }
}

.name {
    height: 30px;
    margin-bottom: 15px;
    font-weight: 500;
    text-align: center;
}

.name_kaspi {
    width: 100px;
}

.custom_radio {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-outline);
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;

    &::before {
        content: "";
        width: 5px;
        height: 5px;
        background-color: white;
        border-radius: 50%;
    }
}
