.box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  //padding: 10px;

  .span {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-black-100);
  }

  .input {
    text-align: center;
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    color: var(--color-black-60);
  }
}