.lessonQuestion {
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-bottom: none;
  &--first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &--last {
    border-bottom: 1px solid #bdbdbd;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__editModePanel {
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    padding: 20px;
    height: 50px;
  }

  &__editModePanelEditButton {
    cursor: pointer;
    box-sizing: border-box;
    width: 120px;
    height: 38px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    font-size: 12px;
    line-height: 30px;
    color: #000000;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #f5f5f5;
      border: 1px solid #3474ff;
      color: #3474ff;
    }
  }

  &__editModePanelMenu {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__teacherPanel {
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    gap: 10px;
    padding: 20px;
  }

  &__teacherPanelStudents {
    width: 350px;

    .selectCustom .selectCustom__control {
      min-height: 30px;
    }
  }

  &__teacherPanelAttempts {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    gap: 10px;
    margin-right: auto;

    button {
      height: 35px;
      padding: 0;
      width: 35px;
    }
  }

  &__teacherMenu {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__teacherMenuButtons, &__editModePanelMenuButtons {
    width: 38px;
    height: 38px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: black;
      height: 15px;
    }

    &:hover {
      background-color: #f5f5f5;
      border: 1px solid #3474ff;

      svg {
        fill: #3474ff;
      }
    }
  }

  &__header {
    align-items: center;
    display: flex;
    padding: 20px 20px 0;

    h3 {
      color: black;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.25;
      margin-left: 20px;
      padding-right: 20px;
    }
  }

  &__number {
    align-self: flex-start;
    background-color: #5a8dee;
    border-radius: 5px;
    color: white;
    display: block;
    flex-shrink: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    text-align: center;
    width: 35px;
  }

  &__action {
    margin-left: auto;
  }

  &__content {
    padding: 20px;
  }

  &__wordsBox {
    border: 1px dashed #c2c5ca;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    user-select: none;

    span {
      background-color: white;
      border: 1px solid #c2c5ca;
      border-radius: 10px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      min-width: 80px;
      padding: 10px 20px;
      text-align: center;
      &:hover {
        background-color: #5a8dee;
        border-color: #5a8dee;
        color: white;
      }

      &.onDrag {
        //opacity: .5;
      }
    }
  }

  &__studentPanel {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid #bdbdbd;
    justify-content: space-between;
  }
  
  &__studentPanelAttempts {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  &__studentPanelAttemptsButton {
    box-sizing: border-box;
    cursor: pointer;
    width: 38px;
    height: 38px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #3f4d63;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 30px;
    color: #3f4d63;
    font-weight: 400;
      &--inactive {
        border: 1px solid #bdbdbd;;
        color: #bdbdbd;
      }
      &--checked {
        background-color: #f5f5f5;
        border: 1px solid #3474ff;
        color: #3474ff;
      }
      &:hover {
        background-color: #f5f5f5;
        border: 1px solid #3474ff;
        color: #3474ff;
      }
  }

  &__studentPanelSaveResultsButton {
    box-sizing: border-box;
    padding: 0;
    transition: none;
    width: 150px;
    height: 38px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #3f4d63;
    font-size: 12px;
    line-height: 30px;
    color: #3f4d63;
    font-weight: 400;

    &--inactive {
      background-color: #ffffff;
      border: 1px solid #bdbdbd;
      color: #bdbdbd;
      pointer-events: none;
    }

    &:hover {
      background-color: #f5f5f5;
      border: 1px solid #3474ff;
      color: #3474ff;
    }
  }

  &__studentPanelTimer {  
    box-sizing: border-box;
    margin-left: auto;
    margin-right: 20px;
    width: 70px;
    height: 38px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 2px solid #3f4d63;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #3f4d63;
  }

  &__contentText {
    color: #5f6368;
    font-size: 14px;
    line-height: 2.5;
  }

  &__contentTextOnly {
    color: #5f6368;
    font-size: 14px;
    line-height: 1.7;
  }

  &__correctAnswer {
    background-color: #b1f5cf !important;
    border: 1px solid #32b76c !important;
  }

  &__wrongAnswer {
    background-color: #ffdae7 !important;
    border: 1px solid #d80027 !important;
  }
}

.questionMakeSentence {
  &__sentence {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;

    span {
      background-color: white;
      border: 1px solid #b5b5b5;
      border-radius: 10px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      height: 35px;
      line-height: 31px;
      min-width: 150px;
      padding: 0 20px;
      text-align: center;
      transform: scale(1);
      transition: .3s;

      &:empty {
        background-color: #f9f9f9;
      }

      &.onDragOver {
        background-color: #c9dcff;
      }
    }
  }
}

.questionSelectWords {
  select {
    height: 35px;
    margin: 0 .5em;
  }
}

.questionMatchWords {
  display: flex;
  //gap: 20px;
  position: relative;

  &__col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 20px;
    width: 50%;
  }

  &__word {
    background-color: white;
    border: 1px solid #d5d7da;
    border-radius: 10px;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    position: relative;
    z-index: 1;

    &.clicked {
      border-color: #3f4d63;
    }
  }

  svg {
    left: 0;
    height: 100%;
    position: absolute;
    stroke-width: 1px;
    stroke: #3f4d63;
    top: 0;
    width: 100%;

    path {
      transition: .3s;
    }
  }
}

.questionMakeWord {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__content {
    flex-direction: column;
  }

  p {
    color: #5f6368;
    font-size: 16px;
    b {
      font-weight: 600;
    }
  }

  div {
    display: flex;
    gap: 20px;

    &:first-of-type span {
      border-color: #353f4d;
      &:empty {
        border-color: #bdbdbd;
      }
    }

    &:last-child {
      span:hover  {
        background-color: #5a8dee;
        border-color: #5a8dee;
        color: white;
      }
    }
  }

  span {
    align-items: center;
    background-color: white;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    color: #5f6368;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 45px;
    justify-content: center;
    text-transform: uppercase;
    transition: .3s;
    width: 45px;

    &:empty {
      background-color: #f2f2f2;
    }

    &.onDragOver {
      background-color: #c9dcff;
      transform: scale(1.2);
    }
  }
}

.questionGroupWords {
  &__cols {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }

  &__col {
    border: 1px solid #c2c5ca;
    border-radius: 5px;
    flex: 1 1 48%;
    overflow: hidden;
    transition: .3s;

    &.onDragOver {
      border-color: #3f4d63;
      box-shadow: inset 0 0 10px #3f4d63;
      header {
        background-color: #3f4d63;
        color: white;
      }
    }

    * {
      pointer-events: none;
    }

    header {
      align-items: center;
      background-color: #c9dcff;
      color: #000;
      display: flex;
      font-size: 16px;
      font-weight: 500;
      height: 45px;
      padding: 0 20px;
      transition: .3s;
    }

    article {
      padding: 20px;
    }

    p {
      color: #5f6368;
      font-size: 14px;
      text-align: center;
    }
  }

  &__dropzone {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 1em;
    min-height: 45px;

    span {
      border: 1px solid #c2c5ca;
      border-radius: 10px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      height: 30px;
      line-height: 30px;
      min-width: 80px;
      padding: 0 1em;
      pointer-events: all;
      text-align: center;
    }
  }
}

.questionButtonLink {
  text-align: center;

  a {
    background-color: white;
    border: 2px solid #3f4d63;
    border-radius: 5px;
    color: #3f4d63;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.5;
    margin: 0 auto;
    padding: 0 1em;
    transition: .3s;
    &:hover {
      background-color: #3f4d63;
      color: white;
    }
  }
}

audio{
  width: 100%;
}
//Unknown pseudo selector '-webkit-media-controls-panel'
/*
audio::-webkit-media-controls-panel{
  background-color: #fff;
}*/