.groupSimpleMainBox {
  
    &__row {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
  
      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
  
    }
   
    &__card {
      background-color: white;
      border: 1px dashed var(--color-primary-2);
      background: var(--color-primary-4);
      border-radius: 7px;
      padding: 5px 20px;
      width: 100%;
    }
  }
  
  .groupSimpleBox {
   
    &__container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      width: 100%;
  
  
      &__valueName {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--color-black-80);
  
        &__optionValue {
          color: var(--color-black-60);
          font-weight: 400;
        }
  
        .delimiter {
          width: 1px;
          height: 5px;
          background: var(--color-black-60);
        }
  
        div {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }
  
      &__value {
        display: flex;
        gap: 10px;
        font-size: 18px;
        align-items: center;
        font-weight: 800;
  
        .delimiter {
          width: 2px;
          height: 10px;
          background: var(--color-black-60);
        }
      }
    }
  }
  .debtors {
    color: var(--color-danger-1);
  }
  
  .progress {
    display: flex;
    width: 100%;
    height: 7px;
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    background: var(--color-black-60);
  
    &__completed {
      background: var(--color-success-1);
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
    }
  
    &__remaind {
      background: var(--color-danger-1);
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }
  