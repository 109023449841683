.timeTableForm {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__col {
    @media (min-width: 768px) {
      width: calc((100% - 40px) / 3);
    }
  }

  &__teachers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      width: calc((100% - 40px) / 3 * 2 + 20px);
    }
  }

  &__teacher {
    align-items: center;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr auto auto;
  }

  &__teacherAdd, &__teacherDel {
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  &__teacherAdd {
    background-image: linear-gradient(#5a8dee, #5a8dee), linear-gradient(#5a8dee, #5a8dee);
    background-size: 100% 3px, 3px 100%;
  }

  &__teacherDel {
    background-image: linear-gradient(black, black), linear-gradient(black, black);
    background-size: 100% 2px, 2px 100%;
    transform: rotate(45deg);
  }

  &__note {
    background-color: #b8d4ff;
    border: 1px solid #abcae4;
    font-size: 14px;
    line-height: 2;
    padding: 10px 20px;
  }

  // Переопределение стилей
  & &__row {
    input {
      &:hover, &:focus {
        box-shadow: none;
      }
    }
  }

  .dataPickerSplit {
    &__middle {
      width: 45px;
    }
    input {
      &:hover, &:focus {
        border: none;
      }
    }
  }

  .dow-box {
    margin: 0;
    width: 100%;
  }

  .hour-counter {
    margin: 0;
    width: 100%;
    &-but {
      flex-shrink: 0;
    }
    &-info {
      border-left: 1px solid #bdbdbd;
      flex-shrink: 0;
      width: 54px;
    }
  }

  .datetime-box {
    &-middle {
      flex-shrink: 0;
      width: 45px;
    }
  }
}
