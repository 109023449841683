.emptyList {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  text-align: center;

  h3 {
    color: var(--color-black-100);
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: .4px;
  }

  p {
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: .28px;
  }

  .button {
    max-width: 270px;
    width: 100%;
    height: 48px;
    border: none;
    color: var(--color-black-0);
    border-radius: 7px;
    background: var(--color-primary-1);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.42px;

    &:hover {
      background: var(--color-primary-2);
    }
  }
}
