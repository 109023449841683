.page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 320px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.header {
    min-height: 300px;
    padding: 70px 0 30px;
    background-image: linear-gradient(#1c3c50, #0099dd);
}
.title {
    max-width: 1070px;
    margin: 0 auto 45px;
    padding: 0 15px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: white;

    @media (min-width: 768px) {
        font-size: 35px;
    }
}
.teacherCard {
    margin: 0 30px;
    &__wrapper {
        max-width: 1045px;
        width: 100%;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 5px;
        padding: 13px;
        display: flex;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
    img {
        width: 110px;
        height: 110px;
    }
    .avatar, img {
        margin-right: 20px;
    }
    &__content {
        margin-top: 15px;
        p {
            &:nth-child(1) {
                margin-bottom: 25px;
                font-weight: 500;
                color: #5f6368;
                font-size: 20px;
            }
            &:nth-child(2) {
                font-size: 16px;
                font-weight: 600;
                color: #5f6368;
            }
        }
    }
}
.center {
    margin: 0 15px;
    .wrapper {
        max-width: 1175px;
        width: 100%;
        margin: 0 auto;
        background-color: #e0e7ef;
        min-height: 100vh;
        box-shadow: 0px 1px 8px 5px rgba(34, 60, 80, 0.29);
        padding: 25px 15px;
    }
}
.error {
    text-align: center;
    margin-top: 40px;
}
.footer {
    width: 100%;
    max-width: 1070px;
    margin: 0 auto;
    padding: 0 15px 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;

    @media (min-width: 768px) {
        padding: 0 30px 40px;
    }
    &_text {
        margin-right: 20px;
    }
    &_img {
        width: 20px;
    }
}
