.coursePage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 15px;

  &__addLessonButton {
    font-family: inherit;
    font-weight: 500;
    width: 100%;
  }

  &__lessons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

}
