.container {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px dashed var(--color-primary-2);
  background: var(--color-primary-4);
  padding: 10px;
  gap: 20px;
  justify-content: space-between;

  .mainBlock {
    display: flex;
    align-items: center;
  }

  span {
    color: var(--color-primary-2);
    font-size: 18px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0.36px;
  }

  svg {
    color: var(--color-black-0);
  }
}

.btn {
  color: var(--color-primary-2);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;
  border-radius: 7px;
  padding: 10px;
  border: 2px solid var(--color-primary-2);
  background: var(--color-success-4);

  &:hover {
    background: var(--color-primary-2);
  }
}