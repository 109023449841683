.contentBox {
  display: flex;
  padding: 20px 0;
}
.removePadding {
  padding: 0;
}

.content {
  flex-grow: 1;
}

.sidebar {
  border-left: 1px solid var(--color-black-60);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
  padding-left: 10px;
}
