.placementTestCreate__options {
  .placementTestLogoBox__wrapper {
   @media screen and (max-width: 400px) {
     width: 230px;
   }
  }
  .placementTestBox {
    &__url {
      @media screen and (max-width: 880px) {
        text-align: center;
      }
      .placementTestInputType {
        width: 67%;
        margin-right: 20px;
        @media screen and (max-width: 880px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    &__row {
      @media screen and (max-width: 880px) {
        flex-direction: column;
        text-align: center;
        margin-top: 17px;
      }
    }
    &__list {
      &:nth-last-child(1) {
        p {
          margin-top: 5px;
        }
      }
    }
  }
}
