.lessonToolbar {
  background-color: var(--color-primary-1);
  display: flex;
  height: 60px;
  justify-content: space-between;
  left: 0;
  padding: 5px 20px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.buttons {
  display: flex;
}
.button {
  color: var(--color-black-0);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;
  gap: 10px;
  align-items: center;
  height: 100%;
    span {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
  }
}
.link .button {
  color: var(--color-black-60);
}
.drawing_board {
  color: var(--color-black-60);
}
.separator {
  background-color: var(--color-black-60);
  height: 50px;
  margin: 0 20px;
  width: 1px;
}
