.fieldset {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  color: var(--color-text);
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
}
.row {
  align-items: center;
  border-bottom: 1px solid var(--color-outline);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: .5em 30px .5em 0;
}
.confirm {
  background-color: #ffdae7;
  border: 1px solid #d80027;
  border-radius: 5px;
  padding: 15px 20px;
}
