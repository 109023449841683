.header, .body, .footer {
  padding: 1em 15px;
  @media (min-width: 768px) {
    padding: 1em 20px;
  }
}

.container {
  background: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  font-family: 'Manrope', sans-serif;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 20px;
    font-weight: 500;
  }
}

.addButton {
  align-items: center;
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  color: var(--color-black-60);
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 0;
  transition: .3s;
  width: 30px;
  &:hover {
    background-color: var(--color-black-0);
    border-color: var(--color-primary-3);
    color: var(--color-primary-2);
    cursor: pointer;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.body{
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
  padding: 15px;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(7, 1fr);
  }
}

.bodyNotFound {
  padding: 15px;
  border-top: 1px solid var(--color-black-40);
}

.notFound {
  text-align: center;
}

.imgNotFound {
  width: 100%;
  max-width: 380px;
}

.link {
  border-radius: 5px;
  border: 1px solid var(--color-black-40);
  padding: 5px;
}

