.teacherGroup {
  padding-top: 20px;
}
.container {
  padding: 0 15px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.noStudents {
  align-items: center;
  color: #959595;
  display: flex;
  justify-content: center;
  height: 200px;
  font-size: 20px;
  text-align: center;
}
