.wrapper {
  display: flex;
  min-height: 100vh;
  min-width: 320px;
  //max-width: 1170px;
  margin: auto;
  font-size: 14px;
}

.select-type2 {
  background: white url("components/ContentBox/img/select-down.svg") center right 10px no-repeat;
  background-size: 8px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  color: #5f6368;
  height: 45px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 20px;
  -webkit-appearance: none;
}
.bn{
  border: none !important;
}
.wm40{
  min-width: 40% !important;
}
.tar{
  text-align: right;
  padding-right: 20px;  
}
.strong{
  font-weight: 600;
}
.aml{
  margin-left: auto;
}
.pb10{
  padding-bottom: 10px !important;
}
.notification{
  color: #959595;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.pl35{
  padding-left: 35px;
}
.mb15{
  margin-bottom: 15px !important;
}
.button-type1 {
  background: white;
  border: 2px solid #3f4d63;
  border-radius: 5px;
  color: #3f4d63;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  line-height: 41px;
  padding: 0 20px;
  text-align: center;
  transition: .3s;
  &:hover {
    background-color: #3f4d63;
    color: white;
  }

  &--disabled {
    border-color: #b3b3b3;
    color: #b3b3b3;
    cursor: not-allowed;
    &:hover {
      background-color: white;
      color: #b3b3b3;
    }
  }
}
.phoenixBox {
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

.onlineSchedulePage {
  padding: 18px 15px;

  &__mt20 {
    margin-top: 20px;
  }
}

.p20{
  padding: 20px;
}
//TMP
.flexLine{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  span{
    display: flex;
    overflow: hidden;
    width: 50%;
    cursor: pointer;
    justify-content: flex-end;
    &:hover{
      text-decoration: underline;
    }
  }
}




/*------------Модальные окна SweetAlert------------*/

.swal-modal {
  will-change: auto !important; // убирает blur

  .swal-title{
    color: #3f4d63;
    font-size: 18px;
  }

  .swal-text{
    color: #3f4d63;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }

  .swal-button{
    background-color: #fff;
    border: 2px solid #3f4d63;
    color: #3f4d63;
    font-family: inherit;
    line-height: 36px;
    padding: 0 20px;
    transition: .3s;
    &:hover {
      background-color: #3f4d63;
      color: #ffffff;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &--cancel {
      background-color: #ffffff;
      border: 2px solid #ffffff;
      color: #3f4d63;
      &:hover {
        background-color: #f2f2f2;
        border-color: #f2f2f2;
        color: #3f4d63;
      }
    }

    &--danger {
      border-color: #a50001;
      color: #a50001;
      &:hover {
        background-color: #db3031;
        color: white;
      }
    }
  }
}

/*------------Модальные окна SweetAlert------------*/