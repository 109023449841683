.employeeCard {
  font-family: 'Manrope', sans-serif;
  padding: 20px 10px;
  @media (min-width: 768px) {
    padding: 20px;
  }
}
.employeeCardGrid {
  display: grid;
  gap: 20px;
  @media (min-width: 1024px) {
    grid-template-columns: 256px 1fr;
  }
}
.employeeCardGridColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.menu {
  @media (min-width: 1024px) {
    display: none;
  }
}
.notifications {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 13px;
}
