.placementTestCreate__crm {
  .placementTestBox {
    &__text {
      margin-top: 7px;
    }
    &__input {
      max-width: 580px;
    }
  }
}
