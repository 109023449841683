.formInput{
  input, textarea {
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    color: var(--color-black-80);
    font-family: inherit;
    transition: all .3s;
    width: 100%;
    &::placeholder {
      color: var(--color-black-60);
    }
    &:hover {
      box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60);
    }
    &:focus {
      border-color: var(--color-primary-2);
      box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60), inset 0 0 0 1px var(--color-primary-2);
    }
  }

  input {
    padding: 0 8px;
    height: 48px;
  }

  textarea {
    padding: 16px 8px;
    height: 120px;
  }
}
.formInputError {
  composes: formInput;

  input {
    border-color: var(--color-danger-1);

    &::placeholder {
      color: var(--color-danger-1);
    }

    &:hover {
      box-shadow: 0 0 0 3px rgba(255, 0, 0, .3);
    }
  }
}
