.employeeSkill {
  align-items: center;
  background-color: var(--color-black-0);
  display: grid;
  grid-template-columns: 188px 1fr 0;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  transition: all .3s;
  @media (min-width: 768px){
    grid-template-columns: 188px 1fr 70px;
    align-items: flex-start;
    padding: 20px;
  }
  &:hover {
    background-color: var(--color-primary-4);

    .btn {
      border-color: var(--color-primary-3);
      color: var(--color-primary-2);
    }
  }
}
.title {
  font-weight: 700;
}

.info {
  color: var(--color-black-80);
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
  grid-row: 2/3;
  padding-left: 1em;
  line-height: 20px;
  padding-top: 5px;

  .programType,
  .bid {
    color: var(--color-black-100);
    font-weight: 500;
  }

  &.salary {
    gap: 30px;
  }
  @media (min-width: 768px) {
    grid-column: auto;
    grid-row: auto;
    line-height: 24px;
    padding-top: 0;
  }
  @media (min-width: 1024px){
    flex-direction: column;
    max-width: 700px;
    flex-wrap: wrap;
  }

  &__list{
    display: flex;
  }

  span:nth-child(n+1){
    display: flex;
  }
}
.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.btn {
  align-items: center;
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  color: var(--color-black-60);
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
  padding: 2px;

  &Edit {
    svg {
      height: 18px;
      width: 18px;
    }
  }
  &Close, &Open {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  &Close svg {
    transform: rotate(180deg);
  }
}
