.box {
  border: 2px dashed var(--color-black-60);
  background-color: var(--color-black-20);
  border-radius: 6px;
  padding: 23px;
}

.crsPointer {
  cursor: pointer;
}

.boxError {
  composes: box;
  border-color: var(--color-error);
}
.choose {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 20px;

  input[type="file"] {
    display: none;
  }
}
.tip {
  align-items: center;
  color: var(--color-black-80);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  span {
    text-transform: uppercase;
    font-weight: lighter;
  }
}
.progress {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  progress {
    background-color: #3f4d63;
    border-radius: 5px;
    height: 10px;
    width: 100%;
    &::-webkit-progress-bar {
      background-color: #bdbdbd;
      border-radius: 5px;
    }
    &::-webkit-progress-value {
      background-color: #3f4d63;
      border-radius: 5px;
    }
  }

  div {
    margin-top: 1em;
  }
}
.variant {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}
.wrapImg {
  height: 84px;
}

.uploadIcon {
  width: 28px;
  height: 28px;
}

.remove {
  position: absolute;
  right: -10px;
  top: -10px;
}