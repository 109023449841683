.box {
  cursor: pointer;
  border: 2px dashed var(--color-black-60);
  background-color: var(--color-black-20);
  border-radius: 6px;
  padding: 10px;
}
.boxError {
  composes: box;
  border-color: var(--color-error);
}
.choose {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

  input[type="file"] {
    display: none;
  }
}

.tip {
  align-items: center;
  color: var(--color-black-80);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  span {
    text-transform: uppercase;
    font-weight: 400;
  }
}

.progress {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  progress {
    background-color: #3f4d63;
    border-radius: 5px;
    height: 10px;
    width: 100%;
    &::-webkit-progress-bar {
      background-color: #bdbdbd;
      border-radius: 5px;
    }
    &::-webkit-progress-value {
      background-color: #3f4d63;
      border-radius: 5px;
    }
  }

  div {
    margin-top: 1em;
  }
}

.variant {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.wrapImg {
  height: 84px;
  position: relative;
  &:hover {
    .delete {
      opacity: 1;
    }
  }

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.remove {
  position: absolute;
  right: -5px;
  top: -5px;
}

.delete {
  align-items: center;
  background: rgba(0, 0, 0, .6);
  border-radius: 5px;
  bottom: 0;
  content: '';
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s;
  svg {
    cursor: pointer;
    fill: white;
    height: 64px;
    transition: .3s;
    width: 64px;
    &:hover {
      fill: var(--color-error);
    }
  }
}
