.gradeRow {
  background: var(--color-primary-4);
  border-radius: 5px;
  padding: 10px;
  display: grid;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr auto auto;
    grid-template-rows: unset;
  }
}
.name {
  color: var(--color-black-100);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;
}
.rateList {
  display: flex;
  grid-column: 1/3;
  justify-content: space-between;
  @media (min-width: 768px) {
    grid-column: unset;
    gap: 4px;
  }
}
.icon {
  border: 1px solid var(--color-black-60);
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  @media (min-width: 768px) {
    height: 30px;
    width: 30px;
  }
  &::before {
    clip-path: circle(6px);
    content: '';
    display: block;
    height: 100%;
    @media (min-width: 768px) {
      clip-path: circle(9px at center);
    }
  }
}
.wrongIcon {
  composes: icon;
  border: 1px solid var(--color-danger-1);
  &::before {
    background-color: var(--color-danger-1);
  }
}
.rightIcon {
  composes: icon;
  border-color: var(--color-success-1);
  &::before {
    background-color: var(--color-success-1);
  }
}
.currentIcon {
  border: 2px solid var(--color-primary-1);
}
.crossIcon {
  cursor: pointer;
  grid-column: 2/3;
  grid-row: 1/2;
  @media (min-width: 768px) {
    grid-column: unset;
    grid-row: unset;
  }
}
