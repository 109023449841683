.title {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 20px;
    text-align: center;
}

.box {
    padding: 50px 20px 0;

    &__online {
        height: max-content;
        padding-bottom: 10px;
    }
}



.table {
    background: white;
    width: 100%;
    border-collapse: collapse;
    caption-side: bottom;
    color: #5f6368;
}

.col {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    &_result {
        border-left: 1px solid #cccccc;;
        border-right: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        text-align: left;
        padding-left: 10px;
    }
}

.row_result {
    background: #5B8DEE;
    color: white;
}

.headCol {
    padding: 10px 0;
    border-left: 1px solid #5f6368;
    border-right: 1px solid #5f6368;
    border-bottom: 1px solid #5f6368;

}

.row_grey {
    background: #f6f6f7;
}



.item {
    padding: 10px 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item__result {

    display: flex;
    flex-direction: column;
    gap: 20px;
    line-height: 1.3em;
}

.itemHead {
    padding: 10px 10px;
    gap: 5px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.last {
    border-bottom: 1px solid #cccccc;
}