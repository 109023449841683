.authorization-wrapper {
  background-color: #fbfbfb;
  background-image: url("bg.jpg");
  background-size: cover;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 15px;
  width: 100%;
}

.authorization-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;

  &__restore-password {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      color: #3f4d63;
      font-size: 18px;
      font-weight: 500;
    }

    p {
      color: #7b7b7b;
      font-size: 14px;
      margin: 2em 0 0.5em;
      text-align: center;
    }
  }

  &__verify {
    h3 {
      margin-top: 27px;
    }
  }
}
