.box {
    padding: 10px;
    background: white;
}

.title {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: gray;
    margin-bottom: 25px;
}

.chart {
    border: 1px solid #bdbdbd;
    border-bottom: none;
    min-height: 50px;
    overflow-x: scroll;

    &::-webkit-scrollbar-thumb {
        width: 5px;
    }

    //row
    &__row {
        display: flex;
        align-items: center;
    }

    &__row_numbers {
        display: flex;
        align-items: center;
    }

    &__subrow {
        display: flex;
        align-items: center;
        position: relative;
    }

    //col
    &__col {
        align-items: center;
        border-bottom: 1px solid #bdbdbd;
        
    }

    &__col_first {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #bdbdbd;
        border-bottom: 1px solid #bdbdbd;
        padding-bottom: 10px;
    }

    //section
    &__section {
        height: 35px;
        width: 82px;
        border-left: 1px solid #bdbdbd;
        border-top: none;
        border-bottom: none;
        padding: 10px 0;
    }

    &__section:last-child {
        width: 20px;
    }

    &__section_name {
        min-width: 40px;
        width: 50px;
        padding: 10px 15px;
        height: 35px;
        text-align: right;
        font-size: 13px;

        span {
            color: #222;
        }
    }

    &__sectItem {
        display: block;
        height: 40px;
        width: 82px;

        padding: 10px 0;
        text-align: center;
    }

    &__sectItem:last-child {
        width: 45px;
        text-align: right;
    }

    &__sp {
        min-width: 161px;
        width: 161px;
        padding: 10px 0;
    }

    &__line {
        height: 18px;
        position: absolute;
        left: 0;
        top: 7px;
        background: #5a8dee;
        display: flex;
        align-items: center;
    }

    &__wrapper_i {
        position: relative;
        width: 100%;
        height: 14px;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 150px 1fr;
    }

    &__info {
        position: absolute;
        right: -40px;
        font-size: 12px;
        font-weight: 600;
    }
}

.noData {
    color: gray; 
}