.hr {
  margin: 0 -20px;
  border: none;
  height: 1px;
  background-color: var(--color-outline);
}

.top_margin {
  margin-top: 20px;
}

.bottom_margin {
  margin-bottom: 20px;
}

.light {
  background-color: #e2e2e2;
}
