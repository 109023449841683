.text {
  color: var(--color-black-80);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.5;
  margin-bottom: 21px;
}
.haveAccount {
  margin-top: 16px;
}
.submit {
  margin-top: 32px;
  gap: 0;
}
.form {
  display: flex;
  flex-direction: column;
  margin-top: 38px;
}
.title {
  margin-top: 130px;
}