.row {
  align-items: center;
  border-radius: 6px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 10px;
  &:hover {
    background-color: var(--color-primary-4);
  }

  .paramNameLevel {
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      color: var(--color-primary-1);
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: .32px;
      text-decoration-line: underline;
      &:hover {
        text-decoration: none;
      }
    }

    span {
      color: var(--color-black-80);
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: .24px;
    }
  }
}
.params {
  cursor: pointer;
  display: grid;
  flex-grow: 1;
  @media (min-width: 768px) {
    grid-template-columns: 1fr repeat(3, auto);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.param {
  display: flex;
  gap: 10px;
  @media (min-width: 768px) {
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
  }
}
.paramName {
  color: var(--color-black-100);
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: .24px;
  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: .28px;
  }
}
.paramValue {
  color: var(--color-success-1);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: .32px;
}
.separator {
  background-color: var(--color-black-40);
  display: inline-block;
  height: 10px;
  margin: 0 10px;
  width: 1px;
}
.separatorH14 {
  composes: separator;
  height: 14px;
}
.percent {
  color: var(--color-primary-2);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .32px;
}
