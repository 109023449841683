.wrapper {
  width: 100%;
  max-width: 310px;
  height: 85px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--color-text);
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  @media (min-width: 768px) {
    height: 115px;
  }
  &::after {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: -100%;
    transition: all 0.2s ease-in-out;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
  }
  &::before {
    position: absolute;
    content: "";
    top: -100%;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 40px;
    height: 40px;
    background-image: url(../../../../../ContentBox/img/basket-w.svg);
    transition: all 0.2s ease-in-out;
    z-index: 2;
  }
  &__delete {
    cursor: pointer;
    &:hover {
      &::after {
        top: 0;
      }
      &::before {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__loading img {
    width: 80px;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  span {
    display: block;
    margin-top: 5px;
  }
}
