.page {
  padding: 20px 0;
  min-width: 100vw;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100vh;
  margin: 100px auto 0;
  align-items: center;
  @media (min-width: 768px) {
    padding: 0 15px;
  }
}

.exercisesSectionsBox {
  margin: 20px auto 0;
}

.bookColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exercisesSectionsBoxStudent {
  display: grid;
  gap: 10px;
  grid-template-columns: auto;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 290/1040*100%;
  }
  margin: 0 auto;
}
.questions {
  display: flex;
  flex-direction: column;
}
.createQuestion {
  align-items: center;
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 500;
  gap: 20px;
  padding: 20px;
  text-align: center;
  transition: .2s;
  &:hover {
    background-color: #3f4d63;
    color: white;
    svg {
      transform: scale(2);
    }
  }
  svg {
    fill: currentColor;
    transform: scale(1);
    transition: .2s;
  }
}
