.wrapper {
    max-width: 1105px;
    margin: 0 auto;
    width: 100%;
}

.inner {
    min-height: 140px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.select {
    flex-basis: 100%;

    @media (min-width: 1024px) {
        flex-basis: calc((100% - 40px) / 3);
    }
}
