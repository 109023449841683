.dataPickerSplit {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  height: 45px;
  display: flex;
  overflow: hidden;

  .react-datepicker-wrapper {
    flex-grow: 1;
  }

  .react-datepicker__input-container {
    height: 100%;
    input {
      border: none;
      height: 100%;
      padding: 0;
      text-align: center;
      width: 100%;
    }
  }

  &__middle {
    align-items: center;
    background-color: #bdbdbd;
    color: #fff;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 43px;
  }
}

.react-datepicker-custom {
  .react-datepicker {
    font-family: inherit;
    &__header{
      background-color: #fff;
      border-bottom: none;

    }
    &-year-header {
      margin-top: 6px;
    }
    &__triangle {
      display: none;
    }
    &__navigation {
      background: url('../../../ContentBox/img/datepicker-arrow-grey.svg') center center no-repeat;
      background-size: 16px 16px;
      border: 1px solid #e6e9e9;
      height: 25px;
      top: 10px;
      width: 35px;
      &:hover {
        background-color: #e6e9e9;
      }
      &--next {
        transform: rotate(180deg);
      }
    }

    &__day-names {
      margin-top: 1em;
    }

    &__day-name {
      margin: 1px;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    &__current-month {
      margin-top: 6px;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    &__monthPicker {
      margin-top: 10px;
    }

    &__month-text {
      border-radius: 0;
      line-height: 32px;
      margin: 0;
      &:hover {
        background-color: #e8f4ff;
      }
    }

    &__month {
      &--disabled {
        color: #d7d7d7;
      }

      &--selected {
        background-color: #5a8dee;
        border-radius: 0;
        &:hover {
          background-color: #5a8dee;
        }
      }
    }

    &__day {
      font-weight: 500;
      margin: 1px;
      &:hover {
        border-radius: 0;
      }

      &--outside-month {
        color: #d7d7d7;
      }

      &--in-selecting-range {
        background-color: #e8f4ff;
        border-radius: 0;
        color: #5f6368;
      }

      &--selected, &--keyboard-selected {
        background-color: #5a8dee;
        border-radius: 0;
        color: white;
      }
    }
  }
}