.showTest {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  &__questions {
    border-bottom: 1px solid var(--color-black-40);
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 10px 21px;
  }

}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: 10px;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
}

.delemetr {
  display: block;
  width: 100%;
  height: 1px;
  background: var(--color-black-40);
}

.attempts {
  margin: 15px 20px;
}
.clickZone {
  align-items: center;
  cursor: pointer;
  align-self: start;
  @media (min-width: 768px) {
    gap: 10px;
    grid-template-columns: auto 1fr 280px;
    align-self: auto;
  }

  .testInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;

    color: var(--color-primary-1);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.28px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
    .delimiter {
      display: none;
      background: var(--color-primary-1);
      height: 20px;
      width: 1px;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }
}

.actionMenu {
  justify-content: center;
  display: flex;
  gap: 20px;
}

.params {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  //padding: 5px 20px;
}

.scoreBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  border-radius: 6px;
  border: 1px dashed var(--color-warning-1);
  background: var(--color-warning-4);
  color: var(--color-warning-1);
  font-size: 18px;
  font-weight: 800;
  line-height: 17px;
  letter-spacing: 0.36px;
  height: fit-content;
  word-break: initial;

  svg {
    width: 17px;
    height: 17px;
  }

  path {
    fill: var(--color-warning-1);
  }
}

.rowScoreAndTime {
  padding: 15px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }
}