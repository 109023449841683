.questionBody{
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    &__mediaBox{
        width: 320px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 20px;
    }
    &__qustionBox{
        padding-top: 20px;
    }
    &__question{
        color: #000000;
        font-size: 12px;
        font-family: 'Montserrat',Arial,sans-serif;
        line-height: 1.55;
        font-weight: 600;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
    }
    &__variantsBox{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding-left: 10px;
        gap: 2px;
        svg{
            width: 20px;
            margin-right: 20px;
        }
    }
    &__variantsUnit{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
    }
}