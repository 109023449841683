.writingEssay {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .essayField {

    &:hover {
      border-radius: 7px;
      box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60);
      cursor: default;
    }

    &:focus-within {
      border-radius: 7px;
      box-shadow: 0 0 0 1px var(--color-primary-2), /* Рамка при фокусе */
      0 0 0 3px rgba(85, 177, 243, 0.60), /* Тень при фокусе */
      inset 0 0 0 1px var(--color-primary-2); /* Внутренняя рамка при фокусе */
    }

    &::placeholder {
      color: var(--color-black-60);
    }
    .countLettersBlock {
      display: flex;
      gap: 15px;
      border-radius: 0 0 7px 7px ;
      border: 1px solid var(--color-black-40);
      padding: 5px 10px;
      border-top: 1px dashed var(--color-black-40);
      background: var(--color-black-10);

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: var(--color-black-80);
      }

      .line {
        width: 1px;
        height: 16px;
        background: var(--color-black-60);
      }
    }

    textarea {
      overflow: hidden;
      font-family: inherit;
      font-size: 14px;
      font-weight: 400;
      border-radius: 7px 7px 0 0;
      line-height: 18px;
      padding: 16px 0 16px 8px;
      min-height: 120px;
      resize: none;
      border: 1px solid var(--color-black-40);
      border-bottom: none;
      color: var(--color-black-100);
      background: var(--color-black-0);
    }
  }
}
  img {
    max-width: 100%;
  }

  .fullImageIcon {
    border-radius: 7px;
    padding: 7px;
    background: var(--color-primary-2);
  }



