.wrap {
  align-items: stretch;
  background-color: #fff;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.period {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  line-height: 1.55;
  padding: 10px;
  @media (min-width: 768px) {
    width: 50%;
  }
}

.time {
  align-items: center;
  border-top: 1px solid var(--color-outline);
  height: 100px;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    border-left: 1px solid var(--color-outline);
    border-top: none;
    width: 50%;
  }
}
.str {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  margin: 1em 0 1.5em;
}

.testEnd {
  color: #ff0000;
  font-size: 25px;
  font-weight: 700;
  margin: auto;
}
