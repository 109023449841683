.swal_content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 14px;
  font-weight: 500;
  @media (min-width: 768px) {
    padding: 10px 40px;
  }

  dt {
    flex-basis: 100%;
    text-align: center;
    @media (min-width: 768px) {
      flex-basis: calc(30% - 20px);
      text-align: left;
    }
  }

  dd {
    padding-bottom: 10px;
    flex-basis: 100%;
    text-align: center;
    border-bottom: 1px solid var(--color-outline);
    @media (min-width: 768px) {
      padding-bottom: 0;
      flex-basis: 70%;
      text-align: right;
      border-bottom: 0;
    }
  }

  dt.swal_content__link {
    margin-top: 10px;
    text-align: center;
    @media (min-width: 768px) {
      flex-basis: 100%;
    }
  }

  a.swal_content__link_text {
    text-align: center;
    font-weight: 500;
    color: var(--color-text-accent);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
