.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px;
}
.book {
  display: flex;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  &:hover {
    .iconBox {
      opacity: 1;
    }
  }

  img {
    width: 100%;
  }

  &__bookImg {
    width: 50px;
    height: 62px;
    flex-shrink: 0;

    img {
      border-radius: 5px;
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &Info {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      font-weight: 700;
      color: var(--color-primary-1);
      font-size: 14px;
    }
  }
}


.preview {
  width: 0;

}

.iconBox {
  align-items: center;
  bottom: 0;
  background: rgba(255,255,255,0.70);
  backdrop-filter: blur(2px);
  content: "";
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
  gap: 20px;
  svg {
    cursor: pointer;
    fill: white;
    height: 16px;
    transition: 0.3s;
    width: 16px;
  }
}

.deleteIcon {
  border: 1px solid var(--color-black-60);
  border-radius: 7px;
  padding: 6px;
  background: var(--color-black-0);
  * {
    fill: var(--color-black-60);
  }

  &:hover {
    border: 1px solid var(--color-error);
    * {
      fill: var(--color-error);
    }
  }
}

.viewIcon {
  button {
    display: flex;
    gap: 15px;
    background: var(--color-black-0);
    color: var(--color-primary-3);
    border: 1px solid var(--color-primary-3);
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 700;

    &:hover {
      background: var(--color-black-0);
      color: var(--color-primary-2);
      border: 1px solid var(--color-primary-2);

      * {
        fill: var(--color-primary-2);
      }
    }
  }
}
