.recordCustomerContainer{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}
.recordCustomerButton{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.recordCustomerButtonContainer{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 180px;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 30px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    cursor: pointer;
    transition: all .3s ease;
    border-radius: 5px;
    margin-right: 35px;
}

.recordCustomerTimeAdd{
    color: #f0f0f0;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}

.recordCustomerButtonContainerAdd{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 180px;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 30px;
    border: 1px solid #bdbdbd;
    font-size: 14px;
    cursor: pointer;
    transition: all .3s ease;
    border-radius: 5px;
    margin-right: 35px;
    background-color: #3f4d63;
    color: #f0f0f0;
}
.container-search-input{
    width: 500px;
}
.container-search-input input{
    width: 100%;
    padding: 15px 20px;
}
.recordCustomerButtonContainer:hover{
    border: 1px solid #5f6368;
}
.recordCustomerTime{
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #3f4d63;
}
.recordCustomerLocation{
    text-align: center;
}
.recordCustomerTitle div {
    font-size: 18px;
    font-weight: 600;
    color: #3f4d63;
    transition: all 2s ease;
}
.recordCustomerSubTitle{
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #959595 !important;
    transition: all 2s ease;
    margin-top: 10px;
}
.recordCustomerInfo{
    display: flex;
    padding-top: 10px;
}
.recordCustomerName{
    width: 250px;
    /*position: relative;*/
}
.recordCustomerName input{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    padding-left: 20px;
}

.recordCustomerAge{
    display: flex;
    flex-direction: column;
    width: 300px;
    padding-left: 20px;

}
.recordCustomerAge input{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 15px;
}

.recordCustomerAge input:focus{
    padding-right: 15px;
}
.recordCustomerAge select{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    padding-left: 20px;
    background: url('../../img/br_down.png') no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: calc(100% - 10px);
}
.recordCustomerTeacher select{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    padding-left: 20px;
    background: url('../../img/br_down.png') no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: calc(100% - 10px);
    padding-right: 32px;
}

.recordCustomerTeacher{
    display: flex;
    flex-direction: column;
    width: 300px;
    padding-bottom: 20px;

}
.recordCustomerEmail{
    width: 250px;
    padding-left: 20px;
}
.recordCustomerEmail input{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    padding-left: 20px;
}

.recordCustomerLocations{
    width: 250px;
    padding-left: 20px;
}
.recordCustomerLocations select{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 20px;
    background: url('../../img/br_down.png') no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: calc(100% - 10px);
}

.requiredName {
    color: red;
    font-size: large;
    position: relative;
    top: -137px;
    right: -235px;
    width: 0;
}

.requiredLastName {
    color: red;
    font-size: large;
    position: relative;
    top: -105px;
    right: -235px;
    width: 0;
}

.requiredEmail {
    color: red;
    font-size: large;
    position: relative;
    top: -137px;
    right: -215px;
    width: 0;
}

.requiredPhone {
    color: red;
    font-size: large;
    position: relative;
    top: -105px;
    right: -215px;
    width: 0;
}
.requiredTest {
    color: red;
    font-size: large;
    position: relative;
    top: -73px;
    right: -215px;
    width: 0;
}

.requiredDateBirth {
    color: red;
    font-size: large;
    position: relative;
    top: -137px;
    right: -265px;
    width: 0;
}

.requiredAge {
    color: red;
    font-size: large;
    position: relative;
    top: -105px;
    right: -265px;
    width: 0;
}
.requiredProgram {
    color: red;
    font-size: large;
    position: relative;
    top: -73px;
    right: -265px;
    width: 0;
}

.requiredDateLine{
    height: 38px;
    top: 11px;
    right: 50px;
    position: relative;
    border: none;
    background-color: #3f4d63;
    color: #3f4d63;
    width: 0px;
}

.requiredDateLineNan{
    width: 0px;
    height: 38px;
    top: -192px;
    right: -215px;
    position: relative;
    border: none;
    width: 0px;
}

.inputData{
    display: flex;
}

.record-error-info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background-color: #ffdae7;
    margin-top: -30px;
    padding-top: 12px;
    margin-bottom: 15px;
    border-radius: 5px;
    width: 800px;
}

.record-error-info div {
    display: flex;
    padding-left: 10px;
}

.record-error-info span {
    padding-right: 5px;
    font-weight: 600;
    padding-bottom: 10px;
}
.record-error-info p {
    padding-bottom: 10px;
}
.record-info-checkbox{
   display: flex;
}

.record-error-info-checkbox{
    display: none;
}


.record-customer-page {
    padding: 18px 15px;
}

.record-customer-fieldset-container {
    background: white;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding: 20px;

    /*!!!*/
    margin-top: 20px;
}
@media screen and (min-width: 768px) {
    .record-customer-fieldset-container {
        padding: 30px;
    }
}

