.sentencesForm {
  .container {
    background-color: #f6f6f6;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    gap: 20px;

    .delete {
      align-self: center;
    }
    .card {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .error {
        box-shadow: 0 0 0 2px rgba(173, 0, 0, 0.25);
        border: 1px solid var(--color-danger-1);
      }
    }
  }
}
