.bookHeader {
  color: var(--color-black-80);
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 9px;
  @media (min-width: 768px) {
    gap: 12px;
  }
  @media (min-width: 1024px) {
    gap: 15px;
  }
}
.activeLessonDateRow {
  display: flex;
  justify-content: space-between;
}
.activeLesson {
  color: var(--color-black-100);
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.4px;
  @media (min-width: 1024px) {
    font-size: 34px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.68px;
  }
}
.date {
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.24px;
  @media (min-width: 768px) {
    font-size: 14px;
    letter-spacing: 0.28px;
  }
}
.courseName {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.32px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.36px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.48px;
  }
}
