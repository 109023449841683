.dropdown {
    display: flex;
    align-items: center;
    gap: 10px;

    &__title {
        color: var(--color-black-100);
        font-size: 12px;
        font-weight: 500;
    }
    &__icon {
        color: var(--color-black-100);
        width: 10px;
        height: 6px;
        transition: 0.5s;

        &_active {
            transform: rotate(180deg);
        }
    }
    &List {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 80px;
        background: var(--color-black-0);
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 5px;
        z-index: 999;
        overflow: hidden;

        &__item {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-black-100);
            padding: 10px;
            border-radius: 7px;
            border: 1px solid var(--color-black-20);
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

            &:hover {
                color: var(--color-primary-2);
            }
        }
    }
}