.button {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary-3);
  color: var( --color-black-0);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 45px;
  justify-content: center;
}

.plus {
  color: var( --color-black-0);
  height: 24px;
  width: 24px;
}
