.noExercises {
  display: flex;
  flex-direction: column;
  max-width: 270px;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 768px) {
    max-width: 400px;
  }

  img {
    width: 100%;
  }
  h3 {
    color: var(--color-black-100);
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.4px;
  }
  p {
    color: var(--color-black-80);
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.28px;
  }
}
