.question {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 1em;
  padding: 20px;
}

.video {
  max-width: 637px;
  margin: 0 auto;

  h4 {
    display: none;
  }
}

.text {
  font-size: 18px;
  color: var(--color-primary-1);
  font-weight: 400;
  padding: 10px 0 0 0;
}

.answerBody {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 23px;
  align-self: stretch;
  padding: 10px;
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.correctAnswer {
  width: 171px;
  height: 36px;
  position: absolute;
  top: -13%;
  padding: 5px 15px;
  border-radius: 6px;
  border: 1px solid var(--color-black-60);
  background-color: var(--color-black-10);
  z-index: 1;
}

.questionPlusText {
  width: 100%;
  height: 270px;
  padding: 20px 10px;
  border-radius: 6px;
  border: 1px solid var(--color-black-60);
  background-color: var(--color-black-10);
  z-index: 0;
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.studentAnswer {
  position: absolute;
  top: -13%;
  padding: 5px 15px;
  border-radius: 6px;
  border: 1px solid var(--color-warning-1);
  background-color: var(--color-warning-4);
  z-index: 1;
}
.studentAnswerText {
  padding: 20px 10px;
  border-radius: 6px;
  border: 1px solid var(--color-warning-1);
  background-color: var(--color-warning-4);
  z-index: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (min-width: 768px) {
    //flex-direction: row;
  }
}

.image {
  flex-shrink: 0;
  max-width: 800px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1em;

  ul {
    display: flex;
    flex-direction: column;
    gap: .5em;
  }

  li {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  i {
    display: block;
    flex-shrink: 0;
    height: 20px;
    width: 20px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.divider {
  border-top: 1px solid var(--color-outline);
}

.valueWords {
  align-items: center;
  display: flex;
  gap: 1em;
  svg {
    height: 1em;
    width: 1em;
  }

  img {
    max-width: 90px;
  }
}
.completeSentence, .textMissingWords, .wordMatching, .wordsInBlanks {
  .container {
    flex-direction: column;
  }
  .image {
    margin: 0 auto;
  }
}
.column {
  li {
    flex-direction: column;
  }
}
.textMissingWordsList {
  li {
    align-items: flex-start;
    margin-bottom: 17px;
  }
}
.textMissingWords {
  li {
    flex-direction: row;
    align-items: center;
  }
}