.stars {
  position: relative;
  display: flex;
  border: 1px solid var(--color-primary-2);
  background: var(--color-primary-2);
  padding: 0 9px 0 27px;
  -webkit-transform: skew(0, 10deg);
  transform: skew(-5deg, 0deg);
  top: 7px;
  border-bottom-left-radius: 15px;
}

.ratingContainer {
  display: flex;
  align-items: center;
  margin-right: 20px;

  .bigStarContainer {
    position: relative;
  }

  .bigStar {
    position: relative;
    left: 30px;
    z-index: 99;
  }

  .averageRating {
    position: absolute;
    top: calc(55%);
    left: calc(50% + 30px);
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    color: var(--color-black-100);
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: 0.36px;
  }
}

.filled {
  color: var(--color-warning-1);
}

.empty {
  color:  var(--color-black-40)
}