.form {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
}

.button_description {
  margin-top: 9px;
  font-size: 12px;
  font-weight: 600;
}

.submit_error {
  margin-top: 10px;
  color: red;
}

.input_file {
  width: 100%;
  max-width: 310px;
}
