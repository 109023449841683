.formSelectCustom{
  :global(.selectCustom) {
    &__control {
      border-color: var(--color-black-40);
      border-radius: 7px;
      height: 48px;
      &--is-focused {
        border-color: var(--color-primary-2);
        box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60), inset 0 0 0 1px var(--color-primary-2);
      }
    }

    &__placeholder {
      color: var(--color-black-60);
    }

    &__value-container {
      padding: 0 8px;
      &>div:last-child {
        margin: 0;
        padding: 0;
      }
    }
  }
  :global(.selectCustom--error){
    :global(.selectCustom) {
      &__control {
        border-color: var(--color-danger-2);
      }

      &__placeholder {
        color: var(--color-danger-1);
      }
    }
  }
}
