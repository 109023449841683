.videoLink {
  background-color: var(--color-black-20);
  border: 1px solid var(--color-black-60);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

  span {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }
  .del {
    align-self: flex-end;
  }

  .error {
    border: 1px solid var(--color-danger-1);
    box-shadow: 0 0 0 2px rgba(173, 0, 0, 0.25);
  }
}

