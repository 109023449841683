.testLogin {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3em;

  h1 {
    color: var(--color-text-accent);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  button {
    max-width: 450px;
    width: 80%;
  }

  .footer a {
    color: var(--color-text-accent);
    display: block;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.digitsBox {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.digits {
  display: flex;
  gap: 5px;
  @media (min-width: 768px) {
    gap: 20px;
  }
}
.digit {
  border: 1px solid #888;
  border-radius: 5px;
  color: var(--color-text-accent);
  height: 40px;
  font-size: 20px;
  text-align: center;
  transition: .3s;
  width: 30px;
  @media (min-width: 768px) {
    font-size: 30px;
    height: 70px;
    width: 50px;
  }
  &:hover {
    border: 1px solid #bdbdbd;
    box-shadow: 0 0 0 3px #b8d4ff;
  }
  &:focus {
    border: 1px solid #0062ba;
    box-shadow: 0 0 0 3px #b8d4ff;
  }
}
.error {
  color: var(--color-error);
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  transform: scale(0);
  transition: .3s;
}
.errorVis {
  composes: error;
  transform: scale(1);
}
.footer {
  border-top: 1px solid var(--color-outline);
  line-height: 1.25;
  padding: 1em;
  text-align: center;
}
