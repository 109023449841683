.button {
  align-items: center;
  background-color: var(--color-success-4);
  border: 2px solid var(--color-success-1);
  color: var(--color-success-1);
  display: flex;
  font-size: 16px;
  font-family: inherit;
  font-weight: 500;
  gap: 20px;
  justify-content: center;
  width: 100%;
  &:hover {
    background-color: var(--color-success-1);
    color: var(--color-success-4);
  }
}
