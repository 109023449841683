.typeSelectorBox {
  border-radius: 7px;
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  border: 1px solid var(--color-primary-1);
  width: 100%;
  overflow: hidden;

  div {
    border-right: 1px solid var(--color-primary-1);
    display: flex;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-primary-1);
    font-weight: 500;
  }

  div:hover {
    background-color: var(--color-primary-1);
    color: var(--color-black-0);
    cursor: pointer;
  }

  div:last-child {
    border: none;
  }
}

.typSelectorSelected {
  background-color: var(--color-primary-1);
  color: var(--color-black-0) !important;
  cursor: pointer;
  font-size: 14px;
}
