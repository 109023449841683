.header, .body, .footer {
  padding: 1em 15px;
  @media (min-width: 768px) {
    padding: 1em 20px;
  }
}

.container {
  background: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  font-family: 'Manrope', sans-serif;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 20px;
    font-weight: 500;
  }
}

.body, .footer {
  border-top: 1px solid var(--color-black-40);
}

.notFound {
  text-align: center;
}

.imgNotFound {
  width: 100%;
  max-width: 380px;
}

