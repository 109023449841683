.switcher {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px;
}
.result {
  background-color: #fff;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
}
.header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
  border-bottom: solid 1px #bdbdbd;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      margin-bottom: 17px;
    }
  }
}
