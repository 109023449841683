.box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 8px;

  strong {
    font-size: 14px;
    font-weight: 600;
  }
}

.pair {
  align-items: center;
  display: flex;
}

.cell {
  flex-grow: 1;
  //margin-right: 20px;
}
.deleteVariant {
  margin-left: 10px;
}
