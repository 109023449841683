.box {
  border-radius: 0 0 5px 5px;
  background: var(--color-primary-1);
  max-height: calc(100vh - 78px);
  position: absolute;
  top: 78px;
  left: 0;
  overflow: auto;
  width: 230px;
  z-index: 600;
  @media (min-width: 768px) {
    max-height: calc(100vh - 60px);
    top: 60px;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}

.item {
  align-items: center;
  border-bottom: 1px solid var(--color-black-60);
  color: var(--color-black-60);
  cursor: pointer;
  display: flex;
  padding: 14px 10px;
  gap: 10px;
  &.active {
    color: var(--color-black-0);
    fill: var(--color-black-0);
  }

  &:last-child {
    border-bottom: none
  }

  svg {
    flex-shrink: 0;
  }
}

.popWrapper {
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;

}