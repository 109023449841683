.slider {
  overflow: hidden;
  transition: height .3s ease-in;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}