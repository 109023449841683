.reportsGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: var(--color-black-0);
    padding: 10px 10px;
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
}