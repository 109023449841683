.container {
    padding: 5px 15px 0;
    display: grid;
    grid-template-columns: repeat(5, minmax(160px, 200px));
    gap: 20px;

}


@media (max-width: 1024px) {
    .container {
        grid-template-columns: repeat(3, minmax(180px, 350px) ); //3, 1fr
    }
}

@media (max-width: 768px) {
    .container {
        grid-template-columns: repeat(2, minmax(140px, 350px)); //3, 1fr
    }
    
}

.card {
    padding: 15px;
    background: wheat;
}

