.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.box {
  border: 1px dashed var(--color-black-60);
  border-radius: 6px;
  padding: 16px;
  background: var(--color-black-20);
}
.boxError {
  composes: box;
  border-color: var(--color-error);
}
.choose {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

  input[type="file"] {
    display: none;
  }

  button {
    background-color: var(--color-text-accent);
    color: white;
    font-family: inherit;
    font-weight: 500;
    width: 205px;
    &:hover {
      background-color: white;
      color: var(--color-text-accent);
    }
  }
}
.tip {
  align-items: center;
  color: var(--color-text-accent);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  span {
    text-transform: uppercase;
  }
}

.variant {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

}
.wrapImg {
  height: 236px;

  img, video {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.remove {
  position: absolute;
  right: -10px;
  top: -10px;
}
