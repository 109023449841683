.contentBoxWrapper {
  background-color: var(--color-black-10);
  display: grid;
  grid-template-columns: 0 1fr;
  margin-top: 61px;
  width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 80px 1fr;
  }
}
.main {
  display: grid;
  grid-template-rows: 1fr auto;
}
.mainInitLesson {
  background: var(--color-black-10);
  font-family: "Manrope", sans-serif;
}
