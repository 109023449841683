.box {
    width: 100%;
    margin: 0;
    padding: 20px;
    border: 1px solid var(--color-outline);
    border-radius: 5px;
    background-color: #fff;
    font-family: inherit;
    font-size: inherit;
}

.header {
    margin-bottom: 20px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.title {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: 20px;
    font-weight: 600;
    color: var(--color-text);
}

.list {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.row {
    display: flex;
    gap: 3px;
    align-items: center;
}

.dateBox {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-right: 10px;
}

.link {
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text);
    cursor: pointer;
}

.icon {
    display: block;
    height: 20px;
}
