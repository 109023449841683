.input {
  width: 50px;

  input {
    padding-left: 0;
    text-align: center;
  }
}

.slots {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  justify-content: space-between;
  column-gap: 40px;
  row-gap: 10px;
}

.slot_wrapper {
  width: 160px;
  gap: 10px;

  &:last-child {
    margin-right: auto;
  }
}

.time {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  border-radius: 5px;
  border: 1px solid var(--color-outline);
  background-color: #f4f4f4;
}
