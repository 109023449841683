.modalChangePassword {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  & &__field {
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    transition: .3s;

    &:hover {
      box-shadow: 0 0 0 3px #b8d4ff;
    }

    &--focus {
      border-color: #0062ba;
      box-shadow: 0 0 0 3px #b8d4ff;
    }

    p {
      align-items: center;
      background-color: #f0f4f7;
      border-right: 1px solid #bdbdbd;
      color: #5f6368;
      display: flex;
      flex-shrink: 0;
      padding-left: 10px;
      width: 170px;
      margin-bottom: 0px;
      @media (max-width: 768px){
        font-size: 12px;
        width: 140px;
      }
      @media (max-width: 320px){
        font-size: 10px;
        width: 120px;
      }
    }

    input {
      border: none;
      margin: 0;
      &:hover, &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }

  &__errors {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 1em;

    ul {
      margin-top: .5em;
      @media (max-width: 320px){
        font-size: 10px;
      }
    }
  }

  &__error {
    color: #db3031;

    &--valid {
      color: #32b76c;
    }
  }
}

.modal-profile-double-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.modal-profile-select-gender {
  display: flex;
  justify-content: space-between;
}

