.switcher {
  display: flex;
}
.btn {
  background-color: #fff;
  border: 1px solid var(--color-outline);
  color: var(--color-text);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
  width: 160px;
  @media (max-width: 767px) {
    width: 120px;
    padding: 15px 5px;
    line-height: 1;
  }

  &:first-child {
    border-right: none;
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-left: none;
    border-radius: 0 5px 5px 0;
  }
}
.btnActive {
  composes: btn;
  background-color: var(--color-accent-light);
  border-color: var(--color-accent-light);
  color: white;
}
