.svg {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
}
