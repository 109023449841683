.control {
  align-self: center;
  align-content: flex-end;
  align-items: stretch;
  visibility: hidden;
  height: 26px;
  border: 1px solid var(--color-black-60);
  border-radius: 7px;
  display: flex;
  background-color: var(--color-black-0);
  position: absolute;
  right: 20px;

  .icon_container {
    cursor: pointer;
    display: flex;
    width: 24px;

    justify-content: center;
    align-items: center;
    color: var(--color-black-60);
    border-right: 1px solid var(--color-black-60);

    &:first-child {
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
    }

    &:last-child {
      border-right: none;
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
    }

    &:hover {
      color: var(--color-primary-2);
      background-color: var(--color-primary-4);
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }
}

