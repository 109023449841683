.form {
  input {
    margin-bottom: 0;
  }
}
.mainSelectorWrapper {
  & > div {
    margin-bottom: 0;
  }
}
.mainSelector {
  display: flex;
  gap: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.row {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

