.stats {
  border-top: 1px solid var(--color-outline);
  display: grid;
  gap: 1em;
  padding: 20px;
  @media (min-width: 768px) {
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
}
.col {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.param {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    span {
      margin-bottom: 7px;
    }
  }
}
.paramEdit {
  align-items: center;
  display: flex;
  gap: 1em;
  svg {
    cursor: pointer;
    height: 1em;
    width: 1em;
  }
}
