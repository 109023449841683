.title {
    text-align: center;
    font-weight: 600;
    color: red;
}

.level {
    min-width: 105px;
}

.excelButton {
    padding: 10px;
}

.totalConversion {
    overflow-x: auto;
}