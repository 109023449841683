.page {
    margin-top: 200px;
    display: grid;
    font-family: Manrope, serif;
  }
  .books {
    padding-left: 100px;
  
    h3 {
      color: var(--color-black-100);
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.84px;
    }
  
    ul {
      line-height: normal;
      padding: 25px 30px;
    }
  
    a {
      color: var(--color-black-100);
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.32px;
      &:hover {
        text-decoration: underline;
      }
  
      span {
        color: var(--color-black-60);
      }
    }
  }
  