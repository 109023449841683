.emptyBalance {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 15px 20px;
}
.image {
  height: 257px;
  @media (min-width: 768px) {
    height: 380px;
  }
}
.btn {
  background-color: var(--color-primary-1);
  border: none;
  color: var(--color-black-0);
  text-align: center;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.42px;
  padding: 16px 40px;

  &:hover {
    background-color: var(--color-primary-2);
  }
}
