.teachers_list {
    padding: 0 15px;
    width: 100%;
}
.slot {
    display: grid;
    grid-template-columns: repeat(auto-fill, 110px);
    justify-content: space-between;
    gap: 10px;
    padding: 15px;
}
