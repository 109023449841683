.wrapper {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 30px;
  row-gap: 20px;
}

.wrapper > div {
  h3,
  div:first-child {
    width: 100%;
  }

  &:nth-child(odd) {
    flex-basis: 100%;
    @media (min-width: 1024px) {
      flex-basis: 30%;
    }
  }
  &:nth-child(even) {
    flex-basis: 100%;
    @media (min-width: 1024px) {
      flex-basis: calc(70% - 30px);
    }
  }
}

.time_selector {
  flex-basis: calc(50% - 10px);
}

.break_selector {
  width: 100%;
}

.time_gap {
  column-gap: 20px;
}

.time_gap_input {
  flex-basis: calc(40% - 10px);

  input {
    text-align: center;
    padding: 0 20px;
  }
  @media (min-width: 768px) {
    flex-basis: 100px;
  }
 }

.time_gap_select {
  flex-basis: calc(60% - 10px);

  @media (min-width: 768px) {
    flex-basis: 150px;
  }
}

.time_gap_text {
  margin-top: 5px;
  margin-left: auto;
  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 0;
  }
}
