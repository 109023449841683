.telegram {
    height: 48px;
    display: flex;
    border: 2px solid var(--color-black-40);
    border-radius: 7px;
    transition: 0.3s all;
    &:hover {
        box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60);
    }
    &__email {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        height: 100%;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-black-20);
        border-right: solid 1px var(--color-black-40);
    }
    &__icon {
        width: 20px;
        height: 20px;
        color: var(--color-black-80);
    }
    input {
        border: none;
        width: 100%;
        padding-left: 10px;
        color: var(--color-black-100);
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        &::placeholder {
            color: var(--color-black-60);
        }
    }
    &.focus {
        border-color: var(--color-primary-2);
        box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60), inset 0 0 0 1px var(--color-primary-2);
    }
    &.error {
        box-shadow: 0px 0px 0px 2px rgba(255, 0, 34, 0.6);
        border: 1px solid var(--color-danger-1);
    }
    &.disabled input {
        background: var(--color-black-20);
        pointer-events: none;
        &::placeholder {
            color: var(--color-black-100);
            font-weight: 300;
        }
    }
}
