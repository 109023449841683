.item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  color: var(--color-text);
  font-size: 12px;
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex-basis: calc(100% / 2 - 20px);
  }
  @media (min-width: 1024px) {
    flex-basis: calc(100% / 4 - 20px);
  }

  p {
    text-align: center;
  }

  a.link {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.title {
  font-weight: 600;
}

.image_wrapper {
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
      width: 50px;
  }
}
