.result {
  align-items: center;
  background: #f0f0f0;
  color: var(--color-text-accent);
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
  justify-content: space-between;
  padding: 0 20px;

  b {
    font-size: 24px;
  }
}
