.multi_upload {
  display: flex;
  flex-direction: column;
  border: 2px dashed #bdbdbd;
  border-radius: 5px;
  padding: 10px;
  //margin-top: 20px;

  input[type="file"] {
    display: none;
  }

  &__types {
    //margin-top: 20px;
    text-align: center;
    font-size: 12px;
    //font-weight: 500;
  }

  &__label {
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    background-color: #3f4d63;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 130px;
    transition: all .2s ease;
    font-size: 12px;
  }

  &__label:hover {
    color: #3f4d63;
    background-color: #fff;
    border: 2px solid #3f4d63;
    transition: all .2s ease;

  }

  &__upload {
    display: flex;
    flex-direction: column;

    &__line {
      display: flex;
      flex-direction: row !important;
      padding-bottom: 10px;
      position: relative;
      @media (max-width: 768px) {
        flex-direction: column !important;
      }

      &--hide {
        height: 0 !important;
        overflow: hidden;
        transition: .2s ease;
      }

      &__cover {
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        height: 100px;
        transition: .2s ease;
        width: 135px;

        img {
          cursor: pointer;
          width: 40px;
          height: 40px;
          transition: .3s;
        }

        img:hover {
          height: 50px;
          width: 50px;
          transition: .3s;
        }

      }

      &__img {
        width: 135px !important;
        height: 100px;
        overflow: hidden;
        border-radius: 5px;
        position: relative;

        &--image {
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 5px;
          }
        }
      }

      &__description {
        width: 70%;
        margin-left: 20px;
        white-space: pre;
        @media (max-width: 768px) {
          padding-top: 20px;
          width: 100%;
          margin-left: 0;
        }

        textarea {
          resize: none;
          max-height: 100px;
          white-space: pre;
        }

        label {
          font-style: italic;
          color: red;
          font-size: 12px;
        }
      }
    }
  }
}

.video_links-box {
  //margin-top: 20px;
  //margin-bottom: 20px;
  padding: 20px;
  background-color: #f6f6f6;
  border: 1px solid #b5b5b5;

  input {
    margin-bottom: 0 !important;
  }

  p {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }

  span {
    text-decoration: underline;
    font-weight: 500;
  }
}

.two-line-card-box {
  //margin-top: 5px;
  //margin-bottom: 10px;
  background-color: #f6f6f6;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  &__close {
    display: block;
    position: absolute;
    content: '&#215;';
    right: 0;
    font-size: 40px;
    color: #3f4d63;
    font-weight: 600;
    cursor: pointer;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    position: relative;
  }
}

.multi_upload_audio {
  .multi_upload_audio-box {
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    background-color: #f1f3f4;
    display: flex;
    flex-direction: column;
    gap: 20px;
    //margin-top: 10px;
    padding: 20px;

    &--audio {
      position: relative;

      &--fix {
        top: 12px;
        right: 0;
        height: 30px;
        width: 50px;
        background-color: #f1f3f4;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;

        img {
          width: 20px !important;
          cursor: pointer;
        }

        //img:hover{
        //  width: 35px !important;
        //}
      }
    }

    audio {
      width: 90%;
    }

    audio::after {
      height: 100px;
      background-color: red;
      width: 20px;
      position: absolute;
    }
  }

  input[type="file"] {
    display: none;
  }

  label {
    //margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
  }
}

.ex_word_matching {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__line {
    display: flex;
    //margin-top: 10px;
    flex-direction: row;
    column-gap: 20px;
    position: relative;

    input {
      margin-bottom: 0 !important;
    }

    &__close {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      font-size: 20px;
      color: #3f4d63;
      cursor: pointer;
    }
  }
}

.modalLessonQuestion {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__exerciseText {
    margin-top: 20px;
  }

  &__emptyAreaError {
    border-color: rgb(219, 48, 49);
  }

  &__description {
      color: #5f6368;
      font-weight: 600;
      margin: 0;
      padding: 0;
      border: 0;
      vertical-align: baseline;
      margin-bottom: -10px;
  }

  /*&__descriptionExerciseText {
    margin-top: 20px;
  }*/

  & &__exLink {
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      margin: 0;
    }
  }
}

.modalLessonQuestionGalleryTypeSelector {

  &__label {
    color: #5f6368;
    font-weight: 600;
  }

  &__container {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
  }

  &__block {
    align-items: center;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    width: 30%;
    font-size: 12px;
    transition: .3s ease;

    &:hover, &--selected {
      background-color: #e0e7ef;
    }

    p {
      text-align: center;
    }

    img{
      height: 45px;
    }
  }
}