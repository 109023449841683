.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__margin {
    margin-bottom: 20px;
  }
}

.inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  &:focus-visible + .custom_checkbox {
    box-shadow: 0 0 0 2px white, 0 0 0 4px var(--color-accent);
  }
}

.checkbox:checked + .custom_checkbox {
  background-color: var(--color-text-accent);

  &::before, &::after {
    background-color: white;
    transform: scaleX(1);
  }
  &::before {
    transform: scale(1) rotate(-45deg);
  }
  &::after {
    transform: scale(1) rotate(45deg);
  }
}

.checkbox:disabled + .custom_checkbox,
.checkbox:disabled ~ .label_text,
.checkbox:disabled ~ .description {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom_checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 7px;
  background-color: white;
  border: 2px solid var(--color-text-accent);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    border-radius: 2px;
    background-color: white;
    transition: background-color 0.3s, transform 0.3s;
    transform: scale(1) rotate(0);
  }

  &::before {
    top: 50%;
    left: 5px;
    width: 10px;
  }
  &::after {
    top: 9px;
    left: 3px;
    width: 5px;
  }
}

.label_text {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text);
  cursor: pointer;
}
