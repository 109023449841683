.notifications {
    width: 300px;
    border-radius: 5px;
    z-index: 905;
    background: var(--color-black-0);
    overflow: hidden;
    font-family: "Manrope", sans-serif;
    box-shadow: -4px 4px 4px 0px #0000000d;

    &Header {
        background: var(--color-primary-3);
        height: 70px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
            color: var(--color-black-0);
            font-size: 16px;
            font-weight: 700;
        }
        &__icon {
            cursor: pointer;
            color: var(--color-black-0);

            svg {
                width: 18px;
                height: 13px;
            }
        }
    }

    &Warning {
        padding: 15px;
        text-align: center;

        &__title {
            font-size: 14px;
            font-weight: 700;
            color: var(--color-black-80);
        }
    }

    &List {
        padding: 15px 15px 11px;

        &__item {
            padding: 5px 0;
            border-top: solid 1px var(--color-black-20);
            border-bottom: solid 1px var(--color-black-20);
            cursor: pointer;

            &:nth-child(1) {
                border-top: none;
            }
            &:nth-last-child(1) {
                border-bottom: none;
            }

            &_delete {
                transition: 0.9s;
                opacity: 0;
                transform: translateX(300px);
            }
        }
        &__box {
            position: relative;
            display: flex;
            gap: 5px;
        }
        &__check {
            width: 13px;
            background: #eafff8;
            position: relative;

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 5px;
                height: 5px;
                content: "";
                background: #1abc97;
                border-radius: 50%;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        &__title {
            color: #2d3436;
            font-size: 12px;
            font-weight: 700;
        }
        &__text {
            color: #636e72;
            font-size: 8px;
            display: flex;
        }
        &__time {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            font-size: 6px;
            color: #636e72;
            padding: 4px 7px;
            background: #eafff8;
            border-radius: 5px;
        }
    }

    &Footer {
        border-top: solid 1px var(--color-black-40);
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &__content {
            display: flex;
            align-items: center;
            gap: 6px;
        }
        &__title {
            font-weight: 700;
            color: var(--color-black-80);
            font-size: 12px;
        }
        &__arrow {
            color: var(--color-black-80);
            display: flex;
            align-items: center;

            svg {
                width: 10px;
            }
        }
    }
}
