.wrapper {
  width: auto;
  position: absolute;
  max-width: 290px;
  padding: 5px 0;
  top: 80%;
  left: 10px;
  z-index: 1;
  background-color: white;

  &__right {
    left: auto;
    right: 10px;
  }

  @media (min-width: 768px) {
    max-width: none;
  }
}

.item {
  padding: 10px;
  transition: background-color 0.3s;
  white-space: nowrap;

  &:hover {
    background-color: #ededed;
  }

  @media (min-width: 768px) {
    padding: 10px 15px;
  }

  &__warning {
    color: var(--color-error);
  }
}

.dayname {
  text-transform: uppercase;
}

.show {
  &:local {
    animation: show-animation 0.3s ease;
  }
}

.hide {
  & :local {
    animation: hide-animation 0.3s ease;
  }
}

@keyframes show-animation {
  0% {
    opacity: 0;
    top: 50%;
  }

  100% {
    opacity: 1;
    top: 80%;
  }
}

@keyframes hide-animation {
  0% {
    opacity: 1;
    top: 80%;
  }

  100% {
    opacity: 0;
    top: 50%;
  }
}
