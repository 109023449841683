.audioFiles {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.row {
  align-items: center;
  gap: 15px;
  grid-template-columns: 1fr min-content;
}
