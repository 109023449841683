.workSchedule-box {
  &__ms-box {
    padding: 20px;
    width: 100%;

    &--templates {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    p {
      color: #5f6368;
      font-size: 12px;
      line-height: 1.25;
      margin-top: 1em;
      b {
        font-weight: 600;
      }
    }

    &--row-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    &--info {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 600;
    }

    &--info:hover {
      text-decoration: none;
    }
  }

  &__card {
    background-color: #f6f6f6;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
    position: relative;
  }

  &__cardCloseButton {
    cursor: pointer;
    height: 20px;
    opacity: .8;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    &:hover {
      opacity: 1;
    }
    &::before, &::after {
      background-color: black;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      top: 9px;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }

  &__cardRow {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    &--two {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &--three {
      display: grid;
      grid-template-columns: 235px 1fr 1fr;
    }
  }

  &__labelCheck {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__label {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    //line-height: 2;
    margin-bottom: .5em;
  }

  // Переопределения
  .datetime-box-middle {
    flex-shrink: 0;
    width: 43px;
  }
}

.wt-calendar {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  margin-top: 20px;

  &__day {
    align-items: center;
    border: 1px solid #bdbdbd;
    color: black;
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 45px;
    justify-content: center;
    position: relative;
    width: calc((100% - 6px) / 7);

    &--transparent {
      border: none;
    }

    span {
      font-size: 12px;
    }
  }

  &__green {
    background-color: #b1f5cf;
  }
}

.wt-calendar-box {
  height: 0;
  overflow: hidden;
  transition: .5s ease-in;

  &--active {
    height: 300px;
    transition: .5s ease-in;

  }
}