.wrapper {
  border-radius: 5px;

  &__margin {
    margin-bottom: 20px;
  }

  &__error {
    border: 1px solid var(--color-error);
    box-shadow: 0 0 1px 3px #ffdae7;
  }
}

.label {
  margin: 0 0 7px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text);

  span {
    color: var(--color-error);
  }
}

.select {
  border-radius: 5px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 0 3px #b8d4ff;
  }
}
