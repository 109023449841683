.variants {
    border: dashed 1px rgb(194, 197, 202);
    border-radius: 5px;
    padding: 20px;
    min-height: 75px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 0;
    padding-right: 0;
    span {
        border-radius: 10px;
        border: solid 1px rgb(194, 197, 202);
        margin-right: 20px;
        font-weight: 500;
        padding: 10px 15px;
        cursor: pointer;
        transition: 0.5s;
        margin-bottom: 20px;
        &:hover {
            background: #3474ff;
            color: white;
            border: solid 1px #3474ff;
            transition: 0.5s;
        }
    }
}
.images {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}
.answer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 170px;
    width: 100%;
    margin-bottom: 20px;
    img {
        margin-bottom: 10px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    div {
        border: solid 1px rgb(194, 197, 202);
        border-radius: 10px;
        padding: 5px;
        min-height: 35px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
            background: #3474ff;
            color: white;
            border: solid 1px #3474ff;
            transition: 0.5s;
        }
    }
}
