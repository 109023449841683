.studytestmain {

  &_noResults {
    display: flex;
    width: 100%;
    padding: 20px;
    text-align: center;
    font-style: italic;
    color: #959595;
    font-size: 20px;
    align-items: center;
    justify-content: center;
  }

  /*&__spy-options {
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    background-color: #fff;
    margin: 10px;
    display: flex;
    flex-direction: row;
    height: 175px;

    &--sec {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 50%;
      color: #3f4d63;
      font-size: 14px;

      span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
      }

      span:first-child {
        font-size: 12px;
        color: #5f6368;
        padding-bottom: 10px;
      }
    }
  }*/
}

.studyTestMain {
  padding: 20px 15px;

  &__again {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    margin: auto;
    padding: 20px 0 0 0;

    &Button {
      border: 2px solid #ff0000;
      color: #ff0000;
      font-size: 20px;
      font-weight: 700;
      line-height: 60px;
      border-radius: 5px;
      background-color: #fff;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      transition: .2s;
      width: 100%;

      &:hover {
        background-color: #ff0000;
        color: #fff;
      }
    }

    &Count {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      height: 50px;
    }
  }

  &__studentCard {
    margin-top: 20px;
  }

  .main-table-card-box {
    margin: 10px 0;
  }
}