.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.typeEasy {

}

.withMarks {
  display: flex;
}

.marks {
  position: relative;
  display: flex;
  padding: 4px;
  border: 1px dashed var(--color-success-1);
  border-radius: 6px;
}

.marksShort {
  width: 100%;
  justify-content: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-success-1);
  text-align: center;
  font-size: 26px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: 0.6px;
  width: 100%;



  &:hover {
    cursor: pointer;
  }
}

.markGreen {
  background: var(--color-success-4);
  border: 1px dashed var(--color-success-1);
  * {
    color: var(--color-success-1);
  }

  &:hover {
    box-shadow: inset 0 0 0 1px var(--color-success-1);
    border: 1px solid var(--color-success-1);
  }
}
.markYellow {
  background: var(--color-warning-4);
  border: 1px dashed var(--color-warning-1);
  * {
    color: var(--color-warning-1);
  }

  &:hover {
    box-shadow: inset 0 0 0 1px var(--color-warning-1);
    border: 1px solid var(--color-warning-1);
  }
}
.markRed {
  background: var(--color-danger-4);
  border: 1px dashed var(--color-danger-1);
  * {
    color: var(--color-danger-1);
  }

  &:hover {
    box-shadow: inset 0 0 0 1px var(--color-danger-1);
    border: 1px solid var(--color-danger-1);
  }
}

.pressedGreen {
  background: var(--color-success-1);
  border: 1px solid var(--color-success-1);
  * {
    color: var(--color-black-0);
  }
}

.pressedYellow {
  background: var(--color-warning-1);
  border: 1px solid var(--color-warning-1);
  * {
    color: var(--color-black-0);
  }
}

.pressedRed {
  background: var(--color-danger-1);
  border: 1px solid var(--color-danger-1);
  * {
    color: var(--color-black-0);
  }
}