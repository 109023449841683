.box {
  display: grid;
  grid-template-columns: repeat(6,1fr);
  gap: 10px;
}

.styleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-black-40);
  border-radius: 6px;
  padding: 4px;

  &:hover {
    border: 1px solid var(--color-primary-1);
    background: var(--color-primary-4);
    cursor: pointer;
  }
}

.boxBorder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 6px;
}

.boxBorderCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.active {
  border: 1px solid var(--color-primary-1);
  background: var(--color-primary-4);
}

.error {
  border: 1px solid var(--color-danger-1);
  border-radius: 6px;
}