.placementTestBox {
  &__toggleLine {
    display: flex;
    flex-direction: row;
    width: 72%;
    justify-content: space-between;
    padding-bottom: 15px;
    &--right {
      padding-left: 35px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      padding-left: 0 !important;
    }
    input {
      max-width: 577px;
      height: 45px;
    }
  }
  &__activeToggle {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    p {
      font-weight: 500;
    }
    .placementTestBox__block {
      height: 20px;
      width: 28px;
    }
  }
  @media screen and (max-width: 700px) {
    .id-selector {
      margin: 0 auto;
      flex-direction: column;
      &__arrows {
        margin: 0 auto;
        img {
          margin: 0;
          &:first-child {
            transform: rotate(-270deg) !important;
            margin-right: 25px;
            margin-top: 5px;
          }
          &:nth-last-child(1) {
            transform: rotate(270deg) !important;
            margin-left: 25px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  &__activeField {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
}
