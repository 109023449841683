.wrapper {
  padding: 0 15px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}

.label {
  margin-bottom: 10px;
  flex-grow: 1;
  font-weight: 500;

  @media (min-width: 768px) {
    margin-bottom: 20px;
    margin-right: 20px;
    text-align: right;
  }
}

.select {
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-basis: 280px;
  }
}

.count {
  flex-basis: 100%;
  padding: 13px 20px;
  color: white;
  font-weight: 600;
  background-color: var(--color-accent-600);
  border-radius: 5px;
}
