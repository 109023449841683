.surf-menu {
  border-bottom: 2px solid #c7d7e4;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 15px;
  user-select: none;
  font-weight: 600;

  &__grad {
    margin-bottom: -2px;
    overflow-x: auto;
    position: relative;

    &::before, &::after {
      bottom: 2px;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 10px;
    }

    &::before {
      background: linear-gradient(to right, #e0e7ef, transparent);
      left: 0;
    }

    &::after {
      background: linear-gradient(to right, transparent, #e0e7ef);
      right: 0;
    }
  }

  &__tabs {
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__tab {
    border-bottom: 2px solid transparent;
    color: #7996ad;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    //line-height: 2.5;
    padding: 12px 20px;
    transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    white-space: nowrap;
    @media (min-width: 768px) {
      font-size: 16px;
    }

    &--active {
      border-bottom-color: #3f4d63;
      color: #3f4d63;
      font-weight: 600;
    }
  }

  &__count {
    font-size: 13px;
    vertical-align: super;
  }

  &__right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__action {
    align-items: center;
    display: flex;
  }
}
