.scheduleSettings {
  padding: 18px 15px 0;

  &__selectLocation {
    max-width: 350px;
    width: 100%;
    select {
      max-width: 350px;
      width: 100%;
      padding-right: 20px;
    }
  }
  &__selectTimeZone{
    max-width: 350px;
    width: 100%;
    select {
      max-width: 350px;
      width: 100%;
    }
  }
  &__selectBox{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

  }

  &__slots {
    margin-top: 20px;
  }

  &__saveBox {
    align-items: center;
    background-color: white;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
  }

  &__hoursText {
    align-items: center;
    display: flex;
    flex-direction: column;

    p {
      color: #5f6368;
      font-size: 12px;
      text-align: center;
      @media (min-width: 768px) {
        font-size: 14px;
      }

      &:not(:first-child){
        margin-top: 1em;
      }

      b {
        font-weight: bold;
      }
    }
  }

  &__isClosedSlot {
    margin: 1em 0;
  }
}

.scheduleBooked{
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  &__slots{
    padding: 20px;
    border-bottom: 1px solid #bdbdbd;
    &--title{
      color: #5f6368;
      font-size: 20px;
      font-weight: 500;
    }
    &--note{
      padding: 15px 20px;
      line-height: 18px;
      font-size: 12px;
      color: #4d5055;

    }
    &--exp{
      padding: 0px 20px;
      border-top: 1px solid #bdbdbd;
    }
  }
}