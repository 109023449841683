.playerContainer {
  border: 1px solid var(--color-primary-3);
  background: var(--color-primary-4);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  div {
    border-radius: 6px;
    background-color: transparent;
  }
}