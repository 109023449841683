.contentPagination {
  align-items: center;
  color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 20px 15px;
  user-select: none;

  &__params {
    align-items: center;
    display: flex;
  }

  &__inputNumber {
    //background-color: #fff;
    //border: 1px solid #eeeeee;
    //border-radius: 5px;
    //height: 32px;
    margin-left: 1em;
    //position: relative;
    //width: 54px;

    input[type="number"] {
      background: #fff;
      border: none;
      border-radius: 5px;
      height: 100%;
      text-align: center;
      width: 54px - 20px;
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    select{
      width: 51px;
      border-radius: 5px;
      padding-left: 5px;
      height: 30px;
      &:hover {
        border: 1px solid #bdbdbd;
        box-shadow: 0 0 0 3px #b8d4ff;
        transition: .3s;
        cursor: pointer;
      }
      &:focus {
        border: 1px solid #0062ba;
        box-shadow: 0 0 0 3px #b8d4ff;
      }
    }
  }

  &__info {
    margin-left: auto;
    @media (min-width: 768px) {
      margin-left: 20px;
    }
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    width: 100%;

    @media (min-width: 768px) {
      margin-left: auto;
      margin-top: 0;
      width: auto;
    }
  }

  &__pages {
    flex: 1 1 50%;
    max-width: 100%;
    @media (max-width: 727px) {
      width: 0;
    }

    ul {
      display: flex;
    }

  }

  &__pageLink {
    align-items: center;
    background-color: white;
    border: 1px solid #e4e9f0;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
    min-width: auto;
    width: 32px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    //&:hover {
    //  border: 1px solid #3f4d63;
    //}
    &:hover {
      border: 1px solid #bdbdbd;
      box-shadow: 0 0 0 3px #b8d4ff;
      transition: .3s;
      cursor: pointer;
    }
    &:focus {
      border: 1px solid #0062ba;
      box-shadow: 0 0 0 3px #b8d4ff;
    }

    &--current {
      border: 1px solid #3f4d63;
      color: #3f4d63;
      cursor: default;
    }

    &--prev, &--next {
      &::before {
        border-top: 2px solid #6c757d;
        border-right: 2px solid #6c757d;
        content: "";
        display: block;
        height: 8px;
        width: 8px;
      }
    }

    &--prev::before {
      transform: rotate(-135deg);
    }

    &--next::before {
      transform: rotate(45deg);
    }

    &--sep {
      background: transparent;
      border-color: transparent;
      cursor: default;
      &:hover {
        border-color: transparent;
      }

      i, i::before, i::after {
        background-color: #6c757d;
        border-radius: 50%;
        display: block;
        height: 4px;
        width: 4px;
      }

      i {
        position: relative;

        &::before, &::after {
          content: "";
          position: absolute;
        }

        &::before {
          left: -7px;
        }

        &::after {
          right: -7px;
        }
      }
    }
  }

  &__customPage {
    display: flex;

    input {
      border: 1px solid white;
      border-radius: 3px;
      height: 32px !important;
      text-align: center;
      width: 32px !important;
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    button {
      height: 32px;
      margin-left: 10px;
      padding: 0 10px;
      text-transform: uppercase;
    }
  }
}
