.userInfo {
  background: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  font-family: 'Manrope', sans-serif;
  gap: 20px;
  padding: 20px 0;
  position: relative;
}
.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 15px;
}
.avatar {
  position: relative;
}
.nameAvatar {
  align-items: center;
  border-radius: 7px;
  display: flex;
  font-size: 72px;
  height: 100px;
  justify-content: center;
  width: 100px;
  > div:only-child {
    font-size: 34px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.68px;
    color: var(--color-black-0);
  }
}
.cameraBtn {
  align-items: center;
  background-color: var(--color-primary-2);
  border-radius: 50%;
  bottom: -4px;
  color: var(--color-black-0);
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  position: absolute;
  right: -4px;
  width: 30px;

  svg {
    height: 18px;
    width: 18px;
  }
}
.info {
  align-items: center;
  display: flex;
  flex-direction: column;

  div {
    color: var(--color-black-60);
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;

    &:first-child {
      color: var(--color-black-100);
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      word-break: break-word;
    }
    &:last-child {
      color: var(--color-black-80);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}
.balance {
  background-color: var(--color-primary-4);
  border: 1px solid var(--color-primary-3);
  color: var(--color-black-100);
  border-radius: 7px;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  padding: 1em;
  width: 100%;

  div:last-child {
    color: var(--color-black-80);
    font-size: 18px;
    font-weight: 700;
  }

}
.statusBtn {
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
  &.green {
    color: var(--color-success-1);
  }
  &.red {
    color: var(--color-danger-1);
  }
}
.kyndelik {
  max-width: 225px;
  width: 100%;
  a {
    align-items: center;
    border-radius: 6px;
    border: 1px solid var(--color-black-60);
    background: var(--color-black-0);
    justify-content: center;
    display: flex;
    padding: 5px 0;
  }
}
.menu {
  color: var(--color-black-80);
  display: none;
  font-size: 14px;
  gap: 16px;
  line-height: 48px;
  width: 100%;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}
.menuItem {
  cursor: pointer;
  padding: 0 24px;
  &:hover, &Selected {
    background-color: var(--color-primary-4);
  }
  &Selected {
    border-right: 3px solid var(--color-primary-1);
  }
}
