.buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }

  button {
    max-width: 250px;
    width: 100%;
  }
}
