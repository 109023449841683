.tableWrapper {
  overflow-x: auto;
}

.tableWrapper_h {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 100vh;
}

.sticky {
  display: none;
  overflow-x: hidden;
  position: fixed;
  top: 61px;
  z-index: 1;
  thead {
    background-color: #fff;
  }
}

.table {
  background-color: #fff;
  color: #5f6368;
  position: relative;
  width: 100%;
}

.thead {
  border-bottom: 2px solid #80868b;
  font-size: 14px;
  font-weight: 600;

  /*&:nth-of-type(2) {
    background-color: #fff;
    display: none;
    left: 0;
    position: absolute;
    right: 0;
  }*/

  tr:nth-child(2) {
    border-top: 1px solid #80868b;
    font-size: 12px;
    font-weight: 500;
  }

  th {
    border-left: 1px solid #80868b;
    border-right: 1px solid #80868b;
    vertical-align: middle;
    padding: 1em 10px;

    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
    
  }
}

.tbody {
  line-height: 1.5;

  tr {
    &.bdt {
      border-top: 1px solid #ccc;
    }
    &.strip {
      background-color: #f6f6f7;
    }
    &.nobr {
      white-space: nowrap;
    }
  }

  td {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    vertical-align: top; // переопределение reset.css
    padding: 0.5em 10px;

    &.bdt {
      border-top: 1px solid #ccc;
    }

    &.bdb {
      border-bottom: 1px solid #ccc;
    }

    &.textAlignCenter {
      text-align: center;
    }

    &.fz12 {
      font-size: 12px;
    }

    &.fz14 {
      font-size: 14px;
    }

    &.fw500 {
      font-weight: 500;
    }

    &.fw600 {
      font-weight: 600;
    }

    &.color {
      color: #3f4d63;
    }

    &.ttu {
      text-transform: uppercase;
    }

    &.bgw {
      background-color: #fff;
    }
    &.bgg {
      background-color: #f6f6f7;
    }

    &.tdTotal {
      background-color: #5a8dee;
      color: white;
      font-size: 12px;
      font-weight: 600;
      padding: 1em 10px;
    }

    &.bdlN {
      border-left: none;
    }

    &.bdrN {
      border-right: none;
    }
    a {
      color: #3f4d63;
      font-weight: 500;
      outline: none;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.tbodyStriped {
  tr:nth-child(even) {
    background-color: #f6f6f7;
  }
}

.tdContentGroup {
  display: flex;
  flex-direction: column;
  gap: 2em;
  span {
    font-size: 12px;
  }
}

.tdContentPrices {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.tdContentVacPos {
  font-size: 12px;
  font-weight: 600;
  color: #5f6368;
}
.tdContentVacNeg {
  font-size: 12px;
  font-weight: 600;
  color: #db3031;
}

.tdContentBank {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 1em;
  div:first-child {
    font-size: 14px;
    font-weight: 500;
  }
}
.tdMiddle {
  vertical-align: middle !important;
}
.groupDescription {
  font-size: 12px;
  &.medium {
    font-weight: 500;
  }
  &.bottom {
    margin-bottom: 5px;
  }
}

.noData {
  text-align: center;
}