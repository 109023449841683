.fileList {
  &_upload {
    border: 2px dashed #bdbdbd;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    input[type='file'] {
      display: none !important;
    }

    label {
      &:hover {
        background-color: #576377;
        color: #fff;
      }
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    input {
      margin: 0;
    }
  }

  &_delete {
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    padding-right: 5px;
  }
}
