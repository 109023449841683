.day {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: calc(100vw / 20);
  justify-content: center;
  user-select: none;
  @media (min-width: 768px) {
    font-size: 16px;
  }

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}
.disabled {
  border: 0;
  cursor: default;
  pointer-events: none;
}
.selected {
  border: 2px solid #fff;
}
.day:hover, .selected {
  background-color: #216ba5;
  color: #fff;
}
.day:hover {
  border: none;
}
