.radioInput {
    cursor: pointer;
    display: flex;
    gap: 10px;
}
.input {
    border: 1px solid #bdbdbd;
    border-radius: 50%;
    height: 16px;
    transition: .2s;
    width: 16px;
}
.inputChecked {
    composes: input;
    border: 5px solid #3f4d63;
    /*box-shadow: inset 0 0 0 5px #3f4d63;*/
}
.label {

}