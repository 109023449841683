.addBtn {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 36px;
  min-width: 234px;
  padding: 0 15px;
  color: var(--color-primary-3);
  border: 1px solid var(--color-primary-3);
  border-radius: 7px;
  font-family: Manrope, sans-serif;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;


  svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    color: var(--color-primary-2);
    background: var(--color-black-0);
    border: 1px solid var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
  }
}

