$border: 1px solid var(--color-primary-3);

.languageSelector {
  background-color: var(--color-black-0);
  border: $border;
  border-radius: 7px;
  cursor: pointer;
  height: 50px;
  position: relative;
  user-select: none;
  width: 100px;
}
.current {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 10px;
}
.currentImg {
  width: 54px;
  img {
    display: block;
    width: 100%;
  }
}
.arrow {
  border-left: $border;
  border-bottom: $border;
  height: 6px;
  transform: rotate(-45deg);
  width: 6px;
}
.popup {
  background: var(--color-black-0);
  border: $border;
  border-radius: 7px;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 55px;

  li {
    align-items: center;
    border-top: $border;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    &:first-child {
      border-top: none;
    }
    &:hover {
      background-color: var(--color-primary-4);
    }

    div {
      width: 35px;
      img {
        display: block;
        width: 100%;
      }
    }
    span {
      font-size: 12px;
      font-weight: 700;
    }
  }
}
.popupHide {
  display: none;
}
