.courseDocs {
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;

  &__header {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    padding: 10px 20px;

    &--count {
      span:last-child {
        cursor: pointer;
        font-weight: 600;
        color: #3f4d63;
        text-decoration: underline;
      }

      span:last-child:hover {
        text-decoration: none;
      }
    }

    button {
      font-family: inherit;
      font-weight: 500;
      line-height: 36px;
      padding: 0 20px;
    }
  }

  &__body {
    padding: 10px 20px;
  }

  &__doc {
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (min-width: 768px) {
      flex-direction: row;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__file {
    align-self: flex-start;
    display: flex;
    gap: 10px;
    @media (min-width: 768px){
      flex: 0 0 38%;
    }
  }

  &__icon {
    position: relative;

    span {
      bottom: 8px;
      color: #fff;
      font-size: 9px;
      position: absolute;
      text-transform: uppercase;
      left: 10px;
      z-index: 2;
    }
  }

  &__name {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: auto;
    text-decoration: underline;
    font-weight: 500;

    a {
      color: #3f4d63;
      outline: none !important;
    }
  }

  &__description {
    flex: 0 0 38%;
    font-size: 12px;
    line-height: 16px;
  }

  &__date {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    line-height: 16px;
    margin-left: auto;

    span:first-child {
      font-size: 12px;
    }
  }

  &__action {
    align-self: flex-start;
    margin-top: 10px;
  }

}