@import "../MainStyles";

.answerBody {
  @include answerBodyMain;
  padding: 20px 10px 10px 10px;
}

  .gapCntr {
  margin-top: 32px;
}

.span {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.36px;
}

.answText {
  align-self: flex-start;
}

.rightAnswer {
  @include rightAnswerMain;
}

.wrongAnswer {
  @include wrongAnswerMain;
}

.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.correctColor {
  @include correctColorMain;
}

