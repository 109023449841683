.clientChart {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: auto 1fr;
    background: white;
    border-radius: 5px;
    border: 1px solid rgb(189, 189, 189);

    &_header {
        min-height: 80px;
        grid-column-start: 2;
        grid-column-end: 3;
        padding: 5px 20px;
        border-bottom: 1px solid rgb(189, 189, 189);
        display: flex ;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
            grid-column-start: 1;
            flex-direction: column;
            justify-content: center;
        }
    }

    &_title {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(189, 189, 189);
        
        h3 {
            transform: rotate(-180deg);
            writing-mode: vertical-rl;
            display: block;
            width: max-content;
            height: max-content;
            font-weight: normal;
            color: #3f4d63;
            strong {
                font-weight: 800;
            }
        }
        @media screen and (max-width: 767px) {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 3;
            border-right: none;
            border-bottom:1px solid rgb(189, 189, 189);
            h3 {
                transform: rotate(0);
                writing-mode: horizontal-tb;
                padding: 10px 0;
            }
        }
    }

    &_content {
        display: flex;
        justify-content: space-between;
        padding: 40px 20px;
        flex-wrap: wrap;
        @media screen and (max-width: 767px) {
            flex-direction: column;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    
    &_itemBox {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 30%;
        @media screen and (max-width: 767px) {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
    &_item {
        display: grid;
        grid-template-columns: 65px auto 1fr;
        align-items: center;
        @media screen and (max-width: 767px) {
            min-width: 50%;
        }
    }

    &_line {
        display: block;
        width: 5px;
        margin: 0 10px;
        border-radius: 10px;
        height: 40px;
        background-color: black;
    }

    &_number {
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
        font-size: 24px;
        color: #3f4d63;
        font-weight: 800;
        span {
            display: block;
            font-size: 14px;
            color: #a2a2a2;
            font-weight: normal;
        }
    }

    &_btn {
        border: 2px solid #3f4d63;
        border-radius: 5px;
        background-color: white;
        color: #3f4d63;
        width: fit-content;
        &:hover {
            color: white;
            background-color: #3f4d63;

        }
        @media screen and (max-width: 767px) {
            width: 100%;
            margin: 10px 0;
        }
    }

    &_field {
        width: 254px;
        margin-right: 20px;
        padding: 5px 0;
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-right: 0;
        }
    }

    &_wrapper {
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 767px) {
            justify-content: center;
            width: 100%;
        }
    }
    &_wrapper_doughnut {
        align-self: center;
        display: flex;
        justify-content: center;
        width: 40%;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

}

.doughnut {
    &_box {
        width: 260px;
        position: relative;
    }
    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
    }
    &_title {
        font-size: 60px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 30px;
        span {
            font-weight: normal;
            display: block;
            font-size: 12px;
        }

    }
    &_subtite {
        text-align: center;
        font-size: 25px;
        color: #5a8dee;
        font-weight: 600;
        span {
            font-weight: normal;
            display: block;
            font-size: 12px;
            color: black;;
        }
    }
  

}