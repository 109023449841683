.studentSelector {
  align-items: center;
  border-radius: 6px;
  border: 1px dashed var(--color-warning-1);
  background: var(--color-warning-4);
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
  position: relative;
}
.chooseStudent {
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  letter-spacing: 0.28px;
}

.button,
.buttonSelected {
  align-items: center;
  border-radius: 7px;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  width: 40px;
}

.button {
  border: 1px solid var(--color-black-60);
  background: var(--color-black-0);
  color: var(--color-black-60);

  &:hover {
    background-color: var(--color-primary-4);
    color: var(--color-primary-2);
  }
}

.buttonSelected {
  border: 1px solid var(--color-primary-1);
  background: var(--color-primary-3);
  color: var(--color-black-0);

  &:hover {
    background-color: var(--color-primary-4);
    color: var(--color-primary-2);
  }
}

.popup {
  border-radius: 6px;
  border: 1px solid var(--color-black-60);
  background: var(--color-black-0);
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  position: absolute;
  right: 60px;
  top: 10px;
  width: 250px;
  z-index: 1;
}
.popupClose {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.student {
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .42px;
  padding: .5em;
  &:hover, &Selected {
    background-color: var(--color-primary-4);
  }
}
