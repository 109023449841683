.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 439px;
    text-align: center;
    padding: 0 15px;
    font-family: "Manrope", sans-serif;

    @media (min-width: 768px) {
        height: 539px;
    }

    &__image {
        width: 256px;
        height: 193px;
        margin-bottom: 38px;

        @media (min-width: 768px) {
            width: 380px;
            height: 286px;
            margin-bottom: 56px;
        }
    }
    &__title {
        margin-bottom: 20px;
        color: var(--color-black-100);
        font-weight: 500;
        font-size: 20px;
    }
    &__btn {
        font-weight: 500;
        color: var(--color-black-0);
        background: var(--color-primary-1);
        border-radius: 7px;
        padding: 16px 50px;

        @media (min-width: 768px) {
            padding: 16px 78px;
        }

        &:hover {
            background: var(--color-primary-2);
        }
    }
}
