.type-selector-box {
  border-radius: 5px;
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  border: 1px solid var(--color-primary-2);
  width: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 62px;
  }

  div {
    border-right: 1px solid var(--color-primary-2);
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.42px;
    //width: calc(100% / 3 + 5px);
    color: var(--color-primary-2);
    @media (max-width: 768px) {
      height: 30px;
      width: 100%!important;
    }
  }

  div:hover {
    cursor: pointer;
  }

  div:last-child {
    border: none;
  }
}

.type-selector-box .type-selector-selected {
  background-color: var(--color-primary-2);
  color: var(--color-black-0);
  cursor: pointer;
}
