.toggleCheckbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 15px;
  font-weight: 500;

  &__disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  &__unselected {
    color: var(--color-black-60);
  }

  &__label {
    color: var(--color-black-80);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    user-select: none;
  }
}

.label {
  color: var(--color-black-80);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
  user-select: none;
}

.toggle {
  background-color: var(--color-black-60);
  border-radius: 19px;
  height: 24px;
  transition: 0.2s;
  width: 44px;
  position: relative;
  user-select: none;
  min-width: 45px;

  &::before {
    background-color: var(--color-black-0);
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    margin-top: 2px;
    margin-left: 2px;
    transition: 0.2s;
    width: 20px;
  }

  &__checked {
    background-color: var(--color-primary-1);

    &::before {
      margin-left: 22px;
    }
  }
}

.checkMark, .cross {
  position: absolute;
  top: 4px;
  width: 16px;
  height: 16px;
  * {
    fill: var(--color-black-0);
  }
}

.cross {
  right: 2px;
}

.checkMark {
  left: 2px;
}