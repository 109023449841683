.QuestionAudios {
  display: flex;
  flex-direction: column;

  &__body {
    position: relative;

    audio::-webkit-media-controls-panel {
      background-color: #fff;
    }

    &__audioBox {
      display: flex;
      position: relative;

      &--script {
        position: absolute;
        background-color: #fff;
        right: 0;
        width: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50px;

        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }
  }

  &__audioDescription {
    font-weight: 700;
  }

  &__scriptBody {
    border: 1px solid #eef0ff;
    position: absolute;
    z-index: 500;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    //white-space: pre;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px rgba(238, 240, 255, 0.6);
    line-height: 1.25;
    &::after {
      content: '';
      position: absolute;
      right: 6px;
      top: -16px;
      border: 8px solid transparent;
      border-bottom: 8px solid #fff;
    }

    p:first-child {
      padding-bottom: 10px;
    }

    transition: opacity .2s ease-in-out;
  }
}

