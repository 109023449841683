.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #e0e7ef;
}

.header {
  min-height: 300px;
  padding: 70px 0 30px;
  background-image: linear-gradient(#1c3c50, #0099dd);
}

.title {
  max-width: 1070px;
  margin: 0 auto 45px;
  padding: 0 15px;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: white;

  @media (min-width: 768px){
    font-size: 35px;
  }
}

.main {
  flex-grow: 1;
  width: 100%;
  max-width: 1070px;
  margin: 0 auto;
  padding: 30px 15px 40px;

  @media (min-width: 768px) {
    padding: 30px 30px 40px;
  }
}

.footer {
  width: 100%;
  max-width: 1070px;
  margin: 0 auto;
  padding: 0 15px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 768px) {
    padding: 0 30px 40px;
  }
}

.footer_text {
  margin-right: 20px;
}

.footer_img {
  width: 20px;
}

.wrapper__selects {
  padding: 0 15px;
  @media (min-width: 768px) {
    padding: 0 30px;
  }
}