.container {
  display: grid;
  gap: 20px;
}

.studentList {
  overflow: hidden;

  li {
    width: 100%;
    padding: 10px 0;
    vertical-align: middle;
    cursor: pointer;
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 600;
    position: relative;
    &:hover {
      color: var(--color-black-100);
  }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      border-radius: 5px;
      border: 1px solid var(--color-black-40);
      background-color: var(--color-black-0);
      position: absolute;
      right: 10px;
      top: 7px;
      cursor: pointer;
      &:hover {
        border-color: var(--color-primary-2);
      }
      &:active {
        border-color: var(--color-primary-2);
      }
      .deleteIcon {
        height: 100%;
        width: 100%;
        color: var(--color-black-60);
        &:hover {
          color: var(--color-primary-2);
        }
        &:active {
          color: var(--color-primary-2);
        }
      }
    }
  }
}


.gmtText {
  padding-top: 7px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-black-60);
}

.time {
  display: flex;
  gap: 20px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--color-black-40);
}

.order {
  display: grid;
  gap: 8px;
  width: 100%;
}

.datePicker {
  width: 20px;
  height: 46px;
  padding: 15px 7px;
  opacity: 0;
  background-color: var(--color-black-40);
}

.datetimeBox {
  width: 100%;
  height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  overflow: hidden;
}

.datetimeBox input {
  width: 100%;
  height: 51px;
  margin-bottom: 0 !important;
  border: none !important;
  padding-left: 0 !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-black-100);
}

.datetimeBox:hover {
  box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60);
}

.datetimeBox:focus {
  border-color: var(--color-primary-2);
  box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60), inset 0 0 0 1px var(--color-primary-2);
}

.datetimeBoxMiddle {
  min-width: 20px;
  background-color: var(--color-black-40);
  color: var(--color-black-80);
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.lineText {
  padding-left: 60px;
  font-size: 12px;
  color: var(--color-black-80);;
}

.optionsNumber {
  margin-left: auto;
  width: 100%;
  max-width: 200px;
}

.optionBox {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.optionBoxFs {
  justify-content: flex-end;
}