.dow-box{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    height: 45px;
}
.dow-box div{
    width: calc(100% / 9);
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.dow-box-selected{
    background-color: #5a8dee;;
    border-color: #5a8dee;
    color: #fff;

}
.dow-box div:hover{
    background-color: #5a8dee;
    border-color: #5a8dee;
    color: #fff;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
}

.dow-box div:first-child{
    border-left: 1px solid #bdbdbd;
    border-radius: 5px 0px 0px 5px;
}

.dow-box div:nth-last-child(1){
    border-radius: 0px 5px 5px 0px;
}
.dow-dis{
    background-color: #e2e2e2 !important;
}