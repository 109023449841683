.tabs {
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow-x: auto;
  user-select: none;

  &::-webkit-scrollbar {
    height: 3px;
  }
}

.tabsItem {
  border-bottom: 2px solid transparent;
  display: flex;
  transition: .3s;

  &__active, &:hover {
    border-color: #007be8;
  }

  span {
    align-items: center;
    color: #757575;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px 10px;
    text-align: center;
    white-space: nowrap;
  }

  &__active {
    span {
      color: #000000;
    }
  }
}