.lessonStudent {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.bookContainer {
  display: flex;
  margin: 100px auto 0;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.bookColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.noExercises {
  text-align: center;
}

.review {
  padding: 20px;
  width: 100%;
  max-width: 900px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid var(--color-black-40);
  background: var(--color-black-0);

  .reviewBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 10px 20px;
    border: 2px solid var(--color-primary-2);
    background: var(--color-primary-4);
    border-radius: 6px;
    color: var(--color-primary-2);
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.36px;

    &:hover {
      cursor: pointer;
      color: var(--color-black-0);
      background: var(--color-primary-1);
      svg {
        fill: var(--color-black-0);
      }
    }
  }
}

.aiButton {
	position: fixed;
	bottom: 80px;
	right: 80px;
	z-index: 100;
}


.aiChat {
	position: fixed;
	bottom: 0px;
	right: 0px;
	z-index: 100;
}

@media (max-width: 1440px) {
	.aiButton {
			right: 15px;
	}
}