.inputInn {
  align-items: stretch;
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  display: grid;
  grid-template-columns: 85px 1fr;
  height: 48px;
  input {
    border: none;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    font-family: inherit;
    padding: 0 15px;
    color: var(--color-black-100);
    font-weight: 300;
    &::placeholder {
      color: var(--color-black-60);
      font-weight: 300;
    }
  }
  &.error {
    box-shadow: 0 0 0 2px rgba(173, 0, 0, 0.25);
    border: 1px solid var(--color-danger-1);
  }
  &.hover {
    box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
    border: 1px solid var(--color-black-40);
  }
  &.focus {
    box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
    border: 1px solid var(--color-primary-2);
  }
  &.disabled input {
    background: var(--color-black-20);
    pointer-events: none;
    &::placeholder {
      color: var(--color-black-100);
      font-weight: 300;
    }
  }
}
.selector {
  border-right: 1px solid var(--color-black-40);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  position: relative;

  ul {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 10px;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    background: var(--color-black-0);
    width: 92px;
    li {
      padding: 10px;
      cursor: pointer;
      position: relative;
      &:hover, &.active {
        border-radius: 5px;
        background: var(--color-black-20);
        &::after {
          content: '';
          background: var(--color-primary-2);
          width: 2px;
          height: 22px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2.5px;
          border-radius: 5px;
        }
      }
    }
  }
}
.current {
  align-items: center;
  background-color: var(--color-black-10);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding-right: 14px;
  gap: 29px;
  min-width: 93px;
  border-right: solid 1px var(--color-black-40);
  color: #111314;
  font-weight: 300;
  font-size: 14px;

  &::after {
    border-bottom: 1px solid var(--color-black-100);
    border-right: 1px solid var(--color-black-100);
    content: "";
    display: block;
    height: 6px;
    margin-top: -3px;
    transform: rotate(45deg);
    width: 6px;
    transition: 0.3s;
  }
  
  &_active {
    &::after {
      transform: rotate(225deg);
      margin-bottom: -5px;
    }
  }
}
