.clock {
  .timezoneBad {
    color: var(--color-danger-1);
  }
}

.point {
  width: 7px;
  transition: all .2s ease-in;
}

.pointOp0 {
  composes: point;
  opacity: 0;
}

.timezone {
  color: var(--color-black-100);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.24px;
}

.utc {
  color: var(--color-black-100);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.24px;
}