.box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: .5em;

  strong {
    font-size: 14px;
    font-weight: 600;
  }
}
.variants {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.variant {
  align-items: center;
  display: flex;

  label {
    margin-left: 0;
  }
}
.cell {
  flex-grow: 1;
}

.deleteVariantBtn {
  margin-left: 20px;
}