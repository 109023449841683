.InputFlagPhone {
  & &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    height: 48px;
    width: 100%;
    img {
      width: 18px;
      height: 13px;
    }
    & input {
      border: none;
      width: 100%;
      height: 100%;
      font-size: 14px;
      padding: 0 10px;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      font-weight: 300;
      color: var(--color-black-100);
      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }
      &::placeholder {
        color: var(--color-black-60);
      }
    }
    &--disabled {
      input {
        background-color: var(--color-black-20);
        border: 1px solid var(--color-black-40);
        border-radius: 0;
        pointer-events: none;
        &::placeholder {
          color: var(--color-black-100);
        }
      }
    }
    &--error {
      border-radius: 7px;
      border: 1px solid var(--color-danger-1);
      background: var(--color-black-0);
      box-shadow: 0 0 0 2px rgba(173, 0, 0, 0.25);
      input::placeholder {
        color: var(--color-black-100);
      }
    }
    &--hover {
      border-radius: 7px;
      border: 1px solid var(--color-black-40);
      background: var(--color-black-0);
      box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.60);
    }
    &--focus {
      border-radius: 7px;
      border: 1px solid var(--color-primary-2);
      background: var(--color-black-0);
      box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.60);
    }
  }
  &__modal {
    position: absolute;
    content: "";
    top: 105%;
    background: var(--color-black-0);
    z-index: 5;
    left: 0;
    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px solid var(--color-black-20);
    min-width: 92px;
    border-radius: 7px;
    display: none;
    padding: 10px;
    &--open {
      display: block;
    }
    img {
      width: 20px;
      height: 10px;
      margin-right: 4px;
    }
    p {
      cursor: pointer;
      color: #111314;
      font-weight: 300;
      padding-left: 5px;
      padding-right: 8px;
      padding-top: 11px;
      padding-bottom: 11px;
      border-radius: 5px;
      position: relative;
      &:hover, &.InputFlagPhone__modal--active {
        background: var(--color-black-20);
        &::after {
          content: '';
          background: var(--color-primary-2);
          width: 2px;
          height: 22px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2.5px;
          border-radius: 5px;
        }
      }
    }
  }
  & &__btn {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    justify-content: space-between;
    min-width: 93px;
    cursor: pointer;
    background: var(--color-black-10);
    height: 100%;
    border-right: solid 1px var(--color-black-40);
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    user-select: none;
    color: #111314;
    font-weight: 300;
    & img {
      width: 20px;
      height: 10px;
    }
    &::after {
      content: "";
      border-bottom: 1px solid var(--color-black-100);
      border-right: 1px solid var(--color-black-100);
      display: block;
      height: 6px;
      width: 6px;
      margin-top: -3px;
      right: 6px;
      transform: rotate(45deg);
    }
  }
}