.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.employeeSkills {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  font-family: 'Manrope', sans-serif;
}
.employeeSkillsBody {
  background-color: var(--color-black-40);
  display: flex;
  flex-direction: column;
  gap: 1px;
}
