$brand: #3f4d63;
$grey-25: #e6e6e6;
$grey-5: #fcfcfc;


.form-container {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.promoted-input-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;


}

.promoted-checkbox {
  overflow: hidden;
  input:checked + label > svg {
    height: 10px;
    animation: draw-checkbox ease-in 0.2s forwards;

  }

  label:active::after {
    color: #5f6368;
  }

  label {
    line-height: 20px !important;
    color: #5f6368;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    cursor: pointer;
    position: relative;
    // margin-left: 10px;
    display: grid;
    grid-template-columns: 30px 1fr;
    &:after {

      content: "";
      height: 20px!important;
      width: 20px!important;
      margin-right: 10px;
      order: -1;
      display: block;
      float: left;
      border: 2px solid #3f4d63;
      transition: all .15s ease-out;
      border-radius: 5px;
      background-color: transparent;
    }
  }

  svg {
    display: block;
    stroke: $brand;
    stroke-width: 5px;
    height: 12 !important; //Firefox fix
    width: 12px;
    position: absolute;
    left: 4px;
    top: 5px;
    stroke-dasharray: 33; //Firefox fix
  }
  &--error {
    label {
      &:after {
        border: solid 2px red;
      }
    }
  }
}

.mover {
  top: -3px !important;
}

.label-txt {
  font-size: 20px !important;
  color: #405d83;
  cursor: pointer;
  width: 400px !important;
  display: flex;
  margin-top: -3px !important;
  position: absolute;
  margin-left: 20px;

}


@keyframes draw-checkbox {
  0% {
    stroke-dashoffset: -33;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
