.content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .show {
        font-size: 18px;
        box-shadow: 0 0 0 2px #55b1f399;
        border: 1px solid var(--color-primary-2);
        background: var(--color-black-0);
    }
    .hide {
        cursor: default;
    }
}
.text {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-black-100);
    line-height: 19.12px;

    @media (min-width: 768px) {
        line-height: 28px;
    }
    @media (min-width: 1024px) {
        margin-bottom: 20px;
    }
}
.top {
    border: 1px dashed var(--color-warning-1);
    border-radius: 6px;
    background: #fff9e9;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    span {
        border: 1px solid var(--color-black-60);
        border-radius: 6px;
        background: var(--color-black-0);
        display: flex;
        min-height: 30px;
        padding-right: 10px;
        padding-left: 6px;
        padding-top: 6px;
        padding-bottom: 6px;
        align-items: center;
        justify-content: center;
        cursor: grab;
        font-size: 18px;
        font-weight: 400;
        color: var(--color-black-100);
        word-break: break-word;
        & .dote {
            height: 100%;
            width: 3px;
            margin-right: 7px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;

            div, &::after, &::before {
                min-width: 3.5px;
                background: var(--color-black-60);
                border-radius: 50%;
                height: 3.5px;
                content: '';
                display: block;
            }
        }
        &.onDrag {
            border: 1px solid var(--color-black-40);
            background: var(--color-black-10);
            color: var(--color-black-40);

            & .dote {
                div, &::after, &::before {
                    background: var(--color-black-20);
                }
            }
        }
        &.selected {
            border: 1px solid var(--color-black-20);
            background: var(--color-black-10);
            color: var(--color-black-40);
            pointer-events: none;

            & .dote {
                div, &::after, &::before {
                    background:var(--color-black-20);
                }
            }
        }
    }
}
.bottom {
    border: 1px dashed var(--color-black-60);
    border-radius: 6px;
    background: var(--color-black-10);
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    span {
        padding: 0 23px;
        font-size: 14px;
        font-weight: 400;
        color: var(--color-black-100);
        min-height: 30px;
        min-width: 64px;
        border-radius: 7px;
        background: var(--color-black-0);
        border: 1px solid var(--color-black-40);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        word-break: break-word;
        @media (min-width: 768px) {
            min-width: 82px;
        }
        &.onDragOver {
            box-shadow: 0 0 0 2px #55b1f399;
            border: 1px solid var(--color-primary-2);
            background: var(--color-black-0);
        }
    }

  .correct {
    box-shadow: 0 0 0 2px #1abc9799;
    border: 1px solid var(--color-success-1);
  }

  .mistake {
    box-shadow: 0 0 0 2px #ff002299;
    border: 1px solid var(--color-danger-1);
    background: var(--color-black-0);
  }
}
