.searchBooks {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (min-width: 768px) {
    gap: 20px;
  }

  &__btn {
    color: var(--color-primary-2);
    border: solid 1px var(--color-primary-2);
    font-size: 14px;
    font-weight: 700;
    padding: 10px;
    border-radius: 7px;

    @media (min-width: 768px) {
      padding: 10px 15px;
    }

    svg {
      display: block;
      width: 17px;
      height: 17px;

      @media (min-width: 768px) {
        display: none;
      }
    }

    span {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }

    &:hover {
      background: var(--color-primary-2);
      color: var(--color-black-0);
    }
  }

  &Field {
    border: solid 1px var(--color-black-60);
    border-radius: 7px;
    background: var(--color-black-0);
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 12px;
    gap: 12px;
    font-family: "Manrope", sans-serif;
    width: 100%;

    &__icon {
      width: 20px;
      height: 20px;
      color: var(--color-black-60);
    }

    &__field {
      width: 100%;
      height: 100%;
      border: none;

      &,
      &::placeholder {
        font-size: 12px;
        font-weight: 500;
      }

      &::placeholder {
        color: var(--color-black-60);
      }
    }

    &__clear {
      min-width: 12px;
      height: 12px;
      position: relative;
      cursor: pointer;

      &::after,
      &::before {
        content: "";
        height: 1.2px;
        width: 12px;
        background: var(--color-primary-2);
        position: absolute;
        top: 50%;
        left: 0;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    }

    &_active {
      border: solid 1px var(--color-primary-2);

      .searchBooksField {
        &__field {
          color: var(--color-primary-2);
        }

        &__icon {
          color: var(--color-primary-2);
        }
      }
    }
  }
}

.filterBtn {
  background: var(--color-black-0);
  border: 1px solid var(--color-black-60);
  border-radius: 7px;
  color: var(--color-black-60);
  display: flex;
  gap: 15px;
  padding: 10px;
  @media (min-width: 768px) {
    padding: 10px 15px;
  }

  &__filled {
    background-color: var(--color-black-0);
    border-color: var(--color-success-1);
    box-shadow: inset 0 0 0 1px var(--color-success-1);
    color: var(--color-success-1);
    position: relative;
    &::after {
      background-color: var(--color-success-1);
      border-radius: 50%;
      content: '';
      display: block;
      height: 8px;
      position: absolute;
      right: 5px;
      top: 5px;
      width: 8px;
    }
  }

  span {
    font-weight: 700;
    font-size: 14px;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--color-black-0);
    border-color: var(--color-black-80);
    box-shadow: inset 0 0 0 1px var(--color-black-80);
    color: var(--color-black-80);
  }
}
