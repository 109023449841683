.wrapper {

}

.label {

}

.label_text {
  display: block;
  margin: 0 0 7px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text);
}

.input_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 0 0 3px #b8d4ff;
  }

  &:focus-within {
    box-shadow: 0 0 1px 3px #b8d4ff;
  }

  &__error,
  &__error:focus-within {
    box-shadow: 0 0 1px 3px #ffdae7;

    & .input {
      border: 1px solid var(--color-error);
    }
  }
}

.input {
  width: 100%;
  height: 45px;
  padding-left: 20px;
  color: var(--color-text);
  border: 1px solid var(--color-outline);
  border-radius: 5px 0 0 5px;
  transition: border 0.3s;
  appearance: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border: 1px solid #0062ba;
  }
}

.currency {
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0 5px 5px 0;
  border: 1px solid var(--color-outline);
  border-left: none;
  background-color: #d4d4d4;
}
