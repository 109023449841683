.wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(2px);
    z-index: 999;
}

.box {
    max-width: max-content;
    border-radius: 7px;
    padding: 10px 10px 50px;
    background: var(--color-black-0);
    box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    @media (min-width: 768px) {
        padding: 30px 30px 50px;
    }
}

.image {
    width: 100%;
    border-radius: 7px;
    max-height: 700px;
}

.header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.close {
    width: 40px;
    height: 40px;
    border-radius: 7px;
    background: var(--color-primary-2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background: var(--color-primary-1);
    }

    svg {
        height: 17px;
        width: 17px;
        fill: white;
    }
    @media (min-width: 768px) {
        width: 50px;
        height: 50px;
    }
}
