@mixin flexStyles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin menuIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--color-black-40);
  color: var(--color-black-60);
}

.card {
  @include flexStyles;
  font-family: "Manrope", sans-serif;
  padding: 15px 20px;
  flex-direction: row;
  @media (max-width: 1439px) {
    flex-direction: column;
  }

  .item {
    @include flexStyles;
    width: 100%;
    @media (max-width: 1439px) {
      flex-direction: column;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .info {
      width: 300px;
      @media (max-width: 1919px) {
        width: 100%;
        max-width: 285px;
      }
      @media (max-width: 1439px) {
        width: 100%;
        max-width: 100%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }

      .level {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.24px;
        color: var(--color-black-80);
      }

      .testLink {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.32px;

        a {
          color: var(--color-primary-1);
          border-bottom: 1px solid var(--color-primary-1);
        }
      }
    }

    .questionBlock {
      @media (max-width: 1439px) {
        @include flexStyles;
        margin-top: 10px;
        width: 100%;
      }
      @media (max-width: 767px) {
        margin-top: 0;
      }

      .question {
        width: 280px;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: var(--color-black-100);
        @media (max-width: 1919px) {
          width: 132px;
        }
        @media (max-width: 1439px) {
          display: flex;
          align-items: center;
          width: 85px;
          text-align: left;
        }
        @media (max-width: 767px) {
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }

      .goToLinkBlock {
        display: none;
        min-width: 222px;
        justify-content: right;
        gap: 25px;
        @media (max-width: 1919px) {
          display: none;
        }
        @media (max-width: 1439px) {
          display: flex;
          position: relative;
          bottom: 15px;
        }
        @media (max-width: 767px) {
          display: none;
        }

        .menu {
          @include menuIcon;
        }
      }
    }

    .data {
      text-align: center;
      width: 315px;
      font-size: 12px;
      letter-spacing: 0.24px;
      @media (max-width: 1919px) {
        width: 285px;
      }
      @media (max-width: 1439px) {
        display: none;
      }
      @media (max-width: 767px) {
        display: none;
      }

      .start {
        line-height: 24px;
        font-weight: 400;
        color: var(--color-black-100);
        span {
          font-size: 12px;
        }
        strong {
          font-weight: 700;
          font-size: 14px;
        }
        .dataStrong {
          color: var(--color-black-100);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }

      .finish {
        line-height: 24px;
        font-weight: 500;
        span {
          font-size: 12px;
          font-weight: 500;
          color: var(--color-black-80);
        }
        strong {
          font-weight: 700;
          font-size: 14px;
          color: var(--color-black-100);
        }
      }
    }

    .status {
      text-align: center;
      width: 250px;
      @media (max-width: 1919px) {
        width: 267px;
      }
      @media (max-width: 1439px) {
        display: none;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }

    .goToLink {
      display: flex;
      width: 222px;
      justify-content: right;
      gap: 25px;
      @media (max-width: 1919px) {
        display: flex;
      }
      @media (max-width: 1439px) {
        display: none;
      }
      @media (max-width: 767px) {
        display: flex;
        margin-top: 12px;
      }

      .menu {
        @include menuIcon;
      }
    }
  }
}

.dataStatusBlock {
  display: none;
  @media (max-width: 1439px) {
    display: flex;
    gap: 50px;
    width: 100%;
  }
  @media (max-width: 767px) {
    @include flexStyles;
    gap: 0;
    width: 100%;
  }

  .dataTest {
    width: auto;
    font-size: 12px;
    letter-spacing: 0.24px;
    text-align: left;

    .startTest {
      line-height: 24px;
      font-weight: 400;
      font-size: 12px;
      color: var(--color-black-100);

      strong {
        font-size: 14px;
        font-weight: 700;
      }
    }

    .finishTest {
      line-height: 24px;
      font-weight: 500;
      font-size: 12px;
      color: var(--color-black-80);

      strong {
        font-size: 14px;
        font-weight: 700;
        color: var(--color-black-100);
      }
    }
    
  }

  .statusTest {
    display: flex;
    align-items: center;
  }
}