.book {
  border: 1px solid var(--color-black-60);
  border-radius: 5px;
  background-color: var(--color-black-10);
  padding-bottom: .5em;
  position: relative;
  a {
    display: block;
    padding: 10px 10px 0;
  }
  &:hover {
    border: 1px solid var(--color-primary-1);
    box-shadow: inset 0 0 0 1px var(--color-primary-1);
    background-color: var(--color-primary-4);

    .delimiter {
      color: var(--color-primary-1);
    }
  }
}
.image {
  position: relative;
  width: 100%;
  &::before {
    content: '';
    display: block;
    padding-bottom: 129%;
  }
}
.img, .imgNotFound {
  background-color: #ddd;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  img {
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  svg {
    width: 100%;
    top: 50%;
    position: absolute;
    margin-top: -78px;
  }
}

.imgNotFound {
  background-color: #FDEFFC;
}

.name {
  color: var(--color-primary-1);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25;
  height: 4.5em;
  padding: .5em 0;
  text-decoration: underline;
  font-family: Manrope, sans-serif;
  word-break: break-word;
}
.footer {
  display: flex;
  justify-content: space-between;
}
.params {
  //align-items: center;
  text-align: center;
  //display: flex;
  //flex-direction: column;
  flex-grow: 1;
  font-size: 12px;
  gap: .5em;
  padding: 0 10px;
  color: var(--color-black-80);
  font-weight: 500;
  font-family: Manrope, sans-serif;
}
.chapters {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-60);
  border-left: none;
  border-radius: 0 5px 5px 0;
  color: #4d5055;
  font-size: 10px;
  left: 0;
  line-height: 18px;
  padding: 0 10px;
  position: absolute;
  top: 20px;
}
.menu {
  position: absolute;
  top: 17px;
  right: 17px;
  z-index: 600;
}

.courseLvl {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.additionalMaterials {
  cursor: pointer;
  padding: 8px 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 6px;
  color: var(--color-black-80);
  border: 1px solid var(--color-black-60);
  
  &:hover {
    border: 1px solid var(--color-primary-1);
    color: var(--color-primary-2);
  }
}
