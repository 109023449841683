.extendedTeachersSchedule {

  &__filter {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    @media (min-width: 768px) {
      flex-direction: row;
    }

    .modal-selectors {
      select {
        margin-top: 0;
        width: 100%;
      }
    }
  }

  &__locationPeriod {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (min-width: 768px) {
      flex: 0 0 33%;
      //width: 33%;
    }
  }

  &__notice {
    background-color: #3474ff;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    line-height: 1.2;
    padding: 1em 20px;
    @media (min-width: 768px) {
      font-size: 14px;
    }

    b {
      font-weight: bold;
    }
  }

  &__showButton {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    @media (min-width: 768px) {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 33%;
    }

    &[disabled] {
      background: white;
      border: 1px solid #959595;
      color: #959595;
      cursor: default;
    }
  }

  .id-selector {
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      flex-grow: 1;
    }

    .id-selector__block {
      width: 100%;
      @media (min-width: 768px) {
        height: 260px;
      }
    }
    .id-selector__block::-webkit-scrollbar {
      width: 10px;
    }

    .id-selector__arrows {
      flex-direction: row;
      height: 38px;
      justify-content: center;
      width: 100%;
      @media (min-width: 768px) {
        flex-direction: column;
        height: 100%;
        width: 38px;
      }

      img {
        transform: rotate(0);
        @media (min-width: 768px) {
          transform: rotate(-90deg);
        }

        &:last-child {
          transform: rotate(180deg);
          @media (min-width: 768px) {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__count {
    background-color: #3474ff;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 20px;
    padding: 1em 20px;
  }

  .main-table-card-box {
    margin-left: 0;
    margin-right: 0;
  }
}