@import "../MainStyles";

.variant {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--color-black-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.54px;
}

.unchecked {
  border-radius: 6px;
  border: 2px solid var(--color-black-60);
  background: var(--color-black-0);
  flex-grow: 1;
}

.numberContainer {
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.checkedRight {
  border-radius: 6px;
  border: 2px solid var(--color-success-1);
  background: var(--color-success-4);
}

.checkedWrong {
  border-radius: 6px;
  border: 2px solid var(--color-danger-1);
  background: var(--color-danger-4);
}

.answerBody {
  @include answerBodyMain;
  padding: 25px 10px 10px 10px;
}

.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.correctColor {
  @include correctColorMain;
}