.menu {
    position: absolute;
    width: 140px;
    opacity: 0;
    z-index: 2;

    &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: var(--leftBefore); //var(--leftBefore)
        width: 10px;
        height: 10px;
        border: 1px solid var(--color-outline);
        background-color: white;
        transform: rotate(45deg);
    }

    &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: var(--leftAfter); //var(--leftAfter)
        width: 12px;
        height: 10px;
        background-color: white;
    }
}

.menu_list {
    padding: 10px 0;
    border-radius: 5px;
    border: 1px solid var(--color-outline);
    background-color: white;
}

.menu_show {
    composes: menu;
    opacity: 1;
}

.menu_item {
    padding: 10px 15px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ededed;
    }

    &__warning {
        font-weight: 500;
        color: var(--color-error);
    }
}