.box {
  align-items: center;
  border: 2px dashed var(--color-outline);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  input[type="file"] {
    display: none;
  }

  button {
    background-color: var(--color-text-accent);
    color: white;
    font-family: inherit;
    font-weight: 500;
    width: 210px;
    @media (min-width: 768px){
      width: 300px;
    }
    &:hover {
      background-color: white;
      color: var(--color-text-accent);
    }
  }
}

.boxError {
  composes: box;
  border-color: #db3031;
}

.tip {
  color: var(--color-text-accent);
  font-size: 12px;
  text-align: center;
  span {
    text-transform: uppercase;
  }
}

.progress {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  progress {
    background-color: #3f4d63;
    border-radius: 5px;
    height: 10px;
    width: 100%;
    &::-webkit-progress-bar {
      background-color: #bdbdbd;
      border-radius: 5px;
    }
    &::-webkit-progress-value {
      background-color: #3f4d63;
      border-radius: 5px;
    }
  }

  div {
    margin-top: 1em;
  }
}

.preview {
  align-self: stretch;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  &:hover {
    .delete {
      opacity: 1;
    }
  }

  img {
    width: 100%;
  }
}
.previewAudio {
  align-self: stretch;
  display: flex;
}

.delete {
  align-items: center;
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  content: '';
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s;
  svg {
    cursor: pointer;
    fill: white;
    height: 64px;
    transition: .3s;
    width: 64px;
    &:hover {
      fill: var(--color-error);
    }
  }
}
