.enterWords {
  color: var(--color-black-100);
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.28px;
  span {
    line-height: 40px;
  }
  span[contenteditable] {
    word-break: break-word;
    overflow-y: hidden;
    color: var(--color-black-60);
    border-bottom: 1px solid var(--color-black-60);
    height: auto;
    line-height: 30px;
    vertical-align: middle;
    min-width: 150px;
    padding: 0 6px;
    margin-right: 5px;
    display: inline-block;


    &:hover {
      color: var(--color-black-100);
      border-color: var(--color-black-80);
      transition: 0.2s;
    }

    &.placeholder {
      text-align: center;
    }
    &.entered {
      font-weight: 700;
      border-bottom: 2px solid var(--color-black-80);
      color: var(--color-black-100);
    }
    &:focus {
      border-bottom: 2px solid var(--color-primary-2);
    }
  }
  .right {
    font-weight: 700;
    color: var(--color-success-1);
    border-color: var(--color-success-1);
  }

  .wrong {
    font-weight: 700;
    color: var(--color-danger-1);
    border-color: var(--color-danger-1);
  }
  .unknown {
    font-weight: 700;
    border-color: var(--color-primary-1);
  }
}

.view {
  color: var(--color-black-100);
  border-bottom: 2px solid var(--color-black-60);
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  padding: 0 5px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  user-select: none;
}

