.counterBox {
    width: 100%;
    height: 48px;
    border-radius: 7px;
    border: 1px solid var(--color-black-40);
    overflow: hidden;
    display: flex;

    &:hover {
        box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60);
    }

    &Focused {
        border-color: var(--color-primary-2);
        box-shadow: 0 0 0 3px rgba(85, 177, 243, 0.60), inset 0 0 0 1px var(--color-primary-2);
    }

    .button {
        align-items: center;
        background-color: var(--color-primary-2);
        color: var(--color-black-0);
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        width: 40px;
        font-size: 20px;
        cursor: pointer;
        user-select: none;
    }

    input {
        border: none!important;
        flex-grow: 1;
        text-align: center;
        padding-left: 0!important;
        width: 100%!important;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: var(--color-primary-2);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}