.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  span {
    color: var(--color-black-100);
    font-weight: 500;
    word-break: break-word;
  }
  &.checked {
    .input {
      box-shadow: inset 0 0 0 10px var(--color-primary-1);
      transition: 0.1s;
      border: 2px solid var(--color-primary-1);
      background: var(--color-primary-1);
      &::before {
        transform: rotate(-45deg) scale(1);
        transition: 0.1s 0.1s;
      }
    }
  }
  &.correct {
    .input{
      box-shadow: inset 0 0 0 10px var(--color-success-1);
      border: 2px solid var(--color-success-1);
      background: var(--color-success-1);
    }
    &::before {
      transform: rotate(-45deg) scale(1);
      transition: 0.1s 0.1s;
    }
  }
  &.mistake {
    .input {
      box-shadow: inset 0 0 0 10px var(--color-danger-1);
      border: 2px solid var(--color-danger-1);
      background: var(--color-danger-1);
      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 1px;
        background-color: var(--color-black-0);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.disabled {
    pointer-events: none;
    .input {
      box-shadow: inset 0 0 0 10px var(--color-black-20);
      border: 2px solid var(--color-black-40);
      &::before {
        border-left: 2px solid var(--color-black-60);
        border-bottom: 2px solid var(--color-black-60);
      }
    }
    span {
      color: var(--color-black-60);
    }
  }
  &.indeterminate {
    pointer-events: all;
    span {
      color: var(--color-black-100);
      font-weight: 500;
    }
    .input {
      box-shadow: none;
      border: 2px solid var(--color-black-40);

      &::before {
        width: 8px;
        height: 8px;
        background: var(--color-primary-1);
        border-radius: 2.5px;
        transform: none;
        border: none;
        top: 2px;
      }
    }
  }
}
.input {
  border: 2px solid var(--color-black-40);
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  height: 16px;
  position: relative;
  transition: 0.1s 0.1s;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    display: block;
    border-left: 2px solid var(--color-black-0);
    border-bottom: 2px solid var(--color-black-0);
    height: 5px;
    transform: rotate(-45deg) scale(0);
    width: 10px;
    transition: 0.1s;
    position: absolute;
    top: 3px;
  }
}
