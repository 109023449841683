
.box {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
}

.item {
    width: 150px;
}

.input {
    height: 43px;
    padding: 0 10px;
}

.select_reason {
    grid-column-start: 1;
    grid-column-end: 3;
}