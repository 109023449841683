.placementTestCreate__ligaments {
  .placementTestBox {
    &__bundleRequired {
      max-width: 200px;
    }
    &__filter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @media screen and (max-width: 810px) {
        justify-content: center;
      }
      input,
      .selectCustom {
        width: 320px;
        margin-bottom: 20px;
        @media screen and (max-width: 400px) {
          width: 240px;
        }
      }
      button {
        margin-left: 15px;
      }
    }
    &__btns {
      @media screen and (max-width: 810px) {
        text-align: center;
      }
      button {
        width: 320px;
        color: #3f4d63;
        font-weight: 500;
        font-size: 14px;
        margin-top: 8px;
        margin-right: 20px;
        @media screen and (max-width: 810px) {
          margin: 0 0 20px;
        }
        @media screen and (max-width: 400px) {
          width: 240px;
        }
        &:hover {
          color: white;
        }
      }
    }
    &__title,
    &__subtitle {
      @media screen and (max-width: 810px) {
        text-align: center;
      }
    }
  }
}
