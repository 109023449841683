.row {
    display: grid;
    align-items: center;
    justify-content: space-between;
    row-gap: 10px;
    grid-template-columns: 1fr;

  
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1440px) {
        grid-template-columns: 1fr 1fr 1fr 1fr auto;
    }
}

.titleBox {
    place-self: stretch;
    grid-column-start: 1;
    grid-column-end: 5;

    @media (min-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    @media (min-width: 1440px) {
        grid-column-start: 1;
        grid-column-end: 2;
    }
}

.date {
    color: var(--color-black-80);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.24px;
}

.subtitleRow {
    color: var(--color-black-80);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    display: flex;
    gap: 10px;
}
.title {
    color: var(--color-primary-1);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
    cursor: pointer;
}

.linkWrapper {
    width: fit-content;
    display: block;
}

.dateBox {
    min-width: 120px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    place-self: stretch;
    @media (min-width: 1440px) {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 4;
    }
}

.questionBox {
    place-self: center;
    color: var(--color-black-100);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
    letter-spacing: 0.24px;

    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;

    @media (min-width: 768px) {
        place-self: stretch;
    }
    @media (min-width: 1440px) {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
        place-self: center;
    }
}

.btnBox {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;

    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 5;
    justify-self: center;

    @media (min-width: 768px) {
        grid-column-start: 3;
        grid-column-end: 6;
        grid-row: 1/4;
        justify-self: end;
    }
    @media (min-width: 1440px) {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 5;
        grid-column-end: 6;
    }
}

.badgeWrapper {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    place-self: center stretch;
    @media (min-width: 1440px) {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 4;
        grid-column-end: 5;
    }
}
