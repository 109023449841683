.hour-counter {
  width: 220px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  overflow: hidden;

  input {
    border-radius: 0;
    width: 90px;
    height: 45px;
    margin-bottom: 0 !important;
    border: none !important;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      outline: none;

    }
  }

  & &__input {
    flex-grow: 1;
  }

  &-but {
    background-color: #5a8dee;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;

  }

  &-info {
    background-color: #f0f4f7;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    width: 60px;
    height: 45px;
    padding: 3px;
  }
}
