.modalTestResult {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__selects {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      flex-direction: row;
    }

    &>div {
      flex: 1 1 50%;
    }
  }

  &__date {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: center;

    &>span {
      font-weight: 600;
    }
  }

  input {
    margin-bottom: 0;
  }

  .selectCustom {
    input {
      height: auto;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .block_Textarea {
    margin-top: 0;
  }
}