.clientBalances {
  background: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
}
.tabs {
  border-bottom: 1px solid #bdbdbd;
  display: none;
  gap: 20px;
  margin: 0 20px;
  @media (min-width: 768px) {
    display: flex;
  }
}
.tab {
  border-bottom: 3px solid transparent;
  color: var(--color-black-100);
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.42px;
  padding: 12px 0;

  &Active {
    border-color: var(--color-primary-1);
    color: var(--color-primary-1);
    white-space: nowrap;
  }

  &Add {
    font-weight: 600;
    margin-left: auto;
  }
}
.companySelect {
  padding: 20px 15px;
  @media (min-width: 768px) {
    display: none;
  }
}
.card {
  border-top: 1px solid #eaeaea;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 30px;
  grid-template-rows: 1fr auto;
  padding: 15px;
  @media screen and (min-width: 768px) {
    align-items: center;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: unset;
    padding: 20px;
  }
  @media (min-width: 1440px) {
    grid-template-columns: 1fr auto 235px auto;
    align-items: center;
  }
  &:has(.bonusBalance) {
    align-items: start;
    @media screen and (min-width: 1440px) {
      align-items: center;
    }
  }
  &First {
    border-top: none;
  }
}
.topUp {
  display: none;
  @media (min-width: 1440px) {
    display: block;
    justify-self: flex-end;
  }
}
.topUpButton {
  align-items: center;
  background-color: var(--color-primary-1);
  border: none;
  border-radius: 5px;
  color: var(--color-black-0);
  display: flex;
  gap: 20px;
  height: 30px;
  justify-content: center;
  padding: 0;
  white-space: nowrap;
  width: 185px;
  &:hover {
    background-color: var(--color-primary-2);
  }
}
.groupName {
  color: var(--color-black-60);
  font-size: 12px;
  letter-spacing: 0.36px;
}
.name {
  align-self: center;
  color: var(--color-black-80);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.42px;
  @media (min-width: 1440px) {
    font-size: 16px;
    letter-spacing: 0.48px;
  }
}

.sum {
  color: var(--color-black-80);
  font-size: 24px;
  font-weight: 600;
  grid-row: 2/3;
  letter-spacing: 0.72px;
  line-height: 30px;
  @media (min-width: 768px) {
    grid-row: unset;
  }
}
.negativeValue {
  color: var(--color-danger-1);
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.72px;
  @media (min-width: 1440px) {
    line-height: 80px;
  }
}
.balancesBlock {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 0;
  grid-row: 2/3;
  @media (min-width: 768px) {
    grid-row: unset;
  }
  @media (min-width: 1440px) {
    align-items: center;
    flex-direction: row;
    gap: 30px;
  }
  @media (min-width: 1920px) {
    gap: 70px;
  }
}
.bonusBalance {
  text-align: center;
  border-radius: 6px;
  padding: 7px 17px;
  border: 2px solid var(--color-warning-1);
  background: var(--color-warning-4);

  .title {
    color: var(--color-black-80);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.42px;
  }

  .value {
    color: var(--color-success-1);
    font-size: 20px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.6px;

    span {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.48px;
    }
  }
}