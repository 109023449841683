.questionImagesThumb {
  padding-top: 10px;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;
    overflow: hidden;
    transition: .3s;
  }

  .fullImageIcon {
    border-radius: 7px;
    padding: 7px;
    background: var(--color-primary-2);
  }

  li {
    text-align: right;
    transition: left .3s, top .3s;
    width: calc((100% - 20px) / 2);
    @media (min-width: 768px) {
      width: calc((100% - 40px) / 3);
    }

    .description{
      color: var(--color-black-80);
      word-break: break-word;
      font-size: 12px;
      line-height: 16px;
      min-height: 26px;
      font-weight: 300;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}