.modalCourseForm {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__col2 {
    @media (min-width: 768px) {
      flex: 1 1 50%;
    }
  }

  & input {
    margin: 0;
  }
}