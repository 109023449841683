.fieldsetCustomer {
  background: white;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 30px;

  &__title {
    color: #5f6368;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 15px;
  }

  &__noClientMessage {
    color: #5f6368;
    margin: 1em 0;
  }

  &__cols {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  &__col {
    width: 100%;
    @media (min-width: 1024px) {
      width: 310px;
    }
  }

  &__field {
    //height: 60px;
    margin-top: 10px;
    position: relative;
    span{
      padding: 15px 0px !important;
    }
    &--required {
      &::after {
        color: #db3031;
        content: "*";
        display: block;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        right: 10px;
        top: 5px;
      }
    }
  }
  &__label {
    color: #5f6368;
    font-weight: 500;
    padding-bottom: 5px;
    //padding-left: 10px;

    &::before {
      color: #db3031;
      content: "*";
      //display: block;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 2px;
      //position: relative;
      //bottom: 15px;
      //right: 9px;
    }
  }
  &__noReqLabel{
      color: #5f6368;
      font-weight: 500;
      padding-bottom: 5px;
      display: flex;
      width: 45%;
  }

  &__input, &__select {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
    padding-left: 20px;
    //input:hover {
    //  border: 1px solid #bdbdbd;
    //  box-shadow: 0 0 0 3px #b8d4ff;
    //  transition: .3s;
    //}
    // input:focus {
    //  border: 1px solid #0062ba;
    //  box-shadow: 0 0 0 3px #b8d4ff;
    //}
    &:hover {
      border: 1px solid #bdbdbd;
      box-shadow: 0 0 0 3px #b8d4ff;
      transition: .3s;
      cursor: pointer;
    }
    &:focus {
      border: 1px solid #0062ba;
      box-shadow: 0 0 0 3px #b8d4ff;
    }
  }


  &__select {
    -webkit-appearance: none;
    background: #fff url('../../../img/br_down.png') no-repeat center right 10px;
  }

  &__errors {
    background: #ffdae7;
    border-radius: 5px;
    color: #9b0b31;
    line-height: 2;
    margin-top: 20px;
    padding: 10px;

    b {
      font-weight: 600;

      &::before {
        content: "* ";
      }

      &::after {
        content: ": "
      }
    }
  }
}