.btn {
  cursor: pointer;
  user-select: none;
  width: 100%;
  border-radius: 7px;
  padding: 10px 23px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  &.green {
    border: 2px solid var(--color-success-1);
    color: var(--color-success-1);
    background-color: var(--color-success-4);
    &:hover, &.selected {
      color: var(--color-black-0);
      background-color: var(--color-success-1);
    }
  }
  &.red {
    border: 2px solid var(--color-danger-1);
    color: var(--color-danger-1);
    background-color: var(--color-danger-4);
    &:hover, &.selected {
      color: var(--color-black-0);
      background-color: var(--color-danger-1);
    }
  }
  &.yellow {
    border: 2px solid var(--color-warning-1);
    color: var(--color-warning-1);
    background-color: var(--color-warning-4);
    &:hover, &.selected {
      color: var(--color-black-0);
      background-color: var(--color-warning-1);
    }
  }
}