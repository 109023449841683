.contractDoc {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: 1em;
  padding: 1em 20px;
  @media (min-width: 768px) {
    grid-template-columns: none;
    grid-template-rows: none;
  }

  @media (min-width: 768px) {
    grid-template-columns: auto 1fr auto auto;
  }
}
.gridIcon {
  grid-row: 1/3;
  @media (min-width: 768px) {
    grid-row: unset;
  }
}
.gridButton {
  display: flex;
  justify-content: center;
}
.documentIcon {
  position: relative;

  span {
    bottom: 8px;
    color: var(--color-black-100);
    left: 4px;
    position: absolute;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
}
.documentDesc {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column: 2/4;
  @media (min-width: 768px) {
    grid-column: unset;
  }

  h5 {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.36px;
  }

  p {
    color: var(--color-black-80);
    font-size: 12px;
    line-height: 16px;
  }
}
.btn {
  color: var(--color-primary-1);
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.36px;
}
