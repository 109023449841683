.wrapper {
    margin-bottom: 10px;
    border: 1px solid var(--color-outline);
    border-radius: 5px;
    background-color: white;
}

.header {
    padding: 20px 10px 20px 20px;
    & h2 {
        width: 60%;
    }
}

.weekDays {
    display: none;

    @media (min-width: 1024px) {
        width: 100%;
        padding: 5px 0;
        display: flex;
        justify-content: stretch;
        align-items: center;
        border-bottom: 1px solid var(--color-outline);
        border-top: 1px solid var(--color-outline);
    }
}

.weekday {
    width: 14.2857%;
    font-size: 12px;
    color: #a8a8a8;
    text-align: center;

    &:nth-of-type(6),
    &:nth-of-type(7) {
        color: var(--color-error);
    }
}

.dayList {
    position: relative;
    min-height: 216px;
    margin: 0 -1px -1px -1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: stretch;
    border: none;
    border-left: 1px solid var(--color-outline);
    border-top: 1px solid var(--color-outline);
    border-radius: 0 0 5px 5px;

    @media (min-width: 1024px) {
        border-top: none;
    }
}
