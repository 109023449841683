.title {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: gray;

}


.box {
    padding-top: 20px;
    margin-top: 20px;
    background: white;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.total {
    font-size: 18px;
    font-weight: bold;
    margin:10px 0 0 15px;
}