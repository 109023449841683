.box {
  footer {
    border-top: 1px solid #bdbdbd;
    display: flex;
    justify-content: center;
    padding: 20px;
    span {
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.row {
  align-items: center;
  border-top: 1px solid #bdbdbd;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  &:first-child {
    border-top: none;
  }
}