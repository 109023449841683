@import "../MainStyles";

.answerBody {
  @include answerBodyMain;
  padding: 20px 10px 10px 10px;

  .span {
    align-self: flex-start;
    color: var(--color-primary-1);
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.36px;
  }
}

.writtenCorrect {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--color-success-1);
}

.totalWords {
  padding-left: 10px;
}

.dashedBorder {
  border: 1px dashed;
  border-color: var(--color-secondary-1);
  padding: 6px;
}


.countWords {
  background-color: var(--color-secondary-4);
  border-radius: 7px;
  display: flex;
  padding: 16px 24px 16px;
  margin-top: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--color-black-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.54px;

  svg * {
    fill: var(--color-secondary-1);
  }
}


.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.ai {
  border: none;
  background-color: var(--color-primary-3);
  color: var(--color-black-0);
  cursor: pointer;
  svg * {
    fill: var(--color-black-0);
  }
}

.correctColor {
  @include correctColorMain;
}

.font {
  font-weight: 800;
  color: var(--color-primary-1);
}