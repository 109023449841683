.matchWords {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  position: relative;

  svg {
    left: 0;
    height: 100%;
    position: absolute;
    stroke-width: 2px;
    stroke: #3f4d63;
    top: 0;
    width: 100%;
  }
}

.col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.colRight {
  composes: col;
  align-items: flex-end;
}

.word {
  background-color: white;
  border: 2px solid #d5d7da;
  border-radius: 10px;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  position: relative;
  user-select: none;
  z-index: 1;
  @media (max-width: 767px) {
    font-size: 12px;
    padding: 5px 10px;
  }
}

.image {
  background-color: white;
  border: 2px solid #d5d7da;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
  padding: 20px;
  max-width: 150px;
  height: 80px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    height: 200px;
  }
  &.clicked {
    border-color: #3f4d63;
  }
  img {
    width: 100%;
    pointer-events: none;
  }
}

.clicked {
  border-color: #3f4d63;
}
