.blueLine {
  align-items: center;
  background-color: var(--color-primary-4);
  border-radius: 7px;
  color: var(--color-primary-1);
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 16px;
  line-height: 16px;
  padding: 6px 15px;
  width: 100%;

  svg {
    display: block;
    height: 24px;
    width: 24px;
  }
}
