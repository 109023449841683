.cpt {
  &__students {
    &--title {
      margin-bottom: 15px;
      font-weight: 600;
      color: #5f6368;
    }

    &--list {
      border: 1px solid #b5b5b5;
      border-radius: 5px;
      overflow: hidden;

      li {
        width: 100%;
        background-color: #f6f6f6;
        border-bottom: 1px solid #b5b5b5;
        padding: 15px 25px;
        vertical-align: middle;
        cursor: pointer;
        color: #5f6368;
        position: relative;

        span {
          display: block;
          position: absolute;
          content: '&#215;';
          right: 10px;
          padding: 0;
          font-size: 25px;
          color: #3f4d63;
          top: 7px;
          font-weight: 600;
          cursor: pointer;
        }

      }

      li:last-child {
        border: none;
      }
    }
  }

  &__options {
    &--inLIneNote {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      padding: 10px 0;
      font-weight: 500;
    }

    &--line {
      border-bottom: 1px solid #dcdcdc;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 5px 10px;

      &--numbers {
        margin-left: auto;

        input {
          width: 40px !important;
          margin-bottom: 0 !important;
          margin-left: auto;
        }
      }

      input {
        width: 30px;
        margin-bottom: 0 !important;
        margin-left: auto;
      }
    }

    &--lineIN {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;

      span {
        text-align: left;
      }

      &--text {
        padding-left: 36px;
      }

      &--fs {
        justify-content: flex-end;
      }

      input {
        width: 30px;
        margin-bottom: 0 !important;
        margin-left: auto;
      }
    }
  }
}