.wrap {
  background: linear-gradient(180deg, rgb(44, 62, 80) 0%, rgb(52, 152, 219) 30%, rgb(255, 255, 255) 30%);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-height: 100vh;
  min-width: 320px;
  padding: 50px 0;
}
.container {
  flex-grow: 1;
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 10px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 0 20px;
  }
}
.question {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px 40px 30px;
}
.delay {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  img {
    width: 125px;
  }

  p {
    margin-top: 55px;
    font-size: 20px;
    font-style: italic;
    color: #3f4d63;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
