.invoiceDetail {
  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;
  }

  li {
    align-items: center;
    display: flex;
    gap: 20px;

    span {
      &:first-child {
        order: 1;
      }
      &:last-child {
        order: 3;
      }
    }

    &::after {
      background-color: #e2e2e2;
      content: '';
      display: block;
      flex-grow: 1;
      height: 1px;
      order: 2;
    }
  }
}