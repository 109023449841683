.menu {
  width: 40px;
  border-radius: 7px;
  border: 1px solid var(--color-black-60);
  background: var(--color-black-0);
  position: relative;
  &:hover {
    border-color: var(--color-primary-3);
  }
}

.popupClickOut {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 500;
}

.button {
  border-bottom: 1px solid var(--color-black-60);
  color: var(--color-black-60);
  position: relative;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-child {
    border-radius: 7px 7px 0 0;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 7px 7px;
  }


  &:first-child#{&}:last-child {
    border-radius: 7px;
  }

  &:hover {
    background-color: var(--color-primary-4);
    color: var(--color-primary-2);

    .info {
      display: block;
    }
  }

  &Disabled {
    background-color: var(--color-black-20);
    color: var(--color-black-40);
    pointer-events: none;
  }

  &Active {
    background-color: var(--color-primary-3);
    border: 1px solid var(--color-primary-2);
    color: var(--color-black-0);
    margin: -1px;
    //pointer-events: none;
    &:last-child {
      border-bottom: 1px solid var(--color-primary-2);
    }
  }
}

.menu .button {
  &Pushed {
    background-color: var(--color-primary-3);
    border: 1px solid var(--color-primary-2);
    color: var(--color-black-0);
    margin: -1px;
    pointer-events: none;
    border-radius: 7px;
  }
}

.info {
  display: none;
  position: absolute;
  padding: 5px 10px;
  background: var(--color-primary-3);
  color: var(--color-black-0);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  top: -35px;
  right: 20px;
  border-radius: 7px;
  white-space: nowrap;
  z-index: 10;

  &::before {
    position: absolute;
    content: '';
    top: 25px;
    right: 5px;
    width: 10px;
    height: 10px;
    background: #55B1F3;
    clip-path: polygon(0 0, 50% 97%, 100% 0);
  }
}