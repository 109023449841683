.modalClientInfo {

  &__blockLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: var(--color-primary-2);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.28px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  input {
    &[disabled] {
      background-color: var(--color-black-20);
    }
  }

  select {
    &:disabled {
      background-color: var(--color-black-20);;
    }
  }
}

.fieldRow {
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--color-black-40);
  margin: 20px 0;
}

.innError {
  color: var(--color-danger-1);
  font-size: 14px;
  font-weight: 500;
}