.chapterName {
  align-items: center;
  color: var(--color-black-100);
  display: flex;
  gap: 15px;
  line-height: 36px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.4px;
    @media (min-width: 768px) {
      font-size: 26px;
      font-weight: 700;
      letter-spacing: 0.52px;
    }
    @media (min-width: 1024px) {
      font-size: 34px;
      font-weight: 700;
      letter-spacing: 0.68px;
    }
  }
}
.sectionImage {
  margin-top: 20px;
  img {
    width: 100%;
    border-radius: 6px;
  }
}