.questions {
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 20px 30px;
  width: 100%;
}

.qn {
  align-items: center;
  border: 2px solid #bdbdbd;
  display: flex;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  width: 30px;
  color: #bdbdbd;
  border-radius: 5px;
  cursor: default;
}
.qnAnswered {
  background-color: var(--color-accent-600);
  border-color: var(--color-accent-600);
  color: white;
}
.qnActive {
  background-color: #fff;
  border-color: var(--color-accent-600);
  color: var(--color-accent-600);
}
