.placementTestBox--error {
  display: flex;
  align-items: center;
  p {
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
  }
  svg {
    fill: red;
    width: 20px;
    height: 20px;
  }
}
