.wrapper {
  width: 100%;
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
}

.select {
  flex-basis: 100%;

  @media (min-width: 1024px) {
    flex-grow: 1;
  }
}

.error {
  width: 100%;
  padding-top: 20px;
  text-align: center;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
}
