.placementTestResult {
  &__topline {
    height: 77px;
    width: 100%;
    background: #eff4f8;
    margin-top: 2px;
    padding-top: 8px;
    padding-left: 15px;
    margin-bottom: 20px;
    a {
      font-size: 12px;
      color: rgb(36, 81, 178);
      font-weight: 400;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
    h1 {
      font-size: 26px;
      margin-top: 11px;
      color: rgb(63, 77, 99);
      font-weight: 600;
      span {
        text-transform: uppercase;
      }
      @media screen and (max-width: 700px) {
        font-size: 18px;
      }
      @media screen and (max-width: 350px) {
        font-size: 16px;
      }
    }
  } 
  &__box {
    //min-height: 175px;
    margin: 20px 15px 0;
    background: #ffffff;
    border: solid 1px #bdbdbd;
    border-radius: 5px;
    padding: 17px 0 6px;
    padding-left: 30px;
    padding-right: 20px;
    display: flex;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      padding: 20px;
    } 
  }
  &__corrects {
    width: 125px;
    min-height: 103px;
    text-align: center;
    margin-top: 10px;
    margin-right: 55px;
    @media screen and (max-width: 700px) {
      margin: 0 auto;
      border: solid 2px rgb(0, 153, 222);
      border-radius: 5px;
      padding: 20px 0 50px;
      width: 200px;
    }
    p:nth-child(1) {
      font-size: 75px;
      color: rgb(0, 153, 222);
      font-weight: 600;
    }
    p:nth-child(2) {
      margin-bottom: 13px;
      font-size: 12px;
      color: rgb(0, 153, 222);
      font-weight: 400;
      position: relative;
      bottom: 4px;
    }
    p:nth-child(3) {
      font-size: 16px;
      color: rgb(44, 62, 80);
      font-weight: 600;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      margin-top: 20px;
    }
    &--name {
      @media screen and (max-width: 700px) {
        margin-bottom: 20px;
        text-align: center;
      }
      p:nth-child(1) {
        font-size: 20px;
        color: rgb(1, 1, 1);
        font-weight: 500;
        margin-bottom: 5px;
      }
      p:nth-child(2) {
        font-size: 12px;
        color: rgb(1, 1, 1);
        font-weight: 400;
      }
    }
    &--contact {
      display: flex;
      align-items: center;
      a {
        font-size: 14px;
        color: rgb(1, 1, 1);
        text-decoration: underline !important;
        padding-bottom: 2px;
        font-weight: 400;
        @media screen and (max-width: 350px) {
          margin-bottom: 10px;
        }
        &:hover {
          border-bottom: none;
          text-decoration: none !important;
        }
      }
      p {
        margin: 0 40px;
        @media screen and (max-width: 700px) {
          margin: 0 20px;
        }
        @media screen and (max-width: 350px) {
          display: none;
        }
      }
      @media screen and (max-width: 350px) {
        flex-direction: column;
      }
    }
  }
  &__description {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 992px) {
      display: none;
    }
    &--mobile {
      display: none;
      @media screen and (max-width: 992px) {
        display: block;
      }
      @media screen and (max-width: 700px) {
        max-width: 400px;
        margin: 0 auto;
      }
      p:nth-child(odd) {
        font-size: 14px;
        color: rgb(1, 1, 1);
        font-weight: 400;
        margin-bottom: 5px;
      }
      p:nth-child(even) {
        font-size: 14px;
        color: rgb(1, 1, 1);
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
    p {
      margin-bottom: 14px;
    }
    &--titles {
      margin-right: 15px;
      p {
        font-size: 14px;
        color: rgb(1, 1, 1);
        font-weight: 400;
      }
    }
    &--text {
      p {
        font-size: 14px;
        color: rgb(1, 1, 1);
        font-weight: 600;
      }
    }
  }
  &__btn {
    max-width: 500px;
    height: 60px;
    border: solid 2px rgb(211, 16, 39);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    margin: 30px auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgb(211, 16, 39);
      transition: 0.4s;
      p {
        color: rgb(255, 255, 255);
        transition: 0.4s;
      }
    }
    @media screen and (max-width: 520px) {
      margin: 30px 20px;
    }
    p {
      font-size: 20px;
      color: rgb(211, 16, 39);
      font-weight: 600;
      @media screen and (max-width: 520px) {
        font-size: 14px;
      }
      @media screen and (max-width: 400px) {
        font-size: 12px;
      }
    }
  }
  &__result {
    border: solid 1px rgb(189, 189, 189);
    background-color: rgb(255, 255, 255);
    max-width: 1042px;
    border-radius: 5px;
    margin: 0 15px;
  }
  &__total {
    height: 87px;
    border-bottom: solid 1px #bdbdbd;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 400px) {
      height: 180px;
    }
    &--box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 600px;
      @media screen and (max-width: 400px) {
        flex-direction: column;
      }
    }
    &--time {
      color: rgb(1, 1, 1);
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      @media screen and (max-width: 400px) {
        margin-bottom: 20px;
      }
      svg {
        margin-right: 20px;
        width: 25px;
        height: 25px;
      }
    }
    &--correct,
    &--mistake {
      color: rgb(1, 1, 1);
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      @media screen and (max-width: 400px) {
        margin-bottom: 20px;
      }
      svg {
        width: 25px;
        height: 25px;
        margin-right: 20px;
      }
    }
  }
  &__answers {
    padding: 33px 27px 0;
    width: 100%;
    height: 443px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &--click {
      cursor: pointer;
    }
    @media screen and (max-width: 992px) {
      height: 700px;
    }
    @media screen and (max-width: 400px) {
      height: 1000px;
    }
    li {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin-right: 10px;
        width: 21px;
        text-align: center;
        color: rgb(0, 0, 0);
        font-size: 20px;
        font-weight: 500;
      }
      svg {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
}
