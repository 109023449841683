.exTest {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.typeContent {
    padding: 20px;
}
.typeContent p {
    font-size: 12px;
    margin-top: 1em;
}
.pointInputRow {
    align-items: center;
    display: flex;
    justify-content: space-between;

}
.pointInput {
    margin: 0 20px 0 80px;
}
.pointInput.pointInput input {
    margin-bottom: 0;
    width: 70px;
}
.questions {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.pointsRow {
    align-items: center;
    background-color: #f0f0f0;
    color: #3f4d63;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
    margin: 0 -20px;
    padding: .5em 20px;
}
.points {
    font-size: 24px;
}