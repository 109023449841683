.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 15px;
}
.calendar {
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
