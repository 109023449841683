.question {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 1em;
  padding: 20px;
}
.text {
  font-weight: 500;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.image {
  flex-shrink: 0;
  max-width: 320px;
  img {
    width: 100%;
  }
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1em;

  ul {
    display: flex;
    flex-direction: column;
    gap: .5em;
  }

  li {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  i {
    display: block;
    flex-shrink: 0;
    height: 20px;
    width: 20px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.divider {
  border-top: 1px solid var(--color-outline);
}

.valueWords {
  align-items: center;
  display: flex;
  gap: 1em;
  svg {
    height: 1em;
    width: 1em;
  }

  img {
    max-width: 90px;
  }
}
.completeSentence, .textMissingWords, .wordMatching, .wordsInBlanks {
  .container {
    flex-direction: column;
  }
  .image {
    margin: 0 auto;
  }
}
.column {
  li {
    flex-direction: column;
  }
}
.textMissingWordsList {
  li {
    align-items: flex-start;
    margin-bottom: 17px;
  }
}
.textMissingWords {
  li {
   flex-direction: row;
   align-items: center;
  }
}