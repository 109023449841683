.bookInfo {
  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  li {
    align-items: center;
    color: var(--color-black-100);
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    height: 80px;
    letter-spacing: 0.42px;
    line-height: 1.5;
    padding: 0 10px;
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 285px 1fr;
      padding: 0 20px;
    }
    &:nth-child(even){
      background-color: var(--color-black-0);
      border-radius: 7px;
    }
  }
}
.paramName{

}
.paramValue {
  font-weight: 700;
}
