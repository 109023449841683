.coloringBookContainer {
  cursor: pointer;
}

.colorPickerBlock {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 155px;
  background: var(--color-black-0);
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--color-black-40);
  box-sizing: content-box;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 0;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-black-0);
  }
}

.coloringBookBlock {
  position: relative;

  .svgContainer svg {
      width: 100%;
      height: auto;
  }

  .panelContainer {
    position: absolute;
    bottom: 5px;
    left: 3px;
  }

  .panelBlock {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    padding: 3px;
    background-color: var(--color-black-20);
    border-radius: 6px;
    z-index: 1;

    svg {
      path {
        fill: var(--color-black-60);
      }
    }
  }
}

.circleBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.separator {
  height: 24px;
  width: 1px;
  background: var(--color-black-40);
}