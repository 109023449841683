.companyText {
  color: var(--color-black-60);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
}
.book {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  @media (min-width: 768px) {
    gap: 30px;
  }
}
.bookText {
  color: var(--color-black-100);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
}
.bookImage {
  border-radius: 5px;
  margin: 0 auto;
  width: 110px;
  @media (min-width: 768px) {
    width: 150px;
  }
  @media (min-width: 1440px) {
    width: 272px;
  }
}
