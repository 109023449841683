.attempts {
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid var(--color-black-60);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.attempts .back {
  background: var(--color-black-60);
  border-radius: 50%;
  height: 17px;
  width: 17px;
}

.selected {
  border: 1px solid var(--color-primary-2);
}

.selected .back {
  background: var(--color-primary-2);
}

