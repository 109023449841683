.title_wrapper {
  margin-bottom: 20px;
}

.select {
  width: 100%;
  @media (min-width: 768px) {
    width: 320px;
  }
}

.slots_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 110px);
  justify-content: space-between;
  gap: 10px;
}

.empty_text {
  padding-top: 20px;
  font-size: 20px;
  font-style: italic;
  text-align: center;
  color: var(--color-text);
}

.timeZone {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  background: var(--color-accent-600);
  padding: 5px;
  color: var(--color-black-0);
  border-radius: 5px;
  font-size: 17px;
  font-weight: bold;

  .info_icon {
    transform: rotate(180deg);

    svg {
      fill: white;
      display: block;
      height: 30px;
      width: 30px;
    }
  }
}

.workingHoursNotice{
  background: var(--color-accent-600);
  padding: 5px;
  color: var(--color-black-0);
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 15px;
}