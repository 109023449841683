.fieldset {
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.switch {
  display: flex;
  align-items: center;
  text-align: center;
  height: 45px;
  border: solid 1px #bdbdbd;
  border-radius: 5px;
  overflow: hidden;

}
.case {
  width: 50%;
  height: 100%;
  display: flex;
  cursor: pointer;
  font-weight: 500;
  color: #5f6368;
  align-items: center;
  justify-content: center;
  border-right: solid 1px #bdbdbd;
  &__active {
    color: white;
    background: #057fed;
  }
  &:nth-last-child(1) {
    border-right: none;
  }
}
