.slots_item {
  width: 110px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  cursor: pointer;

  &:hover {
    border-color: var(--color-text-accent);
  }
}

.selected {
  background-color: var(--color-text-accent);
  color: white;
}

.time {
  font-weight: 500;
}

.text {
  padding: 5px 5px 0;
  font-size: 9px;
  text-transform: uppercase;
}
