.MainExerciseImage {
  margin-bottom: 20px;
}

.fullImageIcon {
  border-radius: 7px;
  padding: 7px;
  background: rgba(240, 243, 245, 0.8);
  color: var(--color-black-80);

  &:hover {
    background-color: var(--color-black-0);
  }
}

.mainImage {
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: 1024px) {
    margin-bottom: 20px;
  }
}

.editBlock {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 3px;
  background-color: rgba(240, 243, 245, 0.8);
  border-radius: 6px;
  z-index: 1;

  &:hover {
    background-color: var(--color-black-0);
  }
}

.toolsPanel {
  display: flex;
  min-width: 180px;
  flex-direction: column;
  gap: 10px;
  border-radius: 7px;
  background: var(--color-black-0);
  padding: 10px 5px;
  margin-bottom: 10px;

  .panelContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--color-black-40);
}

.tool {
  color: var(--color-black-80);
  display: flex;
  align-items: center;
  gap: 10px;

  div {
    cursor: pointer;
  }
}

.panelBlocks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.boxBorderCircle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.colorPickerContainer {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 155px;
  background: var(--color-black-0);
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--color-black-40);
  box-sizing: content-box;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 0;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-black-0);
  }
}

.inputRange {
  display: flex;
  align-items: center;
  gap: 5px;

  input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100px;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: var(--color-primary-1);
    border-radius: 5px;
  }

  input[type=range]::-webkit-slider-thumb {
    border: 2px solid var(--color-primary-1);
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: var(--color-black-0);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--color-primary-1);
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: var(--color-primary-1);
    border-radius: 5px;
  }

  input[type=range]::-moz-range-thumb {
    border: 2px solid var(--color-primary-1);
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: var(--color-black-0);
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: var(--color-primary-1);
    border-radius: 10px;
  }

  input[type=range]::-ms-fill-upper {
    background: var(--color-primary-1);
    border-radius: 10px;
  }

  input[type=range]::-ms-thumb {
    margin-top: 1px;
    border: 2px solid var(--color-primary-1);
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: var(--color-black-0);
    cursor: pointer;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: var(--color-primary-1);
  }

  input[type=range]:focus::-ms-fill-upper {
    background: var(--color-primary-1);
  }

  div {
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }
}

.sketchContainer {
  position: relative;

  svg {
    border-radius: 6px;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .sketchWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.verticalSeparator {
  height: 24px;
  width: 1px;
  background: var(--color-black-40);
}