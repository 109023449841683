.sizeWrapper {
  gap: 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.controlAttempsInstance {
  flex: 0 0 auto;
}

.div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.textWrapper {
  color: var(--color-black-100);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.36px;
  line-height: 30px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.attemptsTwo {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
}
