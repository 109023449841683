.pagination {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  user-select: none;
  @media (min-width: 768px) {
    column-gap: 30px;
    display: grid;
  }
  @media (min-width: 1024px) {
    grid-template-columns: auto 1fr auto;
  }
}
.limitInfo {
  align-items: center;
  display: flex;
  gap: 20px;
  @media (min-width: 768px) {
    grid-column: 1/3;
    justify-self: center;
  }
  @media (min-width: 1024px) {
    grid-column: unset;
  }
}
.selectLimit {
  select {
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    color: var(--color-black-60);
    padding: 0 5px;
    height: 30px;
    &:hover {
      border: 1px solid var(--color-primary-3);
      color: var(--color-primary-2);
    }
    &:focus {
      border: 1px solid #0062ba;
      box-shadow: 0 0 0 3px #b8d4ff;
    }
  }
}
.info {
  color: var(--color-black-80);
  font-size: 14px;
  line-height: 16px;
  b {
    font-weight: 700;
  }
}
.pages {
  ul {
    display: flex;
    gap: 5px;
    @media (min-width: 768px) {
      gap: 10px;
    }
  }

  @media (min-width: 768px) {
    justify-self: flex-end;
  }
}
.pageButton {
  align-items: center;
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  color: var(--color-black-100);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.42px;
  height: 24px;
  justify-content: center;
  transition: .3s;
  width: 24px;
  @media (min-width: 768px) {
    height: 30px;
    width: 30px;
  }
  &:hover {
    background-color: var(--color-primary-4);
    border: 1px solid var(--color-primary-3);
    color: var(--color-primary-2);
  }

  &Selected {
    background-color: var(--color-primary-3);
    border-color: var(--color-primary-2);
    color: var(--color-black-0);
    cursor: default;
    pointer-events: none;
  }

  &Sep {
    background: transparent;
    border-color: transparent;
    cursor: default;
    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &Prev, &Next {
    color: var(--color-black-40);
  }
  &Prev {
    transform: rotate(180deg);
  }
}
.customPage {
  display: flex;
  gap: 10px;

  input[type="number"] {
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    color: var(--color-black-60);
    height: 30px;
    padding: 0;
    text-align: center;
    width: 30px;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  button {
    align-items: center;
    border: 1px solid var(--color-black-60);
    color: var(--color-black-60);
    display: flex;
    height: 30px;
    justify-content: center;
    padding: 0;
    width: 30px;
    &:hover {
      background-color: var(--color-primary-4);
      border-color: var(--color-primary-3);
      color: var(--color-primary-2);
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }
}
