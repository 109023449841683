.search {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 90%;

    @media (min-width: 768px) {
        max-width: 600px;
        width: 100%;
    }

    &__field {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
    }
    &__list {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #254b7733;
    backdrop-filter: blur(5px);
}
