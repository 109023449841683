.container {
  position: relative;
  display: flex;
  gap: 12px;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  padding: 21px 14px;
  border-radius: 6px;
  border: 1px dashed var(--color-warning-1);
  background: var(--color-warning-4);
  color: var(--color-primary-1);

  .icon {
    color: var(--color-warning-1);
    max-width: 24px;
    width: 100%;
  }

  .line {
    background: var(--color-warning-1);
    height: 24px;
    width: 1px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .params {
      display: flex;
      gap: 12px;

      .block {
        display: flex;
        gap: 10px;

        .quantity {
          font-weight: 800;
        }
      }

      &.bottomRow {
        flex-direction: column;

        .line {
          display: none;
          @media (min-width: 768px) {
            display: block;
          }
        }

        @media (min-width: 768px) {
          flex-direction: row;
        }
      }

      .line {
        @extend .line;
      }
    }

    .text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

