
// Course of Study and Group Options
.clientSimpleBox {

  &__cardHeader {
    align-items: center;
    border-bottom: 1px solid var(--color-black-40);
    color: var(--color-black-100);
    display: flex;
    font-size: 20px;
    font-weight: 500;
    justify-content: space-between;
    padding: 10px 20px;
  }

  &__cols {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1440px) {
      grid-template-columns: 1fr 2fr;
    }
  }

  &__col {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;

    &__card {
      border: 1px solid var(--color-black-40);
      border-radius: 7px;
      background: var(--color-black-0);
    }
  }

  &__card {
    background-color: white;
    border: 1px dashed var(--color-primary-2);
    background: var(--color-primary-4);
    border-radius: 7px;
    padding: 5px 20px;
    width: 100%;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__cardHeader {
    align-items: center;
    border-bottom: 1px solid var(--color-black-40);
    color: var(--color-black-100);
    display: flex;
    font-size: 20px;
    font-weight: 500;
    justify-content: space-between;
    padding: 10px 20px;
  }

  &__config {
    font-size: 14px;
    padding: 10px 20px;

    &__container {
      display: grid;
      gap: 15px;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
  
      @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }
  
      @media (min-width: 1440px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 294px;

        .itemName {
          color: var(--color-black-80);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.24px;
        }

        .itemValue {
          color: var(--color-black-100);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.28px;
        }
      }
    }
  }

  &__list {
    display: flex;
    gap: 20px;
    padding: 10px 20px;
    
      img {
        width: 50px;
        height: 62px;
      }
  }

  &__listItem {
    display: flex;
    height: 62px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 0;

    a {
      color: #254b77;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.28px;
    }
  }

  &__bookInfo {
    display: flex;
    align-items: flex-start;
    gap: 11px;
    align-self: stretch;

    span {
      color: var(--color-black-80);
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.24px;
      line-height: 16px;
    }
  }

  &__docsBox {
    background: var(--color-primary-4);
    border-bottom: 1px solid var(--color-primary-3);
    border-top: 1px solid var(--color-primary-3);
  }
  &__docsBox_info {
    align-items: center;
    display: flex;
    padding: 10px 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__pdf {
    align-items: center;
    display: flex;
    gap: 10px;
  }
  &__imgLink {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    &__img {
      height: 36px;
      width: 28px;
    }
    span {
      color: var(--color-black-100);
      font-weight: 700;
    }
  }

  &__imgText {
    color: var(--color-black-100);
    font-size: 10px;
    font-weight: 700;
    left: 4px;
    position: absolute;
    text-transform: uppercase;
    top: 19px;

  }

  &__goToLink {
    align-items: center;
    background-color: var(--color-black-0);
    border: 1px solid var(--color-black-40);
    border-radius: 5px;
    color: var(--color-black-60);
    display: flex;
    height: 30px;
    justify-content: center;
    transition: 0.3s;
    width: 30px;
  }
}

.groupSimpleMainBoxDocs {
&__link {
    color: var(--color-black-100);
    font-weight: 600;
  }
}

// Prices
.clientPaymentInfo {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;

  &__box {
    display: grid;
    padding: 20px;
    gap: 20px;
  
    @media (min-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr;;
    }

    @media (min-width: 1440px) {
      grid-template-columns: repeat(4, 1fr);
    }

  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  
    flex: 1 0 0;

    span {
      font-weight: 700;
    }

    &__title {
      padding: 0 10px;
      color: var(--color-black-100)
    }

    &__bilimPrice {
      display: flex;
      padding: 5px 10px;
      gap: 10px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 6px;
      border: 1px dashed #c903ff;
      background: #fdeffc;
    }
    &__specialPrice {
      color: var(--color-black-100);
      display: flex;
      flex-direction: column;
      padding: 0px 10px;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
    }
    &__activeDate {
      display: flex;
      gap: 10px;

      p {
        color: var(--color-black-80);
      }
      span {
        font-weight: 400;
        color: var(--color-black-80);
      }
    }

    &__certificate {
      display: flex;
      padding: 10px;
      gap: 10px;
      align-items: center;
      align-content: center;
      row-gap: 10px;
      align-self: stretch;
      flex-wrap: wrap;
      border-radius: 6px;
      border: 1px dashed #ffc700;
      background: #fff9e9;
    }

    &__certificateItem {
      display: flex;
      gap: 5px;
      flex-direction: column;

      p, span {
        color: var(--color-black-100);
      }
    }
  }
}


