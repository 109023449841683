.display_none {
    display: none;
}


.button_link {
    border: 1px solid #b8d4ff;
    background: #f4f8ff;
    text-align: center;
    color: #5f6368;
    margin: 0 auto;
}

.element {
    &__sign {
        font-family: 'Calligraph_Sign';
        font-size: 35px;
        padding: 0 35px 15px;
        border-bottom: 2px solid black;
        width: fit-content;
    }

    &__initials {
        padding: 0 35px 15px;
        border-bottom: 2px solid black;
        width: fit-content;

    }

    &__pdf {
        width: 100px;

    }
    &__pdf_wrapper {
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

    }

    &__input {
        &_wrapper {
            padding-top: 10px;
            display: flex;
            gap: 15px;
        }

        &_number {
            height: 30px !important;
            width: 50px;
            border-radius: 5px;
            padding: 5px;
            border: 1px solid gray;
        }
    }

    &__box {
        position: relative;
        transition: .3s;
        padding: 7px 5px;

        &_view {
            padding: 7px 5px;
            overflow: hidden;
        }

        &:hover {
            .element__wrapper_icon {
                display: flex;
            }

            .element__footer_text {
                display: flex;
            }

            box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
        }
    }

    &__listingMarked_item {
        margin-bottom: 10px;
    }

    &__listingNumbered_item {
        margin-bottom: 10px;
        display: flex;
    }

    &__listingNumbered_num {
        padding-left: 4px;
        width: 20px;
        display: block;
    }

    &__text {
        border-radius: 5px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__text {
            font-weight: 600;

        }

        &__label {
            width: 50%;
            min-width: 280px;
        }

        &__btn {
            width: 50%;

        }
    }

    &__row {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    &__img {
        width: 100%;
    }

    &__imgForm {
        display: flex;
        flex-direction: column;
        gap: 30px
    }

    &__userForm {
        &__box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 15px;
        }

        &__input {
            border: none;
            border-bottom: 1px solid gray;
            padding-bottom: 5px;

            flex-grow: 1;
        }
    }


    &__wrapper_icon {
        position: absolute;
        top: 0px;
        right: 10px;
        display: none;
        gap: 10px;
        background: white;
        padding: 3px;
        z-index: 10;
    }

    &__wrapper_flex {
        display: flex;
        justify-content: center;
    }


    &__listingMarked_list {
        padding: 0 20px;
        list-style-type: disc;
    }

    &__checkBox_list {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(var(--gridTemplateColumns), 1fr);

    }

    &__title_h1 {
        font-size: 22px;
        color: black;
        margin-bottom: 15px;
    }

    &__title_h2 {
        font-size: 19px;
        font-weight: 600;
        color: black;
        margin-top: 15px;
    }

    &__title_h3 {
        font-size: 16px;
        font-weight: 600;
        color: black;
        margin-top: 15px;
    }

    &__footer {
        width: 100%;
        height: 80px;

        &_text {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: #3f4d63;
            opacity: .5;
            font-weight: 600;
            font-size: 1.3em;
        }
    }

}