.teacher-slots-card {
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  margin-top: 10px;

  &__header {
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    height: 52px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }

  &__header-content {
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
  }

  &__name {
    color: black;
    flex-basis: 80%;
    font-size: 14px;
    font-weight: 500;
    @media (min-width: 768px) {
      flex-basis: 50%;
    }
  }

  &__count {
    font-size: 18px;
    font-weight: 600;
  }

  &__slider {
    height: 0;
    overflow: hidden;
    transition: all .3s;
  }

  &__slots {
    display: grid;
    gap: 10px 20px;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    @media (min-width: 768px) {
      gap: 15px;
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  &__slot {
    align-items: center;
    border: 1px solid #3f4d63;
    border-radius: 5px;
    color: #3f4d63;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    height: 30px;

    &--booked {
      background-color: #b1f5cf;
      border-color: #32b76c;
      color: #4d5055;
    }

    &--deleted {
      background-color: #ffdae7;
      border-color: #d80027;
      color: #000;
    }

    &--hc {
      background-color: #fed76e;
      border-color: #ebc05f;
    }

    &--deleted#{&}--hc {
      background: linear-gradient(to bottom right, #fed76e, #fed76e 49%, #ffdae7 51%, #ffdae7);
      border-color: #d80027;
    }

    sup {
      font-size: 8px;
      margin-bottom: 8px;
    }
  }
}
