.inn_wrapper {
  position: relative;
}

.label {
  display: block;
  margin-bottom: 7px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text);
}

.label_required span::before {
  content: '* ';
  color: var(--color-error);
}

.input_wrapper {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 0 3px #b8d4ff;
  }

  &__disabled {
    &:hover {
      box-shadow: none;
    }
    & .input {
      background-color: #f2f2f2;
    }
  }

  &:focus-within {
    box-shadow: 0 0 1px 3px #b8d4ff;
  }

  &__error,
  &__error:focus-within {
    box-shadow: 0 0 1px 3px var(--color-error-light);

    & .input {
      border: 1px solid var(--color-error);
    }
  }
}

.select {
  position: relative;
  flex-basis: 76px;
  padding-left: 15px;
  border-radius: 5px 0 0 5px;
  border: 1px solid var(--color-outline);
  background: #ebebeb url('../../ContentBox/img/select-down.svg') center right 10px no-repeat;
  background-size: 8px;
  -webkit-appearance: none;
}

.input {
  width: calc(100% - 76px);
  height: 44px;
  padding-left: 20px;
  color: var(--color-text);
  border: 1px solid var(--color-outline);
  border-left: none;
  border-radius: 0 5px 5px 0;
  transition: border 0.3s;
}

.error_text {
  position: absolute;
  bottom: -15px;
  right: 0;
  font-size: 10px;
  color: var(--color-error);
}
