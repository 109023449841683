.container {
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 20px;
  }
}
.exercisesSectionsBox {
  display: grid;
  gap: 20px;
  padding-top: 20px;
  @media (min-width: 1440px) {
    grid-template-columns: 254px 1fr;
  }
}

.questionsContainer {
  margin: 20px auto 0;
  max-width: 667px;
}
.questions {
  display: flex;
  flex-direction: column;
  //max-width: 737px;
}
.createQuestion {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-60);
  border-radius: 7px;
  color: var(--color-black-60);
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
  line-height: 36px;
  margin: 10px auto;
  text-align: center;
  transition: .3s;
  width: 300px;
  &:hover {
    border-color: var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
    color: var(--color-primary-2);
  }

  @media (min-width: 768px) {
    width: 580px;
  }
  @media (min-width: 1024px) {
    width: 667px;
  }
}

.bodyNotFound {
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 10px 0 66px 0;
  @media (min-width: 768px) {
    padding: 62px 0 83px 0;
  }

  .imgNotFound {
    width: 100%;
    max-width: 400px;
  }

  .text {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: var(--color-black-100);
  }

  .addNewBtn {
    margin-top: 20px;
    min-width: 270px;
    border: none;
    color: var(--color-black-0);
    border-radius: 7px;
    background: var(--color-primary-1);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    &:hover {
      background: var(--color-primary-2);
    }
  }
}
.noChapter {
 margin-left: 50px;
}

.backButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 15px;
  }

  .backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    max-width: 100%;
    width: 100%;
    height: 36px;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid var(--color-primary-1);
    background: var(--color-black-0);
    color: var(--color-primary-1);
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.42px;

    @media (min-width: 768px) {
      max-width: 195px;
    }

    svg {
      width: 16px;
      height: 16px;
      transform: scaleX(-1);
    }
  }
}

.aiButton {
	position: fixed;
	bottom: 80px;
	right: 80px;
	z-index: 100;
}

.aiChat {
	position: fixed;
	bottom: 0px;
	right: 0px;
	z-index: 1000;
}

@media (max-width: 1440px) {
.aiButton {
		right: 15px;
}
}