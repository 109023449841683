.modalMultiPhoneNumbers {
   display: flex;
   flex-direction: column;
   gap: 10px;

   &__phone {
      display: flex;
      gap: 10px;
   }

   &__btnDel {
      align-items: center;
      color: #3f4d63;
      cursor: pointer;
      display: flex;
      font-size: 40px;
      justify-content: center;
   }

   &__btnAdd {
      align-items: center;
      display: flex;
      font-size: 30px;
      height: 45px;
      justify-content: center;
   }
}
