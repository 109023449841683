.box {
  align-items: center;
  border: 2px dashed #bdbdbd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  input[type="file"] {
    display: none;
  }

  button {
    font-family: inherit;
    font-weight: 500;
  }
}

.boxError {
  composes: box;
  border-color: #db3031;
}

.progress {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  progress {
    background-color: #3f4d63;
    border-radius: 5px;
    height: 10px;
    width: 100%;
    &::-webkit-progress-bar {
      background-color: #bdbdbd;
      border-radius: 5px;
    }
    &::-webkit-progress-value {
      background-color: #3f4d63;
      border-radius: 5px;
    }
  }

  div {
    margin-top: 1em;
  }
}