.wrapper {
  &__margin {
    margin-bottom: 20px;
  }
}

.title {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text);

  &__margin {
    margin-bottom: 20px;
  }

  &__description {
    margin-bottom: 6px;
  }

  &__adaptive {
    @media (max-width: 639px) {
      font-size: 14px;
    }
  }
}

.secondary {
  font-weight: 500;
  color: var(--color-text-accent);
}

.description {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-text);
}
