.tooltip {
  background-color: #fff;
  border: 1px solid var(--color-outline);
  border-radius: 3px;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0;
  padding: .5em 15px;
  position: fixed;
  text-align: center;
  transition: opacity .2s;
  white-space: nowrap;
  z-index: 1100;

  &__show {
    opacity: 1;
  }

  &:before {
    background-color: white;
    border-top: 1px solid var(--color-outline);
    border-left: 1px solid var(--color-outline);
    content: "";
    display: block;
    height: 10px;
    left: calc(50% - 7px);
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    width: 10px;
  }

  span {
    color: var(--color-text-accent);
  }
}
