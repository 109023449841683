.wrapper {
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  background-color: var(--color-black-0);
  min-width: 254px;

  .row {
    display: flex;
    justify-content: space-between;
    color: var(--color-black-100);
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.4px;
    padding: 10px 20px;
    border-bottom: 1px solid var(--color-black-40);

    .btn {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px;
      border: 1px solid var(--color-black-40);
      border-radius: 5px;
      color: var(--color-black-60);

      &:hover {
        color: var(--color-primary-2);
        border-color: var(--color-primary-3);
      }
    }
  }
  .empty {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.28px;
    text-align: center;
    padding: 10px 0;
  }
}

