.writeTextBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.writeTextInput {
  overflow-y: hidden;
  color: var(--color-black-100);
  border: 1px solid var(--color-black-60);
  border-radius: 7px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  width: 170px;
  padding: 0 20px;
  margin-right: 5px;
  display: inline-block;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
    cursor: default;
  }

  &:focus-within {
    border: 1px solid var(--color-primary-2);
    box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
  }

  &::placeholder {
    font-size: 16px;
    color: var(--color-black-60);
    text-align: center;
  }
}

.writeText {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  border: 0;
  background: var(--color-black-0);
  color: var(--color-black-80);
  padding: 0;

  &:hover {
    background: var(--color-black-0);
    color: var(--color-black-60);
  }

  &:disabled {
    color: var(--color-black-60);
  }
}