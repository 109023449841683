.ask {
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .askHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header {
  color: var(--color-text);
  font-weight: 600;
}
.point {
  align-items: center;
  display: flex;
  justify-content: space-between;

  p {
    color: var(--color-text);
    font-weight: 500;
  }
}
.point.point {
  input {
    width: 86px;
  }
}
.variants {
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}
.close {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;

  &::after, &::before {
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    background: #000;
    height: 2px;
    width: 100%;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
}

.isError {
  border: 1px solid var(--color-danger-1);
}
