.strBox {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__header {
    align-self: stretch;
    background: linear-gradient(#2c3e50, #3498db);
    color: #fff;
    line-height: 1.25;
    min-height: 300px;
    text-align: center;

    h1 {
      color: white;
      font-size: 18px;
      font-weight: 700;
      padding-top: 1.5em;
      @media (min-width: 768px) {
        font-size: 35px;
      }

      span {
        display: block;
        text-transform: uppercase;
      }
    }
  }

  &__student {
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 14px;
    }

    b {
      font-size: 16px;
      font-weight: 500;
      @media (min-width: 768px) {
        font-size: 25px;
      }
    }
  }

  &__testInfo {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: -110px auto 0;
    position: relative;
    @media (min-width: 768px) {
      align-self: stretch;
      margin-top: -70px;
    }
  }

  &__testData {
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    @media (min-width: 768px) {
      color: white;
      flex-direction: row;
      gap: 320px;
      position: absolute;
      top: 1em;
    }

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 200px;
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      align-items: flex-start;
      flex-direction: row;
      gap: 320px;
      position: absolute;
      top: 100px;
    }

    &>div {
      width: 200px;
    }
  }

  &__btn {
    display: flex;
    bottom: 0;
    width: 200px;
    height: 40px;
    align-items: center;
    justify-content: center;
    color: #3498db;
    font-size: 14px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1;
    font-weight: 500;
    border-radius: 5px;
    background-color: #ffffff;
    border: 2px solid #3498db;
    cursor: pointer;
    &--disabled {
      pointer-events: none;
      opacity: 0;
    }

    &:hover {
      background-color: #3498db;
      color: #ffffff;
    }
  }

  &__error {
    margin-top: 40px;
    color: #ff0000;
    font-weight: 700;
    text-align: center;
    width: 70%;
    font-size: 35px;
    line-height: 30px;

    p:last-child {
      margin-top: 10px;
      font-size: 22px;
      font-weight: 500;
    }
  }

  &__sections {
    margin-top: 20px;
  }

  &_results {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 50px;
    width: 70%;

    &_abox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin-bottom: 20px;

      svg {
        margin-right: 10px;
        float: left;
      }

      span {
        min-width: 25px;
        font-weight: 500;
        font-size: 18px;

        &:last-child {
          display: flex;
          justify-content: center;
          padding-left: 10px;
        }
      }
    }


    &_abox div:nth-child(odd) {
      display: flex;
      justify-content: center;
      line-height: 24px;
      font-size: 24px;
      padding-top: 15px;
      width: 30px;
    }

    &_abox div:nth-child(even) {
      width: 125px;
      display: flex;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      flex-direction: row;
      align-items: center;
      padding-top: 0;
      padding-left: 10px;
    }

    &_abox-many {
      width: 175px;
      display: flex;
      flex-direction: row;
      padding-top: 0;
      margin-bottom: 10px;
    }
  }

  &_tr {
    transform: rotate(-90deg);
    transform-origin: left top 0;
    left: -40px;
    position: absolute;
    bottom: -30px;
    color: #3498db;
    font-size: 37px;
    font-weight: 700;
    text-transform: uppercase;
    width: 600px;
    align-items: center;
  }

  &_resultsList {
    display: flex;
    height: 500px;
    background-color: #ededed;
    width: 100%;
    padding: 20px 30px;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
