.base {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  border: 1px solid var(--color-text-accent);
  border-radius: 0 5px 5px 0;
  background-color: white;
  transition: opacity 0.3s;

  &:hover {
    background-color: white;
    opacity: 0.7;
  }

  &:focus-visible {
    box-shadow: 0 0 1px 1px var(--color-accent);
  }

  &::before {
    content: '';
    margin-left: 5px;
    border: 5px solid white;
    border-left: 5px solid var(--color-text-accent);
  }
}

.left {
  border-radius: 5px 0 0 5px;
  border-right: none;

  &::before {
    margin-left: -5px;
    transform: rotate(180deg);
  }
}

.disabled {
  border-color: var(--color-outline);
  cursor: not-allowed;

  &:hover {
    opacity: 1;
  }

  &::before {
    border-left-color: var(--color-outline);
  }
}
