.main-table-card-box {
  $time: .3s;

  display: flex;
  margin: 5px 10px;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  position: relative;

  &__subTable_header {
    display: grid;
    width: 100%;
    flex-grow: 1;
    gap: 20px;
    padding-bottom: 20px;
  }

  &__subTable_headerCol {
    display: grid;
    width: 100%;
    gap: 20px;
    font-size: 12px;
    min-height: 25px;
    line-height: 20px;
  }
  &__subTable_header_box {
   padding-top: 10px;
   display: flex;
   flex-direction: column;
   gap: 15px;
  }
  &__subTable_headerCol_center {
   display: flex;
   justify-content: center;
   font-size: 12px;
    font-weight:600 ;
  }



  &--deleted {
    background-color: #ffdae7;
    border-color: #d80027;
    color: #000;
  }

  &__image {
    display: flex;
    flex-shrink: 0;
    height: 70 / 320 * 100vw;
    padding: 10px;
    font-size: 7vw;
    font-weight: 600;
    transition: $time;
    width: 70 / 320 * 100vw;
    @media (min-width: 768px) {
      font-size: 40px;
      height: 150px;
      width: 150px;
    }

    img {
      border: 1px solid #bdbdbd;
      border-radius: 5px;
      height: 100%;
      transform: scale(1);
      transition: $time;
      transition-delay: $time;
      width: 100%;
      object-fit: contain;
    }
    &--hide {
      height: 0;
      padding: 0;
      transition-delay: $time;
      width: 0;

      & {
        img, .main-table-card-box__ava {
          transform: scale(0);
          transition-delay: 0s;
        }
      }
    }
  }

  &__ava {
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: $time;
    transition-delay: $time;

    svg {
      fill: currentColor;
      height: 70%;
      transform: scale(1);
      transition: $time;
      transition-delay: $time;
      width: 70%;
    }
  }

  &__box {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  &__boxError {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 1px solid #d80027;
    border-radius: 5px;
  }

  &__header {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    //overflow: hidden;
    height: 60px;
    padding: 10px;
    @media (min-width: 768px) {
      align-items: center;
      height: 60px;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      height: 118px;
    }
    button{
      padding: 6px 15px;
      margin-right: 26px;
      margin-left: 20px;
      font-weight: 600;
      color: #cc0000;
      border-color: #fff;
    }
    button:hover{
      background-color: #007be8;
      border-color: #007be8;
      color: #fff;
    }
  }

  &__color{
    display: flex;
    position: relative;
    width: 20px;
    min-height: 45px;
    &__line{
      display: flex;
      position: absolute;
      width: 20px;
      min-height: 65px;
      left: -10px;
      top: -10px;
      border-radius: 5px 0 0 5px;
    }
    &--not_paid{
      background-color: #cc0000;
    }
    &--paid{
      background-color: #76cf9d;
    }
    &--cancelled{
      background-color: #bdbdbd;
    }
    &--pending{
      background-color: #fed76e;
    }
  }

  &__header-array {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    color:var(--color-primary-1);
    @media (min-width: 768px) {
      align-items: center;
      flex-direction: row;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      width: 100%;
    }


    &>div {
      //flex: 1 1 100%;
      //overflow: hidden;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 25%; /* 1 */
        height: 100%;
        //background: linear-gradient(to right, transparent, white);
      }

      &:first-child {
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;

        @media (max-width: 700px) {
          align-self: flex-start;
          margin-bottom: 10px;
        }
      }
    }

    button {
      padding: 6px 15px;
      font-weight: 600;
    }

    a {
      color: #222222;
      &:hover {
        text-decoration: underline;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__header-button {
    display: flex;
    align-items: center;
    font-weight: 600;

    p {
      padding: 6px 15px;
      margin-right: 30px;
      margin-left: 20px;
      color: #76cf9d;
    }
    @media (max-width: 800px) {
     display: none;
    }
  }
  &__header-buttons{
   font-weight: 600;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__body-slide {
    align-items: flex-end;
    display: flex;
    overflow: hidden;
    transition: $time;
    height: 0;
  }

  &__body {
    border-top: 1px solid #bdbdbd;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    @media (min-width: 768px){
      padding: 10px;
    }
  }

  &__body-unit {
    align-items: flex-start;
    display: flex;
    font-size: 12px;
    min-height: 25px;
    min-width: 20%;
    flex-direction: row;
    line-height: 20px;

    img{
      width: 100%;
      max-width: 400px;
    }

    span:first-child {
      flex-shrink: 0;
      width: 150px;
      font-weight: 600;
      @media (min-width: 768px){
        width: 170px;
      }
    }
  }

  & &__payment-link {
    background-color: #3474ff;
    border: 2px solid #3474ff;
    border-radius: 5px;
    color: #fff;
    display: block;
    font-weight: 600;
    line-height: 28px;
    margin: auto;
    text-transform: uppercase;
    text-align: center;
    transition: .3s;
    width: 150px;
    &:hover {
      background-color: #fff;
      color: #3474ff;
      text-decoration: none;
    }
  }

  & &__link {
    color: black;
    outline: none;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.redDebetor{
  color: red;
  font-weight: 600;
  &--big{
    font-size: 20px;
    text-align: right;
    padding-right: 10px;
  }
  &--not_paid {
    text-align: right;
    padding-right: 10px;
    font-size: 16px;
    color: #c00;
  }
}

.isConfirmed{
  font-weight: 600;
  &--true{
    color: #76cf9d !important;
  }
  &--false{
    color: #cc0000 !important;
  }
}
.reqStatus{
  &--wait{
    color: #cc0000 !important;
  }
  &--allowed{
    color: #76cf9d !important;
  }
  &--forbidden{
    color: #cc0000 !important;
  }
}
.paymentButton{
  //display: flex;
  //width: 80%;
  //align-items: center !important;
  //justify-content: center !important;
  //color: #fff !important;
  //background-color: #3474ff !important;
  //text-transform: uppercase;
  &--span{
    display: flex;
    width: 100%;
    align-items: center !important;
    justify-content: center !important;
    font-weight: 600;
    text-transform: uppercase;
  }
  &--not_paid{
    color: #cc0000 !important;
  }
  &--paid{
    color: #76cf9d !important;
  }
  &--cancelled{
    color: #bdbdbd !important;
  }
  &--pending{
    color: #fed76e !important;
  }
  /*&:hover{
    color: #3474ff !important;
    background-color: #fff !important;
  }*/
}
.u_list{
  display: flex;
  flex-direction: column;
  a{
    line-height: 21px;
    text-decoration: underline !important;
    color: #333333 !important;
    &:hover{
      text-decoration: none !important;
    }
  }
}
.blink {
  -webkit-animation: blink 1.4s linear infinite;
  animation: blink 1.4s linear infinite;
}
@-webkit-keyframes blink {
  0% { color: rgba(34, 34, 34, 1); }
  50% { color: rgba(34, 34, 34, 0); }
  100% { color: rgba(34, 34, 34, 1); }
}
@keyframes blink {
  0% { color: rgba(34, 34, 34, 1); }
  50% { color: rgba(34, 34, 34, 0); }
  100% { color: rgba(34, 34, 34, 1); }
}
.refresh {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  padding: 3px;
}
.attempts {
  color: var(--color-success-1);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

}

.attemptsWord {
  color: black;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  font-weight: 500;
  line-height: 24px;

  @media (min-width: 768px){

  }
}