.wrapper {
  align-self: stretch;
}

.label {
  display: inline-block;
  margin-bottom: 7px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text);
}

.textarea {
  width: 100%;
  min-height: 75px;
  padding-left: 20px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text);
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  transition: border 0.3s;
  resize: none;

  &:hover {
    box-shadow: 0 0 0 3px #b8d4ff;
  }

  &:focus {
    border: 1px solid #0062ba;
    box-shadow: 0 0 1px 3px #b8d4ff;
  }

  &.error {
    border: 1px solid var(--color-error);
    box-shadow: 0 0 1px 3px #ffdae7;
  }

  &.resize {
    resize: vertical;
  }
}
