.id-selector {
  display: flex;

  &__block {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #959595;
    width: 260px;
    height: 225px;
    overflow: auto;
    padding: 5px 5px;
  }

  &__arrows {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    gap: 20px;
    width: 38px;
  


    img {
      width: 20px;
      &:first-child {
        transform: rotate(0deg) !important;
        margin-bottom: 20px;
        margin-top: -20px;
      }

      &:nth-last-child(1) {
        transform: rotate(180deg) !important;
      }
    }
  }

  &__row {
    line-height: 20px;
    color: #5f6368;
    cursor: pointer;
    width: 100%;
    padding: 5px 15px;
    &:hover {
      background-color: #007be8;
      color: #fff;
    }
  }
}
