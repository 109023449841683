.book, .bookWithoutComment {
    display: flex;
    background-color: #fff;
    padding: 20px 0;
    &:hover {
        background-color: var(--color-black-10);
    }
}

.book {
    align-items: flex-start;
}

.bookWithoutComment {
    align-items: center;
}

.body {
    width: 100%;
    padding-right: 10px;
}

.info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    flex-wrap: wrap;
    @media (max-width: 320px) {
        gap: 5px;
    }
}


.name {
    font-weight: bold;
}

.textBody {
    color: var(--color-black-80);
    font-size: 12px;
}

.img {
    width: 28px;
}

.imgLink {
    position: relative;
}

.documentImg {
    margin: 0 17px 0 17px;
    &:hover {
        cursor: pointer;
    }
}

.imgText {
    position: absolute;
    top: 19px;
    left: 4px;
    text-transform: uppercase;
    font-size: 10px;
    color: #000000;
    font-weight: 700;
}