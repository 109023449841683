.container {
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 20px;
  }
}
.page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}
.content {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 6px;
  padding: 10px;
  @media (min-width: 768px) {
    padding: 20px;
  }
}
.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
