.reactDatepickerCustom {
  :global(.react-datepicker-wrapper) {
    width: 100%;

    input {
      text-align: center;
      padding: 0;
    }
  }
}
