.separator {
	display: flex;
	align-items: center;
	gap: 37px;
	font-size: 14px;
	font-weight: 300;
	line-height: 16px;
	color: var(--color-black-80);
	margin: 10px 0;

	div {
		width: 100%;
		height: 1px;
		background: var(--color-black-40);
	}
}

.alienMessage {
	display: flex;
	justify-content: flex-end;
}

.userMessage {
	display: flex;
	justify-content: flex-start;
}
