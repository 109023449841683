.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: var(--color-black-20);
    height: 156px;
    gap: 15px;
    padding: 0 20px;

    @media (min-width: 1440px) {
        padding: 0 30px;
    }
    @media (min-width: 768px) {
        justify-content: space-between;
        flex-direction: row;
        gap: 0;
        height: 78px;
    }
    &__left {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 18px;

        @media (min-width: 768px) {
            flex-direction: row;
            gap: 36px;
        }
        @media (min-width: 1024px) {
            gap: 18px;
        }
    }
    &__logo {
        width: 33px;
        height: 43px;
    }
    & &__content {
        display: flex;
        flex-direction: column;
        
        @media (min-width: 768px) {
            gap: 5px;
        }

        p, a {
            font-size: 12px;
            font-weight: 600;
        }
        p {
            color: var(--color-black-80);
        }
        & a {
            color: var(--color-primary-2);
            &:hover {
                text-decoration: underline;
                text-decoration-color: var(--color-primary-2);
            }
        }
    }
    &__version {
        color: #636E72;
        font-size: 12px;
        font-weight: 600;
    }
}