.book {
    border: 1px solid var(--color-outline);
    border-radius: 5px;
    background-color: #fff;
    padding-bottom: 15px;
    position: relative;

    a {
        display: block;
        padding: 10px 10px 0;
    }
}

.wrapper_img {
    position: relative;
    width: 100%;
    height: 220px;
    background-color: #ddd;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.row_info {
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.img {
    width: 106px;
}


.name_client {
    font-size: 12px;
    font-weight: 400;
}

.name {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    height: 6.5em;
    padding: .5em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.params {
    align-items: flex-start;
    color: #4d5055;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 12px;
    gap: .5em;
    padding: 0 10px;
}

.chapters {
    background-color: #fff;
    border: 1px solid #bdbdbd;
    border-left: none;
    border-radius: 0 5px 5px 0;
    color: #4d5055;
    font-size: 10px;
    left: 0;
    line-height: 18px;
    padding: 0 10px;
    position: absolute;
    top: 20px;
}

@media (max-width: 768px) {
    .img {
        max-width: 86px;
    }
}