.timeTableDatePicker {
    position: relative;
    height: 30px;
    width: 30px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
        opacity: 1;
        width: 15px;
        height: 15px;
        cursor: pointer;

    }
    &__box {
        left: -130px;
        top: 10px;
    }
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        height: 30px;
        width: 30px;
        z-index: 0;
        cursor: pointer;
    }
    .react-datepicker__triangle {
        display: none;
    }
}