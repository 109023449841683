.box {
  padding: 0;
}

.visitRow {
  border-bottom: 1px solid var(--color-outline);
  display: grid;
  grid-template-columns: 200px minmax(100px, 100px) repeat(4, 1fr);
  padding: 20px;
}

@media (max-width: 768px) {
  .visitRow {
    grid-template-columns: minmax(100px, 150px) repeat(4, 1fr);
  }
}

@media (max-width: 480px) {
  .visitRow {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    grid-gap: 10px;
  }

  .visitRow > div:nth-child(2) {
    grid-row: 2;
  }

  .visitRow > div:nth-child(3) {
    grid-row: 3;
  }

  .visitRow > div:nth-child(4) {
    grid-row: 4;
  }

  .visitRow > div:nth-child(5) {
    grid-row: 5;
  }

  .visitRow > div:nth-child(6) {
    grid-row: 6;
  }
}