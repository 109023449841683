.counterBox {
    width: 100%;
    height: 45px;
    border-radius: 7px;
    border: 1px solid var(--color-primary-1);
    overflow: hidden;
    display: flex;

    &__buttonTrue {
        background-color: var(--color-primary-1);
        width: 50%;
        color: var(--color-black-0);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        font-weight: 500;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    &__buttonFalse {
        background-color: var(--color-black-0);
        width: 50%;
        color: var(--color-primary-1);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        font-weight: 500;

        @media (max-width: 767px) {
            text-align: center;
        }
    }
}