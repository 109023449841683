@import "../MainStyles";

.answerBody {
  @include answerBodyMain;
  padding: 23px 10px 10px 10px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 657px;
  margin: 0 auto;
}

.line {
  width: 57px;
  height: 1px;

  @media (min-width: 768px) {
    min-width: 40px;
  }

  @media (min-width: 1024px) {
    min-width: 57px;
  }
}

.words {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 115px;
  padding: 5px 15px;
  text-align: center;
  word-break: break-word;
  gap: 10px;
  border-radius: 6px;
  color: var(--color-black-100);
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.36px;
  align-self: stretch;

  @media (min-width: 768px) {
    width: 265px;
  }

  @media (min-width: 1024px) {
    width: 300px;
  }
}

.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.correctColor {
  @include correctColorMain;
}

.rightAnswer {
  border: 1px solid var(--color-success-1);
  background-color: var(--color-success-4);
}

.wrongAnswer {
  border: 1px solid var(--color-danger-1);
  background-color: var(--color-danger-4);
}