.sidebar {
  bottom: 0;
  top: 61px;
  text-align: left;
  user-select: none;
  z-index: 899;
  position: fixed;

  &Nav {
    background: var(--color-black-20);
    border-right: 1px solid var(--color-black-40);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 15px;
    position: relative;
    transition: .3s;
    transition-delay: 0s;
    width: 0;

    @media (min-width: 768px) {
      width: 80px;
    }

    &__active {
      transition-delay: 333ms;
      width: 300px;
    }
  }
}

.openButton {
  position: absolute;
  right: -40px;
  top: 0;
  height: 32px;
  width: 32px;
  border-radius: 5px;
  border: 1px solid var(--color-black-40);
  background: var(--color-black-10);
  cursor: pointer;
  transition: .3s;

  &.active {
    right: -10px;
  }

  &.active & {
    &__icon_1 {
      transform: rotate(180deg);
      color: rgb(99, 110, 114);
    }
    &__icon_2 {
      transform: rotate(180deg);
      color: rgb(178, 190, 195);
    }
  }

  &__icon_1, &__icon_2 {
    position: absolute;
    transition: .5s;
    top: 3px;
  }
  &__icon_1 {
    right: 5px;
    color: rgb(151, 244, 255);
  }
  &__icon_2 {
    left: 5px;
    color: rgb(85, 177, 243);
  }

  @media (min-width: 768px) {
    right: -10px;
    top: 13px;
  }
}

.mainMenu {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.category {
  align-items: center;
  cursor: pointer;
  display: grid;
  height: 48px;
  gap: 8px;
  grid-template-columns: auto 1fr;
  text-align: left;
  transition: .2s;

  .title {
    color: var(--color-black-80);
    font-size: 16px;
    font-weight: 700;
    transform: scaleX(0);
    transform-origin: left center;
    transition: .3s;
    transition-delay: 0s;
    line-height: 24px;

    &.active {
      color: var(--color-primary-1)
    }
  }

  &.active {
    .title {
      transform: scaleX(1);
      transition-delay: 333ms;
    }
  }

  &Opened {
    color: var(--color-primary-1);
  }
}

.subList {
  padding: 0 10px;

  &__item {
    border-radius: 7px;

    &:hover {
      background: var(--color-black-0);

      .subList__link {
        font-weight: 600;
        color: var(--color-primary-1);
      }
    }
  }

  &__link {
    position: relative;
    color: var(--color-black-80);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.28px;
    display: flex;
    padding: 12px 10px 12px 30px;
    gap: 10px;
    align-items: center;

    &:hover {
      &::after {
        content: '';
        top: 8px;
        bottom: 8px;
        background-color: var(--color-primary-1);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 24px;
      bottom: 24px;
      right: 3px;
      display: block;
      width: 2px;
      background-color: transparent;
      transition: all .3s;
    }
  }

  &__dot {
    width: 4px;
    height: 4px;
    background: rgba(99, 110, 114, 1);
    border-radius: 100%;
  }
}
