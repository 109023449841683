.customTextarea {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 20px;

  &:hover & {
    &__bar {
      display: flex;
    }
  }

  &__bar {
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    border-radius: 5px 5px 0 0;
    display: none;
    gap: 1px;
    height: 33px;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: -30px;
  }

  &__btn {
    background-color: #fff;
    cursor: pointer;
    padding-top: 7px;
    transition: .2s ease;
    width: calc((100% - 11px) / 12);
    &:hover {
      background-color: #bdbdbd;
    }

    &--active {
      background-color: #bdbdbd;
    }

    img {
      display: block;
      margin: auto;
    }
  }

  &__textarea {
    min-height: 100px;
    max-height: 1000px;
    width: 100%;
    line-height: 19px;
    border: 1px solid #bdbdbd;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 18px;
    overflow: auto;
    background-color: #ffffff;
    border-radius: 5px;

    &--error {
      border-color: #db3031;
    }

    &:empty:not(:focus)::before {
      content: attr(data-text);
      color: #959595;
    }
  }
}
