.workTimeInfo {
  position: relative;
  display: flex;
  justify-content: center;
}

.workTimeInfo__header {
  border: 1px solid var(--color-black-40);
  width: fit-content;
  margin: 0 auto;
  top: -10px;
  position: absolute;
  color: var(--color-black-80);
  font-size: 12px;
  padding: 5px 10px;
  background: var(--color-black-0);
  border-radius: 5px;
}

.remainderForToday {
  border: 1px solid var(--color-success-2);
  border-radius: 5px;
  background: var(--color-success-4);
  padding: 24px 0 10px 0;
  height: fit-content;
  width: 100%;
  @media (min-width: 320px) {
    padding: 24px 5px 10px 5px;
  }
}

.timeWork {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  gap: 10px;

  span {
    color: var(--color-black-80);
    white-space: nowrap;
  }

  b {
    font-size: 18px;
    color: var(--color-black-100);
  }
}

.timeWorkItem {
  display: flex;
  flex-direction: column;

  span {
    &:nth-child(1) {
      font-size: 12px;
    }
  }
}

.exemplaryTime {
  text-align: center;

  span {
    color: var(--color-black-80);
  }

  & > span {
    font-size: 12px;
  }
}

.workedOutToday {
  display: flex;
  justify-content: center;
  gap: 10px;

  span {
    font-size: 16px;
    color: var(--color-black-100);
  }
}
