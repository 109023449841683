@mixin answerBodyMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 6px;
  position: relative;
  margin-top: 20px;
  align-self: stretch;
}

@mixin answerTitleMain {
  position: absolute;
  display: flex;
  height: 36px;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  color: var(--color-black-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.36px;
  top: -20px
}

@mixin studentColorMain {
  border: 1px solid var( --color-warning-1);
  background: var(--color-warning-4);
}


@mixin correctColorMain {
  border: 1px solid var(--color-black-60);
  background: var(--color-black-10);
}


@mixin rightAnswerMain {
  color: var(--color-success-1);
}

@mixin wrongAnswerMain {
  color: var(--color-danger-1);
}