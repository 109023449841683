.text {
  color: var(--color-black-80);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.5;
}
.restorePasswordError {
  background-color: var(--color-danger-1);
  border-radius: 5px;
  color: var(--color-black-0);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 2;
  margin-top: 15px;
  padding: 0 10px;
}
