@import "./days";

.employeeTabSchedule {
  &__holiday {
    font-weight: 500;
    font-size: 12px;
    color:var(--color-black-100);
  }

  &__scheduleBox {
    background-color: var(--color-black-0);
    border: 1px solid var(--color-black-40);
    border-radius: 5px;
  }

  &__day {
    text-align: center;
    align-items: center;
    border: 1px solid var(--color-black-40);
    color: black;
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 50px;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    font-weight: 600;
    width: calc((100% - 15px) / 4);
    @media (min-width: 768px) {
      width: calc((100% - 35px) / 8);
    }
    @media (min-width: 1440px) {
      width: calc((100% - 50px) / 11);
    }
    @media (min-width: 1920px) {
      width: calc((100% - 75px) / 16);
    }

    & > span {
      color: var(--color-black-100);
    }

    &:hover & {
      &Tooltip {
        transform: scaleY(1);
        opacity: 1;
        &::after {
          transform: scale(1);
        }
      }
    }

    @each $day, $bgc, $bdc, $fc in $days {
      &--#{$day} {
        background-color: $bgc;
        border-color: $bdc;
        color: $fc;
      }
    }

    &--7, &--8 {
      background: linear-gradient(to right bottom, white, white 49%, #ffdae7 51%, #ffdae7);
      border: none;
    }

    &--8 {
      background: linear-gradient(to right, var(--color-black-20), var(--color-black-20) 49%, var(--color-success-4) 51%, var(--color-success-4));

    }

    &--7 {
      background: linear-gradient(to right, #F0F3F5, #F0F3F5 49%, #EAFFF8 51%, #EAFFF8);
    }

    &--10 {
      background: var(--color-success-4);
      border: 1px solid var(--color-success-2);
    }

    &--holiday, &--otpuskbs {
      color: white;
    }

    &--7:before, &--8:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      border-right: 1px solid var(--color-success-2);
      border-top: 1px solid var(--color-success-2);
      border-bottom: 1px solid var(--color-success-2);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &--7:after, &--8:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-left: 1px solid var(--color-black-40);
      border-top: 1px solid var(--color-black-40);
      border-bottom: 1px solid var(--color-black-40);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &--later {
      border-left: 3px solid var(--color-danger-1);
    }

    &--before {
      border-right: 3px solid var(--color-danger-1);
    }

    small {
      font-size: 8px;
    }
  }

  &__dayTooltip {
    --position-right: 0;
    --position-left: 0;
    --arrow-position-left: calc(50% - 7px);
    --arrow-position-right: auto;

    background: white;
    border: 1px solid var(--color-black-40);
    border-radius: 5px;
    color: #4d5055;
    font-size: 12px;
    line-height: 1.5;
    opacity: 0;
    padding: 15px;
    position: absolute;
    top: 60px;
    transform: scaleY(0);
    transform-origin: center top;
    transition: transform .3s;
    white-space: nowrap;
    z-index: 1000;

    &Right {
      right: var(--position-right);
    }

    &Left {
      left: var(--position-left);
    }

    &__header {
      margin-bottom: 20px;
      b {
        font-weight: 500;
        font-size: 12px;
        color:var(--color-black-100);
      }
      span {
        font-weight: 500;
        margin-bottom: 20px;
        color:var(--color-black-100);
      }
    }

    &::before {
      background-color: white;
      border-top: 1px solid var(--color-black-40);
      border-left: 1px solid var(--color-black-40);
      content: "";
      display: block;
      height: 10px;
      left: var(--arrow-position-left);
      right: var(--arrow-position-right);
      position: absolute;
      top: -6px;
      transform: rotate(45deg);
      width: 10px;
    }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      content: "";
      display: block;
      height: 13px;
      top: -13px;
      transform: scale(0);
    }

    div {
      text-align: center;
    }


    &FlexRow {
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 400;
        font-size: 10px;
        color: #2D3436;
      }
    }

    & &Red {
      color: var(--color-danger-1);
    }
  }

  &__mh {
    height: 20px;
  }

  &__daysExplain {
    margin-top: 20px;
  }
}

.dayInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: var(--color-black-80);

  small {
    font-weight: 400;
  }
}
