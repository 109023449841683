.page {
  display: grid;
  font-family: 'Manrope', sans-serif;
  min-height: 100vh;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}
.content {
  padding: 30px 20px;
  @media (min-width: 1024px) {
    padding: 80px 20px 40px;
  }
}
.authBox {
  margin: 0 auto;
  @media (min-width: 768px) {
    max-width: 360px;
  }
}
.heading1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.48px;
  margin-top: 84px;
  color: var(--color-black-100);
  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 1.5;
    letter-spacing: -0.56px;
  }
  @media (min-width: 1440px) {
    font-size: 42px;
    line-height: 48px;
    letter-spacing: -0.84px;
  }
}
.heading1Loading {
  span {
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-name: authEmojiSwing;
    display: inline-block;
    transform-origin: bottom center;
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 46px;
}
.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .container {
    position: relative;
    .eye {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }
  }
}
.label {
  color: var(--color-black-100);
  font-size: 14px;
  font-weight: 600;
}
.labelRequired {
  composes: label;
  &::before {
    color: var(--color-danger-2);
    content: "*";
    margin-right: 4px;
  }
}
.input {
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  padding: 15px 12px;
  width: 100%;
  background: var(--color-black-0);
  font-family: inherit;

  &:hover {
    box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
    border: 1px solid var(--color-black-40);
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
    border: 1px solid var(--color-primary-2);
  }

  &Error {
    box-shadow: 0 0 0 2px rgba(173, 0, 0, 0.25);
    border: 1px solid var(--color-danger-1);
  }
}
.submit {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    background-color: var(--color-primary-1);
    border: 1px solid var(--color-primary-1);
    border-radius: 7px;
    color: var(--color-black-0);
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 16px;
    padding: 16px 38px;
    transition: 0.2s;
    width: 100%;
    &:hover {
      background-color: var(--color-primary-2);
    }
  }
}
.haveAccount {
  text-align: left;
  p {
    font-size: 12px;
    font-weight: 700;
    color: var(--color-black-80);
    a {
      color: var(--color-primary-1);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@keyframes authEmojiSwing {
  from {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(30deg);
  }
  to {
    transform: rotate(0);
  }
}

