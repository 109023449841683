.container {
  height: 300px;
  display: grid;
  position: relative;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  gap: 1px;
  .item {
    cursor: pointer;
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    display: flex;
    &.hovered {
      background-color: var(--color-primary-3);
    }
  }
}