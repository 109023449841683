.player-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px;
  background-color: red;

  &__tip {
    font-size: 12px;
  }
}

.player-box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #3f4d63;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .3s ease-in;
    color: #fff;
    font-size: 40px;
    font-weight: 600;

    &:hover {
      opacity: 0.8;
      transition: all .3s ease-in;
    }
  }
}

.video-player-box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
