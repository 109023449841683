.select-date-birth {
  align-items: stretch;
  display: flex;
  font-size: 14px;
  height: 45px;
  justify-content: space-between;

  select {
    -webkit-appearance: none;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAVUlEQVQImW3NsQ1FABRG4Y9obWIQCaUBDKBViv7t8yRmUJtEoZWbkCBOdW7On9yk64cRBTZPcqwZdjSveLGk+KH6iHW0GAQTylsM/4fEi4sZ7XmEgwNLlgt6xLRGiQAAAABJRU5ErkJggg==") no-repeat center right 10px #fff;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding: 0 20px 0 10px;

    &:first-child {
      margin-right: 10px;
      min-width: 1px;
      width: 25%;
    }

    &:nth-child(2){
      width: 50%;
    }

    &:last-child {
      margin-left: 10px;
      min-width: 70px;
      width: 25%;
    }
    &:hover{
      border: 1px solid #0062ba;
      box-shadow: 0 0 3px 3px #b8d4ff;
      transition: .3s;
    }

  }

  option:not(:disabled){
    color: #000;
  }

  input {
    border: none;
    padding-left: 20px;
    width: 100%;
  }

  
}
.error select{
  border: 1px solid red !important;
}