.employeeContracts {
  background: var(--color-black-0);
  border-radius: 7px;
  border: 1px solid var(--color-black-40);
}
.emptyContracts{
  display: block;
  text-align: center;
}
.footer {
  height: 20px;
}
