.container {
  padding: 0 10px;

  @media (min-width:768px) {
    padding: 0 20px;
  }
}
.booksContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.empty {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
}

.loadMoreBtn {
  display: block;
  margin: 15px auto 0 auto;
  color: var(--color-black-60);
  min-width: 300px;
  border: 1px solid var(--color-black-60);
  border-radius: 7px;
  height: 48px;
  &:hover {
    color: var(--color-primary-3);
    border: 1px solid var(--color-primary-3);
    background: var(--color-black-0);
  }
}
