.column {
  background-color: #f6f6f6;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    color: var(--color-text);
    font-size: 14px;
    font-weight: 600;
  }

  &>div {
    line-height: .5;
  }
}

.error {
  border: 1px solid var(--color-danger-1);
  box-shadow: 0 0 0 2px rgba(173, 0, 0, 0.25);
}