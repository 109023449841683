.essay {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.text {
  display: flex;
  flex-direction: column;
  gap: .5em;
  textarea {
    font-family: inherit;
  }
}
.textLength {
  align-self: flex-end;
  font-size: 12px;
}
.fileUploader {
  align-items: center;
  border: 2px dashed var(--color-outline);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.chooseFile {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 1em;
  text-align: center;

  button {
    max-width: 250px;
    width: 100%;
  }
}
.button {
  background: white;
  border: 2px solid #3f4d63;
  border-radius: 5px;
  color: #3f4d63;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  transition: .3s;
}
.files {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.file {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: .5em;
}
.fileIcon {
  position: relative;
  span {
    bottom: 6px;
    color: white;
    font-size: 8px;
    left: 12px;
    position: absolute;
    text-transform: uppercase;
  }
}
.fileName {
  color: var(--color-text);
  font-size: 12px;
  font-weight: 500;
}
.fileDelete {
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;

  &::before, &::after {
    background-color: black;
    border-radius: 1px;
    content: "";
    position: absolute;
  }
  &::before {
    left: 0;
    height: 2px;
    transform: translateY(-1px) rotate(45deg);;
    top: 50%;
    width: 100%;
  }
  &::after {
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-1px) rotate(45deg);
    width: 2px;
  }
}
