.group-filter {
  background-color: #fff;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 0 5px 8px -4px rgba(50, 50, 50, 0.5);

  &__levels {
    align-items: stretch;
    background-color: #bdbdbd;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 1px;
    overflow: hidden;
    @media (min-width: 768px){
      flex-direction: row;
    }
  }

  &__level {
    background-color: #fff;
    color: #3f4d63;
    cursor: pointer;
    font-size: 30px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 100%;
    @media (min-width: 768px){
      line-height: 75px;
      height: 75px;
      width: calc(100% / 2);
    }
    &--selected {
      background-color: #3f4d63;
      color: #fff;
    }
  }

  &__selectors {
    display: grid;
    gap: 20px;
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__selectorUnit {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font-weight: 700;
    }

    &Double {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
    &Triple {
      display: grid;
      gap: 20px;
      grid-template-columns: 5fr 5fr 1fr;
    }
  }

  &__days {
    width: 100%;

    .dow-box {
      margin: 0;
    }
  }

  &__line {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px){
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    .filter-box__buttons {

    }
  }
}

.user-group-listing-unit {
  background-color: #fff;
  margin: 20px;
  min-height: 80px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.user-group-listing-unit-info-header {
  display: flex;
}

.user-group-listing-unit-info-header span {
  color: #3f4d63;
  font-size: 16px;
  min-height: 30px;
}

.user-group-listing-unit-info-header span:first-child {
  font-weight: 600 !important;
}

.user-group-listing-unit-info-content span:first-child {
  color: #888888;
  margin-right: 20px;
}

.user-group-listing-unit-info-content span {
  color: #000;
}

.user-group-listing-unit-button button {
  padding-left: 70px;
  padding-right: 70px;
  text-transform: uppercase;
  @media (max-width: 414px) {
    margin-top: 15px;
  }
}