.avatarContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 5px;
}

.avatarContent img{
    width: 100px;
    height: 100px;
}