.contentHeader {
  align-items: center;
  //background-color: #eff4f8;
  //border-top: 3px solid #ededed;
  display: flex;
  height: 88px;
  padding: 1em 15px;
  position: relative;
}
.left {
  display: flex;
  flex-direction: column;
}
.title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .userID {
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.28px;
  }

  h1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.48px;
    color: var(--color-black-100);
    @media (min-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 1440px) {
      font-size: 24px;
    }
  }
}
.actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: auto;

  button {
    align-items: center;
    background-color: var(--color-black-0);
    border: 1px solid var(--color-primary-3);
    border-radius: 7px;
    color: var(--color-primary-3);
    display: flex;
    font-family: inherit;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    line-height: 16px;
    letter-spacing: .42px;
    padding: 7px;
    @media (min-width: 768px){
      padding: 7px 15px;
    }

    &:hover {
      background-color: var(--color-black-0);
      border-color: var(--color-primary-2);
      box-shadow: inset 0 0 0 1px var(--color-primary-2);
      color: var(--color-primary-2);
    }

    svg {
      height: 22px;
      width: 22px;
    }

    span {
      display: none;
      @media (min-width: 768px){
        display: inline-block;
      }
    }
  }
}
.linkButton {
  a {
    align-items: center;
    background-color: var(--color-black-0);
    border: 1px solid var(--color-primary-3);
    border-radius: 7px;
    color: var(--color-primary-3);
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    line-height: 16px;
    letter-spacing: .42px;
    padding: 7px 15px;
    transition: .3s;
    white-space: nowrap;

    &:hover {
      background-color: var(--color-black-0);
      border-color: var(--color-primary-2);
      box-shadow: inset 0 0 0 1px var(--color-primary-2);
      color: var(--color-primary-2);
    }
  }
}
.actionButton {
  align-items: center;
  background-color: var(--color-black-0);
  border: 1px solid var(--color-primary-3);
  border-radius: 7px;
  color: var(--color-primary-3);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  line-height: 16px;
  letter-spacing: .42px;
  padding: 7px 15px;
  transition: .3s;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-black-0);
    border-color: var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
    color: var(--color-primary-2);
  }
}
// Группы: Статусы
.groupStatus {
  align-items: center;
  background-color: var(--color-success-4);
  border: 1px dashed var(--color-success-1);
  border-radius: 6px;
  color: var(--color-success-1);
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  gap: 5px;
  line-height: 16px;
  letter-spacing: .24px;
  text-transform: uppercase;
  padding: 10px;
  transition: .3s;
  white-space: nowrap;
  &:hover {
    border-style: solid;
    box-shadow: inset 0 0 0 1px var(--color-success-1);
  }

  &--2, &--8 {
    background-color: var(--color-secondary-4);
    border-color: var(--color-secondary-1);
    color: var(--color-secondary-1);
    &:hover {
      box-shadow: inset 0 0 0 1px var(--color-secondary-1);
    }
  }
  &--4, &--5 {
    background-color: var(--color-warning-4);
    border-color: var(--color-warning-1);
    color: var(--color-warning-1);
    &:hover {
      box-shadow: inset 0 0 0 1px var(--color-warning-1);
    }
  }
  &--3, &--6 {
    background-color: var(--color-danger-4);
    border-color: var(--color-danger-1);
    color: var(--color-danger-1);
    &:hover {
      box-shadow: inset 0 0 0 1px var(--color-danger-1);
    }
  }
  &--7 {
    background-color: var(--color-black-20);
    border-color: var(--color-black-80);
    color: var(--color-black-80);
    &:hover {
      box-shadow: inset 0 0 0 1px var(--color-black-80);
    }
  }

  span {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
}
