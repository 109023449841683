.teacherRow {
  display: grid;
  gap: 10px;
  padding: 10px 0;

  @media (min-width: 768px) {
    align-items: flex-start;
    grid-template-columns: 1fr 5fr;
  }

  & &__teachers {
    display: flex;
    flex-direction: column;
    gap: .5em;

    a {
      font-size: 14px;
      outline: none;
      color: var(--color-black-100);
      font-weight: 700;
      letter-spacing: 0.28px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.teacherInfo {
  display: flex;
  gap: 10px;
  align-items: center;
}
.hoursType {
  cursor: pointer;
}
.schedules {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.scheduleActions {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
}
.scheduleGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: var(--color-black-80);
  letter-spacing: 0.28px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }
}
