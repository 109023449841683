.locationForm {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    &__field {
        max-width: 230px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        @media (max-width: 767px) {
            max-width: 100%;
            margin-bottom: 20px;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }
        input {
            margin: 0;
        }
    }
}
