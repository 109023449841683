.action-menu {
  //width: 50px;
  position: relative;

  &__button {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 26px;
    justify-content: center;
    transition: all .3s ease;
    width: 26px;

    &--rotate {
      transform: rotate(90deg);
    }

    &::before, &::after {
      content: '';
    }

    span, &::before, &::after {
      border: 1px solid black;
      border-radius: 50%;
      display: block;
      height: 6px;
      margin: 1px;
      width: 6px;
    }

    &--img, &--svg {
      &::before, &::after {
        content: none;
      }
    }
  }

  &__modal {
    margin-top: 10px;
    overflow: hidden;
    position: absolute;
    right: 0;
    transition: width .3s;
    z-index: 5;

    &--close {
      //max-height: 0;
      width: 0;
    }

    &--open {
      //max-height: 250px;
      width: 210px;
    }
  }

  &__menu {
    background-color: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    a {
      outline: none !important;
    }

    div, a {
      color: #5f6368;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      padding-left: 10px;
      width: 208px;

      &:hover {
        background-color: #ededed;
      }
    }
  }
}
