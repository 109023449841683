.filter-box-client{
    margin-top: 20px;
    position: relative;
}
.filter-box-client input{
    padding: 13px 15px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    font-size: 14px;
}
.filter-box-client input:hover {
    border: 1px solid #bdbdbd;
    box-shadow: 0 0 0 3px #b8d4ff;
    transition: .3s;
}
.filter-box-client input:focus {
    border: 1px solid #0062ba;
    box-shadow: 0 0 0 3px #b8d4ff;
}
.clients-list{
    width: 500px;
    background-color: #fff;
    padding: 10px 0;
    border-left:  1px solid #bdbdbd;
    border-right:  1px solid #bdbdbd;
    border-bottom:  1px solid #bdbdbd;
    border-radius: 0 0 5px 5px;
    position: absolute;
    z-index: 1009;
    max-height: 300px;
    height: 270px;
    overflow: auto;
}

.clients-list-item{
    padding: 15px 15px;
    cursor: pointer;
    transition: all .2s ease;
}

.clients-list-item:hover{
   background-color: #007be8;
   color: #fff;
   transition: all .2s ease;
}
.clients-list-item span{
    text-decoration-line: underline;
}