$border-color: #bdbdbd;

.input-phone {
  align-items: stretch;
  border: 1px solid $border-color;
  border-radius: 5px;
  color: #5f6368;
  display: flex;
  font-size: 14px;
  height: 45px;
  position: relative;
  background-color: #ebebeb;

  &:hover{
    border: 1px solid $border-color;
    box-shadow: 0 0 0 3px #b8d4ff;
    transition: .3s;
  }

  &__current {
    align-items: center;
    border-right: 1px solid $border-color;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    width: 76px;

    &--disabled {
      background-color: #e2e2e2;
    }

    span {
      text-align: center;
      width: 100%;
    }

    &::after {
      content: "";
      border: 4px solid transparent;
      border-top-color: #5f6368;
      display: block;
      height: 8px;
      margin-right: 10px;
      width: 8px;
    }
  }

  input {
    border: none !important;
    height: 100% !important;
    padding-left: 20px;
    width: 100%;
    flex-grow: 1;
    box-shadow: 0 0 0 0 !important;
  }
  //input:focus{
  //  border: 1px solid #006 !important;
  //  box-shadow: 0 0 0 3px #bb5588 !important;
  //}

  &__dropdown {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 5px;
    left: 0;
    padding: 6px 0;
    position: absolute;
    top: 50px;
    width: 76px;
    z-index: 1;

    li {
      cursor: pointer;
      line-height: 30px;
      padding-left: 20px;
      &:hover {
        background-color: #ededed;
      }
    }
  }
}
.input-phoneNew {
  align-items: stretch;
  border-radius: 5px;
  color: #5f6368;
  display: flex;
  font-size: 14px;
  height: 45px;
  position: relative;
  background-color: #ebebeb;
  border: 1px solid #0062ba;
  box-shadow: 0 0 0 3px #b8d4ff;


  &__current {
    align-items: center;
    border-right: 1px solid $border-color;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    width: 76px;

    span {
      text-align: center;
      width: 100%;
    }

    &::after {
      content: "";
      border: 4px solid transparent;
      border-top-color: #5f6368;
      display: block;
      height: 8px;
      margin-right: 10px;
      width: 8px;
    }
  }

  input {
    border: none !important;
    height: 100% !important;
    padding-left: 20px;
    width: 100%;
    flex-grow: 1;
    box-shadow: 0 0 0 0 !important;
  }
  //input:focus{
  //  border: 1px solid #006 !important;
  //  box-shadow: 0 0 0 3px #bb5588 !important;
  //}

  &__dropdown {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 5px;
    left: 0;
    padding: 6px 0;
    position: absolute;
    top: 50px;
    width: 76px;
    z-index: 1;

    li {
      cursor: pointer;
      line-height: 30px;
      padding-left: 20px;
      &:hover {
        background-color: #ededed;
      }
    }
  }
}