.checkPayService {
  display: flex;
  flex-direction: column;
  padding: 30px 0;

  &__header {
    border-bottom: 1px solid #bdbdbd;
    color: #5f6368;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 30px;
  }

  &__order {
    align-items: center;
    display: flex;
    flex-direction: column;

    span {
      font-size: 26px;
      line-height: 1.5;

      &:nth-child(2){
        font-weight: 700;
      }

      &:last-child {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__note {
    font-size: 14px;

    &Title {
      font-weight: 600;
    }

    &List {
      display: flex;
      flex-direction: column;
      line-height: 24px;
      margin-top: .5em;
      padding-left: 20px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    color: #5f6368;
    padding: 30px;

    div {
      min-width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__but {
    display: flex;
    flex-direction: row !important;

    button {
      font-family: inherit;
      font-weight: 500;
      &:last-child {
        margin-left: 10px;
        border-color: #9b0b31;
        color: #9b0b31;

        &:hover {
          background-color: #ffdae7;
        }
      }
    }

    &--red {
      color: red;
      font-weight: 600;
      font-size: 18px;
    }

    &--green {
      padding-top: 20px;
      //padding-bottom: 20px;
      text-align: center;
      color: #289f44;
      font-weight: 600;
      font-size: 20px;
    }
  }

  &__timer {
    span:first-child {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 10px;
    }
  }
}
