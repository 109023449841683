.wrapper {
  position: relative;
  align-self: stretch;
}

.label {
  position: relative;
  display: inline-block;
  margin-bottom: 7px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text);
}

.required::before {
  content: '* ';
  color: var(--color-error);
}

.input {
  width: 100%;
  height: 44px;
  padding-left: 20px;
  color: var(--color-text);
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  transition: border 0.3s;
  appearance: none;

  &:hover {
    box-shadow: 0 0 0 3px #b8d4ff;
  }

  &:focus {
    border: 1px solid #0062ba;
    box-shadow: 0 0 1px 3px #b8d4ff;
  }

  &.error {
    border: 1px solid var(--color-error);
    box-shadow: 0 0 1px 3px #ffdae7;
  }
  &:disabled {
    background-color: #f2f2f2;

    &:hover {
      box-shadow: none;
    }
  }
}

.error_text {
  position: absolute;
  bottom: -15px;
  right: 0;
  font-size: 10px;
  color: var(--color-error);
}
