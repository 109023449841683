.dragWords {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.words {
  align-items: center;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  display: flex;
  gap: 20px;
  justify-content: center;
  min-height: 75px;
  flex-wrap: wrap;
  padding: 10px;

  div {
    border: 1px solid var(--color-outline);
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.5;
    padding: 0 1em;
    user-select: none;
    &:hover {
      background-color: var(--color-accent-600);
      border-color: var(--color-accent-600);
      color: white;
    }
  }
}
.text {
  color: var(--color-text);
  font-size: 16px;
  line-height: 2.5;

  input {
    border: 1px solid var(--color-outline);
    border-radius: 3px;
    cursor: pointer;
    font-family: inherit;
    height: 35px;
    padding: 0 10px;
    text-align: center;
    width: 130px;
  }
}
