.wrap {
  position: relative;
}
.input {
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  height: 45px;
  padding: 0 20px;
  width: 100%;
}
.inputPopup {
  composes: input;
  border-radius: 5px 5px 0 0;
}
.popup {
  background-color: #fff;
  border: 1px solid var(--color-outline);
  border-radius: 0 0 5px 5px;
  position: absolute;
  width: 100%;
}
.row {
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 1em 20px;
  &:hover {
    background-color: var(--color-accent-600);
    color: white;
  }
}
