.clientGroupInfo {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 15px;

  }

  &__mainBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.addBalanceBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 5px 10px;
  color: var(--color-black-0);
  border: 1px solid var(--color-success-1);
  background: var(--color-success-1);
  border-radius: 7px;

  &:hover {
    border: 1px solid var(--color-success-1);
    background: var(--color-success-1);
  }

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }

  span {
    font-size: 16px;
    font-weight: 800;
  }

  svg {
    fill: var(--color-success-1);
  }
}

