.days {
  display: grid;
  flex-wrap: wrap;
  gap: 2px;
  grid-template-columns: repeat(8, 1fr);
}
.day {
  align-items: center;
  border: 1px solid #919396;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 28px;
  justify-content: center;
  transition: .3s;
  user-select: none;
}
.dayGood {
  background-color: #45b167;
}
.dayBad {
  background-color: var(--color-error);
}
