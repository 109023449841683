.wrapper {
  width: 100%;
  margin: 0;
  padding: 20px;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  background-color: #fff;
  font-family: inherit;
  font-size: inherit;
}
