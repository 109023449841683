@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

:root {
  --color-accent: #007be8;
  --color-accent-600: #3474ff;
  --color-accent-light: #5a8dee;
  --color-text: #5f6368;
  --color-text-accent: #3f4d63;
  --color-outline: #bdbdbd;
  --color-error: #db3031;
  --color-error-light: #ffdae7;

  --color-primary-1: #254b77;
  --color-primary-2: #4076b4;
  --color-primary-3: #55b1f3;
  --color-primary-4: #ebfafa;
	--color-primary-5: #4094f7;

  --color-danger-1: #ff0022;
  --color-danger-2: #ff455e;
  --color-danger-4: #ffe7ea;

  --color-success-1: #1abc97;
  --color-success-2: #00f7bf;
  --color-success-4: #eafff8;

  --color-warning-1: #ffc700;
  --color-warning-4: #fff9e9;

  --color-black-0: #fff;
  --color-black-10: #f7fafc;
  --color-black-20: #f0f3f5;
  --color-black-40: #dfe6e9;
  --color-black-60: #b2bec3;
  --color-black-80: #636e72;
  --color-black-100: #2d3436;

  --color-info-3: #97f4ff;
  --color-info-4: #eafcff;

  --color-secondary-1: #c903ff;
  --color-secondary-4: #fdeffc;

  --focus-box-shadow: 0 0 10px rgba(10, 175, 255, 0.35);

}


body, input, select {
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Manrope', sans-serif;
}
body {
  margin: 0;

  font-size: 14px;
  font-weight: 400;
}
b {
  font-weight: bold;
}
i {
  font-style: italic;
}
a,
a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
}
.wrapper_soft{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #e0e7ef;
  min-width: 320px;
  max-width: 1170px;
}

.bold {
  font-weight: 600;
  font-size: 14px;
}

strong{
  font-weight: 600;
}

input::placeholder {
  color: #959595;
  opacity: 1;
}

h1 {
  color: #3f4d63;
  font-weight: 600;
}

textarea::placeholder {
  color: #959595;
  opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  z-index: 1000;
}

.checkbox:hover {
  background: #9faab7;
}

.checkbox:checked {
  background: #3f4d63;
}

.checkbox:checked::before {
  height: 18px;
  width: 18px;
  position: absolute;
  content: '\2713';
  display: inline-block;
  font-size: 13px;
  text-align: center;
}

.checkbox:checked::after {
  background: #3f4d63;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}

input:focus{
  outline: none;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}


.footer{
  height: 200px;
}
.forbidden{
  margin: 30% auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  font-weight: 600;
  color: #3f4d63;
  font-size: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
