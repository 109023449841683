.groups {
    background: var(--color-black-0);
    border-radius: 7px;
    border: solid 1px var(--color-black-40);
    font-family: "Manrope", sans-serif;

    &__link {
        border-radius: 5px;
        border: 1px solid var(--color-black-40);
        padding: 5px;
    }
    &__statuses {
        padding: 10px 22px;
        border-bottom: 1px solid var(--color-black-40);
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 10px;
    }
    &__list {
        padding: 11px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (min-width: 1920px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
    .in_progress {
        border: 1px solid #1abc97;
        color: #1abc97;
        background: rgba(26, 188, 151, 0.1);

        path {
            fill: #1abc97;
        }
    }
    .scheduled {
        background: rgba(64, 118, 180, 0.1);
        border: solid 1px var(--color-primary-2);
        color: var(--color-primary-1);

        path {
            fill: var(--color-primary-1);
        }
    }
    .formation {
        color: var(--color-warning-1);
        border: solid 1px var(--color-warning-1);
        background: rgba(255, 199, 0, 0.1);

        path {
            fill: var(--color-warning-1);
        }
    }
    .finished {
        background: rgba(255, 69, 94, 0.1);
        border: solid 1px var(--color-danger-2);
        color: var(--color-danger-1);

        path {
            fill: var(--color-danger-1);
        }
    }
}
