.header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
    width: 100%;
    height: 61px;
    z-index: 900;
    background: var(--color-black-0);
    font-family: "Manrope", sans-serif;

    &__notification {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: 768px) {
            right: 2%;
            transform: translateX(0);
            left: auto;
        }
    }

    &__logo {
        width: 115px;
        height: 24px;

        @media (min-width: 768px) {
            width: 144px;
            height: 30px;
        }
    }

    &Navbar {
        display: flex;
        align-items: center;
        gap: 24px;
        position: relative;

        &__popup {
            position: fixed;
            top: 0;
            left: 0;
            background: none;
            width: 100%;
            height: 100%;
            z-index: 900;
            display: none;

            &_active {
                display: block;
            }
        }

        &__search {
            width: 24px;
            height: 24px;
            cursor: pointer;
            color: var(--color-black-60);
        }

        &__notification {
            width: 24px;
            height: 24px;
            cursor: pointer;
            position: relative;
            color: var(--color-black-60);

            svg {
                width: 100%;
                height: 100%;
            }

            &_active::before {
                position: absolute;
                top: 0;
                right: -3px;
                content: "";
                background: var(--color-primary-1);
                border-radius: 100%;
                width: 8px;
                height: 8px;
            }
        }

        &__avatar {
            width: 40px;
            height: 40px;
            cursor: pointer;
        }

        &__menu {
            position: absolute;
            top: calc(100% + 14px);
            right: 0;
        }
    }
}
