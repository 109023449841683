.select_wrapper {
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
}

.select {
  width: 100%;

  @media (min-width: 1024px) {
    width: 400px;
  }
}

.checkbox {
  width: 100%;

  @media (min-width: 1024px) {
    width: auto;
  }
}

.subtitle {
  margin: 20px 0;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-accent);
}

.comment {
  height: 0;
  overflow-y: hidden;
  transition: height 0.3s;
}
