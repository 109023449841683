.groupSelector {
  &__item {
    width: 100%;
    border: solid 1px #bdbdbd;
    min-height: 50px;
    border-radius: 5px;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    &--active,
    &:hover {
      background: #f1f8ff;
      .directionPaymentPoint {
        border: solid 5px #3f4d63;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &__title {
    color: #5f6368;
    font-weight: 600;
    margin-bottom: 7px;
  }
  &__text {
    color: #5f6368;
    font-weight: 600;
  }
  &__subtitle {
    color: #5f6368;
    font-size: 12px;
  }
  &Point {
    min-width: 16px;
    height: 16px;
    border-radius: 50px;
    border: solid 1px #bdbdbd;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    &--active,
    &:hover {
      border: solid 5px #3f4d63;
    }
  }
}
