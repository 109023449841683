@import "../MainStyles";

.answerBody {
  @include answerBodyMain;
  padding: 20px 10px 10px 10px;
}

.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}


.correctColor {
  @include correctColorMain;
}

.container {
  max-width: 837px;
  width: 100%;
  border-radius: 6px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;

  .imgSize {
    border-radius: 6px;
    width: 100%;
    overflow: auto;

    @media (min-width: 1024px) {
      max-width: 300px;
    }
  }
}

.line {
  width: 35px;
  height: 2px;
  @media (min-width: 768px) {
    width: 40px;
  }
  @media (min-width: 1024px) {
    width: 57px;
  }
}

.rightAnswer {
  border: 1px solid var(--color-success-1);
  background-color: var(--color-success-4);
}

.wrongAnswer {
  border: 1px solid var(--color-danger-1);
  background-color: var(--color-danger-4);
}