.container {
  background: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 900px;
  width: 100%;
}

.review {
  width: 100%;

  .reviewBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 10px 20px;
    border: 2px solid var(--color-primary-2);
    background: var(--color-primary-4);
    border-radius: 6px;
    color: var(--color-primary-2);
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.36px;

    svg {
      color: var(--color-black-0);
    }

    &:hover {
      cursor: pointer;
      color: var(--color-black-0);
      background: var(--color-primary-1);
      svg {
        fill: var(--color-primary-1);
      }
    }
  }
}

.delimiter {
  width: 100%;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;

  .line {
    width: 100%;
    height: 2px;
    background-color: var(--color-warning-1);
  }
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    border: 1px solid var(--color-warning-1);
    background: var(--color-warning-4);
    padding: 5px 15px;
    color: var(--color-black-100);
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.36px;
  }
}

.marks {
  width: 100%;
}