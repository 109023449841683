.moneyBox{
    display: flex;
    flex-direction: row;
    max-width: 135px;
    border-radius: 7px;
    border: 1px solid var(--color-black-40);
    height: 48px;
    overflow: hidden;
    input{
        text-align: center;
        border: none !important;
        width: 100%;
        color:  var(--color-black-60);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
}
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--color-black-40);
        background-color: var(--color-black-20);
        color: var(--color-black-80);
        min-width: 48px;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.36px;
    }
}