.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  :global(.dow-box) {
    margin: 0;
  }
  :global(.hour-counter) {
    width: 100%;
  }
}
.rowLocationColor {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.row {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.rowTeachersRooms {
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 1fr;
}
.teachers {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.teacher {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr auto auto;
}

.teacherAdd, .teacherDel {
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.teacherAdd {
  background-image: linear-gradient(#5a8dee, #5a8dee), linear-gradient(#5a8dee, #5a8dee);
  background-size: 100% 3px, 3px 100%;
}

.teacherDel {
  background-image: linear-gradient(black, black), linear-gradient(black, black);
  background-size: 100% 2px, 2px 100%;
  transform: rotate(45deg);
}
