@import "../MainStyles";

.variant {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--color-black-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.54px;
}

.unchecked {
  border-radius: 6px;
  border: 2px solid var(--color-black-20);
  background: var(--color-black-10);
}

.checkedRight {
  border-radius: 6px;
  border: 2px solid var(--color-success-1);
  background: var(--color-success-4);
}

.checkedWrong {
  border-radius: 6px;
  border: 2px solid var(--color-danger-1);
  background: var(--color-danger-4);
}

.answerBody {
  @include answerBodyMain;
  padding: 25px 10px 10px 10px;
}

.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.correctColor {
  @include correctColorMain;
}

.uncheckedCheckbox {
  width: 16px;
  height: 16px;
  border: 2px solid #DFE6E9;
  border-radius: 25%;
}

.checkedRightCheckbox {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 25%;
  background: var(--color-success-1);
  &:before {
    position: absolute;
    left: 5%;
    top: 40%;
    height: 33%;
    width: 1px;
    background-color: var(--color-success-4);
    content: "";
    transform: translateX(5px) rotate(-45deg);
    transform-origin: left bottom;
  }
  &:after {
    position: absolute;
    left: 0;
    bottom: 30%;
    height: 1px;
    width: 60%;
    background-color: var(--color-success-4);
    content: "";
    transform: translateX(6px) rotate(-45deg);
    transform-origin: left bottom;
  }
}

.checkedWrongCheckbox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 25%;
  background: var(--color-danger-1);
  &:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 9px;
    top: 48%;
    right: 21%;
    background: var(--color-danger-4);
  }
}