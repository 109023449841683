.wrapper {
  position: relative;
  width: 50%;
  min-height: 108px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: var(--color-outline);
  border-width: 0 1px 1px 0;
  cursor: pointer;
  user-select: none;
  background-color: white;
  transition: background-color 0.2s;

  &:nth-last-of-type(7) {
    border-bottom-left-radius: 5px;
  }
  &:last-of-type {
    border-bottom-right-radius: 5px;
  }

  @media (min-width: 1024px) {
    width: 14.2857%;
  }
}

.selected {
  background-color: #d3e5ff;
}

.today {
  .day_number {
    color: var(--color-text);
    text-transform: uppercase;
  }
}

.past,
.disabled {
  background-color: #f1f1f1;
  cursor: default;
}

.day_header {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #a8a8a8;
  vertical-align: center;
}

.day_number {
  flex-grow: 1;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  row-gap: 5px;
  font-size: 12px;
  text-align: center;
  overflow-wrap: break-word;
}

.data_booked {
  margin-top: 5px;
  display: block;

  span {
    display: block;
    margin-top: 5px;
    font-size: 18px;
    font-weight: 700;
  }
}

.data_warning {
  font-weight: 600;
  color: var(--color-error);
}

.data_yes {
  display: block;
  font-weight: 700;
  color: #00cc00;
}

.data_no {
  display: block;
  font-weight: 700;
  color: #9b0b31;
}
