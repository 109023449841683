.clientGroupInfo__timetable {
    .timetable-box {
        background-color: var(--color-black-0);
        border: 1px solid red;
        border-radius: 6px;
        display: flex;
        flex-direction: column;

        &__header {
            height: 52px;
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #bdbdbd;
            cursor: pointer;
            @media (min-width: 768px) {
                padding: 0 20px;
            }

            h3 {
                font-size: 16px;
                color: #3f4d63;
                font-weight: 700;
            }

            img {
                width: 20px;
            }
        }

        &__list {
            @media (min-width: 768px) {
                padding: 0 20px;
            }
        }
    }
    .time-table-unit-box {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;
        @media (min-width: 768px) {
            padding-left: 0;
        }

        &:not(:first-child) {
            border-top: 1px solid #bdbdbd;
        }

        &__left {
            flex-grow: 1;
            padding-right: 10px;
            @media (min-width: 1024px) {
                display: flex;
            }
        }

        &__teachers {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            min-width: 20%;
            @media (min-width: 1024px) {
                width: 25%;
            }
        }

        & &__teachers {
            a {
                outline: none;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }

        &__name {
            color: #3f4d63;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
        }

        &__date {
            margin-left: 20px;
            min-width: 15%;
        }
        &_row {
            display: flex;
            width: calc(100% / 4);
            line-height: 20px;
        }
        &__text {
            max-width: 40%;
            word-break: break-all;
        }
        &__classroom {
            text-align: right;
        }
        &__units {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5em;
            margin-top: 1em;
            color: #5f6368;
            @media (min-width: 768px) {
                font-size: 14px;
            }
            @media (min-width: 1024px) {
                flex-grow: 1;
                flex-wrap: nowrap;
                margin-top: 0;
            }

            div {
                width: calc((100% - 0.5em) / 2);
                @media (min-width: 1024px) {
                    width: 25%;
                }

                &:nth-child(3) {
                    order: 1;
                    @media (min-width: 1024px) {
                        order: 0;
                    }
                }
            }
        }

        &__hours-total {
            @media (min-width: 1024px) {
                text-align: right;
            }
        }
    }
}
