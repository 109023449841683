.container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  width: 100%;
  border: 1px dashed var(--color-success-1);
  border-radius: 6px;
  font-size: 18px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.36px;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.markContainer {
  position: relative;
  display: flex;
}

.mark {
  position: absolute;
  top: 15px;
  right: 0;
  color: var(--color-success-1);
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: 0.6px;
  width: 100%;
}


.markGreen {
  background: var(--color-success-4);
  border: 1px dashed var(--color-success-1);
  * {
    color: var(--color-success-1);
  }
}
.markYellow {
  background: var(--color-warning-4);
  border: 1px dashed var(--color-warning-1);
  * {
    color: var(--color-warning-1);
  }
}
.markRed {
  background: var(--color-danger-4);
  border: 1px dashed var(--color-danger-1);
  * {
    color: var(--color-danger-1);
  }
}