.container {
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 20px;
  }
}
.pageColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}
.searchFilterValues {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.groups {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  padding: 20px;
  background: var(--color-black-0);
}

.header {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  color: var(--color-primary-1);
  background-color: #fff;
  padding: 15px 20px;
  border: 1px dashed var(--color-warning-1);
  background: var(--color-warning-4);
  border-radius: 7px;
  font-size: 16px;
  @media (min-width: 768px) {
    align-items: center;
  }

  svg {
    color: var(--color-warning-1);
  }
}

.delimiter {
  display: none;
  
  @media (min-width: 768px) {
    display: block;
    background: var(--color-warning-1);
    height: 24px;
    width: 1px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.notFoundGroupBlock {
  margin-top: 20px;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid var(--color-black-40);
  background: var(--color-black-0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  gap: 20px;
  svg {
    max-width: 100%;
  }
}

.addGroupButton {
  display: block;
  margin: 0 auto;
  min-width: 100%;
  min-height: 48px;
  background: var(--color-primary-1);
  border-radius: 7px;
  color: var(--color-black-0);
  border: none;

  @media (min-width: 768px) {
    min-width: 270px;
  }

  &:hover {
    background: var(--color-primary-2);
  }
}