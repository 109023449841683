.slotsExplain {
    display: flex;
    @media (min-width: 768px){
        height: 75px;
    }

    &__icon {
        background-color: #ff9e00;
        border-radius: 5px 0 0 5px;
        color: white;
        display: flex;
        justify-content: center;
        padding-top: 20px;
        width: 75px;
        @media (min-width: 768px){
            align-items: center;
            padding-top: 0;
        }

        svg {
            fill: currentColor;
            display: block;
            height: 40px;
            width: 40px;
        }
    }

    &__items {
        background-color: white;
        border: 1px solid #bdbdbd;
        border-left: none;
        border-radius: 0 5px 5px 0;
        color: #4d5055;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: 12px;
        font-weight: 500;
        gap: 1em;
        padding: 20px;
        @media (min-width: 768px){
            flex-direction: row;
            font-size: 14px;
        }

        div {
            align-items: center;
            display: flex;
            @media (min-width: 768px){
                width: 22%;
            }

            &::before {
                border-style: solid;
                border-width: 1px;
                border-radius: 5px;
                content: "";
                display: block;
                flex-shrink: 0;
                height: 25px;
                margin-right: 10px;
                width: 25px;
                @media (min-width: 768px){
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }

    &__item {

        &::before {
            border-color: #3f4d63;
            background-color: #fff;
        }

        &--booked::before {
            background-color: #b1f5cf;
            border-color: #32b76c;
        }

        &--deleted::before {
            background-color: #ffdae7;
            border-color: #d80027;
        }

        &--hc::before {
            background-color: #fed76e;
            border-color: #ebc05f;
        }
    }
}
