.workTable {
  &__filter {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 350px 1fr;
    }

    .id-selector {
      display: grid;
      grid-template-columns: 1fr 38px 1fr;
      height: 320px;
    }
    .id-selector__block {
      height: 100%;
      width: unset;
    }
  }

  &__filterCol {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__dpInput {
    width: 100%;
    .react-datepicker-wrapper{
      width: 100%;
    }
    &Button{
      background-color: #fff;
      border: 1px solid #bdbdbd;
      border-radius: 5px;
      cursor: pointer;
      line-height: 43px;
      text-align: center;
      &:hover {
        background-color: #3474ff;
        color: #fff;
      }
    }
  }

  &__buttonsPlace {
    display: none;
    @media (min-width: 1024px) {
      display: block;
      height: 44px;
    }
  }

  &__filterButtons {
    display: flex;
    gap: 20px;
    @media (min-width: 1024px) {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 350px;
    }

    button {
      font-family: inherit;
      font-weight: 500;
      width: 100%;
      &:disabled {
        border: solid 2px #cac8c8;
        color: #cac8c8;
        pointer-events: none;
      }
    }
  }

  &__card {
    margin-top: 10px;
  }

  & &__clear {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
}