.questionImagesMatchText {
  &__images {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
  }

  &__unit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: scale(1);
    transition: .3s;
    width: calc((100% - 20px) / 2);
    @media (min-width: 768px) {
      width: calc((100% - 40px) / 3);
    }
    @media (min-width: 1024px) {
      width: calc((100% - 80px) / 5);
    }

    * {
      pointer-events: none;
    }

    div {
      background-color: white;
      border: 1px solid #c2c5ca;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      height: 35px;
      line-height: 31px;
      margin-top: 10px;
      text-align: center;
      transition: .3s;

      &:empty {
        background-color: #f9f9f9;
      }
    }

    &.onDragOver {
      div {
        background-color: #c9dcff;
      }
    }
  }

  img {
    border-radius: 5px;
    display: block;
    width: 100%;
  }
}