.monthlyPlan {
    border: 1px solid #bdbdbd   ;
    background: white;
    color: #5f6368;
    border-radius: 5px;
    width: 100%;
    padding: 30px;
    &__title{
        font-size: 20px;
        margin-bottom: 20px;
        color: #3f4d63;
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__card {
        width: 25%;
        border: 1px solid #bdbdbd;
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        @media (max-width: 768px) {
            min-width: 270px;
        }
    }
    &__bold{
        font-size: 20px;
        font-weight: bold;
        color: #3f4d63;
        margin-top: 10px;
    }
}