.card {
    border-radius: 5px;
    width: 250px;
    height: 85px;
    border: solid 1px #bdbdbd;
    background-color: white;
    padding: 10px 15px;
    position: relative;
    @media (max-width: 767px) {
        width: 100%;
    }

    & .group {
        text-align: center;
        font-weight: 500;
        color: #3f4d63;
        font-size: 12px;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.flag {
    position: absolute;
    top: 0;
    left: 10px;
    width: 40px;
    height: 30px;
    border-bottom: none;
    text-align: center;
    background: white;
    z-index: 1;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
    &::after {
        position: absolute;
        content: "";
        width: 40px;
        height: 3px;
        background: #3c495e;
        top: -3px;
        left: 0;
    }
    .triangle {
        position: absolute;
        bottom: -11px;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 20px 0 20px;
        border-color: white transparent transparent transparent;
        filter: drop-shadow(1px 4px 2px rgba(0, 0, 0, 0.5));
    }
    p {
        &:nth-child(1) {
            color: #3f4d63;
            font-weight: 500;
            margin-top: 3px;
            font-size: 12px;
        }
        &:nth-child(2) {
            font-size: 10px;
            font-weight: 500;
        }
    }
}
.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.description {
    padding-left: 45px;
    p {
        color: #727272;
        font-size: 12px;
        &:nth-child(1) {
            font-size: 14px;
            color: #3f4d63;
            font-weight: 500;
        }
    }
}
