.sexSelector {
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  overflow: hidden;

  ul {
    background-color: var(--color-black-40);
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;
  }
}
.option {
  background-color: var(--color-black-0);
  cursor: pointer;
  line-height: 46px;
  text-align: center;
  transition: all .2s;
}
.optionSelected {
  composes: option;
  background-color: var(--color-primary-2);
  color: var(--color-black-0);
}
