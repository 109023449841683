.avatar {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;

    &__name {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-black-0);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.32px;
    }

    img {
        height: 100%;
        width: 100%;
    }
}