.days {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
  padding: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(9, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(13, 1fr);
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(20, 1fr);
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(28, 1fr);
  }
}
.day {
  align-items: center;
  border: 1px solid var(--color-black-60);
  color: var(--color-black-100);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  min-height: 30px;
  justify-content: center;
  position: relative;
  user-select: none;

  &>span {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &:hover .tooltip {
    display: block;
  }
}
.dayStatus2,
.dayStatus3,
.dayStatus6,
.dayStatus7,
.dayStatus8,
.dayCreditor,
.dayCreditorHalf,
.dayDebtor,
.dayDebtorHalf,
.selectedDay {
  color: var(--color-black-100);
}

.dayStatus1,
.dayStatus4,
.dayStatus5,
.dayStatus9,
.dayStatus10,
.dayStatus11,
.dayHoliday {
  color: var(--color-black-0);
}
.dayStatus1 {
  background-color: var(--color-secondary-1);
  border: var(--color-secondary-1);;
}
.dayStatus2 {
  background-color: var(--color-warning-1);
  border: var(--color-warning-1);
}
.dayStatus3 {
  background-color: var(--color-primary-3);
  border: var(--color-primary-3);
}
.dayStatus4 {
  background-color: var(--color-secondary-1);
  border: var(--color-secondary-1);
}
.dayStatus5, .dayStatus9, .dayStatus10, .dayStatus11, .dayHoliday {
  background-color: var(--color-black-80);
  border: var(--color-black-80);
}
.dayStatus6 {
  background-color: #eac459;
}
.dayStatus7 {
  background-color: var(--color-black-60);
}
.dayStatus8 {
  background-color: var(--color-warning-1);
  border: var(--color-warning-1);
}
.dayCreditor {
  background-color: var(--color-danger-1);
}
.dayCreditorHalf {
  background: linear-gradient(to left, var(--color-danger-1) 50%, var(--color-black-60) 50%);
}
.dayDebtor {
  background-color: var(--color-success-1);
  border: var(--color-success-1);
}
.dayDebtorHalf {
  background: linear-gradient(to left, var(--color-black-0) 50%, var(--color-success-1) 50%);
}
.selectedDay {
  background-color: var(--color-accent);
}

.tooltip {
  background-color: var(--color-primary-4);
  border: 1px solid var(--color-primary-2);
  border-radius: 6px;
  color: var(--color-text);
  display: none;
  padding: 10px;
  position: absolute;
  top: 38px;
  z-index: 1000;
  min-width: 300px;

  &::before {
    background-color: var(--color-primary-4);
    border-top: 1px solid var(--color-primary-2);
    border-left: 1px solid var(--color-primary-2);
    content: "";
    display: block;
    height: 10px;
    left: calc(50% - 7px);
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    width: 10px;
  }

  // для перемещения мыши на подсказку
  &::after {
    content: "";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    right: 0;
    top: -20px;
  }
}
.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.tooltipTitleDur {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.tooltipTitle {
  color: var(--color-black-100);
  font-size: 14px;
  font-weight: 700;
}
.tooltipDuration {
  font-style: italic;
}
.tooltipTeachers {
  display: flex;
  flex-direction: column;
  font-style: italic;
  gap: .5em;
  white-space: nowrap;
}
.tooltipRegisters {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.tooltipRegister {
  display: flex;
  gap: 5px;
  flex-direction: column;

  b {
    font-weight: 500;
    min-width: 160px;
  }

  div {
    font-weight: 400;
    min-width: 160px;
  }
}

.startLessonBtn {
  border: 1px solid var(--color-primary-3);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;

  &:hover {
    color: var(--color-primary-2);
    background: var(--color-black-0);
    border: 1px solid var(--color-primary-2);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
  }
}
