.salaryBox {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__label {
      display: block;
      height: 35px;
  }

  &__subrow {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  &__ratesHeader {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 2fr 2fr ;
  }

  &__rates {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__rate {
    align-items: center;
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 2fr 2fr ;

    span {
      align-items: center;
      color: #5f6368;
      display: flex;
      font-size: 14px;
      font-weight: 600;

      svg {
        fill: currentColor;
        margin-left: .38em;
        width: 22px;
      }
    }

    input {
      margin: 0;
    }
  }

  &__close {
    display: flex;
    font-size: 40px;
    color: #3f4d63;
    cursor: pointer;
  }

}
