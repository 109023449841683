.layout {
  padding: 0 15px;
  display: grid;
  gap: 20px;

  @media (min-width: 1024px) {
    grid-template-columns: 330px 1fr 1fr;
  }
}

.item {
  position: relative;
  width: 100%;
  padding: 140px 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 50%;
    height: 90px;
    width: 90px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    transform: translateX(-50%);
  }

  a.link {
    color: var(--color-text);
    text-decoration: underline;
    word-break: break-word;

    &:hover {
      text-decoration: none;
    }

    &__red {
      color: var(--color-error);
      font-weight: 700;
    }
  }
}

.client {
  @media (min-width: 1024px) {
    width: 330px;
    grid-row: 1 / 3;
  }

  &::before {
    background-image: url('./icons/avatar.svg');
  }
}

.location {
  &::before {
    background-image: url('./icons/map-point.svg');
  }
}

.level {
  &::before {
    background-image: url('./icons/plate-icon.svg');
  }
}

.payment {
  &::before {
    background-image: url('./icons/payinfo.svg');
  }
}

.employee {
  padding-top: 25px;

  &::before {
    content: none;
  }
}

.info_list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.text_wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text);
}

.button {
  width: 100%;
  flex-grow: 1;
  min-width: 148px;
  padding: 10px 4px;
  font-size: 14px;
  font-weight: 500;
  color: white;

  @media (min-width: 1024px) {
    width: calc(50% - 5px);
  }

  &__grey {
    background-color: #bdbdbd;
    border-color: darken(#bdbdbd, 20%);

    &:hover {
      background-color: darken(#bdbdbd, 20%);
      border-color: darken(#bdbdbd, 30%);
    }
  }
  &__blue {
    background-color: #007be8;
    border-color: darken(#007be8, 20%);

    &:hover {
      background-color: darken(#007be8, 20%);
      border-color: darken(#007be8, 30%);
    }
  }
  &__green {
    background-color: #2ecc71;
    border-color: darken(#2ecc71, 20%);

    &:hover {
      background-color: darken(#2ecc71, 20%);
      border-color: darken(#2ecc71, 30%);
    }
  }
  &__red {
    background-color: #ee7272;
    border-color: darken(#ee7272, 20%);

    &:hover {
      background-color: darken(#ee7272, 20%);
      border-color: darken(#ee7272, 30%);
    }
  }
}

.img_wrapper {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--color-outline);
  overflow: hidden;

  img {
    object-fit: cover;
  }

  span {
    color: white;
    font-size: 28px;
    font-weight: 700;
  }
}

.code {
  letter-spacing: 0.2rem;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.break_word {
  word-break: break-word;
}

.results {
  width: calc(100% - 30px);
  margin: 20px 15px;
  color: var(--color-text);

  &__missed {
    background-color: var(--color-error-light);
    border-color: var(--color-error);
    color: var(--color-error);

    p {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
    }
  }
}

.results_subtitle {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
}

.results_level_item:not(:last-child) {
  margin-bottom: 5px;
}

.results_level {
  width: 100%;

  @media (min-width: 768px) {
    width: 250px;
  }
}

.results_level_name {
  font-weight: 500;
}

.results_comment {
  flex-grow: 1;
}

.results_no_data {
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;
  font-style: italic;
  text-align: center;
}
