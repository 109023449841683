.sentences {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sentenceRow {
  cursor: pointer;
  display: flex;
  gap: 20px;
  user-select: none;
}
.number {
  align-items: center;
  border: 2px solid var(--color-outline);
  border-radius: 5px;
  color: var(--color-outline);
  display: flex;
  flex-shrink: 0;
  font-size: 20px;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  width: 34px;
}
.sentence {
  border: 1px solid var(--color-outline);
  border-radius: 10px;
  color: #000;
  font-weight: 500;
  padding: 9px 1em;
}
