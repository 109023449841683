.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  justify-content: space-between;
  gap: 55px;
}

.teacher_wrapper {
  min-width: 150px;
  height: 235px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.teacher {
  position: relative;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border: 1px solid var(--color-outline);
  cursor: pointer;
  transition: background-color 0.3s, border-bottom-color 0.3s;

  &:hover {
    background-color: #f1f8ff;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: 0;
    left: 0;
    border-radius: 0 0 5px 5px;
    transition: background-color 0.3s;
  }
}

.img {
  width: 110px;
  height: 110px;
  background-color: var(--color-outline);
  border-radius: 5px;
  object-fit: cover;

  &__broken {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 30px;
  }
}

.name {
  font-weight: 700;
}

.radio:checked ~ .teacher {
  background-color: #f1f8ff;
  border-bottom-color: var(--color-text-accent);

  &::after {
    background-color: var(--color-text-accent);
  }

  .custom_radio {
    border-color: var(--color-text-accent);
    background-color: var(--color-text-accent);
  }
}

.custom_radio {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-outline);
  border-radius: 50%;
  transition: background-color 0.3s, border-color 0.3s;

  &::before {
    content: '';
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 50%;
  }
}
