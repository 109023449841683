.info {
  display: flex;
  background-color: var(--color-success-4);
  border-radius: 7px;
  flex-direction: column;

  .circle {
    border: 5px solid;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1440px) {
      width: 150px;
      height: 150px;
    }
    @media (min-width: 768px) {
      width: 150px;
      height: 150px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 1440px) {
  flex-direction: row;
  flex-wrap: nowrap;
}

.block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  gap: 10px;
  align-items: center;
  margin-top: 5px;
  padding: 20px 0;
  line-height: 24px;

  @media (min-width: 767px) {
    min-height: 174px;
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    margin-top: 0;
  }

  @media (min-width: 1440px) {
    width: 33%;
    border-right-width: 1px;
    border-right-style: dashed;
    border-bottom: none;
    flex-direction: column;
    margin-top: 0;
  }
}

.testSettings {
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 700;
  color: var(--color-black-100);
  line-height: 24px;
  margin-bottom: 10px;
}

.testSettingsForSecurity {
  margin-top: 10px;
}

.securityBlock {
  display: flex;
  flex-direction: column;
  gap: 15px;
  line-height: 24px;

  p {
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: var(--color-black-80);
    font-weight: 500;
    font-size: 14px;
  }
}

.level {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
}

.infoOfLevel {
  display: flex;
  flex-direction: row;
  color: var(--color-black-80);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

}

.horizontalLine {
  background: var(--color-black-60);
  margin-inline: 10px;
  height: 16px;
  width: 1px;
}

.group {
  display: flex;
  justify-content: flex-start;
  color: var(--color-primary-1);
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid var(--color-primary-1);
  width: fit-content;
  line-height: 24px;
}

.text {
  display: flex;
  padding: 5px 0 10px;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--color-black-60);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  word-break: break-word;
  line-height: 17px;

}

.blockPadding {
  padding: 20px 5px;
  border-top-width: 1px;
  border-top-style: dashed;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 767px) {
    padding: 20px;
    width: 50%;
    border-top: none;
    border-right-width: 1px;
    border-right-style: dashed;
    border-bottom: none;

  }
  @media (min-width: 1440px) {
    width: 33%;
  }
}

.blockForTest {
  padding: 20px 5px;
  border-top-width: 1px;
  border-top-style: dashed;

  @media (min-width: 767px) {
    width: 50%;
    padding: 20px;
    border-top: none;
  }

  @media (min-width: 1440px) {
    width: 33%;
  }
}

.containerForPeriod {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
}

.period {
  text-align: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.blueDate {
  color: var(--color-primary-1);
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  width: 51px;
}

.grayLine {
  background: var(--color-black-60);
  margin: 0 5px;
  height: 16px;
  width: 1px;

  @media (min-width: 768px) {
    margin: 0 10px;
  }
}

.grayText {
  color: var(--color-black-80);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.timeBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.str {
  color: var(--color-black-80);
  font-size: 14px;
  font-weight: 500;
  margin: 1em 0 1.5em;
}

.testEnd {
  color: var(--color-black-100);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
}

.spanText {
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: var(--color-primary-1);
  font-weight: 800;
  font-size: 16px;
}

.lines {
  display: flex;
  flex-direction: column;
  gap: 15px;
  line-height: 24px;

  p {
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: var(--color-black-80);
    font-weight: 500;
    font-size: 14px;

    &:last-child {
      border-bottom: none;
    }

    span {
      font-size: 14px;
      font-weight: 500;

      &:last-child {
        font-weight: 800;
        font-size: 16px;
      }
    }
  }
}

.textTruncate {
  display: inline-block;
  flex-direction: row;
  color: var(--color-primary-1);
  font-size: 12px;
  font-weight: 700;
  align-self: flex-end;
  border-bottom: 1px solid var(--color-primary-1);
  cursor: pointer;
}
