.groupLog {
  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 0;
    @media (min-width: 768px) {
      padding: 10px 10px;
    }
  }

  .row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px;
    transition: .3s;
    
    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
    }

    .time {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      color: var(--color-black-100);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.24px;
      
      @media (min-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
      }

      .clock {
        color: var(--color-black-100);
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
      }
    }

    .name {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: 768px) {
        justify-content: normal;
      }

      a {
        color: var(--color-primary-1);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.42px;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .operation {
      color: var(--color-black-80);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      flex-direction: column;

      .operationName {
        font-weight: 700;
      }
    }


    &:not(:first-child){
      border-top: 1px solid var(--color-black-40);
    }

    &:hover {
      border-radius: 6px;
      background: var(--color-primary-4);
    }
  }


  .moreBtn {
    margin: 10px;
    color: var(--color-black-60);
    border: 1px solid var(--color-black-60);
    border-radius: 7px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.42px;
    &:hover {
      text-decoration: none;
      color: var(--color-primary-2);
      background: var(--color-black-0);
      border: 1px solid var(--color-primary-2);
      box-shadow: inset 0 0 0 1px var(--color-primary-2);
    }
  }
}

.notSelected {
  padding: 20px;
  text-align: center;
  color: var(--color-black-100);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.48px;
}
