.modalEmployerContract {
  textarea {
    resize: none;
  }

  &__toggleMainSelectCol {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__row2 {
    align-items: center;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  &__rowVacation {
    align-items: center;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 185px;
  }

  & &__inputAmount {
    text-align: center;
    padding-left: 0;
  }

  &__link {
    color: #5f6368;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .checkboxToggle__label {
    color: #5f6368;
  }
}

