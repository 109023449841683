.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-black-40);
  padding: 22px;
}
.headerName {
  color: var(--color-black-100);
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.32px;
  @media (min-width: 768px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.4px;
  }
}
.headerRightBlock{
  display: flex;
  gap: 10px;
}
