.imageSelector {
  align-items: center;
  border: 2px dashed #bdbdbd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  input[type="file"] {
    display: none;
  }

  button {
    font-family: inherit;
    font-weight: 500;
  }

  &__tip {
    font-size: 12px;
  }

  &__cropper {
    //max-height: 500px;
  }

  &__currentImage {
    max-width: 100%;
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__deleteButton {
    box-sizing: border-box;
    width: 150px;
    height: 45px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 2px solid #db3031;
    font-size: 14px;
    color: #db3031;

    &:hover {
      background-color: #db3031;
      color: #ffffff;
    }
  }

  &__newImageButton {
    box-sizing: border-box;
    width: 240px;
    height: 45px;
    border-radius: 5px;    
    font-size: 14px;    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #3f4d63;
      color: #ffffff;
    }
  }
}