.makeWords {
  display: grid;
  gap: 20px;
}
.oneWord {
  background-color: var(--color-black-10);
  border-radius: 6px;
  padding: 10px;
}
.counter {
  background-color: var(--color-black-10);
  border-radius: 3px;
  color: var(--color-black-100);
  text-align: center;
  font-size: 12px;
  left: -10px;
  letter-spacing: .24px;
  line-height: 1;
  padding: 2px 4px;
  position: relative;
  top: -16px;
}
