.title {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 20px;
    text-align: center;
}

.box {
    padding: 50px 20px 0;
}

.table {
    border: 1px solid black;
    background: white;
    width: 100%;
    border-collapse:collapse;
    caption-side:bottom;
}

.col {
    border: 1px solid black;
    width: 33%;
}

.headCol {
    padding: 10px 0 ;
    border: 1px solid black;
    width: 33%;
}

.subrow_change {
    cursor: pointer;
    text-decoration: underline;
}

.item {
    padding: 10px 10px;
    height: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
