.questionYoutube {

  &__videoBox {
    display: flex;
    flex-direction: column;
    gap: 10px;


    iframe {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0;
    }
  }

  &__control {
    display: flex;
    gap: 20px;
    justify-content: center;
    user-select: none;

    div, span {
      align-items: center;
      background-color: white;
      border-bottom: 2px solid #5a8dee;
      display: flex;
      height: 30px;
      justify-content: center;
      width: 80px;
    }

    div {
      color: #5a8dee;
      cursor: pointer;
      transition: .3s;

      svg {
        fill: currentColor;
        height: 50%;
      }

      &:last-child {
        svg {
          transform: rotate(180deg);
        }
      }

      &[data-disabled="true"]{
        border-color: #bdbdbd;
        color: #bdbdbd;
        cursor: default;
      }
    }

    span {
      color: #000;
    }
  }
}