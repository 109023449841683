.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card {
  position: relative;
  display: flex;
  gap: 15px;
}
.avatar {
  flex-shrink: 0;
  height: 100px;
  width: 100px;

  img {
    border-radius: 5px;
    display: block;
    object-fit: cover;
  }

  img, div {
    height: 100%;
    width: 100%;
  }
}
.info {
  display: flex;
  flex-direction: column;
  gap:15px;
  color: var(--color-black-80);

  span a {
    color: var(--color-black-100);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.28px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
