.box {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  background-color: white;
  transition: .3s;
}
.boxChecked {
  composes: box;
  background-color: #b1f5cf;
  border-color: #32b76c;
}

.header {
  align-items: center;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  user-select: none;
}

.headerLeft {
  align-items: center;
  display: flex;
  font-weight: 500;
  gap: 20px;
  text-align: left;

  a {
    color: #222222;
    &:hover {
      text-decoration: underline;
    }
  }
}

.check {
  border-bottom: 6px solid #32b76c;
  border-left: 6px solid #32b76c;
  cursor: pointer;
  height: 20px;
  transform: rotate(-45deg);
  transition: .3s;
  width: 50px;
}
.uncheck {
  composes: check;
  opacity: 0.1;
}

.pic {
  background-color: #dddddd;
  border-radius: 5px;
  height: 50px;
  overflow: hidden;
  width: 50px;

  img {
    height: 50px;
    width: 50px;
  }

  div {
    align-items: center;
    color: white;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;

    svg {
      fill: currentColor;
      height: 70%;
      width: 70%;
    }
  }
}