.box {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    & img {
        cursor: pointer;
    }
}

.btn {
    padding: 11px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-accent);
    border-radius: 5px;
    border: 2px solid var(--color-text-accent);
    background-color: white;
    cursor: pointer;
    transition: color 0.4s, background-color 0.4s, border-color 0.4s;

    font-family: inherit;
    font-size: 14px;
    line-height: 140%;
    font-weight: 600;
    text-align: center;

    &:hover {
        color: white;
        background-color: var(--color-text-accent);
    }

    &:focus-visible {
        box-shadow: 0 0 0 3px white, 0 0 1px 5px var(--color-accent);
    }
}
.error {
    color: var(--color-error);
}
