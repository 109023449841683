.label {
  font-weight: 400;
}

.text {
  margin-bottom: 30px;
  font-weight: 500;
  span {
    font-weight: 400;
  }
  .error {
    font-weight: 600;
    color: var(--color-error);
  }
}
