.scheduleSettingSlots {
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 20px;

  &--error {
    background-color: #ff4c52;
    border-color: #ff4c52;
  }

  &__header {
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    h3 {
      color: #5f6368;
      font-size: 18px;
      font-weight: 500;
      @media (min-width: 768px) {
        font-size: 20px;
      }
    }

    &>div {
      margin-top: 20px;
      @media (min-width: 768px) {
        margin-top: 0;
      }
    }

    .promoted-checkbox label {
      font-size: 12px;
    }
  }

  &__slots {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    @media (min-width: 768px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  &__slot {
    align-items: center;
    border: 1px solid #bdbdbd;
    color: #5f6368;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    @media (min-width: 768px) {
      font-size: 16px;
      height: 40px;
    }

    &--active {
      background-color: #3f4d63;
      color: white;
    }
  }


  &__emptySlotsMessage {
    color: white;
    font-size: 20px;
    font-style: italic;
    text-align: center;
  }
}
