.client {
  padding: 0;
  margin-bottom: 10px;
}

.header {
  position: relative;
  min-height: 50px;
  padding: 10px 45px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid var(--color-outline);

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  &__client {
    .name,
    .date {
      margin-bottom: 0;
    }

    @media (min-width: 1024px) {
      align-items: center;

      .menu_wrapper {
        top: 15px;
      }
    }
  }
}

.body {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
}

.details {
  padding: 10px;
  border-top: 1px solid var(--color-outline);
}

.date_wrapper {
  @media (min-width: 1024px) {
    width: 30%;
  }
}

.level_wrapper {
  @media (min-width: 1024px) {
    max-width: calc((100% - 300px) / 2);
    flex-grow: 1;
    text-align: right;
  }
}

.name {
  margin-bottom: 5px;
  font-weight: 500;
  color: black;
}

.date {
  margin-bottom: 5px;
  & span {
    font-weight: 700;
    color: black;
  }
}

.level_title {
  margin-bottom: 5px;
}

.subtitle {
  font-size: 12px;
}

.test_button {
  width: calc(100% + 35px);
  height: 35px;
  padding: 4px 10px;
  font-weight: 500;

  @media (min-width: 1024px) {
    width: 150px;
  }
}

.menu_wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.first_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.second_column {
  flex-grow: 1;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;
}

.docs_list {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;

  @media (min-width: 1024px) {
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.docs_item a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 30px;
  font-size: 12px;
  color: var(--color-text-accent);
  background-color: white;
  border: 1px solid var(--color-text-accent);
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    color: white;
    background-color: var(--color-text-accent);
  }
}

.comment_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  flex-grow: 1;

  &__edit {
    align-items: flex-start;
  }
}

.comment_title {
  margin-right: 15px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
}

.comment_text {
  display: block;
  width: 100%;
  font-size: 12px;
  word-break: break-word;
  line-height: 1.5em;
  cursor: pointer;
}

.comment_add_button {
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--color-text);

  &:hover {
    background-color: white;
    color: var(--color-text);
  }
}

.edit_comment_wrapper {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 5px;

  @media (min-width: 1024px) {
    margin-top: 0;
  }
}

.comment_textarea {
  flex-basis: 100%;
  font-size: 16px;
  font-family: inherit;
  resize: none;

  &::placeholder {
    font-size: 12px;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
    flex-basis: calc(100% - 100px);
  }
}

.comment_buttons_wrapper {
  display: flex;
  gap: 5px;
  @media (min-width: 1024px) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.comment_button {
  width: 90px;
  height: 30px;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.zoom_button {
  width: 100%;
  font-weight: 500;

  @media (min-width: 1024px) {
    font-weight: 600;
  }
}

.zoom_text_long {
  display: none;

  @media (min-width: 1024px) {
    display: inline-block;
  }
}

.zoom_text_short {
  @media (min-width: 1024px) {
    display: none;
  }
}

.name_wrapper,
.docs_list,
.first_column,
.zoom_button {
  @media (min-width: 1024px) {
    width: 300px;
  }
}

.details_title {
  margin-bottom: 10px;
  font-weight: 500;
}

.details_list {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 10px;

  @media (min-width: 1024px) {
    gap: 20px;
  }

  li {
    flex-basis: 100%;

    @media (min-width: 1024px) {
      flex-basis: auto;
    }

    &:first-child {
      @media (min-width: 1024px) {
        flex-basis: 300px;
      }
    }

    &:last-child {
      flex-grow: 1;
    }
  }

  a.details_link,
  .details_zoom {
    color: var(--color-text);
    text-decoration: underline;
    font-size: 12px;

    &:hover {
      color: var(--color-text-accent);
      text-decoration: none;
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }
}

.details_zoom {
  width: 100%;
  padding: 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border-radius: 0;
  border: none;

  &:hover {
    background-color: white;
  }

  @media (min-width: 1024px) {
    text-align: right;
  }
}

.client_details {
  width: 100%;
  font-size: 12px;

  dt {
    margin-bottom: 5px;
    color: black;
    font-weight: 500;
  }

  dd {
    padding-left: 5px;
    color: var(--color-text);
    margin-bottom: 20px;

    a {
      color: var(--color-text);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    a.client_details_link {
      font-size: 12px;
      font-weight: 700;
      color: var(--color-error);
    }
    p:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.label_part {
  display: block;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
