.main {
  font-family: "Manrope", sans-serif;
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;

  .link {
    border-radius: 5px;
    border: 1px solid var(--color-black-40);
    padding: 5px;
  }

  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.filterButtonsBlock {
  display: flex;
  gap: 20px;
  padding: 10px 22px;
  border-bottom: 1px solid var(--color-black-40);
}