.studyTest {
  &__line {
    margin-bottom: 15px;

    &--half {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 15px;
      width: 100%;
    }
  }
}

.DurationNote {
  color: #5f6368;
  display: flex;
  flex-direction: column;

  &--title {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  &--note {
    font-size: 12px;
    padding-bottom: 10px;
  }
}