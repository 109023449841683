.wrapper {
    padding: 0 15px;
}
.settings {
    margin-bottom: 20px;
}
.title {
    font-size: 20px;
    color: #5f6368;
    font-weight: 600;
    margin-bottom: 18px;
}
.text {
    font-size: 12px;
    color: #5f6368;
    margin-top: 5px;
}
.subtitle {
    color: #5f6368;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 13px;
}
.buttons {
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        button:nth-child(1) {
            margin-bottom: 10px;
        }
    }
}