.page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 15px;
}
.firstRow {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
.manageAbonemetButtons {
  display: flex;
  gap: 5px;
}
.balanceButton {
  background-color: #21d06f;
  border-color: #21d06f;
  color: white;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  padding: 0;
  text-align: center;
  user-select: none;
  width: 100%;
  &:hover {
    border-color: var(--color-text-accent);
  }
  &[disabled] {
    background-color: #fff;
    border-color: var(--color-outline);
    color: var(--color-outline);
    pointer-events: none;
  }
}
.box {
  padding: 0;
}
.list {
  color: var(--color-text);
  padding: 0 20px;
}
.list .scheduleRow {
  a {
    color: var(--color-text-accent);
    font-weight: 500;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}
.scheduleRow {
  align-items: center;
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  padding: 10px 0;

  &:not(:first-child){
    border-top: 1px solid var(--color-outline);
  }
}
.scheduleButton {
  display: block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  padding: 8px 20px;
  user-select: none;
  &[disabled] {
    border-color: var(--color-outline);
    color: var(--color-outline);
    pointer-events: none;
  }
}
.historyRow {
  border-bottom: 1px solid var(--color-outline);
  display: grid;
  grid-template-columns: 200px repeat(5, 1fr);
  padding: 20px;
}
.visitRow {
  border-bottom: 1px solid var(--color-outline);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px;

  div a {
    color: var(--color-text-accent);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
