@import "../MainStyles";

.answerBody {
  @include answerBodyMain;
  padding: 25px 10px 10px 10px;
}

.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.correctColor {
  @include correctColorMain;
}

.answers {
  display: flex;
  font-size: 18px;
  font-weight: 500;
}

.rightAnswer {
  @include rightAnswerMain;
}

.wrongAnswer {
  @include wrongAnswerMain;
}

.delimitr {
  color: var(--color-black-40);
}