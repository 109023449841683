
.modalBoard {
  background-color: var(--color-black-0);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 80vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boardPanelWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.outer {
  outline: 2px solid var(--color-black-40);
  margin: 0 auto;
  overflow: auto;
  height: 100%;
  max-height: 1000px;
  max-width: 1000px;

  &::-webkit-scrollbar {
    height: 0;
    display: none;
  }
}

.sketchCanvasWrapper {
  z-index: 10;
  width: 1000px;
  height: 1000px;
}

// Styles for board tools
.panelBlocks {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: 10px;
  left: 10px;
  z-index: 5;

}

.toolsPanel {
  display: flex;
  min-width: 180px;
  flex-direction: column;
  gap: 10px;
  border-radius: 7px;
  background: var(--color-black-0);
  padding: 10px 5px;
  margin-bottom: 10px;

  .panelContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--color-black-40);
}

.tool {
  color: var(--color-black-80);
  display: flex;
  align-items: center;
  gap: 10px;

  div {
    cursor: pointer;
  }
}

.verticalSeparator {
  height: 24px;
  width: 1px;
  background: var(--color-black-40);
}

.inputRange {
  display: flex;
  align-items: center;
  gap: 5px;

  input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100px;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: var(--color-primary-1);
    border-radius: 5px;
  }

  input[type=range]::-webkit-slider-thumb {
    border: 2px solid var(--color-primary-1);
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: var(--color-black-0);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--color-primary-1);
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: var(--color-primary-1);
    border-radius: 5px;
  }

  input[type=range]::-moz-range-thumb {
    border: 2px solid var(--color-primary-1);
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: var(--color-black-0);
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: var(--color-primary-1);
    border-radius: 10px;
  }

  input[type=range]::-ms-fill-upper {
    background: var(--color-primary-1);
    border-radius: 10px;
  }

  input[type=range]::-ms-thumb {
    margin-top: 1px;
    border: 2px solid var(--color-primary-1);
    height: 14px;
    width: 14px;
    border-radius: 10px;
    background: var(--color-black-0);
    cursor: pointer;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: var(--color-primary-1);
  }

  input[type=range]:focus::-ms-fill-upper {
    background: var(--color-primary-1);
  }

  div {
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }
}

.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.colorPickerContainer {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 155px;
  background: var(--color-black-0);
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--color-black-40);
  box-sizing: content-box;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 0;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-black-0);
  }
}

.boxBorderCircle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.editBlock {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 3px;
  background-color: rgba(240, 243, 245, 0.8);
  border-radius: 6px;
  z-index: 1;

  &:hover {
    background-color: var(--color-black-10);
  }
}

.verticalSeparator {
  height: 24px;
  width: 1px;
  background: var(--color-black-40);
}

