.container {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  gap: 15px;
  border-bottom: 1px solid var(--color-black-60);

  &:last-child {
    border: none;
  }
}

.personalInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .person {
    display: flex;
    align-items: center;
    gap: 15px;

    .avatar {
      width: 50px;
      height: 50px;
      
      @media (min-width: 768px) {
        width: 75px;
        height: 75px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .name {
        color: var(--color-black-100);
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.36px;
      }

      .title {
        color: var(--color-black-80);
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.28px;
      }

    }
  }
  .date {
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.28px;
  }
}

.message {
  color: var(--color-black-100);
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.28px;
  word-break: break-word;
}