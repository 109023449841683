.booked_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 110px);
  justify-content: space-between;
  gap: 10px;
}

.booked_item {
  position: relative;
  width: 110px;
  padding: 10px 0;
  border-radius: 5px;
  border: 1px solid var(--color-outline);
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: var(--color-text);
  }
}

.booked_time {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.color_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 170px));
  justify-content: space-between;
  gap: 20px;
}

.color_item {
  min-width: 110px;
  display: flex;
  align-items: center;
}

.color {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border: 1px solid var(--color-text);
  border-radius: 5px;
}

.color_label {
  font-weight: 500;
}

.menu {
  position: absolute;
  width: 140px;
  top: 30px;
  left: -15px;
  padding-top: 10px;
  opacity: 0;
  z-index: 2;
}

.menu_list {
  padding: 10px 0;
  border-radius: 5px;
  border: 1px solid var(--color-outline);
  background-color: white;
}

.menu_show {
  &:local {
    animation: menu-show-animation 0.3s ease forwards;
  }
}

.menu_item {
  padding: 10px 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ededed;
  }

  &__warning {
    font-weight: 500;
    color: var(--color-error);
  }
}

.zoom_link {
  margin-right: 10px;
  flex-grow: 1;
  padding: 14px 20px;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
}

.modal_button {
  min-width: 125px;
}

@keyframes menu-show-animation {
  0% {
    opacity: 0;
    top: 0;
  }

  100% {
    opacity: 1;
    top: 30px;
  }
}
