$border: 1px solid var(--color-black-40);

.personalInfo {
  background: var(--color-black-0);
  border: $border;
  border-radius: 7px;
  font-family: 'Manrope', sans-serif;
}
.body, .footer {
  padding: 1em 15px;
  @media (min-width: 768px) {
    padding: 1em 22px;
  }
}
.body, .footer {
  border-top: $border;
}
.body {
  display: flex;
  flex-direction: column;
  gap: 20px;

  ul {
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
    @media (min-width: 1440px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    li {
      display: flex;
      flex-direction: column;
    }

    a {
      color: var(--color-black-100);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.footer {
  ul {
    display: grid;
    gap: 1em;
    @media (min-width: 768px) {
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
    @media (min-width: 1440px) {
      grid-auto-flow: row;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
    }

    li {
      display: flex;
      flex-direction: column;

      &:last-child {
        @media (min-width: 768px) {
          grid-column: 1/3;
          grid-row: 3/4;
        }
        @media (min-width: 1440px) {
          grid-column: 1/5;
          grid-row: auto;
        }
      }
    }
  }
}
.paramName {
  color: var(--color-black-80);
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 16px;
  @media (min-width: 768px) {
    font-size: 12px;
    letter-spacing: 0.24px;
  }
}
.paramValue {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.28px;
}
.workSince {
  display: flex;
  flex-direction: column;
  .daysAndYears {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.24px;
    color: var(--color-black-60);
  }
}
.telegram {
  border: $border;
  border-radius: 5px;
  line-height: 2;
  padding: .5em 20px;

  p {
    color: var(--color-black-80);
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.2px;
    @media (min-width: 768px) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.24px;
    }
  }
  span {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.edit {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.cursorPointer {
  cursor: pointer;
}