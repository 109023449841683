.questionDragWords {
  &__text {
    margin-top: 2em;
  }

  &__dropZone {
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #b5b5b5;
    border-radius: 10px;
    box-shadow: 0 0 0 #3f4d63;
    cursor: default;
    display: inline-flex;
    font-weight: 500;
    height: 35px;
    justify-content: center;
    min-width: 80px;
    padding: 0 1em;
    transition: .3s;
    vertical-align: middle;

    &.onDragOver {
      background-color: #c9dcff;
    }

    &:not(:empty){
      background-color: white;
    }
  }
}
