.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  border-radius: 7px;
  border: 1px solid var(--color-black-40);
  background: var(--color-black-0);
  box-shadow: -1px 0px 0px 0px #DFE6E9 inset;
  z-index: 999;
  word-break: break-word;




  .info {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid var(--color-black-60);
    width: 100%;

    h2 {
      color: var(--color-black-100);
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0.32px;
    }
  }


  .ratings {
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    color: var(--color-black-80);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.28px;

    .rating {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .stars {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
        color: var(--color-black-80);
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.28px;
      }
    }
  }
}

.filled {
  color: var(--color-warning-1);
}

.empty {
  color:  var(--color-black-60)
}