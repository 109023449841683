@import "../MainStyles";

.answerBody {
  @include answerBodyMain;
  padding: 20px 10px 10px 10px;
}

.conteinerStAnswr {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  align-items: flex-end;

  @media (min-width: 768px) {
    grid-template-columns: 1fr;
    gap: 44px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
  }
}


.imageContainer {
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  .imgSize {
    border-radius: 6px;
    max-height: 80px;

    @media (min-width: 321px) {
      max-height: 300px;
    }

  }

  .imgWord {
    width: 100%;
    margin-top: 10px;
    border-radius: 6px;
    color: var(--color-black-100);
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.36px;
  }
}

.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.correctColor {
  @include correctColorMain;
}

.checkedRight {
  border-radius: 6px;
  border: 2px solid var(--color-success-1);
  background: var(--color-success-4);
}

.checkedWrong {
  border-radius: 6px;
  border: 2px solid var(--color-danger-1);
  background: var(--color-danger-4);
}

.imgSize {
  border-radius: 6px;
}

