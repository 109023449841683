.accentSaveAnswer {
  align-items: center;
  background-color: var(--color-danger-4);
  border: 1px dashed var(--color-danger-1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  transition: .3s;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  &:hover {
    background-color: var(--color-success-4);
    border-color: var(--color-success-1);

    button {
      border-color: var(--color-success-1);
    }
  }
  &__noHover {
    pointer-events: none;
  }

  span {
    align-items: center;
    color: var(--color-black-100);
    display: flex;
    font-size: 14px;
    font-weight: 700;
    gap: 10px;
    line-height: 24px;
    letter-spacing: .28px;

    &.danger {
      svg {
        color: var(--color-danger-1);
      }
    }
  }

  button {
    background-color: var(--color-black-0);
    border: 1px solid var(--color-danger-1);
    border-radius: 5px;
    color: var(--color-black-100);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .42px;
    line-height: 16px;
    opacity: 1;
    padding: .5em 1em;
    text-align: center;
    transform: scale(1);
    transition: .3s;
    &.hide {
      opacity: 0;
      transform: scale(0);
    }
    &:hover {
      background-color: var(--color-success-1);
      color: var(--color-black-0);
    }
  }
}
