.counter {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 25px 1fr 50px 25px;
  height: 45px;
  overflow: hidden;
}

.input.input {
  input {
    border-radius: 0;
    height: 45px;
    border: none;
    outline: none;
    &:hover, &:focus {
      border: none;
      box-shadow: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

.btn {
  align-items: center;
  background-color: var(--color-accent-light);
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 20px;
  user-select: none;
}

.info {
  align-items: center;
  background-color: #f0f4f7;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  padding: 3px;
}
