.questionWriting {
  h3 {
    color: #000;
    font-size: 22px;
    font-weight: 500;
  }

  figure {
    margin: 20px 0;
    img{
      max-width: 100%;
    }
  }

  &__bottom {
    display: flex;
    gap: 20px;
    height: 160px;

    textarea {
      height: 100%;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__button {
    color: #3f4d63;
    font-family: Montserrat, sans-serif;
    font-weight: 500;

    &--cancel {
      border-color: transparent;
      &:hover {
        background-color: #f2f2f2;
        color: #3f4d63;
      }
    }
  }
}