.timeLeft {
  color: #000;
  display: grid;
  font-size: 14px;
  grid-template-columns: repeat(3, 80px);
  justify-content: center;
  text-align: center;

  strong {
    font-size: 40px;
  }

  span {
    margin-left: 5px;
  }
}
