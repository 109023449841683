.pastLesson {
  display: grid;
  gap: 10px;
  padding: 10px;
  @media (min-width: 1440px) {
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }
  &:not(:first-child){
    border-top: 1px solid var(--color-black-40);
  }
  &:hover {
    background-color: var(--color-black-10);
  }
}
.groupCourse {
  display: flex;
  flex-direction: column;

  a {
    color: var(--color-primary-1);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.28px;
    &:link, &:visited {
      text-decoration-line: underline;
    }
    @media (min-width: 1440px) {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.32px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
.group {
  color: var(--color-black-80);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.24px;
}
.timeStatus {
  display: grid;
  grid-template-columns: 1fr auto;
  @media (min-width: 768px) {
    justify-self: flex-start;
    column-gap: 50px;
  }
  @media (min-width: 1440px) {
    justify-self: stretch;
  }

  time {
    color: var(--color-black-100);
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.24px;
    b {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
.status {
  align-self: center;
  grid-column: 2/3;
  grid-row: 1/3;
}
.buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 768px) {
    align-items: flex-end;
    grid-column: 2/3;
    grid-row: 1/3;
  }
  @media (min-width: 1440px) {
    flex-direction: row;
    grid-column: unset;
    grid-row: unset;
    justify-content: flex-end;
  }
}
.link {
  padding: 7px 20px;
  gap: 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;
  border-radius: 7px;
  font-family: "Manrope", sans-serif;
  border: 1px solid var(--color-black-80);
  color: var(--color-black-80);
  cursor: pointer;
  .iconImage {
    max-width: 13px;
    width: 100%;
  }

  &:hover {
    color: var(--color-black-100);
    background: #fff;
  }
}

.linkColored {
  border: 1px solid var(--color-success-1);
  color: var(--color-black-100);

  &:hover {
    outline: 1px solid var(--color-success-1);
    color: var(--color-success-1);
    background: var(--color-black-0)
  }
}