.timeTableSlot {
    background-color: transparent;
    border-radius: 5px;
    box-sizing: border-box;
    width: 5px;
    position: absolute;
    height: 38px;
    transition: .1s;
    animation-delay: -1s;
    transition-property: all;
    transition-duration: 0s;
    transition-delay: 0s;
    transition-timing-function: linear;
    cursor: pointer;
    &__body {
        position: relative;
    }
    &__focusWrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99;
        // background: rgba($color: #000000, $alpha: .1);
    }
}