.box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 0;
  background-color: #fbfbfb;
  position: sticky;
  bottom: 0;
  z-index: 100;
}

.slider {
  display: flex;
  align-items: center;
  gap: 40px;
  border-radius: 5px;
  overflow: hidden;

  .sectionInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    line-height: 16px;
    text-align: center;
    color: var(--color-primary-1);

    span {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.36px;
    }

    div {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.42px;
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  min-width: 30px;
  border-radius: 5px;
  border: 1px solid var(--color-black-40);
  background-color: var(--color-black-0);
  cursor: pointer;

  &:hover {
    border: 1px solid var(--color-primary-2);

    &::before {
      border-top: 2px solid var(--color-primary-2);
      border-right: 2px solid var(--color-primary-2);
    }
  }

  &::before {
    border-top: 2px solid var(--color-black-60);
    border-right: 2px solid var(--color-black-60);
    content: "";
    display: block;
    height: 10px;
    width: 10px;
  }
}

.buttonPrev {
  composes: button;

  &::before {
    transform: rotate(-135deg);
    position: relative;
    left: 2px;
  }
}

.buttonNext {
  composes: button;

  &::before {
    transform: rotate(45deg);
    position: relative;
    right: 2px;
  }
}

[aria-disabled="true"] {
  opacity: 0.5;
  cursor: default;
}