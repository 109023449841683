.wrapper {
  position: relative;
  width: 100%;

  & > div {
    padding: 0 15px;
  }
}

.list {
  margin-bottom: -2px;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
}

.tab {
  padding: 15px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s, border-bottom-color 0.3s;
  border-bottom: 2px solid transparent;

  @media (max-width: 767px) {
    flex-basis: 100%;
  }
}

.tab_hidden {
  display: none;
}

.tab_active {
  font-weight: 600;
  color: var(--color-text-accent);
  border-bottom-color: var(--color-text-accent);
}

.length {
  font-weight: 400;
  vertical-align: super;
}

.menu {
  position: absolute;
  top: 13px;
  right: 40px;
}
