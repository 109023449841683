.lessonQuestion {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.28px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
}

.link {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
}