.label {
  padding: 11px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-accent);
  border-radius: 5px;
  border: 2px solid var(--color-text-accent);
  background-color: white;
  cursor: pointer;
  transition: color 0.4s, background-color 0.4s, border-color 0.4s;

  &:hover {
    color: white;
    background-color: var(--color-text-accent);
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px white, 0 0 1px 5px var(--color-accent);
  }
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.text {
  font-family: inherit;
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
}
