.box {
  header {
    border-bottom: 1px solid #bdbdbd;
    padding: 18px 20px 17px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  ul {
    display: flex;
    flex-direction: column;
    line-height: 1.25;
    gap: 1em;
  }

  li {
    position: relative;
    &:hover {
      .name {
        color: black;
      }
      .panel {
        opacity: 1;
      }
    }
  }
}

.name {
  color: #b9b9b9;
  cursor: pointer;
}

.nameActive {
  composes: name;
  color: #3474ff;
  font-weight: 600;
  text-decoration: underline;
}

.panel {
  background-color: #bdbdbd;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  display: flex;
  gap: 1px;
  margin-top: -12px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 50%;
  transition: .3s;

  div {
    align-items: center;
    background-color: white;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    transition: .3s;
    width: 24px;
    &:hover {
      background-color: #f5f5f5;
      fill: #3474ff;
    }
  }

  svg {
    height: 12px;
  }
}

/* .panelBtn {

}
 */
.createBtn {
  border: 2px solid #bdbdbd;
  border-radius: 5px;
  color: #5f6368;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.5;
  text-align: center;
  transition: .3s;
  &:hover {
    border-color: #3474ff;
    color: #3474ff;
  }
}

