.wrapper {
    position: relative;
    width: 50%;
    min-height: 108px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-color: var(--color-outline);
    border-width: 0 1px 1px 0;
    cursor: pointer;
    user-select: none;
    background-color: white;
    transition: background-color 0.2s;

    &:nth-last-of-type(7) {
        border-bottom-left-radius: 5px;
    }
    &:last-of-type {
        border-bottom-right-radius: 5px;
    }

    @media (min-width: 1024px) {
        width: 14.2857%;
    }
}

.past,
.disabled {
    background-color: #f1f1f1;
    cursor: default;
}

.selected {
    background-color: #d3e5ff;
}

.today {
    .header {
        color: var(--color-text);
        text-transform: uppercase;
    }
}

.body {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: flex-end;
    flex-grow: 1;
    grid-row-gap: 5px;
    row-gap: 5px;
    font-size: 12px;
    text-align: center;
    overflow-wrap: break-word;
}

.icon {
    display: block;
    height: 20px;
}

.text_bold {
    font-size: 14px;
    font-weight: 700;
}
