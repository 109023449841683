.wrapper {
    position: fixed;
    top: 61px;
    right: 0;
    width: 100vw;
    height: 100vh;

    z-index: 999;
    display: flex;
    justify-content: flex-end;
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(2px);
    font-family: "Manrope", sans-serif;
}

.box {
    width: 320px;
    background: var(--color-black-0);
}

.header {
    background: var(--color-primary-1, #254b77);
    padding: 21px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.title {
    color: var(--color-black-0);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: 0.32px;  
    align-items: center;
    display: flex;  
}
.mobileWrapper {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    max-height: calc(100dvh - 170px);
}
.body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px;
}

.filterField {
    &__montPicker {
        .react-datepicker-wrapper {
            width: 100%;
        }
    }

    &__monthRangePicker {
        align-items: stretch;
        border: 1px solid var(--color-black-40);
        border-radius: 7px;
        display: flex;
        transition: .3s;
        &:hover {
            box-shadow: 0 0 0 3px #b8d4ff;
        }

        .react-datepicker-wrapper, .react-datepicker__input-container {
            height: 100%;
        }

        input {
            border: none;
            border-radius: 7px;
            width: 100%;
            display: flex;
            padding: 15px 12px;
        }
    }
}

.btnBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 0 14px 45px 14px;
}

.datePikerContainer {
    position: relative;

    .calendarIcon {
        color: var(--color-black-80);
        position: absolute;
        right: 11px;
        top: 17px;
        height: 16px;
        width: 16px;
        z-index: 99;
    }
}
.btnFix {
    margin-top: 20px;
}