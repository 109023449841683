.container {
  border: 1px solid var(--color-black-40);
  border-radius: 6px;
  background: var(--color-black-0);
}
.open {
  border: 1px solid var(--color-primary-3);
}
.closed {
  border: 1px solid var(--color-black-40);
  @media (min-width: 1440px) {
    min-height: 110px;
  }
  @media (max-width: 1024px) {
    padding-top: 5px;
    padding-bottom: 5px;

    .header, .questionName {
      margin-bottom: 5px;
    }
  }
}

.header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 10px;

  @media (min-width: 768px) {
    align-items: center;
    padding: 10px 20px;
  }
}

.clickZone {
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 1em;

  @media (min-width: 768px) {
    gap: 10px;
  }

  .testInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
    white-space: nowrap;

    color: var(--color-primary-1);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.28px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .delimiter {
      display: none;
      background: var(--color-primary-1);
      height: 20px;
      width: 1px;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .pos {
    color: var(--color-primary-1);
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.48px;
    text-align: left;

    @media (min-width: 768px) {
      text-align: center;
    }
  }

  .headerTitle {
    font-weight: 600;
  }
}

.actionMenu {
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 999;
}

.params {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: .5em;
}

.slide {
  align-items: flex-end;
  display: flex;
  height: 0;
  overflow: hidden;
  transition: .3s;
}

.questionName {
  border-top: 1px solid var(--color-black-40);
  padding: 10px 10px;
  color: var(--color-primary-1);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.32px;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    padding: 10px 20px;
  }
}

.body {
  border-top: 1px solid var(--color-black-40);
  padding: 10px 20px;
  width: 100%;
}

.questionBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 320px 1fr;
  }
}

.mediaBox {
  img {
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
}

.question {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.55;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.answer {
  font-weight: 400;
  font-size: 18px;
  color: var(--color-primary-1);
  line-height: 30px;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.missingWordsAnswer {
  font-weight: 400;
  font-size: 18px;
  color: var(--color-primary-1);
  line-height: 30px;
}
.questionCol {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.variants {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.scoreBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 6px;
  border: 1px dashed var(--color-warning-1);
  background: var(--color-warning-4);
  color: var(--color-warning-1);
  font-size: 18px;
  font-weight: 800;
  line-height: 17px;
  letter-spacing: 0.36px;
  height: fit-content;
  word-break: initial;

  svg {
    width: 17px;
    height: 17px;
  }

  path {
    fill: var(--color-warning-1);
  }
}

.answerTitle {
  color: var(--color-primary-1);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.36px;
  span {
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    font-weight: 700;
  }
}

.separatedWordBlock {
  display: flex;
  align-items: center;
  color: var(--color-black-100);
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.54px;
  word-break: break-all;
  white-space: pre-wrap;

  .verticalSeparator {
    display: block;
    margin: 0 15px;
    width: 1px;
    height: 18px;
    background: var(--color-black-40);
    color: var(--color-black-40);
  }
}

.separatedWord {
  display: flex;
}

.essayInfo {
  display: flex;
  gap: 12px;
  padding: 14px 21px;
  border: dashed 1px var(--color-warning-1);
  border-radius: 7px;
  background: var(--color-warning-4);

  .icon {
    color: var(--color-warning-1);
  }

  .infoBlock {
    color: var(--color-black-100);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .strong {
      font-weight: 700;
      padding: 25px 0;
    }
  }
}

.minimumWords {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  color: var(--color-primary-1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  span {
    font-weight: 800;
  }
}

.sentenceBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-black-100);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.36px;

  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 34px;
    min-height: 34px;
    border-radius: 6px;
    border: 2px solid var(--color-success-1);
    background: var(--color-success-4);
  }

  .sentence {
    width: 100%;
    border-radius: 6px;
    padding: 3px 10px;
    border: 1px solid var(--color-black-60);
    background: var(--color-black-0);
    word-break: break-word;
  }
}

.mappedSentences {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mappedImages {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 10px), 1fr));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.333% - 10px), 1fr));
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 10px), 1fr));
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - 10px), 1fr));
  }
}

.imageBlock {
  img {
    width: 100%;
    height: max-content;
    padding: 20px;
    background: var(--color-success-4);
    border: 2px solid var(--color-success-1);
    border-radius: 6px;
  }
}

.imageBlockGrey {
  img {
    width: 100%;
    height: max-content;
    padding: 20px;
    background: var( --color-black-10);
    border: 2px solid var(--color-black-20);
    border-radius: 6px;
  }
}

.removeMarginTop {
  div {
    margin-top: 0;
    z-index: auto;
  }
}

.description {
  color: var(--color-primary-1);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.36px;
}

.imageContainer {
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  .imgWord {
    width: 100%;
    margin-top: 10px;
    border-radius: 6px;
    border: 2px solid var(--color-success-1);
    background: var(--color-success-4);
    color: var(--color-black-100);
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.36px;
    word-break: break-all;
  }
}

.picture {
  max-height: 120px;
  border-radius: 6px;

  @media (min-width: 321px) {
    max-height: 300px;
  }
}
.divForImage {
  display: flex;
  justify-content: center;
}

.mappedMatchWordsImages {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  align-items: flex-end;

  @media (min-width: 768px) {
    grid-template-columns: 1fr;
    gap: 44px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }
  @media (min-width: 1920px) {
    gap: 20px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.answersBlock {
  padding: 10px;
  border-radius: 6px;
  background: var(--color-success-4);
  border: 2px solid var(--color-success-1);;

  .answer {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-black-100);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.54px;
    word-break: break-word;

    svg {
      border-radius: 4px;
      background: var(--color-success-1);
      min-height: 8px;
      min-width: 13px;
      height: 16px;
      width: 16px;
      path {
        min-height: 8px;
        min-width: 13px;
        fill: var(--color-black-0);
      }
    }
  }
}

.mappedCorrectAnswers{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.videoContainer {
  max-width: 637px;
  margin: 0 auto;

  h4 {
    display: none;
  }
}

.imageMatchingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 677px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--color-success-1);
  background: var(--color-success-4);

  .separator {
    min-width: 35px;
    height: 2px;
    background: var(--color-success-1);

    @media (min-width: 768px) {
      min-width: 40px;
    }

    @media (min-width: 1024px) {
      min-width: 57px;
    }
  }

  img {
    width: 100%;
    overflow: auto;

    @media (min-width: 1024px) {
      max-width: 380px;
      max-height: 300px;
    }
  }
}

.mappedImageMatchingAnswers, .mappedValueMatchingAnswers {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.valueMatchingContainer {
  display: flex;
  align-items: stretch;

  .valueBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    padding: 3px 7px 3px 7px;
    border-radius: 6px;
    border: 2px solid var(--color-success-1);
    background: var(--color-success-4);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    word-break: break-word;
    flex-grow: 1;
    text-align: center;

    @media (min-width: 768px) {
      width: 265px;
    }
    @media (min-width: 1024px) {
      width: 300px;
    }
  }

  .valueSeparator {
    align-self: center;
    min-width: 25px;
    height: 2px;
    background: var(--color-success-1);

    @media (min-width: 768px) {
      min-width: 40px;
    }

    @media (min-width: 1024px) {
      min-width: 57px;
    }
  }
}
