.wordCompare {
    padding-top: 10px;
    &_row {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 10px;
    }

    & .error {
        border: 1px solid var(--color-danger-1);
        color:var(--color-danger-1);
        box-shadow: 0px 0px 0px 2px rgba(173, 0, 0, 0.25);
    }
}


