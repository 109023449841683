.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__margin {
    margin-bottom: 20px;
  }
}

.inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__grid {
    display: grid;
    grid-template-columns: auto 1fr;
    row-gap: 5px;

    .custom_checkbox {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }
    .label_text {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
    .description {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
}

.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  &:focus-visible + .custom_checkbox {
    box-shadow: 0 0 0 2px white, 0 0 0 4px var(--color-accent);
  }
}

.checkbox:checked + .custom_checkbox {
  background-color: var(--color-accent);

  &::before {
    margin-left: 7px;
  }
}

.checkbox:disabled + .custom_checkbox,
.checkbox:disabled ~ .description {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox:disabled ~ .label_text {
  cursor: default;
}

.custom_checkbox {
  height: 18px;
  width: 26px;
  padding: 0 1px;
  margin-right: 10px;
  display: block;
  border: 1px solid var(--color-outline);
  border-radius: 9px;
  background-color: rgba(189, 189, 189, 0.3);
  transition: background-color 0.2s;
  cursor: pointer;

    &::before {
      content: '';
      display: block;
      position: relative;
      width: 14px;
      height: 14px;
      top: 1px;
      border-radius: 50%;
      box-shadow: 1px 0 1px 0.5px rgba(0, 0, 0, 0.12), 2px 4px 6px rgba(0, 0, 0, 0.2);
      background-color: white;
      transition: margin-left 0.2s;
    }
}

.label_text {
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text);
  cursor: pointer;
  user-select: none;
}

.description {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-text);
  cursor: pointer;
}

.info {
  display: flex;
  justify-content: flex-start;

  svg {
    width: 20px;
    height: 20px;
  }

  & > svg {
    margin-left: 20px;
  }
}
