.month {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  border-bottom: 1px solid #e2e2e2;
  text-align: center;
  padding: 15px;
  color: #fff;
  font-weight: 600;
  background-color: #3f4d63;
}
.weekdays, .days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.weekdays {
  border-bottom: 1px solid #e2e2e2;

  div {
    padding: 10px 0;
    text-align: center;
    text-transform: lowercase;
  }
}
