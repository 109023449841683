// Учителя
.teachers {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px;
  overflow-y: auto;
}
.showAll {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    border: 1px solid var(--color-black-60);
    color: var(--color-black-60);
    border-radius: 7px;
    max-width: 353px;
    width: 100%;

    &:hover {
      border: 1px solid var(--color-primary-3);
      color: var(--color-primary-3);
      background: none;
    }
  }
}
