.birthdaySelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  .birthdaySelect__item {
    height: 48px;
    &:nth-child(1) {
      min-width: 85px;
    }
    &:nth-child(2) {
      width: 100%;
    }
    &:nth-child(3) {
      min-width: 85px;
    }
  }
  & .selectCustom__value-container {
    padding: 0 8px;
  }
  &__selectCustom {
    .selectCustom {
      &--error {
        .selectCustom__control {
          box-shadow: 0 0 0 2px rgba(173, 0, 0, 0.25);
          border: 1px solid var(--color-danger-1);

          &--is-focused {
            box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
            border: 1px solid var(--color-primary-2);
          }
        }
      }
      &__control {
        border-radius: 7px;
        transition: none;
        &:hover {
          box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
          border: 1px solid var(--color-black-40);
        }
        &--is-focused {
          box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
          border: 1px solid var(--color-primary-2);
        }
      }
    }
  }
}
