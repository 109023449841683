.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.label {
  color: var(--color-text);
  font-size: 14px;
  font-weight: 600;
}
.variants {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.variant {
  align-items: center;
  display: flex;
  gap: 15px;
}
.cell {
  flex-grow: 1;
}
