.box {

}

.content {
  height: 0;
  overflow: hidden;
  transition: all .5s ease-out;
}

.content.open {
  height: var(--height);
}

.icon {
  color: var(--color-black-80);
  height: 24px;
  transform: rotateX(0deg);
  transition: .3s;
  width: 24px;
  &.open {
    color: var(--color-primary-1);
    transform: rotateX(180deg);
  }
}

.title {
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0 24px;
  width: 300px;
}
