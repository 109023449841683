.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.title {
  color: var(--color-black-100);
  font-weight: 500;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
  }
}
