.container {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 1px solid var(--color-black-60);
  color: var(--color-black-100);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding: 20px 0 0;
    border: none;
  }

  .date {
    text-align: right;
    color: var(--color-black-80);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.24px;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 5px;

    b {
      color: var(--color-primary-2);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.28px;
      text-decoration-line: underline;
    }
  }
}