.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.box {
  border: 1px dashed var(--color-black-60);
  border-radius: 6px;
  padding: 16px;
  background: var(--color-black-20);
  position: relative;
}

.remove {
  position: absolute;
  right: 10px;
  top: 10px;
}

.error {
  border: 1px solid var(--color-danger-1);
}