.wrapper {
  padding: 0 0 15px;
}

.selectors_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calendar_wrapper,
.selectors_wrapper {
  padding: 0 15px;
}

.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}