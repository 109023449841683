.del {
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: var(--color-primary-3);
  color: var(--color-black-0);
  align-items: center;
  .svg {
    width: 17px;
    height: 17px;
  }
  &[disabled] {
    pointer-events: none;
    visibility: hidden;
  }
}
