.title {
    text-align: center;
    color: red;
    font-weight: 600;
}

.item {
    width: 25%;
}

.excelButton {
    padding: 10px;
}