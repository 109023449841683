.easyContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.btn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border-radius: 6px;
  border: 1px dashed var(--color-success-1);
  background: var(--color-success-4);
  color: var(--color-success-1);
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.36px;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--color-success-1);
    box-shadow: inset 0 0 0 2px var(--color-success-1);
  }
}

.failed {
  border: 1px dashed var(--color-danger-1);
  background: var(--color-danger-4);
  color: var(--color-danger-1);

  &:hover {
    border: 1px solid var(--color-danger-1);
    box-shadow: inset 0 0 0 2px var(--color-danger-1);
  }
}