.exerciseAttemptsPanel{
  background-color: var(--color-primary-4);
  border: 1px dashed var(--color-primary-2);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.attempt {
  border: 1px solid var(--color-black-60);
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  @media (min-width: 768px) {
    height: 30px;
    width: 30px;
  }
}
.attemptRight, .attemptWrong, .attemptUnknown {
  box-shadow: inset 0 0 0 5px var(--color-primary-4);
}
.attemptRight {
  background-color: var(--color-success-1);
  border-color: var(--color-success-1);
}
.attemptWrong {
  background-color: var(--color-danger-1);
  border-color: var(--color-danger-1);
}
.attemptUnknown {
  background-color: var(--color-warning-1);
  border-color: var(--color-warning-1);
}
.attemptCurrent {
  border: 2px solid var(--color-primary-2);
}
