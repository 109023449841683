.container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--color-black-10);
  border-radius: 6px;
  margin-top: 20px;
  &.correct {
    background-color: var(--color-success-4);
  }
  &.wrong {
    background-color: var(--color-danger-4);
  }
.sentence {
  word-break: break-word;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.36px;
}

.buttons {
  padding: 10px;
  border-radius: 6px;
  border: 1px dashed var(--color-black-60);
  background-color: var(--color-black-20);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
}