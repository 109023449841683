.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  padding: 0 15px;
  background-color: rgba(50, 50, 50, 0.1);
  transition: 0.3s;
}

.wrapper {
  position: relative;
  width: 520px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3);
  opacity: 0;
  margin-top: -100px;
  transition: margin-top 0.3s, opacity 0.3s;
}

.title_wrapper {
  padding-right: 50px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: 20px;
  row-gap: 20px;
}

.close_button {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background-color: white;
  border: none;
  transition: opacity 0.3s;

  &:focus-visible {
    box-shadow: -1px 1px 0 2px white, -1px 1px 0 4px var(--color-accent);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 5px;
    width: 18px;
    height: 3px;
    border-radius: 3px;
    background-color: var(--color-text);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    background-color: white;
    opacity: 0.7;
  }
}

.shown {
  opacity: 1;
  margin-top: 0;
}
