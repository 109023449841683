.actionMenu {
  position: relative;
}
.button {
  align-items: center;
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 5px;
  color: var(--color-black-40);
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  //transition: all .2s ease;
  width: 30px;
  &Active {
    border-color: var(--color-primary-3);
    color: var(--color-primary-2);

    svg {
      transform: rotate(-90deg);
    }
  }

  svg {
    height: 24px;
    transition: all .3s ease;
    width: 24px;
  }
}
.modal {
  border-radius: 7px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: absolute;
  right: 0;
  transition: width .3s;
  z-index: 5;

  &Close {
    width: 0;
  }

  &Open {
    width: 210px;
  }
}
.menu {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-20);
  border-radius: 7px;
  padding: 20px;
  width: 210px;

  a {
    outline: none !important;
  }

  div, a {
    align-items: center;
    border-radius: 7px;
    color: var(--color-black-100);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 400;
    justify-content: flex-start;
    letter-spacing: 0.36px;
    line-height: 1.5;
    padding: 10px 5px;
    position: relative;
    transition: all .2s;
    &:hover {
      background-color: var(--color-black-20);

      &::after {
        background-color: var(--color-primary-2);
        bottom: 8px;
        position: absolute;
        right: 2px;
        top: 8px;
        width: 2px;
      }
    }

    &::after {
      bottom: 19px;
      content: '';
      display: block;
      transition: .3s;
      top: 19px;
    }
  }
}
