.list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  flex-grow: 1;
}

.label {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text);
}

.options_list {
  display: flex;
  @media (max-width: 579px) {
    flex-direction: column;
  }
}

.options_item {
  display: flex;
  align-items: center;
  margin-right: 30px;

  @media (max-width: 579px) {
    justify-content: space-between;
    margin-bottom: 5px;
  }
}

.options_label {
  display: inline-block;
  margin-right: 15px;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text);
}

.options_value {
  display: inline-block;
  width: 25px;
  height: 20px;
}

.options_value__checked {
  position: relative;

  &::before,
  &::after, {
    content: '';
    position: absolute;
    height: 4px;
    background-color: #0097f6;
    border-radius: 2px;
  }
  &::before {
    left: 4px;
    top: 6px;
    width: 20px;
    transform: rotate(-45deg);
  }
  &::after {
    top: 50%;
    left: 0;
    width: 10px;
    transform: rotate(45deg);
  }
}

.options_value__unchecked {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 4px;
    background-color: red;
    border-radius: 2px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.options_value__color {
  width: 40px;
  border: 1px solid var(--color-text-accent);
  border-radius: 5px;
}
