.content-box{
    background-color: #e0e7ef;
}
.dn{
    display: none;

}

.content-up-second{
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    width:100%;
    border-bottom: 2px solid #e0e0e0;

}

button{
    border:2px solid #3f4d63;
    padding: 11px 20px;
    border-radius: 5px;
    background-color: #fff;
    transition: 0.4s;
}
button:hover{
    background-color: #3f4d63;
    color: #fff;
    transition: 0.4s;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.numbers{
    position: relative;
    background-color: #fff;
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border:1px solid #eeeeee;
    border-radius: 5px;

}
.numbers input[type="number"]{
    border: none;
    
}
.numbers input[type="number"]::-webkit-outer-spin-button,
.numbers input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numbers input[type="number"]:disabled { background: #fff; }
.arr-up{
    position: absolute;
    right: 5px;
    top:3px;
    display: inline-block;
    transform: rotate(180deg); 
    cursor: pointer;
}
.arr-down{
    position: absolute;
    right: 5px;
    top:12px;
    display: inline-block;
    cursor: pointer;
}
.lazy-load-box{
    width:100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.lazy-load-box button{
    padding: 11px 25px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.button_add{
    display: inline-block;
    border:2px solid #3f4d63;
    padding: 11px 20px;
    border-radius: 5px;
    background-color: #fff;
    transition: 0.4s;
    cursor: pointer;
    -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.button_add:hover{
    background-color: #3f4d63;
    color: #fff;
    transition: 0.4s;
}

.disabled {
    background-color: transparent !important;
    border:2px solid #959595 !important;
    color: #959595 !important;
    cursor: default;
}

.sg-probka{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-style: italic;
    color: #bdbdbd;
    width: 100%;
}

.inputfile {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.labelFile {
    font-size: 16px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 60%;
    background-color: #3f4d63;
    color: white;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #3f4d63;
    transition: .3s;
}
.labelFile:hover {
    width: 60%;
    background-color: transparent;
    color: #3f4d63;
    padding: 15px;
    border: 2px solid #3f4d63;
    border-radius: 5px;
}
