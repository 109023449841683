.wrapper {
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    background-color: var(--color-black-0);
    min-width: 254px;
    height: fit-content;
}

.row {
    color: var(--color-black-100);
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.32px;
    padding: 10px;
    border-bottom: 1px solid var(--color-black-40);
}

.show {
    margin-left: 10px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}


.empty_list {
    padding-bottom: 10px;
}
.list {
    padding: 10px 0;
}

.btn {
    display: flex;
    justify-content: center;
}

.noRegisters {
    padding: 10px;
    text-align: center;
    line-height: 18px;
    letter-spacing: 0.28px;
}

