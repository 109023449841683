.box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px;
    border-radius: 5px;
    border: 1px dashed var(--color-black-40);
    background: var(--color-black-20);
    cursor: pointer;

    input[type="file"] {
        display: none;
    }

}
.dragOver {
    border: 1px dashed var(--color-primary-1);
}
.boxError {
    border: 1px solid var(--color-danger-2);
}

.tip {
    color: var(--color-black-80);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;

    h4 {
        color: var(--color-black-100);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.28px;
        margin-bottom: 4px;
    }

    p {
        margin-bottom: 15px;
    }

    b {
        font-weight: 700;
    }

    span {
        text-transform: uppercase;
    }
}

.progress {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;

    progress {
        background-color: #3f4d63;
        border-radius: 5px;
        height: 10px;
        width: 100%;
        &::-webkit-progress-bar {
            background-color: #bdbdbd;
            border-radius: 5px;
        }
        &::-webkit-progress-value {
            background-color: #3f4d63;
            border-radius: 5px;
        }
    }

    div {
        margin-top: 1em;
    }
}

.preview {
    align-self: stretch;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    &:hover {
        .iconBox {
            opacity: 1;
        }
    }

    img {
        width: 100%;
    }
}
.previewAudio {
    align-self: stretch;
    display: flex;
}

.previewFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 45px 27px 50px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    border: 1px solid var(--color-black-60);
    background: var(--color-primary-4);
    &:hover {
        .iconBox {
            opacity: 1;
        }
    }
}

.file {
    position: relative;
    display: flex;
    height: 36px;
    cursor: default;
    width: 28px;
    &Wrapper {
        grid-row: 1 / 4;
    }
    svg {
        width: 100%;
        height: 100%;
    }
    span {
        color: black;
        font-size: 10px;
        font-weight: 700;
        position: absolute;
        top: 18px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.iconBox {
    align-items: center;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    content: "";
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s;
    gap: 20px;
    svg {
        cursor: pointer;
        fill: white;
        height: 16px;
        transition: 0.3s;
        width: 16px;
    }
}

.deleteIcon {
    svg {
        &:hover {
            fill: var(--color-error);
        }
    }
}

.viewIcon {
    svg {
        &:hover {
            fill: var(--color-primary-3);
        }
    }
}
