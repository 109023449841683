.select {
  min-width: 290px;

  @media (min-width: 1024px) {
    width: 350px;
  }
}

.text {
  margin-top: -10px;
  width: 100%;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  background-color: var(--color-accent-600);
}

.company {
  min-width: 290px;
  height: 44px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: var(--color-text);
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: white;

  @media (min-width: 1024px) {
    width: 350px;
  }
}
