.header {
  background: var(--color-primary-1);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr 1fr;
  height: 78px;
  left: 0;
  padding: 10px 20px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 900;
  color: var(--color-black-0);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.42px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, auto) 1fr;
    grid-template-rows: unset;
    height: 60px;
    padding: 5px 20px;
  }
}
.button {
  user-select: none;
  align-items: center;
  color: var(--color-black-0);
  cursor: pointer;
  display: flex;
  gap: 10px;
  height: 100%;
  &Responsive {
    span {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
  }
}
.menu {
  grid-column: 1/4;
  grid-row: 2/3;
  justify-self: center;
  min-width: 210px;

  @media (min-width: 768px) {
    grid-column: unset;
    grid-row: unset;
    justify-self: flex-start;
    padding-right: 20px;
  }
}
.sectionToggle {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 100%;
  @media (min-width: 768px) {
    border-left: 1px solid var(--color-black-60);
    padding: 0 20px;
  }
}
.arrow {
  &Left,
  &Right {
    align-items: center;
    display: flex;
    color: var(--color-black-0);
    cursor: pointer;
  }
  &Left {
    transform: rotate(180deg);
  }
  &Disabled {
    color: var(--color-primary-2);
    pointer-events: none;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 25px;
  @media (max-width: 767px) {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
.home {
  color: var(--color-black-60);
  @media (min-width: 768px) {
    border-left: 1px solid var(--color-black-60);
    padding: 0 20px;
  }
}

.drawing_board {
  color: var(--color-black-60);
  @media (min-width: 768px) {
    border-left: 1px solid var(--color-black-60);
    padding: 0 20px;
  }
}
.exit {
  justify-self: flex-end;
  @media (max-width: 767px) {
    grid-column: 3/4;
    grid-row: 1/2;
  }
}


