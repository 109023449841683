@import "../MainStyles";

.mappedImages {
  display: flex;
  justify-content: start;
  align-self: start;
  gap: 10px;
  flex-wrap: wrap;
}

.imageBlock {
  border-radius:6px;
  img {
    padding: 20px;
    border-radius: 6px;
    max-width: 191px;
    max-height: 286px;
    @media (min-width: 768px){
      border-radius: 25px;
      max-width: 350px;
      max-height: 330px;
    }
    @media (min-width: 1024px) {
      border-radius: 25px;
      width: 480px;
      height: 360px;
    }
  }
}

.answerBody {
  @include answerBodyMain;
  padding: 25px 10px 10px 10px;
}


.answerTitle {
  @include answerTitleMain;
}

.studentColor {
  @include studentColorMain;
}

.correctColor {
  @include correctColorMain;
}

.checkedRightCheckbox {
  border: 2px solid var(--color-success-1);
  background: var(--color-success-4);
}

.checkedWrongCheckbox {
  border: 2px solid var(--color-danger-1);
  background: var(--color-danger-4);
}