.teacherSelector {
  background-color: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
  @media (min-width: 768px){
    padding: 30px;
  }

  &__text {
    color: #5f6368;
    display: block;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
  }

  &__select {
    margin-top: 20px;
  }
}
