.trackerBox {
  border-top: 1px #bdbdbd solid;
  padding: 10px;

  &__header {
    border-bottom: 2px solid #bdbdbd;
    display: none;
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }

    div {
      font-size: 16px;
      font-weight: 600;
      padding: 20px 0;
      text-align: center;
    }
  }

  &__line {
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    color: #4d5055;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  &__date {
    font-size: 16px;
    font-weight: 600;
    padding-left: 10px;
    text-transform: lowercase;
    &--red {
      color: #db3031;
    }
  }

  &__tracks {
    display: flex;
    flex-direction: column;
  }

  &__track {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      line-height: 18px;

      a {
        color: #4d5055;
        font-size: 12px;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    span {
      font-size: 12px;
    }
  }

  &__time {
    font-weight: 600;
    font-size: 14px;
    &--red {
      color: #db3031;
    }
  }

  &__reason {
    align-self: center;
  }

  &__worked {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}