.filter-box {
  background-color: #fff;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  margin-top: 20px;
  margin-bottom: 20px;

  &__header {
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    color: #5f6368;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em 15px;
    user-select: none;
  }

  &__header-title {
    font-weight: bold;
  }

  &__toggle-button {
    cursor: pointer;
    font-size: 10px;
  }

  &__slider {
    display: flex;
    flex-direction: column;
    height: 0;
    justify-content: flex-end;
    overflow: hidden;
    transition: height .3s;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 15px;
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  }

  &__field {
    min-height: 45px;
    width: 100%;
    @media (min-width: 768px) {
      width: calc((100% - 40px)/3);
    }

    &--checkbox {
      align-items: center;
      display: flex;
    }
  }

  &__multi_select {
    width:100%;
    min-width: 250px;
    .id-selector__block{
        width: 47%;
    }
      @media (min-width: 768px) {
        
        width: 66%;
        min-width: 400px;
      }
  }

  select, &__input {
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    color: #5f6368;
    font-size: 14px;
    height: 45px;
    transition: .3s;

    &:hover, &:focus {
      box-shadow: 0 0 0 3px #b8d4ff;
    }

    &:focus {
      border-color: #0062ba;
    }
  }

  select {
    //background: url('../../img/br_down.png') no-repeat right 20px center #fff;
    background: white url('../../img/select-down.svg') center right 10px no-repeat;
    background-size: 8px;
    margin: 0;
    padding-left: 20px;
    padding-top: 5px;
    -webkit-appearance: none;
  }

  input[type="text"] {
    padding: 0 20px;
    width: 100%;
  }
  input[type="number"] {
    padding: 0 20px;
    width: 100%;
  }
  &__buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__apply-button {
    font-family: inherit;
    font-weight: 500;
    line-height: 36px;
    padding: 0 10px;
    @media (min-width: 768px) {
      padding: 0 20px;
    }
  }

  &__reset-button {
    align-items: center;
    color: #3f4d63;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    padding: 0 20px;
    transition: .3s;

    &:hover {
      background-color: #f2f2f2;
      border-radius: 5px;
      color: #5f6368;
    }
  }

  &__month-picker {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  &__month-range-picker {
    align-items: stretch;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    display: flex;
    transition: .3s;
    &:hover {
      box-shadow: 0 0 0 3px #b8d4ff;
    }

    &>div {
      &:nth-child(2) {
        background-color: #bdbdbd;
        color: white;
        flex-shrink: 0;
        line-height: 43px;
        text-align: center;
        width: 43px;
      }
    }

    .react-datepicker-wrapper, .react-datepicker__input-container {
      height: 100%;
    }

    input {
      border: none;
      border-radius: 5px;
      height: 100%;
    }
  }

}