.box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tip {
  color: var(--color-black-100);
  font-size: 12px;
  padding-bottom: 20px;
}
.variants {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.variant {
  align-items: center;
  display: flex;
  gap: 20px;
}

.inputSentenc {
  flex-grow: 1;
  padding-left: 8px;
  height: 48px;
  width: 100%;
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  color: var(--color-black-60);
}
