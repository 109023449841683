.timetable-box {
  background-color: var(--color-black-0);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  display: flex;
  flex-direction: column;

  &__header {
    height: 52px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    cursor: pointer;
    @media (min-width: 768px) {
      padding: 0 20px;
    }

    h3 {
      font-size: 20px;
      color: var(--color-black-100);
      font-weight: 500;
    }

    img {
      width: 20px;
    }
  }
  &__list {
    border-bottom: 1px solid #bdbdbd;
    margin-bottom: 18px;
    transition: .3s;
    @media (min-width: 768px) {
      padding: 0 20px;
    }
  }
}