.weekdays {
  display: none;

  @media (min-width: 768px) {
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: stretch;
    align-items: center;
    border-bottom: 1px solid var(--color-outline);
    border-top: 1px solid var(--color-outline);
  }
}

.weekday {
  width: 14.2857%;
  font-size: 12px;
  color: #a8a8a8;
  text-align: center;

  &:nth-of-type(6),
  &:nth-of-type(7) {
    color: var(--color-error);
  }
}
