.homeTask {
  display: flex;
  padding: 10px 10px;
  justify-content: space-between;

  .listing {
    border-top: none;
  }

  &:not(:first-child){
      border-top: 1px solid var(--color-black-40);
  }

  .box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 20px;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      
      @media (min-width: 768px) {
        gap: 10px;
      }

      a {
        color: var(--color-primary-1);
        font-weight: 700;
        width: 100%;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .date {
    color: var(--color-black-100);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.24px;
  }

  .description {
    color: var(--color-black-80);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    word-break: break-word;
  }

  &:hover {
    background: var(--color-primary-4);
  }
}

.buttons {
  margin-left: 20px;

  .buttonsControl {
    display: none;

    @media (min-width: 768px) {
      display: flex;
      gap: 10px;
    }
  }

  .actionMenu {
    display: flex;

    @media (min-width: 768px) {
      display: none;
    }
  }


  .deleteBasket {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid var(--color-black-40);
    border-radius: 5px;
    transition: all 0.3s ease;

    svg {
      transition: all 0.3s ease;
      color: var(--color-black-60);
    }

    &:hover {
      cursor: pointer;
      border: 1px solid var(--color-primary-3);
      svg {
        color: var(--color-primary-2);
      }

    }
  }
}
