.companyLogo {
  margin: 0 auto;
  width: 170px;
  @media (min-width: 768px) {
    width: 243px;
  }
  img {
    width: 100%;
  }
}
.languagePlaceholder {
  @media (min-width: 1024px) {
    display: none;
  }
}
