.container {
  display: flex;
  flex-direction: column;
  background: var(--color-black-0);
  max-width: 900px;
  width: 100%;
}

.comments {

}

.header {
  width: 100%;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;

  .line {
    width: 100%;
    height: 2px;
    background-color: var(--color-black-60);
  }
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    border: 1px solid var(--color-black-60);
    background: var(--color-black-20);
    padding: 5px 15px;
    color: var(--color-black-100);
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.36px;
  }
}

.leaveComment {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 2px solid var(--color-black-60);
  padding: 10px;


  span {
    color: var(--color-black-100);
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.36px;
  }

  .btnSend {
    color: var(--color-black-0);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.42px;
    border-radius: 7px;
    background: var(--color-primary-3);
    padding: 10px 15px;
    border: none;

    &:hover {
      background: var(--color-primary-2);
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;

  h3 {
      color: var(--color-black-100);
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0.4px;
  }

  span {
    color: var(--color-black-80);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.28px;
  }
}