$screen-tablet-min: 640px;

.shedule-calendar {
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;

  @media (min-width: $screen-tablet-min) {
    width: 100%;
  }
  
  &__dayNumber {
    color: #a8a8a8;
  }
  &_margin {
    margin: 20px 0 0 0;
  }
}

.shedule-calendar-header {
  color: #4d5055;
  font-size: 18px;
  font-weight: 600;
  padding: 35px 20px;
  border-bottom: 1px solid #e0e0e0;
  transition: all 3s ease-in;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  &__slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    img{
      width: 7px;
    }
    div{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #3f4d63;
      height: 35px;
      width: 40px;
    }
    div:hover{
      opacity: 0.6;
      transition: all .2s;
    }
    &--left_but{
      border-radius: 5px 0 0 5px;
      img{
        transform: rotate(180deg);
      }
    }
    &--right_but{
      border-radius: 0 5px 5px 0;
      border-left: none !important;
      content: '&#x25C4;';
    }
  }
}

.shedule-calendar-days-list {
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  width: 100%;

  @media (min-width: $screen-tablet-min) {
    display: flex;
  }

  div {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 12px;
    color: #4d5055;
    width: (100% / 7);

    &:nth-last-child(-n+2) {
      color: #db3031;
    }
  }
}

.shedule-calendar-items-list {
  //width: 100%;
  //box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 700px;

  @media (min-width: $screen-tablet-min) {
    flex-direction: row;
    height: auto;
  }
}

.shedule-calendar-items-list-item {
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  transition: all .3s ease;
  min-height: 100px;
  max-height: 100px;
  //padding-bottom: 20px;
  width: 50%;
  &:hover{
    background-color: #e5f4ff;
  }
  &__slots-length{
    font-weight: 600;
    font-size: 20px !important;
  }
  &__slots-title{
    height: 20px;
    margin-top: -10px;
  }

  @media (min-width: $screen-tablet-min) {
    height: auto;
    max-height: none;
    width: (100% / 7);
  }
  
  &:nth-child(7n), &:nth-child(7n-1){
    .shedule-calendar__dayNumber {
      color: #db3031;
      @media (min-width: $screen-tablet-min) {
        color: #a8a8a8;
      }
    }
  }
}

.scil-active {
  /*border:  2px solid #4d5055;*/
  background-color: rgba(65, 164, 166, 0.2);
  transition: all .5s ease;
}

.shedule-calendar-items-list-item div:nth-child(1) {
  text-align: left;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 14px;
}

.shedule-calendar-items-list-item div {
  text-align: center;
  font-size: 12px;
  padding: 5px 10px;
  color: #4d5055;

}

.shedule-calendar-more {
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
  cursor: pointer;
  @media (min-width: $screen-tablet-min) {
    display: flex;
  }
}

.shedule-calendar-more img {
  width: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.no-pointer {
  cursor: default;
}

.shedule-calendar-items-list-item-count {
  font-weight: 600;
  line-height: 40px;
  font-size: 20px;
}

.slots-count{
  font-size: 12px !important;
  font-weight: 600 !important;
}