.wrapper {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-text);
}
.title {
    position: relative;
    padding-top: 140px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 30px;
    text-align: center;

    &__success {
        color: #00a42a;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(50% - 93px / 2);
        width: 93px;
        height: 109px;
        background-image: url("./phone@2x.png");
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__admin::before {
        background-image: url("./admin.svg");
    }

    &__email::before {
        background-image: url("./email.svg");
    }

    &__simple::before {
        background-image: url("./simple.svg");
    }
}

.text {
    margin-bottom: 10px;
    text-align: center;
}

.phone {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.code_list {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.code_input {
    width: 50px;
    height: 70px;
    font-size: 30px;
    color: var(--color-text);
    text-align: center;
    border: 1px solid var(--color-outline);
    border-radius: 5px;
    transition: border 0.3s, box-shadow 0.3s;

    &:hover {
        box-shadow: 0 0 0 3px #b8d4ff;
    }

    &:focus {
        border: 1px solid #0062ba;
        box-shadow: 0 0 1px 3px #b8d4ff;
    }
}

.error_text {
    margin-top: 10px;
    font-weight: 500;
    color: var(--color-error);
}

.success_text {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.notice {
    max-width: 830px;
    margin-bottom: 5px;
    font-size: 12px;
    text-align: center;
}
