.page {
  display: grid;
  font-family: Manrope, serif;
}
.logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  max-width: 304px;
  &Hidden {
    visibility: hidden;
  }

  img {
    height: 50px;
  }
  span {
    color: var(--color-black-60);
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
  }
}
.books {
  padding-left: 100px;

  h3 {
    color: var(--color-black-100);
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.84px;
  }

  ul {
    line-height: normal;
    padding: 25px 30px;
  }

  a {
    color: var(--color-black-100);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    &:hover {
      text-decoration: underline;
    }

    span {
      color: var(--color-black-60);
    }
  }
}
