.tab {
  min-height: 44px;
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  align-items: stretch;
  border: 1px solid var(--color-outline);
  border-bottom: none;
  cursor: pointer;

  &:first-of-type,
  &:first-of-type .button{
    border-radius: 5px 5px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 5px 5px;
    border-bottom: 1px solid var(--color-outline);
    .button {
      border-radius: 0 0 5px 5px;
    }
  }

  @media (min-width: 768px) {
    flex-basis: auto;
    flex-grow: 1;
    border-right: none;
    border-bottom: 1px solid var(--color-outline);

    &:first-of-type,
    &:first-of-type .button {
      border-radius: 5px 0 0 5px;
    }

    &:last-of-type {
      border-radius: 0 5px 5px 0;
      border-right: 1px solid var(--color-outline);
      .button {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

.button {
  margin: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text);
  border: none;
  border-radius: 0;
  background-color: white;
  opacity: 1;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s;

  &:hover,
  &__active {
    color: white;
    border: none;
    border-radius: 0;
    background-color: var(--color-accent-light);
  }

  &:hover {
    opacity: 0.8;
  }

  &:focus-visible {
    z-index: 1;
    box-shadow: 0 0 0 2px white, 0 0 1px 4px var(--color-accent);
  }
}
