.emptyLessonsBlock {
  font-family: "Manrope", sans-serif;
  display: block;
  text-align: center;
  margin-top: 20px;
  padding-bottom: 20px;

  .text {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: var(--color-black-100);
  }

  .emptyLessonsImg {
    width: 100%;
    max-width: 400px;
  }
}