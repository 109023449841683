.box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: .5em;

  div:first-child {
    font-size: 14px;
    font-weight: 600;
  }
}
.variants {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.variant {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  label {
    margin-left: 0;
  }
}
