.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.gridRow {
    display: flex;
    gap: 20px;
}
.field {
    display: flex;
    flex-direction: column;
    gap: .5em;
}
.label {
    font-weight: 500;
}