.bookPaper {
  background-color: #fbfbfb;
  border-radius: 10px;
  margin: 0 auto;
}
.content {
  max-width: 900px;
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 3px;
  }
  @media (min-width: 1024px) {
    min-width: 685px;
  }
  @media (min-width: 1440px) {
    min-width: 900px;
  }
}
.top, .bottom {
  display: grid;
  grid-template-columns: 0 1fr 0;
  height: 30px;
  @media (min-width: 768px) {
    grid-template-columns: 53px 1fr 53px;
  }
}
.topLeft {
  background: url("./img/top_right.png");
}
.topRight {
  background: url("./img/top_left.png");
}
.topMiddle {
  background: url("./img/top.png");
}
.body {
  display: grid;
  grid-template-columns: 0 1fr 0;
  @media (min-width: 768px) {
    grid-template-columns: 53px 1fr 53px;
  }
}
.left {
  background: url("./img/left.png");
}
.right {
  background: url("./img/right.png");
}
.bottomLeft {
  background: url("./img/bottom_right.png");
}
.bottomRight {
  background: url("./img/bottom_left.png");
}
.bottomMiddle {
  background: url("./img/bottom.png");
}
