.container {
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 20px;
  }
}
.page {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.addChapterButton {
  border: 1px solid var(--color-black-60);
  color: var(--color-black-60);
  font-weight: 700;
  width: 100%;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    border: 1px solid var(--color-primary-2);
    color: var(--color-primary-2);
    background: var(--color-black-0);
    box-shadow: inset 0 0 0 1px var(--color-primary-2);
  }
}

.lessons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 9;
  left: 0;
  top: 0;
}
