.radioList {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    input, label {
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }
    }
  }
}
.radioInput.radioInput {
  display: block;
  height: 20px;
  width: 20px;
  &:hover {
    box-shadow: none;
  }
}
.toggleRadioBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &Label {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
  }
}
.error {
  border: 1px solid var(--color-danger-2);

  &::placeholder {
    color: var(--color-danger-2);
  }
}
