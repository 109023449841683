.info {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  line-height: 1.55;
  padding-bottom: 2em;

  h1 {
    color: white;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 35px;
    }
  }

  button {
    border-color: #3498db;
    color: #3498db;
    &:hover {
      background-color: var(--color-error);
      border-color: var(--color-error);
      color: white;
    }
  }
}
.timer {
  display: grid;
  font-size: 30px;
  font-weight: 600;
  grid-template-columns: repeat(8, 20px);
  text-align: center;
}