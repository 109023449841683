.list {
  width: 100%;
  padding: 0 15px;

  li > div {
    margin: 5px 0;
  }
}

.swal_content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (min-width: 768px) {
    padding: 10px 50px;
  }

  dl {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 14px;

    dt {
      flex-basis: 100%;
      text-align: center;

      @media (min-width: 768px) {
        flex-basis: calc(50% - 20px);
        text-align: left;
      }
    }

    dd {
      padding-bottom: 10px;
      flex-basis: 100%;
      text-align: center;
      border-bottom: 1px solid var(--color-outline);

      @media (min-width: 768px) {
        padding-bottom: 0;
        flex-basis: 50%;
        text-align: right;
        border-bottom: 0;
      }
    }
  }

  .code {
    letter-spacing: 3px;
  }

  .link, .button {
    width: 100%;
    color: var(--color-text);
    font-size: 14px;
    text-align: center;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .button {
    margin-top: -5px;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    border: none;
    background-color: white;

    &:hover {
      background-color: white;
    }
  }
}


