$screen-desktop-min: 1024px;

.slotSelector {

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: $screen-desktop-min) {
      flex-direction: row;
    }
  }

  &__title {
    color: #5f6368;
    font-size: 20px;
    font-weight: 500;
  }

  &__selectTimeZone{
    display: flex;
    min-width: 30%;
  }

  &__error {
    font-weight: 400;
    text-align: center;
    line-height: 20px;
    p{
      margin-top: 20px;
    }
    a{
      color: #3f4d63;
    }
  }

  &__slots {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    justify-content: space-between;
    @media (min-width: $screen-desktop-min) {
      justify-content: flex-start;
    }
  }
}
.w100 {
  width: 100% !important;
}