.order_wrapper {
  margin-bottom: 30px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 14px;
  color: var(--color-text);
}

.list dt {
  flex-basis: 100%;
  @media (min-width: 768px) {
    flex-basis: calc(50% - 20px);
  }
  min-width: 120px;
}

.list dd {
  padding-bottom: 5px;
  flex-basis: 100%;
  text-align: left;
  border-bottom: 1px solid var(--color-outline);
  @media (min-width: 768px) {
    flex-basis: 50%;
    padding-bottom: 0;
    text-align: right;
    border: none;
  }
}

.bold {
  font-weight: 600;
}

.big {
  font-weight: 600;
  font-size: 20px;
}


