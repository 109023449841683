.timeTableTooltip {
    position: absolute;
    background-color: white;
    border: 1px solid var(--color-outline);
    border-radius: 5px;
    width: 170px;
    min-height: 60px;
    padding: 10px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 1;
    opacity: 0;
    transition: opacity .3s;

    &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: var(--leftBefore);
        width: 10px;
        height: 10px;
        border: 1px solid var(--color-outline);
        background-color: white;
        transform: rotate(45deg);
    }

    &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: var(--leftAfter);
        width: 12px;
        height: 10px;
        background-color: white;
    }
}



.tooltip_name {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.tooltip_text {
    font-style: italic;
    font-size: 12px;
    text-align: center;

    & span {
        font-weight: 500;
    }
}

.showTimeTableTooltip {
    composes: timeTableTooltip;
    opacity: 1;
}