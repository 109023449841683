.payService {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 30px;

  &__title {
    color: #5f6368;
    font-size: 20px;
    font-weight: 500;
  }

  &__servicesBox {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__service {
    align-items: center;
    border: 3px solid #c9c9c9;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 140px;
    justify-content: space-between;
    padding: 20px 0;
    transition: .3s;
    width: 190px;
    &:hover{
      border-color: #3f4d63;
    }
    &--selected{
      background-color: #e0e7ef;
      border-color: #3f4d63;
    }
    img{
      width: 150px;
    }

    span{
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
  }
}
