.tip {
  color: var(--color-text);
  font-size: 12px;
  line-height: 1.5;
}

.selectTypeAttention {
  background-color: #e0e7ef;
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  align-items: center;
  display: flex;
  height: 100px;
  line-height: 1.5;
  text-align: center;
}

.makeSentencesBlock {
  background-color: #f6f6f6;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-bottom: 10px;
  align-items: center;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.sentenceBlock {
  display: flex;
  gap: 10px;

  .inputTextBlock {
    width: 100%;
  }
}