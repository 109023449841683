.item {
  width: 715px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;

  @media (max-width: 1023px) {
    width: 100%;
  }
}

.icons {
  width: 60px;
  display: flex;
  column-gap: 20px;
}

.label {
  padding-left: 35px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text);
}
