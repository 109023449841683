.box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;


    .studentInfo {
        background: var(--color-primary-4);
        border:1px dashed var(--color-primary-3);
        border-radius: 5px;
        display: grid;

        .item {
            display: grid;

            &__name {
                color: var(--color-black-80);
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.24px;
            }

            &__value {
                color: var(--color-black-100);
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.28px;
            }

            .educationType {
                font-weight: 600;
                color: var(--color-black-100);
            }
        }

        @media (min-width: 768px) {
            grid-template-columns: 1fr;
        }

        .studyInfo {
            padding: 13px;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .additionalInfo {
            padding: 13px;
            display: grid;
            gap: 10px;
            
            @media (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (min-width: 1024px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width: 1440px) {
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }
}
