.formLabel {
  color: var(--color-black-80);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.32px;
}
.formLabelRequired {
  composes: formLabel;
  &::before {
    content: "* ";
    color: var(--color-danger-1)
  }
}
