.words {
  border: 1px dashed var(--color-warning-1);
  border-radius: 6px;
  background: var(--color-warning-4);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  span {
    border: 1px solid var(--color-black-60);
    border-radius: 6px;
    background: var(--color-black-0);
    display: flex;
    min-height: 30px;
    padding: 6px 10px 6px 6px;
    align-items: center;
    justify-content: center;
    cursor: grab;
    font-size: 18px;
    font-weight: 400;
    word-break: break-word;
    color: var(--color-black-100);
    & .dote {
      height: 100%;
      width: 3px;
      margin-right: 7px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;

      div, &::after, &::before {
        min-width: 3.5px;
        background: var(--color-black-60);
        border-radius: 50%;
        height: 3.5px;
        content: '';
        display: block;
      }
    }
    &.selected {
      border: 1px solid var(--color-black-40);
      background:var(--color-black-10);
      color:var(--color-black-40);
      pointer-events: none;

      & .dote {
        div, &::after, &::before {
          background: var(--color-black-40);
        }
      }
    }
    &.onDrag {
      border: 1px solid var(--color-black-40);
      background: var(--color-black-10);
      color: var(--color-black-40);

      & .dote {
        div, &::after, &::before {
          background: var(--color-black-20);
        }
      }
    }
  }
}
.cols {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.col {
  border: 1px solid var(--color-primary-3);
  border-radius: 6px;
  background: var(--color-primary-4);
  flex: 1 1 48%;
  overflow: hidden;
  transition: .3s;

  * {
    pointer-events: none;
  }

  header {
    background: var(--color-primary-4);
    border-bottom: 1px solid var(--color-primary-3);
    color: #000;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 5px;
    transition: .3s;
    text-align: center;
    line-height: normal;
    letter-spacing: 0.28px;
    word-break: break-word;
  }

  article {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;

    p {
      font-size: 14px;
      font-weight: 700;
      color: var(--color-black-60);
    }
  }

  p {
    color: #5f6368;
    font-size: 14px;
    text-align: center;
  }
}

.dropzone {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1em;
  min-height: 45px;

  span {
    border: 1px solid #c2c5ca;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    min-height: 30px;
    line-height: 30px;
    min-width: 80px;
    padding: 0 1em;
    pointer-events: all;
    text-align: center;
    word-break: break-word;
  }
}

.correct {
    box-shadow: 0 0 0 2px var(--color-success-2);
    border: 1px solid var(--color-success-1);
    background: var(--color-black-0);
}
.mistake {
    box-shadow: 0 0 0 2px var(--color-danger-2);
    border: 1px solid var(--color-danger-1);
    background: var(--color-black-0);
}