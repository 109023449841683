.listRow {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:nth-child(even) {
        background: var(--color-primary-4);
    }

    &__title {
        font-weight: 700;
    }

    &__box {
        display: flex;
        gap: 5px;
        flex-direction: column;
        font-size: 12px;
    }

    &__col {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
        margin-bottom: 5px;
    }
}

.userName {
    color: var(--color-black-60);
}

.listRow:last-child {
    border: none;
}
