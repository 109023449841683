.pageContainer {
  padding: 0 10px 20px;
  @media (min-width: 768px) {
    padding: 0 20px 20px;
  }
}
.booksContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.listContainer {
  border-radius: 7px;
  border: 1px solid var(--color-black-40);
  background: var(--color-black-0);
  padding: 20px 15px;
  @media (min-width: 768px) {
    padding: 20px 10px;
  }
}
.list {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(8, 1fr);
  }
}
