.menu {
    width: 275px;
    min-height: 250px;
    border-radius: 5px;
    box-shadow: -4px 4px 4px 0px #0000000D;
    background: white;
    z-index: 905;
    font-family: 'Manrope', sans-serif;

    &Profile {
        display: flex;
        gap: 13px;
        align-items: center;
        padding: 20px 20px 17px;
        border-bottom: solid 1px var(--color-black-40);

        &__avatar {
            min-width: 50px;
            height: 50px;
        }

        &__detail {
            display: flex;
            flex-direction: column;
            gap: 4px;

            p {
                font-weight: 600;
                font-size: 16px;

                &:nth-child(1) {
                    word-break: break-word;
                    color: #111314;
                }

                &:nth-child(2) {
                    color: var(--color-black-80);
                    font-size: 10px;
                }
            }
        }
    }

    &Nav {
        padding: 20px;
        border-bottom: solid 1px var(--color-black-40);
        &__nothing {
            color: var(--color-black-100);

            &_active {
                color: var(--color-primary-2);
            }
        }

        &__item {
            padding: 7px 17px;
            height: 38px;
            cursor: pointer;
            display: flex;
            align-items: center;
            position: relative;
            justify-content: space-between;


            &:hover {
                border-radius: 7px;
                background: var(--color-black-20);

                & .menuNav__title {
                    color: var(--color-primary-2);
                }

                &::before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 2px;
                    height: 22px;
                    background: var(--color-primary-2);
                    width: 2.5px;
                    content: '';
                    border-radius: 2px;
                }

                .menuNav__box {
                    background: var(--color-black-0);
                }

                .menuNavLanguage__box {
                    background: var(--color-black-0);
                }
            }
        }

        &__title {
            color: var(--color-black-100);
            font-size: 14px;
        }

        &Language {
            position: relative;

            &__menu {
                width: 153px;
                border-radius: 5px;
                box-shadow: -4px 4px 4px 0px #0000000D;
                position: absolute;
                z-index: 905;
                background: var(--color-black-0);
                padding: 20px;
                top: 0;
                right: 46%;

                @media (min-width: 768px) {
                    top: 0;
                    right: calc(100% + 25px);
                }
            }

            &__item {
                height: 38px;
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 0 5px;
                position: relative;

                svg {
                    width: 20px;
                    height: 12px;
                }

                p {
                    color: var(--color-black-100);
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0.24px;
                }

                &:hover {
                    border-radius: 7px;
                    background: var(--color-black-20);

                    p {
                        color: var(--color-primary-2);
                    }

                    &::before {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 2px;
                        height: 22px;
                        background: var(--color-primary-2);
                        width: 2.5px;
                        content: '';
                        border-radius: 2px;
                    }
                }
            }
        }

        & &__boxTimezone {
            width: 105px;
        }
    }

    &Exit {
        display: flex;
        align-items: center;
        padding: 18px 20px 22px;

        p {
            height: 38px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 14px;
            color: #2D3436;
            padding: 0 14px;
            width: 100%;
            position: relative;

            &:hover {
                border-radius: 7px;
                background: var(--color-black-20);
                color: var(--color-primary-2);

                &::before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 2px;
                    height: 22px;
                    background: var(--color-primary-2);
                    width: 2.5px;
                    content: '';
                    border-radius: 2px;
                }
            }
        }
    }
}