.box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  color: var(--color-black-100);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 8px;

  div:first-child {
    font-size: 14px;
    font-weight: 600;
  }
}
.variants {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.variant {
  align-items: center;
  display: flex;
  gap: 10px;

  label {
    margin-left: 0;
  }
}

.inpt {
    width: 100%;
    padding-left: 8px;
    height: 48px;
    border: 1px solid var(--color-black-40);
    border-radius: 7px;
    color: var(--color-black-100);
}
