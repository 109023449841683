.resultSection {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, .35);
  color: #2c3e50;
  display: grid;
  grid-template-rows: 100px 75px;
  font-size: 14px;
  font-weight: 700;
  min-width: 175px;

  header, footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  footer {
    border-top: 1px solid #f2f2f2;
  }
}
.points {
  align-items: center;
  display: flex;

  span {
    &:nth-child(1){
      color: #0099de;
      font-size: 60px;
    }
    &:nth-child(2){
      color: #000;
      font-size: 60px;
      font-weight: 300;
    }
    &:nth-child(3){
      color: #2c3e50;
      font-size: 20px;
    }
  }
}
