.bundleLevels {
  background: #f6f6f6;
  border: solid 1px #b5b5b5;
  border-radius: 5px;
  padding: 20px 20px 0;
  .counterBox {
    max-width: 200px;
    &__button {
      width: 30px;
    }
  }
  &__line {
    margin-bottom: 20px;
    &:nth-last-child(1) {
     margin-top: 20px;
    }
    .super-modal__form-label {
      margin-bottom: 13px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
    color: #73777b;
    margin-bottom: 7px;
  }
  &__scores {
    .bundleLevels__field {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
  &__text {
    font-size: 12px;
    color: #5f6368;
  }
  &__button {
    color: #3f4d63;
    font-weight: 500;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
  }
  &List {
    &__wrapper {
      display: flex;
    justify-content: space-between;
    flex-direction: column;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #b5b5b5;
      padding: 10px 0;
      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
    &__title {
      color: #5f6368;
    }
    &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 20%;
    }
    &__score {
      color: #5f6368;
    }
    &__delete {
      font-size: 20px;
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
