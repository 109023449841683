.vacationInfo {
  display: grid;
  grid-template-rows: 9px 1fr;
  @media (min-width: 768px) {
    max-width: 250px;
  }
}
.header {
  z-index: 1;
  h5 {
    background-color: var(--color-black-0);
    border: 1px solid var(--color-black-40);
    border-radius: 5px;
    color: var(--color-black-80);
    display: block;
    font-size: 10px;
    font-weight: normal;
    height: 18px;
    line-height: 18px;
    letter-spacing: 0.2px;
    margin: 0 auto;
    text-align: center;
    width: max-content;
    padding: 0 5px;
    @media (min-width: 768px) {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.24px;
    }
  }
}
.balanceDays {
  align-items: center;
  background-color: var(--color-success-4);
  border: 1px solid var(--color-success-2);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 85px;

  div {
    display: grid;
    grid-auto-flow: row;

    span {
      text-align: center;
      &:first-child {
        color: var(--color-black-80);
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.2px;
        @media (min-width: 768px) {
          font-size: 12px;
          letter-spacing: 0.24px;
        }
      }
      &:last-child {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.36px;
      }
    }
  }
}
