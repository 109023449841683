.galeryContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .block {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid var(--color-black-60);
    border-radius: 6px;
    cursor: pointer;
    padding: 20px 30px;
    min-height: 186px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: var(--color-primary-2);
    transition: .3s ease;

    &:hover {
      background-color: var(--color-primary-4);
    }

    &.selected {
      background-color: var(--color-success-4);
      border: 2px solid var(--color-success-1);
    }

    p {
      text-align: center;
    }
  }
}

