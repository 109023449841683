.header {
  align-items: center;
  display: flex;
  gap: 20px;
  background: var(--color-warning-4);
  border: 1px solid var(--color-warning-1);
  border-left: none;
  border-radius: 0 7px 7px 0;
  margin-top: 10px;
  width: fit-content;
  padding: 0 13px 0 10px;

  h3 {
    color: black;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.4px;
    word-break: break-word;
    width: 100%;
  }

  p {
    color: var(--color-warning-1);
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0.48px;
  }
}