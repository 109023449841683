.submitBlock {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__btnWrap {
    margin: 1em 0;

    button {
      font-family: inherit;
      font-weight: 500;
      width: 320px;
    }
  }

  &__info {
    color: #5f6368;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;

    &--fz14 {
      font-size: 14px;
    }
  }

  textarea{
    min-width: 320px;
    resize: none;
    margin-top: 20px !important;
    padding-top: 20px !important;
  }
}
