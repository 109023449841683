.paper {
  padding: 20px 0;
}

.title_wrapper {
  padding: 0 20px;
  transition: padding 0.4s linear;

  &__border {
    padding-bottom: 20px;
  }
}

.wrapper {
  transition: height 0.4s ease-in-out;
}

.inner {
  padding: 20px 20px 0;
  border-top: 1px solid var(--color-outline);
}
