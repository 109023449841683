.writeWordsBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  background: var(--color-black-10);
  border-radius: 6px;
  border: 1px dashed var(--color-black-60);

}

.wordsInput {
  max-width: 270px;
  min-width: 150px;
  overflow-y: hidden;
  color: var(--color-black-100);
  border: 1px solid var(--color-black-40);
  border-radius: 7px;
  height: 36px;
  line-height: 30px;
  vertical-align: middle;
  width: 150px;
  padding: 7px 23px;
  text-align: left;
  display: inline-block;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
    cursor: default;
  }

  &:focus-within {
    border: 1px solid var(--color-primary-2);
    box-shadow: 0 0 0 2px rgba(85, 177, 243, 0.6);
  }

  &::placeholder {
    font-size: 16px;
    color: var(--color-black-60);
    text-align: center;
  }
}

.writeText {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  margin-left: 10px;
  background: var(--color-black-0);
  color: var(--color-black-80);
  padding: 0;
  border-radius: 5px;
  border: 1px solid var(--color-black-40);
  background: var(--color-black-0);

  svg {
    color: var(--color-black-60);
  }

  &:hover {
    background: var(--color-black-0);
    border: 1px solid var(--color-primary-3);

    svg {
      color: var(--color-primary-2);
    }
  }

  &:hover {
    background: var(--color-black-0);
    color: var(--color-black-60);
  }

  &:disabled {
    color: var(--color-black-60);
  }
}

.correct {
  box-shadow: 0px 0px 0px 2px #1abc9799;
  border: 1px solid var(--color-success-1);
  background: var(--color-black-0);

}
.mistake {
  box-shadow: 0px 0px 0px 2px rgba(85, 177, 243, 0.60);
  border: 1px solid  var(--color-primary-2);
  background: var(--color-black-0);
}
