.list {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nothing {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-size: 20px;
  text-align: center;
}

.card {
  position: relative;
  min-height: 140px;
  padding: 0;
}

.head {
  padding: 10px 45px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid var(--color-outline);

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
}

.placementTests {
  height: 0;
  overflow: hidden;
  transition: .7s;
  &__active {
    border-top: 1px solid var(--color-outline);
    height: auto;
    transition: .7s;
  }
  &__many {
    border-top: 1px solid var(--color-outline);
    height: 450px;
    overflow-y: scroll;
    transition: .7s;
  }
  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 1.2;
    p {
      &:nth-child(1) {
        font-weight: 500;
      }
    }
  }
  &__bottom {
    border-top: 1px solid var(--color-outline);
    padding-top: 10px;
    margin-top: 10px;
    p {
      &:nth-child(2) {
        margin-top: 5px;
      }
    }
  }
  li {
    margin-bottom: 25px;
    border: 2px dotted var(--color-outline);
    padding: 10px;
    margin: 20px;
  }
}

.name_wrapper {
  flex-basis: 100%;

  @media (min-width: 1024px) {
    flex-basis: calc(100% / 2.5);
  }

  span {
    display: block;
  }
}

.name,
.level_name {
  color: black;
  font-weight: 500;
}

.name {
  margin-bottom: 7px;
}

.level_missed {
  padding-top: 2px;
}

.slot_time {
  color: black;
  font-weight: 700;
}

.phone_wrapper {
  @media (min-width: 1024px) {
    flex-grow: 1;
    text-align: right;
  }

  a.phone {
    color: var(--color-text);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.info {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}

.info_item {
  min-width: 240px;
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  color: var(--color-text);

  &__comment {
    flex-grow: 1;
    width: 100%;

    .info_text {
      word-wrap: break-word;
    }
  }
}

.info_text {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 7px;

  a.employee_name {
    display: block;
    margin-bottom: 7px;
    font-weight: 500;
    text-decoration: underline;
    color: black;
    &:hover {
      text-decoration: none;
    }
  }
}

.info_comment {
  word-break: break-word;
  margin-left: 15px;
  max-width: calc(100% - 30px);
}

.action_menu {
  position: absolute;
  top: 10px;
  right: 10px;
}
