.days {
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(auto-fill, 55px);
  justify-content: center;
  padding: 4px;
}
.day {
  align-items: center;
  border: 1px solid #919396;
  color: #5f6368;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 28px;
  justify-content: center;

  &__teacher, &__classroom, &__holiday {
    border: 1px solid black;
    color: white;
  }

  // Выходной
  &__holiday{
    background-color: #626262;
  }

  &__teacher {
    background-color: #ff0000;
  }

  &__classroom {
    background-color: #e0be00;
  }

  &__teacher#{&}__classroom {
    background: linear-gradient(to top left, #ff0000 0, #ff0000 49%, black 49%, black 51%, #e0be00 51%, #e0be00);
  }
}

