.section {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: 500;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    &:nth-child(odd) {
        background: var(--color-primary-4);
    }
}

.chapterName {
    font-weight: 700;
}

.rightSide {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.btns {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
}

.btn {
    cursor: pointer;
    color: var(--color-primary-1);
    font-weight: 700;
}

.withBackground {
    background: var(--color-primary-4);
}

.info {
    text-align: right;
}

.userName {
    color: var(--color-black-60);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.24px;
}