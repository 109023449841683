.variant {
  align-items: center;
  display: grid;
  font-size: 12px;
  gap: 10px;
  grid-template-columns: 20px 1fr;
  @media (min-width: 768px) {
    gap: 20px;
  }
}
.icon {
  svg{
    height: 20px;
    width: 20px;
  }
}
.valueWords {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: max-content 1em max-content;
}

.valueImages {
  composes: valueWords;
  grid-template-columns: minmax(auto, 90px) 1em minmax(auto, 90px);
  img {
    width: 100%;
  }
}
.valueImage {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 90px;
  img {
    width: 100%;
  }
}

