.slot-result{
  align-items: flex-start;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }

  img{
    width: 20px;
  }

  &__teacher{
    align-items: flex-start;
    min-width: 30%;
    display: flex;
    flex-direction: row;

    div{
      display: flex;
      flex-direction: column;
      line-height: 20px;
      padding-left: 15px;
    }
  }

  & &__teacherLink {
    color: #000;
    font-weight: 500;
    text-decoration: underline;
  }

  a:hover, a:active {
    text-decoration: none;
  }

  &__level{
    align-items: flex-start;
    min-width: 30%;
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  &__level-list{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
  }

  &__comment{
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    line-height: 1.5;
    margin-top: 1em;
    min-width: 40%;
    @media (min-width: 768px) {
      margin-top: 0;
    }

    span{
      display: flex;
      word-wrap: break-word;
      max-width: 300px;
      margin-left: 15px;
    }
  }
}