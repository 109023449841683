.questionImagesList {
    margin: 0 auto;

    li {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        padding: 10px 0;
        border-bottom: 1px solid var(--color-black-20);
        color: var(--color-black-80);

      &:last-child {
          word-break: break-word;
          border-bottom: 0;
          padding: 10px 0 0 0 ;
        }

        .fullImageIcon {
            border-radius: 7px;
            padding: 7px;
            background: var(--color-primary-2);
        }

        div:last-child {
            align-self: flex-end;
            word-break: break-word;
        }

        img {
            display: block;
            width: 100%;
        }
    }
}
